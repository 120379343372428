import { getStore } from "./store";
import { selectWidgetData } from "./widgetsSlice";
//Methods for tracking webui state changes outside components (based on Promises)
//Current use case: switchContainer action to wait for target widget to load before triggering follow up actions
export const widgetLoaded = (widgetId: string): Promise<void> =>
    new Promise((resolve) => {
        const unsubscribe = getStore().subscribe(() => {
            if (selectWidgetData(getStore().getState(), widgetId)) {
                resolve();
                unsubscribe();
            }
        });
    });
