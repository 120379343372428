//TODO This method is coupled with BE contract for relative entity paths

import { getStore } from "../store/store";
import { getEntityStorePath } from "../store/widgetsSlice";

//To be moved to project folder
const CURRENT_ENTITY_PATH = "{currentEntity}";
const PARENT_PATH = "{parent}";
export const parseEntityPath = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath?: string;
}): { widgetId: string; storeEntityPath: string | undefined; parsedEntityPath: string } => {
    let targetWidgetId = widgetId;
    let parsedEntityPath = entityPath;
    const tokens = entityPath.split("::");
    if (tokens.length === 2) {
        targetWidgetId = tokens[0];
        parsedEntityPath = tokens[1];
    }
    parsedEntityPath = replaceAllReservedWord({
        widgetId: targetWidgetId,
        entityPath: parsedEntityPath,
        currentEntityPath
    });
    const storeEntityPath = getEntityStorePath(
        getStore().getState(),
        targetWidgetId,
        parsedEntityPath
    );
    return { widgetId: targetWidgetId, storeEntityPath, parsedEntityPath };
};

const replaceAllReservedWord = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath: string;
}): string => {
    let result = entityPath;
    if (result.includes(CURRENT_ENTITY_PATH)) {
        try {
            result = replaceCurrentEntityPath({
                widgetId,
                entityPath,
                currentEntityPath
            });
        } catch (error) {
            console.error(error);
            return replaceReservedWord({
                entityPath: result,
                reservedWord: CURRENT_ENTITY_PATH,
                replacementPath: "",
                widgetId,
                currentEntityPath
            });
        }
    }
    if (result.includes(PARENT_PATH)) {
        try {
            result = replaceParentPath({
                widgetId,
                entityPath,
                currentEntityPath
            });
        } catch (error) {
            console.error(error);
            return replaceReservedWord({
                entityPath: result,
                reservedWord: PARENT_PATH,
                replacementPath: "",
                widgetId,
                currentEntityPath
            });
        }
    }
    return result;
};

const replaceReservedWord = ({
    widgetId,
    entityPath,
    currentEntityPath,
    reservedWord,
    replacementPath
}: {
    reservedWord: string;
    replacementPath: string;
    entityPath: string;
    widgetId: string;
    currentEntityPath: string;
}) => {
    if (entityPath === reservedWord) {
        return replacementPath;
    }
    //in case reserved word should be removed
    if (replacementPath === "") {
        return entityPath.replace(reservedWord + ".", replacementPath);
    }
    const result = entityPath.replace(reservedWord, replacementPath);
    return replaceAllReservedWord({ widgetId, entityPath: result, currentEntityPath });
};

const replaceCurrentEntityPath = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath?: string;
}): string => {
    if (!currentEntityPath) {
        throw new Error("Missing current entity path.");
    }
    const currentEntityStorePath = getEntityStorePath(
        getStore().getState(),
        widgetId,
        currentEntityPath
    );
    if (!currentEntityStorePath) {
        throw new Error("Can not find current entity path.");
    }
    return replaceReservedWord({
        entityPath,
        reservedWord: CURRENT_ENTITY_PATH,
        replacementPath: currentEntityStorePath,
        widgetId,
        currentEntityPath
    });
};

const replaceParentPath = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath?: string;
}): string => {
    if (!currentEntityPath) {
        throw new Error("Missing current entity path.");
    }
    let prefix = currentEntityPath;
    let pathToReplace = PARENT_PATH;
    if (entityPath.indexOf(PARENT_PATH) !== 0) {
        prefix = entityPath.substring(0, entityPath.indexOf(PARENT_PATH));
        pathToReplace = prefix + PARENT_PATH;
        if (prefix.endsWith(".")) {
            prefix = prefix.substring(0, prefix.length - 1);
        }
    }
    const parentEntityPath = getParentPath(prefix);
    const currentEntityStorePath = getEntityStorePath(
        getStore().getState(),
        widgetId,
        parentEntityPath
    );
    if (!currentEntityStorePath) {
        throw new Error("Can not find current entity path.");
    }
    return replaceReservedWord({
        entityPath,
        reservedWord: pathToReplace,
        replacementPath: currentEntityStorePath,
        widgetId,
        currentEntityPath
    });
};

export function getParentPath(path, count = 1) {
    let result = path;
    for (let i = 0; i < count; ++i) {
        result = result.replace(/\.[^.]*$/g, "");
    }
    return result;
}
