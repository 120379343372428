import { Action, ActionContext, Entity } from "@uneo/platform-commons/webui/types";
import {
    getEntity,
    updateEntityProps,
    updateEntityUiState
} from "@uneo/platform-commons/webui/store/actions";
import { manageButtonState } from "../widgets/quote/oav-quote-questionnaire/checkRequiredFields";

export const toggleQuestion = async (action: Action, context: ActionContext) => {
    const currentEntity: Entity | false = getEntity({
        widgetId: context.widgetId,
        entityPath: context?.currentEntityPath || context.widgetId,
        currentEntityPath: context?.currentEntityPath
    });
    const currentUiState: Record<string, any> = (currentEntity && currentEntity.uiState) || {};
    if (currentUiState && currentUiState.visible === action.properties?.visible) {
        return;
    }
    updateEntityUiState({
        widgetId: context.widgetId,
        entityPath: context.currentEntityPath,
        uiState: action.properties
    });
    //if the entity is visible it should be validated
    //and vice versa
    const shouldValidate: boolean = action.properties?.visible;
    await updateEntityProps({
        widgetId: context.widgetId,
        entityPath: context.currentEntityPath,
        props: {
            validate: shouldValidate,
            hiddenQuestion: !shouldValidate
        }
    });
    manageButtonState(context.widgetId);
};
