"use client";
import React, { lazy } from "react";

const MemberDashboard = lazy(
    () => import("./widgets/member-dashboard/MemberDashboard")
) as React.FC;
const MemberContactPreferences = lazy(
    () => import("./widgets/member-contact-preferences/MemberContactPreferences")
) as React.FC;
const MemberContractsView = lazy(
    () => import("./widgets/member-contracts-view/MemberContractsView")
) as React.FC;
const MemberBeneficiariesView = lazy(
    () => import("./widgets/member-beneficiaries-view/MemberBeneficiariesView")
) as React.FC;
const MemberDocuments = lazy(
    () => import("./widgets/member-my-documents/MemberDocuments")
) as React.FC;
const MemberRequestSocialAid = lazy(
    () => import("./widgets/member-request-social-aid/MemberRequestSocialAid")
) as React.FC;
const MemberMagazines = lazy(
    () => import("./widgets/member-magazines/MemberMagazines")
) as React.FC;
const MemberRequestForms = lazy(
    () => import("./widgets/member-request-forms/MemberRequestForms")
) as React.FC;
const MemberStatusAndNotices = lazy(
    () => import("./widgets/member-status-and-notices/MemberStatusAndNotices")
) as React.FC;
const MemberTPCard = lazy(() => import("./widgets/member-tp-card/MemberTPCard")) as React.FC;
const MemberProcessStatus = lazy(
    () => import("./widgets/member-process-status/MemberProcessStatus")
) as React.FC;
const MemberPayMyDues = lazy(
    () => import("./widgets/member-pay-my-dues/MemberPayMyDues")
) as React.FC;
const MemberPayMyDuesStatus = lazy(
    () => import("./widgets/member-pay-my-dues-status/MemberPayMyDuesStatus")
) as React.FC;
const MemberMySocialAid = lazy(
    () => import("./widgets/member-my-social-aid/MemberMySocialAid")
) as React.FC;
const MemberPremiumsView = lazy(
    () => import("./widgets/member-premiums-view/MemberPremiumsView")
) as React.FC;
const MemberFindPractitioner = lazy(
    () => import("./widgets/member-find-a-practitioner/MemberFindPractitioner")
) as React.FC;
const MemberRequestHospitalRefundSimple = lazy(
    () =>
        import("./widgets/member-hospital-refund-simple-request/MemberRequestHospitalRefundSimple")
) as React.FC;
const MemberContactUs = lazy(
    () => import("./widgets/member-contact-us/MemberContactUs")
) as React.FC;
const MemberAttestations = lazy(
    () => import("./widgets/member-attestations/MemberAttestations")
) as React.FC;
const MemberFollowUpRequests = lazy(
    () => import("./widgets/member-follow-up-requests/MemberFollowUpRequests")
) as React.FC;
const MemberRequestHealthQuote = lazy(
    () => import("./widgets/member-request-health-quote/MemberRequestHealthQuote")
) as React.FC;
const MemberRequestReimbursement = lazy(
    () => import("./widgets/member-request-reimbursement/MemberRequestReimbursement")
) as React.FC;
const MemberMyReimbursements = lazy(
    () => import("./widgets/member-my-reimbursements/MemberMyReimbursements")
) as React.FC;
const MemberRequestConfirmation = lazy(
    () => import("./widgets/member-request-confirmation/MemberRequestConfirmation")
) as React.FC;
const MemberModifyPaymentFrequency = lazy(
    () => import("./widgets/member-modify-payment-frequency/MemberModifyPaymentFrequency")
) as React.FC;
const MemberPersonalDetails = lazy(
    () => import("./widgets/member-personal-details/MemberPersonalDetails")
) as React.FC;
const MemberConnectionDetails = lazy(
    () => import("./widgets/member-connection-details/MemberConnectionDetails")
) as React.FC;
const MemberEditAddress = lazy(
    () => import("./widgets/member-edit-address/MemberEditAddress")
) as React.FC;
const MemberEditEmail = lazy(
    () => import("./widgets/member-edit-email/MemberEditEmail")
) as React.FC;
const MemberEditHomePhone = lazy(
    () => import("./widgets/member-edit-home-phone/MemberEditHomePhone")
) as React.FC;
const MemberEditMobilePhone = lazy(
    () => import("./widgets/member-edit-mobile-phone/MemberEditMobilePhone")
) as React.FC;
const MemberDeclareIbanCertificate = lazy(
    () =>
        import(
            "./widgets/member-declare-iban/member-declare-iban-certificate/MemberDeclareIbanCertificate"
        )
) as React.FC;
const MemberDeclareIbanDetails = lazy(
    () =>
        import("./widgets/member-declare-iban/member-declare-iban-details/MemberDeclareIbanDetails")
) as React.FC;
export const MemberWidgets = {
    "member-dashboard": MemberDashboard,
    "member-contact-preferences": MemberContactPreferences,
    "member-contracts-view": MemberContractsView,
    "member-beneficiaries-view": MemberBeneficiariesView,
    "member-request-health-quote": MemberRequestHealthQuote,
    "member-tp-card": MemberTPCard,
    "member-request-reimbursement": MemberRequestReimbursement,
    "member-my-reimbursements": MemberMyReimbursements,
    "member-my-documents": MemberDocuments,
    "member-request-social-aid": MemberRequestSocialAid,
    "member-magazines": MemberMagazines,
    "member-request-forms": MemberRequestForms,
    "member-status-and-notices": MemberStatusAndNotices,
    "member-request-confirmation": MemberRequestConfirmation,
    "member-process-status": MemberProcessStatus,
    "member-pay-my-dues": MemberPayMyDues,
    "member-pay-my-dues-status": MemberPayMyDuesStatus,
    "member-my-social-aid": MemberMySocialAid,
    "member-premiums-view": MemberPremiumsView,
    "member-find-a-practitioner": MemberFindPractitioner,
    "member-hospital-refund-simple-request": MemberRequestHospitalRefundSimple,
    "member-modify-payment-frequency": MemberModifyPaymentFrequency,
    "member-contact-us": MemberContactUs,
    "member-attestations": MemberAttestations,
    "member-follow-up-requests": MemberFollowUpRequests,
    "member-personal-details": MemberPersonalDetails,
    "member-connection-details": MemberConnectionDetails,
    "member-edit-address": MemberEditAddress,
    "member-edit-email": MemberEditEmail,
    "member-edit-home-phone": MemberEditHomePhone,
    "member-edit-mobile-phone": MemberEditMobilePhone,
    "member-declare-iban-certificate": MemberDeclareIbanCertificate,
    "member-declare-iban-details": MemberDeclareIbanDetails
};
