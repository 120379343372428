"use client";
import React, { useState, useRef, useEffect } from "react";

export default function CountdownTimer(props: {
    onTimerCountdown?: any;
    timerText: string;
    seconds?: number;
}) {
    const setCounterTimer = useRef<NodeJS.Timeout | null>(null);
    const milliseconds = (props.seconds ?? 10) * 1000;
    const [counter, setCounter] = useState(milliseconds);

    useEffect(() => {
        if (!setCounterTimer.current) {
            setCounterTimer.current = setInterval(() => {
                if (counter > 0) {
                    setCounter((prevCounter) => prevCounter - 1000);
                }
            }, 1000);
        }
        return () => clearInterval(setCounterTimer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (setCounterTimer.current && counter <= 0) {
            clearInterval(setCounterTimer.current);
            if (props.onTimerCountdown) {
                props.onTimerCountdown();
            }
        }
    }, [counter, props]);
    const seconds = Math.ceil(counter / 1000);

    return (
        <div className="un-counter-container">
            <p className="tq-paragraph">
                {props.timerText || ""}
                <span className="tq-text-node">&nbsp;{`${seconds}s`}</span>
            </p>
        </div>
    );
}
