"use client";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import React from "react";
import { Hyperlink } from "@uneo/platform-commons/platform/components";
export default function PortalLogo() {
    const { data } = useWidgetDataSelector("portal-logo");
    if (!data || !data.entities) {
        return <></>;
    }
    const logoEntity = data.entities[0];
    const src = logoEntity.properties?.src;
    const alt = logoEntity.properties?.alt;
    //todo replace img with next/image <Image>
    return (
        <div className="tq-logo-box">
            <Hyperlink
                entity={logoEntity}
                extraClasses="tq-logo"
                onClick={() => {
                    triggerMatomoEvent("Header", "[Header] - Logo Unéo", "Cliquer");
                }}
            >
                <img src={src} alt={alt} width={50} height={50} />
            </Hyperlink>
            <div className="un-logo-text"></div>
        </div>
    );
}
