"use client";
import React from "react";
import { IBaseComponentProps, IEventHandlers } from "../../../types";

const RadioOption = (props: IRadioOptionProps) => {
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            props.onClick(event);
        }
    };

    let optionClass = " tq-radio-option";
    if (props.active) {
        optionClass += " tq-selected";
    }
    if (props.disabled) {
        optionClass += " tq-disabled";
    }
    if (props.extraClasses) {
        optionClass = props.extraClasses + optionClass;
    }

    let tabIndex;
    if (!props.disabled) {
        tabIndex = "0";
    }

    return (
        <div
            className={optionClass}
            onKeyDown={onKeyDown}
            data-value={props.value}
            data-tq-id={props.dataTqId}
            role="radio"
            aria-checked={props.active ? "true" : "false"}
            onClick={props.onClick}
            tabIndex={tabIndex}
        >
            <div className="tq-radio" />
            <span className="tq-text-node">{props.label || props.value}</span>
        </div>
    );
};

interface IRadioOptionProps extends IBaseComponentProps, IEventHandlers {
    active: boolean;
    disabled?: boolean;
    label?: string;
    dataTqId?: string;
}

export default RadioOption;
