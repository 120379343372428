import React from "react";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import { INBOX_ID, LOGOUT_ID, NOTIFICATIONS_ID, TITLE_ID } from "./ProfileMenu";
import MenuItem from "../portal-header/MenuItem";
import { useWidgetContext } from "../../../commons/webui/widget/WidgetContext";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { Entity } from "@uneo/platform-commons/webui/types";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";

export const getNonSpecialLinks = (links: Entity[] = []): Entity[] => {
    return links.filter(
        (link) => link.id !== LOGOUT_ID && link.id !== NOTIFICATIONS_ID && link.id !== INBOX_ID
    );
};

export default function ProfileMenuSidebar(props) {
    // const { t } = useTranslation();
    const { data } = useWidgetDataSelector("profile-menu");
    const { data: portalHeaderData } = useWidgetDataSelector("portal-header");
    const metadata = getEntity({ widgetId: "content-root", entityPath: "metadata" });
    const matomoPageTitle = metadata ? metadata?.properties?.title : "MenuSidebar";
    const personalSpaceLink = findEntityById(portalHeaderData, "personal-space-link");
    const loginButton = findEntityById(data, "login");
    const isAuthenticated = !loginButton;
    const { showOnlyTitle } = props;
    const { triggerEntityActions } = useWidgetContext();
    if (!data || !isAuthenticated) {
        return <></>;
    }
    const links = findEntityById(data, "links");
    const title = findEntityById(data, TITLE_ID);
    const { initials, fullName } = title.properties;
    return (
        <>
            <li className="un-profile-section">
                <div className="un-user-letters">
                    <span>{initials}</span>
                </div>
                <div className="un-user-link">
                    <p className="tq-paragraph">{fullName}</p>
                    {
                        // render this link only when the rest profile menu links are hidden
                        showOnlyTitle && (
                            <button
                                className="tq-hyperlink un-ds-hyperlink un-ds-btn-tertiary tq-text-center tq-main-color"
                                onClick={(e) => {
                                    e.preventDefault();
                                    triggerEntityActions(title);
                                    triggerMatomoEvent(
                                        matomoPageTitle,
                                        `[${matomoPageTitle}] - Gérer mon profil`,
                                        "Cliquer"
                                    );
                                }}
                            >
                                <span className="tq-hyperlink-z-index">Gérer mon profil</span>
                            </button>
                        )
                    }
                    {
                        // render this link only when the rest profile menu links are hidden
                        !showOnlyTitle && personalSpaceLink && (
                            <button
                                className="tq-hyperlink un-ds-hyperlink un-ds-btn-tertiary tq-text-center tq-main-color"
                                onClick={(e) => {
                                    e.preventDefault();
                                    triggerEntityActions(personalSpaceLink);
                                    triggerMatomoEvent(
                                        matomoPageTitle,
                                        `[${matomoPageTitle}] - Mon compte`,
                                        "Cliquer"
                                    );
                                }}
                            >
                                <span className="tq-hyperlink-z-index">Mon compte</span>
                            </button>
                        )
                    }
                </div>
            </li>
            {
                //render all links except special links, like logout, connect(the first link), inbox and notifications
                !showOnlyTitle &&
                    links &&
                    links.entities &&
                    getNonSpecialLinks(links.entities).map((link) => (
                        <MenuItem
                            link={link}
                            key={link.id}
                            matomoContext={metadata ? metadata?.properties?.title : undefined}
                        />
                    ))
            }
        </>
    );
}
