"use client";
import React, { useEffect, useState } from "react";
import { Entity, Widget } from "@uneo/platform-commons/webui/types";
import {
    findEntityById,
    getEntityChildren,
    getEntityProps
} from "@uneo/platform-commons/webui/search";
import "./body.css";
import MenuItem from "./MenuItem";
import PortalLogo from "../portal-logo/PortalLogo";
import ProfileMenu from "../profile-menu/ProfileMenu";
import ProfileMenuMobile from "../profile-menu/ProfileMenuMobile";
import { useFetchWidgetData } from "@uneo/platform-commons/platform/hooks";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { useWidgetDataSelector } from "../../../commons/webui/store/hooks";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { LoaderButton, Hyperlink } from "@uneo/platform-commons/platform/components";
import { useFetchFeature } from "@uneo/platform-commons/platform/features";
import { xssFilter } from "@uneo/platform-commons/platform/utils/xssFilter";

const QUOTE_ID = "oav-devis";
const PERSONAL_SPACE_LINK_ID = "personal-space-link";
const RIBBON_MESSAGES_ID = "messages";
const RADIO_ID = "radio";

export default function PortalHeader({ info }: { info: Widget }) {
    const { triggerEntityActions, widgetId } = useWidgetContext();
    const { data: profileMenuData } = useWidgetDataSelector("profile-menu");
    const { data, error } = useFetchWidgetData(info);
    const [openedHeaderMobileMenu, setOpenedHeaderMobileMenu] = useState(false);
    const [openedProfileMobileMenu, setOpenedProfileMobileMenu] = useState(false);
    const [burgerButtonOpenClassState, setBurgerButtonOpenClassState] = useState(false);
    const [headerShadow, setHeaderShadow] = useState("");
    const { enabled, settings } = useFetchFeature("radio");
    const {
        methods: { updateEntityProps }
    } = useWidgetContext();
    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 80 && headerShadow !== "un-header-shadow") {
                setHeaderShadow("un-header-shadow");
            } else if (window.scrollY <= 80 && headerShadow !== "") {
                setHeaderShadow("");
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [headerShadow]);
    const closeAllMenus = () => {
        setOpenedProfileMobileMenu(false);
        setOpenedHeaderMobileMenu(false);
        setBurgerButtonOpenClassState(false);
    };
    const hasMobileMenuOpened = openedHeaderMobileMenu || openedProfileMobileMenu;
    useEffect(() => {
        if (hasMobileMenuOpened) {
            setBodyStyle();
        } else {
            resetBodyStyles();
        }
    }, [hasMobileMenuOpened]);
    if (!data) {
        return <></>;
    }
    if (error) {
        throw new Error("No data available in PortalHeader");
    }
    const loginButton = findEntityById(profileMenuData as Entity, "login");
    const logoutButton = findEntityById(profileMenuData as Entity, "logout-page");
    const isAuthenticated = !loginButton;
    const initials = getEntityProps(profileMenuData as Entity, "title", "initials");
    const fullName = getEntityProps(profileMenuData as Entity, "title", "fullName");
    const links = findEntityById(data, "links");
    if (!links?.entities) {
        return <></>;
    }
    //personal space is the last top level link
    const personalSpace = findEntityById(data, PERSONAL_SPACE_LINK_ID);
    const linksMainDesktop = links.entities?.map((link) => {
        if (link.id === QUOTE_ID) {
            return;
        }
        return (
            <li key={link.id} className={link.properties?.active ? "un-current-page" : ""}>
                <Hyperlink
                    entity={link}
                    target="_self"
                    onClick={() => {
                        triggerMatomoEvent(
                            "Header",
                            `[Header] - ${link.properties?.value}`,
                            "Cliquer"
                        );
                    }}
                >
                    <span className="tq-text-node">{link.properties?.value}</span>
                </Hyperlink>
            </li>
        );
    }) || <></>;
    const linksMainMobile = links.entities?.map((link) => {
        //active link (personal space) is rendered separately on mobile
        //same goes for quote link
        if (link.id === PERSONAL_SPACE_LINK_ID || link.id === QUOTE_ID) {
            return;
        }

        return (
            <li
                className="un-main-menu-element"
                key={link.id}
                onClick={(e) => {
                    e.stopPropagation();
                    triggerEntityActions(link);
                    triggerMatomoEvent("Header", `[Header] - ${link.properties?.value}`, "Cliquer");
                }}
            >
                <Hyperlink
                    entity={link}
                    extraClasses="tq-hyperlink un-ds-hyperlink"
                    skipTriggerEntityActions={true}
                >
                    <span className="tq-hyperlink-z-index un-menu-label">
                        {link.properties?.value}
                    </span>
                </Hyperlink>
            </li>
        );
    }) || <></>;
    const quote = findEntityById(data, QUOTE_ID);
    const quoteButtonDesktop = (quote && (
        <button
            type="button"
            className="tq-button un-ds-button un-ds-component-sm un-ds-button-outline"
            onClick={() => {
                triggerEntityActions(quote);
                triggerMatomoEvent("Header", `[Header] - ${quote.properties?.value}`, "Cliquer");
            }}
        >
            <span className="tq-text-node">{quote.properties?.value}</span>
        </button>
    )) || <></>;
    const quoteButtonMobile = (quote && (
        <div
            role="button"
            className="un-ds-action-section-center tq-text-center un-pt-2 un-pb-2"
            onClick={() => {
                triggerEntityActions(quote);
                triggerMatomoEvent("Header", `[Header] - ${quote.properties?.value}`, "Cliquer");
                closeAllMenus();
            }}
        >
            <button
                type="button"
                className="tq-button un-ds-button un-ds-component-sm un-ds-button-outline"
            >
                <span className="tq-text-node">{quote.properties?.value}</span>
            </button>
        </div>
    )) || <></>;
    const radioDescription: string = settings?.["radio-description"] || "";
    const radioLogo: string = settings?.["radio-logo"] || "";
    const radioSection = (enabled && (
        <div className="un-burger-skyrock" id="burger-skyrock">
            {radioDescription && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: xssFilter.process(radioDescription)
                    }}
                ></div>
            )}
            <div className="un-skyrock-container un-skyrock-mobile" id="skyrock-container">
                <div className="un-skyrock-elements">
                    {radioLogo && (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img src={radioLogo} alt={"radio logo"} />
                    )}
                    <button
                        className="tq-button un-ds-button-only-icon un-button-play"
                        type="button"
                        aria-label="play"
                        id="burger-skyrock-play"
                        onClick={() => {
                            updateEntityProps({
                                widgetId: "portal-footer",
                                entityPath: RADIO_ID,
                                props: { remotePlay: true }
                            });
                        }}
                    >
                        <i className="un-ds-s-icon-play"></i>
                    </button>
                </div>
            </div>
        </div>
    )) || <></>;
    const ribbonMessageEntities = getEntityChildren(data, RIBBON_MESSAGES_ID);
    return (
        <>
            <header className={`tq-header un-header ${headerShadow}`} role={"banner"}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        const content = document.querySelector(".tq-main-content");
                        const possiblyFocusable = content?.querySelectorAll(
                            'button, [href], input:not(:disabled), select, textarea, [tabindex]:not([tabindex="-1"])'
                        );
                        if (!possiblyFocusable || possiblyFocusable.length === 0) {
                            return;
                        }
                        const pfArray = Array.from(possiblyFocusable);
                        const focusable = pfArray.filter((el) => {
                            const rect = el.getBoundingClientRect();
                            if (rect.width === 0 && rect.height === 0) {
                                return false;
                            }
                            return true;
                        })[0];
                        (focusable as HTMLElement)?.focus();
                    }}
                    className="un-skip-link"
                    href="#un-main"
                >
                    Aller au contenu
                </a>
                <nav className="tq-flex un-header-main">
                    <button
                        aria-label="basculer le menu mobile"
                        type="button"
                        className={
                            "tq-button un-burger-button" +
                            (burgerButtonOpenClassState ? " un-burger-open" : "")
                        }
                        onClick={() => {
                            setOpenedHeaderMobileMenu(!openedHeaderMobileMenu);
                            setBurgerButtonOpenClassState(!openedHeaderMobileMenu);
                        }}
                    >
                        {}
                    </button>
                    <div className="un-burger-menu">
                        {/* when the search filed is empty there is no need to have a clear button */}
                        {/* To show the search remove class "un-hide-search" */}
                        <div className="un-search un-hide-search" role={"search"}>
                            <div className="tq-form-element un-ds-form-element un-form-vertical tq-no-label un-search-input">
                                <div className="tq-input-container tq-text-input tq-has-feedback">
                                    <input
                                        className="tq-input"
                                        placeholder="Recherche"
                                        type="text"
                                    />
                                    <button
                                        className="tq-button un-ds-button-only-icon un-search-clear-btn"
                                        aria-label="masquer le filtre"
                                    >
                                        <i className="tq-icon-close" />
                                    </button>
                                </div>
                            </div>
                            <button
                                aria-label="Recherche"
                                className="tq-button un-ds-button-only-icon"
                                onClick={() =>
                                    triggerMatomoEvent("Header", "[Header] - Recherche", "Cliquer")
                                }
                            >
                                <i className="tq-icon-search" />
                                {}
                            </button>
                        </div>
                        <div className="un-scrollable-area">
                            {isAuthenticated && (
                                <div className="un-profile-section">
                                    <div className="un-user-letters">
                                        <span>{initials}</span>
                                    </div>
                                    <div className="un-user-link">
                                        <p className="tq-paragraph">{fullName}</p>
                                        <button
                                            className="tq-hyperlink un-ds-hyperlink un-ds-btn-tertiary tq-text-center tq-main-color un-profile-menu-open"
                                            onClick={() => {
                                                setOpenedProfileMobileMenu(true);
                                                //design request check example: pf/examples/dashboard-mob-burger.html
                                                setTimeout(() => {
                                                    setBurgerButtonOpenClassState(false);
                                                }, 500);
                                                triggerMatomoEvent(
                                                    "Header",
                                                    "[Header] - Gérer mon profil",
                                                    "Cliquer"
                                                );
                                            }}
                                        >
                                            <span className="tq-hyperlink-z-index">
                                                Gérer mon profil
                                            </span>
                                        </button>
                                    </div>
                                    <button
                                        aria-label="Déconnexion"
                                        type="button"
                                        className="tq-button un-ds-button-only-icon tq-main-color"
                                        onClick={() => {
                                            triggerMatomoEvent(
                                                "Header",
                                                "[Header] - Déconnexion",
                                                "Cliquer"
                                            );
                                            triggerEntityActions(logoutButton);
                                        }}
                                    >
                                        {}
                                        <i className=" tq-icon-power-off" />
                                    </button>
                                </div>
                            )}

                            <ul className="un-main-menu" role={"navigation"}>
                                {linksMainMobile}
                                {isAuthenticated && (
                                    <PersonalSpaceButton link={personalSpace}>
                                        <ul className="tq-list tq-borders tq-selectable un-secondary-menu">
                                            {personalSpace.entities?.map((link) => (
                                                <MenuItem
                                                    link={link}
                                                    key={link.id}
                                                    onNavigation={closeAllMenus}
                                                />
                                            ))}
                                        </ul>
                                    </PersonalSpaceButton>
                                )}
                            </ul>
                            {quoteButtonMobile}
                            {radioSection}
                        </div>
                    </div>
                    <div
                        className={"un-profile-menu " + (openedProfileMobileMenu ? " un-open" : "")}
                    >
                        <i
                            className="tq-icon-close un-close-profile-menu"
                            role="button"
                            onClick={closeAllMenus}
                        />
                        <ProfileMenuMobile onNavigation={closeAllMenus} />
                    </div>
                    <PortalLogo />
                    <div className="un-header-links" role="navigation">
                        <ul className="tq-list-new un-horizontal">
                            {linksMainDesktop}
                            {/* loupe is temporary removed */}
                            {/* <li key={"search"}>
                                <button
                                    className="tq-button un-ds-button-only-icon un-search-button"
                                    aria-label="search"
                                >
                                    <i className="un-ds-m-icon-search" />
                                </button>
                            </li> */}
                        </ul>
                    </div>
                    <div className="un-header-btn-wrapper" role={"navigation"}>
                        {quoteButtonDesktop}
                        {isAuthenticated && (
                            <>
                                <ProfileMenu />
                                <button
                                    className="tq-button un-ds-button  un-btn-my-profile"
                                    type="button"
                                    onClick={() =>
                                        setOpenedProfileMobileMenu(!openedProfileMobileMenu)
                                    }
                                >
                                    <span className="tq-text-node">{initials}</span>
                                </button>
                            </>
                        )}
                        {!isAuthenticated && (
                            <div className="un-header-btn-dropdown">
                                <LoaderButton
                                    extraClasses="tq-button un-button-sm un-button-profile un-ds-component-sm un-ds-button-solid un-ds-button-solid-color1"
                                    entity={loginButton}
                                />
                            </div>
                        )}
                    </div>
                </nav>
            </header>
            <div id="js-sticky-header" className="un-sticky-header-container">
                {ribbonMessageEntities.length > 0 && (
                    <div className="un-ribbons">
                        {ribbonMessageEntities.map((item, eIndex) => (
                            <RibbonMessage key={`ribbon-message-${eIndex}`} message={item} />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

const resetBodyStyles = () => {
    document.body.classList.remove("un-overflow-y-hidden");
};

const setBodyStyle = () => {
    document.body.classList.add("un-overflow-y-hidden");
};

function PersonalSpaceButton({ link, children }: { link: Entity; children: React.ReactNode }) {
    const [expanded, setExpanded] = useState(true);
    const className =
        "un-main-menu-element un-expandable tq-current" + (expanded ? " un-expanded" : "");
    return (
        <li className={className}>
            <span
                className="tq-hyperlink-z-index un-menu-label"
                role="button"
                onClick={() => setExpanded(!expanded)}
            >
                {link.properties?.value}
            </span>
            {children}
        </li>
    );
}

function RibbonMessage({ message }: { message: Entity }) {
    const [closed, setClosed] = useState(false);
    const { triggerEntityActions } = useWidgetContext();
    const type = message.properties?.type || "info";
    return (
        <div
            className={`un-ribbon-container un-${type}-message ${closed ? "tq-hide" : ""}`}
            role={"alert"}
        >
            <div
                className="un-ribbon-messages"
                dangerouslySetInnerHTML={{
                    __html: xssFilter.process(message.properties?.value)
                }}
            ></div>
            {!message.properties?.required && (
                <i
                    className="tq-icon-close"
                    role="button"
                    onClick={() => {
                        setClosed(true);
                        triggerEntityActions(message);
                        // because of progressBar
                        window.dispatchEvent(new Event("resize"));
                    }}
                ></i>
            )}
        </div>
    );
}
