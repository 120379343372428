import { getToken } from "@uneo/platform-commons/platform/hooks/useRecaptcha";
import { getActionUrl } from "@uneo/platform-commons/platform/services/urlService";
import { Action, ActionContext } from "@uneo/platform-commons/webui/types";

const DEFAULT_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json"
};
// supports only GET
// special action for OavAffiliationSignatureLoading page
// this action sends request and returns the json response - the refresh logic is placed in the consumer
// todo: remove this siren action; duplicate code with httpClient
const refresh = async (action: Action, context: ActionContext) => {
    let fetchOptions: Record<string, any> = {
        method: action.method
    };
    const url = getActionUrl(action, context);

    if (!fetchOptions.headers) {
        fetchOptions.headers = Object.assign({}, DEFAULT_HEADERS);
    } else {
        fetchOptions.headers = Object.assign({}, DEFAULT_HEADERS, fetchOptions.headers);
    }
    if (context?.currentEntityPath) {
        try {
            const token = await getToken(context.currentEntityPath);
            if (token) {
                fetchOptions.headers = Object.assign(fetchOptions.headers, {
                    "x-captcha-token": token
                });
            }
        } catch (recaptchaError) {
            console.error(recaptchaError);
        }
    }
    Object.keys(fetchOptions.headers).forEach((item) => {
        if (fetchOptions.headers[item] === undefined) delete fetchOptions.headers[item];
    });
    if (!fetchOptions.redirect) {
        fetchOptions.redirect = "manual";
    }
    if (!fetchOptions.credentials) {
        fetchOptions.credentials = "include";
    }
    const response = await fetch(url, fetchOptions);
    return await response.json();
};

export default refresh;
