import { Action } from "../../webui/types";
import { getEntity, updateEntity as updateStoreEntity } from "../../webui/store/actions";
import { fromSirenEntityToEntity } from "../../webui/adapters";
import { CONTENT_ROOT_WIDGET_ID } from "../constants";
import { getContentRootInnerWidgetViewId } from "../utils/contentRoot";
//specific action
//sets "success-messages" into the "content-root" widget
export const showMessage = async (action: Action) => {
    if (!action.properties?.data) {
        return Promise.reject("Missing action.properties.data");
    }
    const entity: any = action.properties?.data;
    const widgetViewId = getContentRootInnerWidgetViewId();
    updateStoreEntity({
        widgetId: CONTENT_ROOT_WIDGET_ID,
        entityPath: widgetViewId + ".success-messages",
        entity: fromSirenEntityToEntity(entity)
    });
};

export const removeMessage = async (action: Action) => {
    if (!action.targets) {
        return Promise.reject("Missing targets.url.");
    }
    const successMessages = getEntity({
        widgetId: CONTENT_ROOT_WIDGET_ID,
        entityPath: "success-messages"
    });
    if (successMessages && successMessages.entities) {
        action.targets.forEach((target) => {
            successMessages.entities = successMessages.entities?.filter(
                (item) => item.id !== target
            );
        });
        updateStoreEntity({
            widgetId: CONTENT_ROOT_WIDGET_ID,
            entityPath: `success-messages`,
            entity: successMessages
        });
    }
};
