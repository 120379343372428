/**Hyperlink component which:
 * extracts its "href", if not passed from entity's OpenTarget or SwitchContainer action
 * executes the entity's actions on click, if not explicitly passed NOT to do so
 * renders <a> with icon, if given and whatever else passed as children
 */
import React from "react";
import { Action, Entity } from "../../../webui/types";
import { useWidgetContext } from "../../../webui/widget/WidgetContext";

export const getLinkAddress = (link: Entity | undefined): string => {
    let href: string = "#";
    const openTargetAction: Action | undefined = link?.actions?.find(
        (action) => action.type === "openTarget"
    );

    if (openTargetAction?.url) {
        href = openTargetAction.url;
    } else {
        const switchContainerAction: Action | undefined = link?.actions?.find(
            (action) => action.type === "switchContainer"
        );
        if (switchContainerAction) {
            href = `${switchContainerAction.properties?.address}`;
        }
    }
    return href;
};

const Hyperlink = ({
    entity,
    href,
    onClick,
    target,
    icon,
    children,
    tabIndex = 0,
    skipTriggerEntityActions = false,
    extraClasses = ""
}: {
    entity: Entity | undefined;
    target?: string;
    href?: string;
    icon?: string;
    onClick?: (event) => void;
    skipTriggerEntityActions?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    tabIndex?: number;
    extraClasses?: string;
}) => {
    const { triggerEntityActions } = useWidgetContext();

    const handleClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        if (!skipTriggerEntityActions) {
            event.stopPropagation();
            triggerEntityActions(entity);
        }
        if (onClick) {
            onClick(event);
        }
    };
    const additionalProps: Record<string, any> = {};
    if (target) {
        additionalProps.target = target;
    }
    return (
        <a
            className={extraClasses}
            href={href || getLinkAddress(entity)}
            onClick={handleClick}
            tabIndex={tabIndex}
            {...additionalProps}
        >
            {icon && <i className={icon} />}
            {children}
        </a>
    );
};
export default Hyperlink;
