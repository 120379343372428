"use client";
import React, { useState, useEffect, useRef } from "react";
import { fileIcons, fileMimeTypes } from "./FileUploadUtils";
import { IEventHandlers } from "@uneo/platform-commons/platform/types";

export interface IFile {
    lastModified: number;
    lastModifiedDate?: Record<string, any>;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}
export interface IFileUploadDragAndDropProps extends IEventHandlers {
    onFileRemoved?: (event: any) => void;
    title?: string;
    subTitle?: string;
    name?: string;
    accept?: string;
    singleFile?: boolean;
    disabled?: boolean;
    maxFiles?: number;
    files?: IFile[];
    inputReference: React.RefObject<any>;
}

const FileUploadDragAndDrop: React.FC<IFileUploadDragAndDropProps> = (props) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const imgRefs = useRef({});

    useEffect(() => {
        // Update img elements when files change
        if (props.files?.length) {
            props.files.forEach((file: IFile, i: number) => {
                const imgRefKey = `image-${i}`;
                const fileType = fileMimeTypes[file.type];
                if (file && fileType === "image" && file instanceof Blob) {
                    const fileReader = new FileReader();
                    fileReader.onload = function (event) {
                        if (imgRefs && imgRefs.current && event.target) {
                            imgRefs.current[imgRefKey].src = event.target.result;
                        }
                    };
                    fileReader.readAsDataURL(file);
                }
            });
        }
    }, [props.files]);

    useEffect(() => {
        window.addEventListener("dragover", preventDefault, false);
        window.addEventListener("drop", preventDefault, false);
        return () => {
            window.removeEventListener("dragover", preventDefault);
            window.removeEventListener("drop", preventDefault);
        };
    }, []);

    const getThumbnailContent = (file: IFile, index: number) => {
        const imgRefKey = `image-${index}`;
        const fileType = fileMimeTypes[file.type];
        const icon = fileIcons[fileType] ? fileIcons[fileType] : "tq-o-icon-document-blank";

        return (
            <li key={"item-" + index} className="tq-thumbnail">
                <button
                    className="tq-button tq-delete-file"
                    onClick={props.onFileRemoved}
                    data-file-index={index}
                >
                    <i className="tq-icon-close" />
                </button>
                <span className="tq-thumbnail-content" onClick={(event) => event.stopPropagation()}>
                    {fileType === "image" && file instanceof Blob ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                            ref={(imgRef) => {
                                imgRefs.current[imgRefKey] = imgRef;
                            }}
                            alt={file.name}
                            className="tq-thumb"
                        />
                    ) : (
                        <span className="tq-file-image">
                            <i className={icon} />
                        </span>
                    )}
                </span>
                <span className="tq-file-name" onClick={(event) => event.stopPropagation()}>
                    {file.name}
                </span>
            </li>
        );
    };

    const onMouseEnter = () => {
        if (!props.disabled) {
            setMouseOver(true);
        }
    };

    const onMouseLeave = () => {
        if (mouseOver) {
            setMouseOver(false);
        }
    };

    const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (event) {
            event.stopPropagation();
        }
        const element = props.inputReference.current;
        element.click();
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onClick(event);
        }
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
        }
        if (
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files.length &&
            !props.disabled
        ) {
            if (props.onChange) props.onChange(event);
        }
    };

    const buildTitles = () => {
        const depositFilesText =
            props.maxFiles && props.maxFiles > 1
                ? "Je dépose mes fichiers ici"
                : "Je dépose mon fichier ici";
        return (
            <div className="tq-drag-file-text">
                <label className="tq-drag-title">{props.title || depositFilesText}</label>
                {props.subTitle && <span className="tq-drag-subtitle">{props.subTitle}</span>}
            </div>
        );
    };

    const { length } = props.files || {};
    const maxFilesReached = props.maxFiles && length ? props.maxFiles <= length : false;
    const hasUploads = !!(props.files && props.files.length);
    const classes = buildClasses({ maxFilesReached, hasUploads });

    return (
        <>
            <div
                className={classes}
                key="drop-area"
                onClick={maxFilesReached ? undefined : onClick}
                onKeyDown={onKeyDown}
                tabIndex={!props.disabled ? 0 : -1}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onDrop={onDrop}
                onDragOver={preventDefault}
            >
                {!hasUploads && (
                    <div
                        className={mouseOver ? "tq-bounce-content tq-bounce" : "tq-bounce-content"}
                    >
                        <i className="tq-file-upload-icon" />
                    </div>
                )}
                {!maxFilesReached && buildTitles()}
                {props.files && props.files.length > 0 && (
                    <div className="tq-thumbnails">
                        <ul className="tq-thumbnails-container">
                            {props.files.map((file, index) => getThumbnailContent(file, index))}
                            {!maxFilesReached && !props.singleFile && (
                                <li className="tq-thumbnail tq-multiple-upload-icon-container">
                                    <i className="tq-multiple-file-upload-icon" />
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <input
                className="tq-file-input tq-hide"
                key="drop-area-hidden-input"
                ref={props.inputReference}
                type="file"
                name={props.name}
                multiple={!props.singleFile}
                accept={props.accept}
                onChange={props.onChange}
                disabled={props.disabled}
                tabIndex={-1}
                value=""
            />
        </>
    );
};

export default FileUploadDragAndDrop;

const buildClasses = ({ maxFilesReached, hasUploads }) => {
    let result = "tq-drag-file";
    if (maxFilesReached) result += " tq-max-uploaded";
    if (hasUploads) result += " tq-file-attached";
    return result;
};

const preventDefault = (event) => {
    //We do this magic to have proper cursor over the drop area.
    if (event && event.dataTransfer) {
        const dt = event.dataTransfer;
        if (dt.files && dt.files.length) {
            event.preventDefault();
        } else if (dt.types && dt.types.length) {
            if (Array.isArray(dt.types)) {
                if (dt.types.includes("Files")) {
                    event.preventDefault();
                }
                //types is DOMStringList
            } else if (dt.types.contains && dt.types.contains("Files")) {
                event.preventDefault();
            }
        }
    }
};
