"use client";
import { useEffect, useState } from "react";

import { useFetchFeature } from "@uneo/platform-commons/platform/features";

export default function MetaPixel() {
    const { enabled, settings } = useFetchFeature("meta-pixel");

    const id = settings.id;
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Set the initial consent state
        const initialConsent = !!(window as any)?.tarteaucitronConsents?.facebookpixel?.accepted;
        if (initialConsent !== consentGiven) {
            setConsentGiven(initialConsent);
        }
        // Define the event listener callback function
        function handleConsentChange(event) {
            if (
                event.detail.facebookpixel &&
                event.detail.facebookpixel.accepted !== consentGiven
            ) {
                setConsentGiven(event.detail.facebookpixel.accepted);
            }
        }
        // Add the event listener when the component mounts
        document.addEventListener("tarteaucitronConsentChange", handleConsentChange);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("tarteaucitronConsentChange", handleConsentChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!scriptLoaded && !!document.head.querySelector("script#meta-pixel")) {
            setScriptLoaded(true);
            return;
        }

        if (enabled && id && !scriptLoaded && consentGiven) {
            const headElement = document.getElementsByTagName("head")[0];

            const firstScript = document.createElement("script");
            firstScript.type = "text/javascript";
            firstScript.id = "meta-pixel";
            firstScript.innerHTML = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${id}');
fbq('track', 'PageView');`;
            headElement.appendChild(firstScript);
            const noScript = document.createElement("noscript");
            noScript.innerHTML = `
<img height="1" width="1" style="display:none" 
       src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>`;
            headElement.appendChild(noScript);

            setScriptLoaded(true);
        }
    }, [enabled, id, scriptLoaded, consentGiven]);

    return null;
}
