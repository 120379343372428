import React from "react";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";
import Input, { IInputPropsKeys } from "./Input";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";

export default function InputContainer(props) {
    const onBlur = (state) => {
        if (props.onBlur) {
            props.onBlur(state);
        }
    };

    const extractedProps: any = extractComponentProps(props, {
        componentProps: IInputPropsKeys,
        additionalProps: ["extraClasses", "value", "verticalLabel", "debounce"],
        propsDefaults: {
            extraClasses: "",
            verticalLabel: true
        }
    });

    const debounceOptions = props.debounce || {};
    const debounceTimeout: number = debounceOptions.wait || 200;
    const debounceImmediate = !!debounceOptions.immediate;

    const onChangeDebounced = debounce(
        (state): void => {
            if (props.onChange) {
                props.onChange(state);
            }
        },
        debounceTimeout,
        debounceImmediate
    );

    const onChange = (state): void => {
        if (props.debounce) {
            onChangeDebounced(state);
        } else if (props.onChange) {
            props.onChange(state);
        }
    };

    extractedProps.onChange = onChange;
    return <Input {...extractedProps} onBlur={onBlur} />;
}
InputContainer.displayName = "UneoInput";
