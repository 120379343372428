import { Entity } from "../../webui/types";

const EXCEPTION_ERROR_MESSAGE = `<p>La mise à jour a échoué.  Veuillez essayer ultérieurement.</br>
Si le problème persiste, nous vous invitons à nous le signaler via notre formulaire de contact. </p>`;

export function isExceptionResponse(result: any) {
    return typeof result === "object" && Object.keys(result).length === 0;
}

export function generateErrorsEntity(message?: string): Entity {
    return {
        id: "errors",
        entities: [
            {
                id: "0",
                properties: {
                    value: message || EXCEPTION_ERROR_MESSAGE
                }
            }
        ]
    };
}

export function generateErrorWidgetEntity(widgetId: string): Entity {
    return {
        id: widgetId,
        properties: {
            exception: true
        }
    };
}
