"use client";
import React from "react";
import Popover from "../../popover/Popover";
import Scrollbar from "@tinqin/tinqin-react-custom-scrollbars/lib";
import DropdownOption, { IDropdownOptionProps } from "./DropdownOption";
import DropdownSelection from "./DropdownSelection";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import useDropdown from "./useDropdown";
import {
    ILabeledProps,
    IBaseComponentProps,
    IEventHandlers,
    IBaseComponentPropsKeys,
    IEventHandlersKeys,
    ILabeledPropsKeys
} from "../../../types";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";

export default function Dropdown(props: IDropdownProps) {
    const {
        onKeyDown,
        onBlur,
        onFocus,
        onClose,
        onOpen,
        onChangeSuggestionValue,
        buildOptionsProps,
        anchorRef,
        contentRef,
        scrollbarRef,
        suggestionInputRef,
        arrowIconRef,
        pillsRef,
        active,
        suggestionValue,
        value
    } = useDropdown(props);

    let valueContainerClass = "tq-select";
    if (props.multiSelection) {
        valueContainerClass += " tq-has-pills tq-has-action";
    }
    if (props.truncateLongValue) {
        valueContainerClass += " tq-overflow";
    }

    let selectionClass = "tq-drop-down-selected";
    if (props.multiSelection) {
        selectionClass = "tq-pills-container";
    }

    const labelProps = getLabelProps(props, {
        valid: props.valid,
        valueContainerClass: valueContainerClass,
        skipLabel: props.skipLabel,
        extraClasses: props.autosuggestion
            ? `${props.extraClasses} un-ds-autosuggest`
            : props.extraClasses
    });

    const onToggle: (e) => void = active ? onClose : onOpen;
    const haveSuggestionValue = props.autosuggestion && suggestionValue !== null;
    return (
        <Labeled {...labelProps}>
            <div
                ref={anchorRef}
                onClick={!props.disabled ? onToggle : undefined}
                tabIndex={props.disabled || haveSuggestionValue ? -1 : 0}
                onFocus={!props.disabled ? onFocus : undefined}
                onKeyDown={!props.disabled ? onKeyDown : undefined}
                onBlur={!props.disabled ? onBlur : undefined}
                className={selectionClass}
            >
                <DropdownSelection
                    autosuggestion={props.autosuggestion}
                    placeholder={props.placeholder}
                    multiSelection={props.multiSelection}
                    disabled={props.disabled}
                    options={props.options}
                    suggestionValue={suggestionValue}
                    onChangeSuggestionValue={onChangeSuggestionValue}
                    value={value}
                    suggestionRef={suggestionInputRef}
                    pillsRef={pillsRef}
                />

                <span className="tq-input-feedback tq-select-trigger">
                    <i className="tq-icon-arrow-down-big" ref={arrowIconRef} />
                </span>
            </div>
            <Popover
                extraClasses={
                    props.popoverClasses
                        ? `${props.popoverClasses} un-ds-dropdown-options`
                        : `un-ds-dropdown-options`
                }
                preferredPosition="bottom-right"
                minWidthToAnchor={props.popoverMinWidthToAnchor}
                maxWidthToAnchor={props.popoverMaxWidthToAnchor}
                anchor={anchorRef.current}
                active={active}
            >
                <ul className="tq-drop-down-options" ref={contentRef}>
                    <Scrollbar
                        ref={scrollbarRef}
                        hideTracksWhenNotNeeded
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={200}
                    >
                        {buildOptionsProps().map((optionProps) => (
                            <DropdownOption {...optionProps} key={optionProps.key} />
                        ))}
                    </Scrollbar>
                </ul>
            </Popover>
        </Labeled>
    );
}

export interface IDropdownProps extends ILabeledProps, IBaseComponentProps, IEventHandlers {
    autosuggestion?: boolean;
    filterStrategy: "includes" | "startsWith";
    autosuggestionServerSide?: boolean;
    popoverClasses?: string;
    placeholder?: string;
    multiSelection: boolean;
    options: IDropdownOptionProps[];
    optionsWithCheckboxes?: boolean;
    truncateLongValue?: boolean;
    popoverMinWidthToAnchor?: boolean;
    popoverMaxWidthToAnchor?: boolean;
    onChangeSuggestionValue?: (...args: any[]) => any | void;
    selectHighlightedOptionOnBlur?: boolean;
    clearSuggestionInputOnFocus?: boolean;
    preserveSuggestionValue?: boolean;
}

export const IDropdownPropsKeys = [
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    ...ILabeledPropsKeys,
    "autosuggestion",
    "filterStrategy",
    "autosuggestionServerSide",
    "popoverClasses",
    "placeholder",
    "multiSelection",
    "options",
    "optionsWithCheckboxes",
    "truncateLongValue",
    "popoverMinWidthToAnchor",
    "popoverMaxWidthToAnchor",
    "onChangeSuggestionValue",
    "selectHighlightedOptionOnBlur",
    "clearSuggestionInputOnFocus",
    "preserveSuggestionValue"
];
