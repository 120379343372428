import React, { useEffect, useRef, useState } from "react";
import DomUtils from "@tinqin/tinqin-utils/src/dom";
import Scrollbar from "@tinqin/tinqin-react-custom-scrollbars/lib";

export default function useDropdown(props) {
    const [active, setActive] = useState<boolean>(false);
    const [suggestionValue, setSuggestionValue] = useState<string | null>(null);
    const [value, setValue] = useState<string[]>(props.value.length ? props.value.split(",") : []);
    const [keyboardMarker, setKeyboardMarker] = useState<IKeyboardMarker>({
        value: "",
        visible: false
    });

    const valRef = useRef<string[]>(value);
    const anchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLUListElement>(null);
    const scrollbarRef = useRef<typeof Scrollbar>(null);
    const suggestionInputRef = useRef<HTMLInputElement>(null);
    const arrowIconRef = useRef<HTMLElement>(null);
    const pillsRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const propsValue = props.value.length ? props.value.split(",") : [];
        if (!arraysEqualIgnoringOrder(propsValue, value)) {
            setValue(propsValue);
            valRef.current = propsValue;
        }
    }, [props.value]);

    useEffect(() => {
        if (active && !props.multiSelection && value.length) {
            const items = contentRef.current;
            if (items) {
                const selected: HTMLLIElement | null = items.querySelector(
                    `li[data-code='${value[0]}']`
                );
                let scrollTop = 0;
                if (selected && selected.offsetTop > 0) {
                    scrollTop = selected.offsetTop - selected.offsetHeight;
                }
                scrollbarRef.current.scrollTop(scrollTop);
            }
        }
    }, [active, value, props.multiSelection]);

    useEffect(() => {
        if (
            props.autosuggestionServerSide &&
            props.options.length &&
            keyboardMarker.value !== props.options[0].value
        ) {
            setKeyboardMarker({ value: props.options[0].value, visible: true });
        }
    }, [JSON.stringify(props.options)]);

    function manageSelection(code) {
        if (!props.multiSelection) {
            return [code];
        } else {
            const filteredSelection = value.filter((selectedItem) => {
                return selectedItem !== code;
            });
            if (filteredSelection.length !== value.length) {
                return filteredSelection;
            } else {
                const items = Array.from(value);
                items.push(code);
                return items;
            }
        }
    }

    function onChange(event: React.ChangeEvent, newCode: string) {
        event.stopPropagation();
        if (suggestionValue !== null) {
            setSuggestionValue(null);
        }
        const code = newCode || event.currentTarget.getAttribute("data-code");
        const options = props.options;
        const option = options.find((option) => option.value === code);
        if (option && option.disabled) {
            //We do nothing on attempt to select disabled item!
            return;
        }
        const newSelection = manageSelection(code);
        if (!arraysEqualIgnoringOrder(newSelection, value)) {
            valRef.current = newSelection;
            setValue(newSelection);
            props.onChange && props.onChange(newSelection.join(","));
        }
        if (!props.multiSelection && active) {
            onClose(event);
        }
    }

    function onBlur(event) {
        const clickOnArrowIcon = arrowIconRef.current && event.target === arrowIconRef.current;
        if (clickOnArrowIcon) {
            return;
        }
        const outsideContent = DomUtils.isClickOutside(event, contentRef.current);
        const newFocusedElement = event.relatedTarget || document.activeElement;
        const newFocusIsDeleteIcon = newFocusedElement.getAttribute("data-target") === "deleteX";
        const newFocusOnAnchor = anchorRef.current === newFocusedElement;
        const outsidePillsContainer = DomUtils.isClickOutside(event, pillsRef.current);
        const outsideAnchor = DomUtils.isClickOutside(event, anchorRef.current);

        if (!outsideContent || (newFocusIsDeleteIcon && !outsidePillsContainer)) {
            //Note: If we blur on option, we must invoke change with that option.
            const newSelectionCode: string = newFocusedElement.getAttribute("data-code");
            onChange(event, newSelectionCode);
            anchorRef?.current?.focus?.();
        } else if (outsidePillsContainer && !newFocusOnAnchor) {
            if (props.autosuggestion) {
                if (props.selectHighlightedOptionOnBlur && keyboardMarker.value && active) {
                    onChange(event, keyboardMarker.value);
                } else if (suggestionValue) {
                    const suggestionValueLowerCase = suggestionValue.toLowerCase();
                    const optionMatch = props.options.find(
                        (option) => option.label.toLowerCase() === suggestionValueLowerCase
                    );
                    if (optionMatch) {
                        onChange(event, optionMatch.value);
                    }
                } else if (suggestionValue === "") {
                    setSuggestionValue(null);
                }
            }

            if (active) {
                onClose(event);
            }

            if (outsideAnchor && props.onBlur) {
                props.onBlur();
            }
        }
    }

    function onFocus(event) {
        if (
            props.autosuggestion &&
            props.clearSuggestionInputOnFocus &&
            event.target === suggestionInputRef.current
        ) {
            setSuggestionValue("");
        }
        const newFocusedElement = event.target;
        const dataTarget = newFocusedElement.getAttribute("data-target");
        const isDeleteIcon = dataTarget === "deleteX";
        if (isDeleteIcon) {
            const newCode = newFocusedElement.getAttribute("data-code");
            onChange(event, newCode);
        }
        props.onFocus && props.onFocus();
    }

    function onOpen() {
        setActive(true);
        props.onOpen && props.onOpen();
        if (!props.multiSelection && props.options.length) {
            if (value[0]) {
                if (keyboardMarker.value !== value[0]) {
                    setKeyboardMarker({ value: value[0], visible: false });
                }
            } else if (keyboardMarker.value !== null) {
                setKeyboardMarker({ value: "", visible: false });
            }
        } else if (keyboardMarker.value !== null) {
            setKeyboardMarker({ value: "", visible: false });
        }
        //Whenever Dropdown is opened the focus is in the suggestion input.
        if (props.autosuggestion) {
            suggestionInputRef.current?.focus?.();
        }
    }

    function onClose(event: React.ChangeEvent) {
        if (event.type === "click") {
            const outsidePillsContainer = DomUtils.isClickOutside(event, pillsRef.current);
            const outsideAnchor = DomUtils.isClickOutside(event, anchorRef.current);
            if (!outsidePillsContainer && outsideAnchor)
                //In case we are in multi selection with pills, clicking inside pill container,
                //will not toggle the Dropdown active state!
                return;
        }
        if (
            props.selectHighlightedOptionOnBlur &&
            keyboardMarker.value &&
            keyboardMarker.visible &&
            valRef.current[0] !== keyboardMarker.value &&
            //if the click is inside options, ignore the keyboardMarker
            DomUtils.isClickOutside(event, contentRef.current) &&
            event["key"] !== "Escape"
        ) {
            onChange(event, keyboardMarker.value);
        } else if (!props.preserveSuggestionValue && suggestionValue !== null) {
            setSuggestionValue(null);
        }

        setKeyboardMarker({ value: "", visible: false });
        setActive(false);
        props.onClose && props.onClose();
    }

    function onEnter(event) {
        event.preventDefault();
        if (!active) {
            event.stopPropagation();
            onOpen();
        } else {
            if (props.multiSelection) {
                if (keyboardMarker.value) {
                    onChange(event, keyboardMarker.value);
                } else {
                    onClose(event);
                }
            } else if (keyboardMarker.value) {
                onChange(event, keyboardMarker.value);
            } else {
                onClose(event);
            }
        }
    }

    function getNewSelectionIndex(eventKey, enabledOptions) {
        let selectionIndex = 0;
        if (value.length) {
            const currentSelectionIndex = enabledOptions.indexOf(value[0]);
            selectionIndex = currentSelectionIndex - 1;
            if (!currentSelectionIndex) {
                selectionIndex = enabledOptions.length - 1;
            }
            if (eventKey === "ArrowDown") {
                selectionIndex = currentSelectionIndex + 1;
                if (currentSelectionIndex === enabledOptions.length - 1) {
                    selectionIndex = 0;
                }
            }
        }
        return selectionIndex;
    }

    function manageKeyboardMarker(eventKey: "ArrowUp" | "ArrowDown") {
        const filteredOptions = filterOptions(suggestionValue);
        const options = filteredOptions.map((i) => i.value);
        const marker = keyboardMarker.value;
        const optionIndex = marker ? options.findIndex((option) => option === marker) : -1;
        const newMarkerIndex =
            eventKey === "ArrowUp"
                ? Math.max(optionIndex - 1, 0)
                : Math.min(optionIndex + 1, options.length - 1);

        setKeyboardMarker({ value: options[newMarkerIndex], visible: true });
    }

    function onArrows(event) {
        event.preventDefault();
        if (active) {
            manageKeyboardMarker(event.key);
        } else if (!props.multiSelection) {
            let enabledOptions;
            if (props.autosuggestion) {
                enabledOptions = filterOptions(suggestionValue)
                    .map((option) => {
                        if (!option.disabled) {
                            return option.value;
                        }
                    })
                    .filter(Boolean);
            } else {
                enabledOptions = props.options
                    .map((option) => {
                        if (!option.disabled) {
                            return option.value;
                        }
                    })
                    .filter(Boolean);
            }
            const newSelectionIndex = getNewSelectionIndex(event.key, enabledOptions);
            onChange(event, enabledOptions[newSelectionIndex]);
        }
    }

    function onEscape(event) {
        event.preventDefault();
        if (active) {
            onClose(event);
        }
    }

    function onKeyDown(event) {
        //
        switch (event.key) {
            case "Enter":
                onEnter(event);
                break;
            case "ArrowUp":
            case "ArrowDown": {
                onArrows(event);
                break;
            }
            case "Escape": {
                onEscape(event);
                break;
            }
            case "Tab": {
                return;
            }
            default: {
                if (props.autosuggestion && !active && suggestionValue === null) {
                    //ignore special keys
                    const isInputSymbol = event.key.length === 1;
                    //Note: Android does not support onKeyDown properly.
                    const superSpecialAndroidCase =
                        event.key === "Unidentified" && event.keyCode === 229;
                    if (
                        event.key &&
                        event.key !== " " && //ignore "Space" key, which key is " " for unknown reasons
                        (isInputSymbol || superSpecialAndroidCase) &&
                        !event.ctrlKey &&
                        !event.altKey
                    ) {
                        onOpen();
                    }
                }
                return;
            }
        }
    }
    function onChangeSuggestionValue(event) {
        if (!active) {
            setActive(true);
        }
        setSuggestionValue(event.currentTarget.value);
        if (!props.autosuggestionServerSide) {
            const filteredOptions = filterOptions(event.currentTarget.value);
            const newMarker = filteredOptions.length ? filteredOptions[0].value : "";
            if (
                keyboardMarker.value !== newMarker ||
                keyboardMarker.visible !== !!newMarker.length
            ) {
                setKeyboardMarker({ value: newMarker, visible: !!newMarker.length });
            }
        }
        props.onChangeSuggestionValue &&
            props.onChangeSuggestionValue({ suggestionValue: event.currentTarget.value });
    }

    function filterOptions(suggestionVal) {
        if (props.autosuggestionServerSide) {
            return props.options;
        }
        const filter = (suggestionVal || "").toLowerCase();
        return props.options.filter((option) => {
            const optionLabel = (option.label || "").toLowerCase();
            if (props.filterStrategy === "startsWith") {
                return optionLabel.indexOf(filter) === 0;
            } else {
                return optionLabel.includes(filter);
            }
        });
    }

    function buildOptionsProps() {
        let options: any = [...props.options];
        if (props.autosuggestion) {
            options = filterOptions(suggestionValue);
        }

        return options.map((option, index) => {
            let selected = value?.includes(option.value);
            return {
                key: `option-${index}`,
                withCheckbox: props.multiSelection && props.optionsWithCheckboxes,
                selected: selected,
                value: option.value,
                label: option.label,
                selectedLabel: option.selectedLabel,
                disabled: option.disabled,
                scrollbarRef: scrollbarRef,
                keyboardMarker: keyboardMarker
            };
        });
    }

    return {
        // callbacks
        onKeyDown,
        onBlur,
        onFocus,
        onChangeSuggestionValue,
        onClose,
        onOpen,
        buildOptionsProps,
        // refs
        anchorRef,
        contentRef,
        scrollbarRef,
        suggestionInputRef,
        arrowIconRef,
        pillsRef,
        // state
        active,
        suggestionValue,
        value
    };
}

const arraysEqualIgnoringOrder = (a, b) => {
    a.sort();
    b.sort();
    return a.length === b.length && a.every((element, index) => element === b[index]);
};

interface IKeyboardMarker {
    value: string;
    visible: boolean;
}
