"use client";
import React, { useState, useEffect, useRef } from "react";
import FileUtils from "@tinqin/tinqin-utils/src/file";
import FileUploadDragDrop, { IFile } from "./FileUploadDragDrop";
import { isEqual, getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import {
    IBaseComponentProps,
    ILabeledProps,
    IEventHandlers,
    ILabeledPropsKeys,
    IEventHandlersKeys,
    IBaseComponentPropsKeys
} from "../../../types";

interface IFileUploadProps extends IBaseComponentProps, ILabeledProps, IEventHandlers {
    onFileRemoved?: (...args: any[]) => any | void;
    title?: string;
    subTitle?: string;
    name?: string;
    accept?: string;
    singleFile?: boolean;
    disabled: boolean;
    maxFiles?: number;
    files?: IFile[];
    inputReference: React.RefObject<any>;
    onExceedMaxUploads?: (...args: any[]) => any | void;
    failedValidations?: Record<string, any>;
}

interface IStateProps {
    active?: boolean;
    valid?: boolean;
    files?: IFile[];
    failedValidations?: Record<string, any>;
}

const FileUpload: React.FC<IFileUploadProps> = (props) => {
    const [active, setActive] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean | undefined>(props.valid);
    const [files, setFiles] = useState<IFile[]>(props.files || []);
    const [failedValidations, setFailedValidations] = useState<Record<string, any> | undefined>(
        props.failedValidations || {}
    );
    const fileRef = useRef<HTMLInputElement>();

    useEffect(() => {
        let newFilesSame = true;
        const validFiles = true;
        const state: IStateProps = {};

        if (props.files && props.files.length !== files.length) {
            newFilesSame = false;
            state.failedValidations = {};
        } else {
            for (let i = 0; i < files.length; i++) {
                const areFilesSame =
                    newFilesSame &&
                    props.files &&
                    props.files[i] &&
                    FileUtils.areEqual(props.files[i], files[i]);
                if (areFilesSame !== undefined) {
                    newFilesSame = areFilesSame;
                }
            }
        }

        if (!newFilesSame) {
            state.files = (validFiles && props.files) || [];
        }
        if (props.valid !== valid) {
            state.valid = props.valid;
        }
        if (state.files && state.files.length && props.valid === valid) {
            state.valid = undefined;
        }

        if (!isEqual(props.failedValidations, failedValidations)) {
            state.failedValidations = props.failedValidations;
            state.valid = !!props.failedValidations?.length;
        }

        if (state.valid) setValid(state.valid);
        if (state.files) setFiles(state.files);
        if (state.failedValidations) setFailedValidations(state.failedValidations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.files, props.failedValidations, props.valid]);

    const onChange = (event) => {
        const stateFiles: IFile[] = files || [];
        const maxFiles: number = props.maxFiles || Infinity;
        let newFiles: IFile[] = [];
        if (event.dataTransfer) {
            const dt = event.dataTransfer;
            if (dt.files && dt.files.length) {
                for (const element of dt.files) {
                    newFiles.push(element);
                }
            } else if (dt.items && dt.items.length) {
                // During the drag even the dataTransfer.files is null
                // but Chrome implements some drag store, which is accesible via dataTransfer.items
                for (const element of dt.items) {
                    newFiles.push(element);
                }
            }
        } else if (event.target && event.target.files) {
            for (const element of event.target.files) {
                newFiles.push(element);
            }
        }

        const newFilesCount = newFiles.length || 0;
        let filesToSet: IFile[];
        let duplicatedFiles: number = 0;
        //Goes through the new files and adds the unique ones to the ones already stored in state.
        //If no files are stored in the state or if the component has the singleFile property,
        // replace the state with the newly passed file or files.
        if (!props.singleFile && stateFiles.length) {
            filesToSet = stateFiles.slice();
            for (const newFile of newFiles) {
                const existingFile = stateFiles.find(function (f) {
                    return FileUtils.areEqual(f, newFile);
                });
                if (!existingFile) {
                    if (filesToSet.length < maxFiles) filesToSet[filesToSet.length] = newFile;
                } else {
                    duplicatedFiles++;
                }
            }
        } else {
            newFiles = newFiles.slice(0, Math.min(newFiles.length, maxFiles));
            filesToSet = newFiles;
        }

        const stateToSet: IStateProps = {
            valid: undefined,
            failedValidations: {}
        };
        stateToSet.files = filesToSet;
        const attemptedFiles = stateFiles.length + newFilesCount - duplicatedFiles;
        setValid(stateToSet.valid);
        setFailedValidations(stateToSet.failedValidations);
        setFiles(stateToSet.files);
        if (props.onChange) {
            props.onChange({ valid, files, active, failedValidations, ...stateToSet }, props);
        }
        if (attemptedFiles > maxFiles) {
            if (props.onExceedMaxUploads) {
                props.onExceedMaxUploads(
                    { valid, files, active, failedValidations, ...stateToSet },
                    props
                );
            }
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur({ valid: true, files, failedValidations });
        }
    };

    const onFileRemoved = (event) => {
        event.stopPropagation();
        const index = event.currentTarget.getAttribute("data-file-index");
        const modifiedFiles = files.slice();
        modifiedFiles.splice(index, 1);
        const stateToSet: IStateProps = {
            valid: undefined,
            failedValidations: []
        };
        stateToSet.files = modifiedFiles;
        setValid(stateToSet.valid);
        setFailedValidations(stateToSet.failedValidations);
        setFiles(stateToSet.files);
        if (props.onChange) {
            props.onChange({ valid, files, active, failedValidations, ...stateToSet }, props);
        }
        if (props.onFileRemoved) {
            props.onFileRemoved({ valid, files, active, failedValidations, ...stateToSet }, props);
        }
    };

    const onOpen = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
        setActive(true);
        if (props.onOpen) {
            props.onOpen({ valid: true, files, failedValidations });
        }
    };

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus({ valid, files, failedValidations, active });
        }
    };

    const getFailedValidationMessage = () => {
        if (failedValidations?.length) {
            return (
                <h6 className="tq-title tq-text-center tq-red tq-error-feedback">
                    {failedValidations.map((message: string, index: number) => (
                        <span key={index + message} className="tq-text-node">
                            {message}
                        </span>
                    ))}
                </h6>
            );
        }
        return null;
    };

    //Deal with Labeled component props.
    const valueContainerClass = "tq-file-upload tq-offset-bottom-s tq-drag-file-container";
    const labelProps = getLabelProps(props, { valid, valueContainerClass });
    // labelProps.errorMessage = props.errorMessage; ??

    return (
        <Labeled {...labelProps} skipLabel={!labelProps.label}>
            <div data-files-id={props.dataTqId}>
                <FileUploadDragDrop
                    {...props}
                    inputReference={fileRef}
                    files={files}
                    onFileRemoved={onFileRemoved}
                    onClick={!props.disabled ? onOpen : undefined}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {getFailedValidationMessage()}
            </div>
        </Labeled>
    );
};

export default FileUpload;

export const IFileUploadPropsKeys = [
    ...IEventHandlersKeys,
    ...IBaseComponentPropsKeys,
    ...ILabeledPropsKeys,
    "onFileRemoved",
    "title",
    "subTitle",
    "name",
    "accept",
    "singleFile",
    "disabled",
    "maxFiles",
    "files",
    "inputReference",
    "onExceedMaxUploads"
];
