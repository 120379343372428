import ruleDictionary from "./rules";
import { EvaluationResult } from "./../evaluation/types";
import { ApplicationRule } from "./types";
import { Entity } from "@uneo/platform-commons/webui/types";

export const evaluateRule = (
    applicationRule: ApplicationRule,
    steps: Entity[],
    dictionary: Record<string, any> = ruleDictionary
): EvaluationResult => {
    const ruleName: string | null = applicationRule?.id || null;
    if (ruleName && dictionary[ruleName]) {
        if (steps) {
            return dictionary[ruleName](steps);
        } else {
            return dictionary[ruleName](applicationRule);
        }
    } else {
        return dictionary["unknown"](ruleName);
    }
};

export default {
    evaluate: evaluateRule
};
