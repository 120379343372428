import { FunctionElement, LiteralFunctionElementFactory } from "../types";

export type LiteralFunctionElementNames =
    | "string"
    | "number"
    | "boolean"
    | "null"
    | "undefined"
    | "unknown";

//Record<string, FunctionElement>
export const dictionary: Record<LiteralFunctionElementNames, LiteralFunctionElementFactory> = {
    string: (value: string): FunctionElement => ({
        name: "string",
        evaluate: () => value,
        toString: () => `"${value}"`
    }),
    number: (value: number): FunctionElement => ({
        name: "number",
        evaluate: () => value,
        toString: () => "" + value
    }),
    boolean: (value: boolean): FunctionElement => ({
        name: "boolean",
        evaluate: () => value,
        toString: () => "" + value
    }),
    null: (): FunctionElement => ({
        name: "null",
        evaluate: () => null,
        toString: () => "null"
    }),
    undefined: (): FunctionElement => ({
        name: "undefined",
        evaluate: () => undefined,
        toString: () => "undefined"
    }),
    unknown: (): FunctionElement => ({
        name: "unknown",
        evaluate: () => Promise.reject(`unknown result`),
        toString: () => `unknown function element`
    })
};
