"use client";
import React from "react";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "../Labeled/Labeled";
import {
    IBaseComponentProps,
    IBaseComponentPropsKeys,
    ILabeledProps,
    ILabeledPropsKeys,
    IEventHandlers,
    IEventHandlersKeys
} from "../../../types";
import { ComponentEnumEntity } from "../../../utils/commonTypes";
import RadioOption from "./RadioOption";

const RadioGroup = (props: IRadioGroupProps) => {
    const value = props.value || "";

    const buildItems = (items: ComponentEnumEntity[] = []) => {
        return items.map((item, index) => {
            let itemProperties: Partial<ComponentEnumEntity["properties"]> = item.properties || {};
            let disabled = props.disabled;
            if (Object.prototype.hasOwnProperty.call(itemProperties, "disabled")) {
                disabled = itemProperties.disabled;
            }
            return (
                <RadioOption
                    active={value === itemProperties.value}
                    value={itemProperties.value}
                    label={itemProperties.label}
                    key={index}
                    disabled={disabled}
                    onClick={!disabled ? onChange : undefined}
                />
            );
        });
    };

    const onChange = (event) => {
        let dataValue = event.currentTarget.getAttribute("data-value");
        if (dataValue !== value && props.onChange) {
            props.onChange({ value: dataValue });
        }
    };
    let valueContainerClass = "tq-radio-group";
    if (!props.verticalOptions) {
        valueContainerClass += " tq-horizontal-group";
    }
    if (props.valueContainerClass) {
        valueContainerClass = props.valueContainerClass;
    }
    let labelProps = getLabelProps(props, {
        containerClass: props.extraClasses || "",
        valueContainerClass: valueContainerClass,
        skipLabel: !props.label //RadioGroup historically worked in a bit different way - skip label if not provided!
    });
    return (
        <Labeled {...labelProps} role="radiogroup">
            {buildItems(props.entities)}
        </Labeled>
    );
};

interface IRadioGroupProps extends ILabeledProps, IBaseComponentProps, IEventHandlers {
    verticalOptions?: boolean;
    entities: ComponentEnumEntity[];
}

export const IRadioGroupPropKeys = [
    ...ILabeledPropsKeys,
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    "verticalOptions",
    "entities"
];
export default RadioGroup;
