import { triggerExplicitValidations } from "@uneo/platform-commons/platform/validations/ValidateEntity";
import { ValidationRule } from "@uneo/platform-commons/platform/validations/interfaces";

export const ValidationTooltip: React.FC<any> = (props: {
    title?: string;
    value: string;
    validations: ValidationRule[];
}) => {
    const validations = props.validations || [];
    if (!validations.length) {
        return null;
    }

    const validationResult = triggerExplicitValidations(props.value, validations);
    return (
        <div className="un-ds-checklist-strength un-popover-arrow">
            {props.title && <p>{props.title}</p>}
            <ul>
                {validations.map((validation, index) => {
                    if (!props.value) {
                        return <li key={`validation-${index}`}>{validation.message}</li>;
                    }
                    return (
                        <li
                            key={`validation-${index}`}
                            className={
                                validationResult.find((i) => i.message === validation.message)
                                    ? "fail"
                                    : "success"
                            }
                        >
                            {validation.message}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
