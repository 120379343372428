export const fileIcons = {
    pdf: "tq-o-icon-pdf",
    word: "tq-icon-document-word-big",
    excel: "tq-icon-document-excel-big",
    text: "tq-icon-document-txt-big",
    svg: "tq-icon-document-svg-big",
    presentation: "tq-icon-document-ppt-big",
    archive: "tq-icon-document-zip-big",
    xml: "tq-icon-document-xml-big"
};

export const fileMimeTypes = {
    "image/svg+xml": "svg",

    "application/zip": "archive",
    "application/x-zip-compressed": "archive",
    "application/x-rar-compressed": "archive",
    "application/x-7z-compressed": "archive",
    "application/x-tar": "archive",
    "application/x-gtar": "archive",

    "application/msword": "word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template": "word",
    "application/vnd.ms-word.document.macroEnabled.12": "word",
    "application/vnd.ms-word.template.macroEnabled.12": "word",

    "application/vnd.ms-excel": "excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": "excel",
    "application/vnd.ms-excel.sheet.macroEnabled.12": "excel",
    "application/vnd.ms-excel.template.macroEnabled.12": "excel",
    "application/vnd.ms-excel.addin.macroEnabled.12": "excel",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": "excel",

    "application/vnd.ms-powerpoint": "presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template": "presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow": "presentation",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12": "presentation",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12": "presentation",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": "presentation",

    "application/pdf": "pdf",
    "application/x-pdf": "pdf",
    "application/x-bzpdf": "pdf",
    "application/x-gzpdf": "pdf",

    "application/xml": "xml",
    "text/xml": "xml",

    "text/plain": "text",

    "image/gif": "image",
    "image/x-icon": "image",
    "image/jpeg": "image",
    "image/png": "image"
};
