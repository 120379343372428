import { fromSirenEntityToEntity } from "../../webui/adapters";
import { Action, ActionContext, Entity } from "../../webui/types";
import {
    deleteEntity as deleteStoreEntity,
    getEntity,
    getWidget,
    traverseEntityTree,
    updateEntity as updateStoreEntity,
    updateEntityProps,
    updateEntityUiState,
    updateWidget
} from "../../webui/store/actions";
import { fetchData, fetchFile } from "../../platform/httpClient/httpClient";
import { removeMessage, showMessage } from "./messages";
import { createMultipartFormData } from "../utils/serialization/createMultipartFormData";
import { downloadBinary } from "./download";
import { closeModal, openModal } from "./modal";
import { generateFormData } from "../utils/serialization/formData";
import { evaluateCondition } from "@uneo/platform-oav/actions/evaluateCondition";
import { toggleQuestion } from "@uneo/platform-oav/actions/toggleQuestion";
import refresh from "@uneo/platform-oav/actions/refresh";
import { validateTargets } from "../validations/ValidateTargets";
import { ValidationResult } from "../validations/interfaces";
import {
    generateErrorsEntity,
    generateErrorWidgetEntity,
    isExceptionResponse
} from "../utils/exceptions";
import { loadFeature } from "../features";
import { updateCoverageSelection } from "@uneo/platform-oav/widgets/quote/actions/manageCoverageSelection";
import formSubmit from "./formSubmit";
import { getActionUrl } from "../services/urlService";
import { getUID } from "../services/uid";
import { widgetLoaded } from "../../webui/store/events";
import { PORTAL_WIDGET_ID, ROOT_WIDGET } from "../constants";
import { getParentPath } from "../../webui/utils/paths";
import { ERRORS_ENTITY, METHOD_HEAD, METHOD_POST, SUCCESS_ENTITY } from "./constants";
import { saveScrollPositionForCurrentUrl, updateUrlsScrollList } from "./manageScrollRestoration";
import { refreshSession } from "./refreshSession";

// switchContainer special use cases:
// switchContainer + switchContainer
// switchContainer + openTarget
// switchContainer + showMessage
// switchContainer + errors response
const switchContainer = async (action: Action, actionContext: ActionContext) => {
    refreshSession().then();
    const url = getActionUrl(action, actionContext);
    let target = action.targets?.[0] || ROOT_WIDGET;
    //on browser actions, history state should not be changed
    if (action.properties && action.properties.address) {
        saveScrollPositionForCurrentUrl();
        history.pushState("", "", action.properties.address);
        updateUrlsScrollList();
    }
    if (target === ROOT_WIDGET || target === PORTAL_WIDGET_ID) {
        target = PORTAL_WIDGET_ID;
        const widgetData = await fetchData({ url, actionContext });
        if (isExceptionResponse(widgetData)) {
            const errorData = generateErrorWidgetEntity(target);
            updateWidget({
                id: target,
                url: url,
                loadingState: 0,
                data: errorData
            });
        }
        updateWidget({
            id: target,
            url: url,
            loadingState: 0,
            data: fromSirenEntityToEntity(widgetData)
        });
        return;
    }
    const widgetInfo = getWidget(target);
    if (!widgetInfo) {
        console.error("Unknown target widget", target);
        return;
    }
    const parentWidgetId = widgetInfo?.parentId;
    const newWidgetProps = Object.assign({ id: target }, action.properties || {}, {
        href: url,
        //resetting parameters
        parameters: {},
        dataCacheId: getUID()
    });
    updateEntityProps({ widgetId: parentWidgetId, entityPath: target, props: newWidgetProps });
    //in case of followup actions, wait for new widget to load data
    if (action.actions) {
        await widgetLoaded(target);
    }
};

const openTarget = async (action: Action, context: ActionContext) => {
    deleteStoreEntity({
        widgetId: context?.widgetId,
        entityPath: context?.currentEntityPath + "." + SUCCESS_ENTITY,
        currentEntityPath: context?.currentEntityPath
    });
    deleteStoreEntity({
        widgetId: context?.widgetId,
        entityPath: context?.currentEntityPath + "." + ERRORS_ENTITY,
        currentEntityPath: context?.currentEntityPath
    });
    const url = getActionUrl(action, context);
    if (await checkTargetLocation(url)) {
        const target = action.properties?.target || "_self";
        if (!isIOSMobileApp()) {
            window.open(url, target);
            //(UPF-1776)Opening new page in the current tab takes time during which "openTarget" action should remain unresolved to preserve loading animations
            if (target === "_self") {
                await new Promise(() => {
                    // unresolvable promise
                });
            }
        } else {
            //We are in iOS app and use the window.open fallback with anchor created and click
            openExternalLinkFallback({ url, target });
        }
        //Use case: download button "success" visualization for opened documents
        if (target === "_blank") {
            updateStoreEntity({
                widgetId: context.widgetId,
                entityPath: context.currentEntityPath + "." + SUCCESS_ENTITY,
                currentEntityPath: context.currentEntityPath,
                entity: { id: "success", properties: { value: "true" } }
            });
        }
    } else {
        updateStoreEntity({
            widgetId: context.widgetId,
            entityPath: context.currentEntityPath + "." + ERRORS_ENTITY,
            currentEntityPath: context.currentEntityPath,
            entity: generateErrorsEntity(
                "Une erreur est survenue lors du téléchargement. Veuillez réessayer plus tard."
            )
        });
    }
};

const checkTargetLocation = async (url: string): Promise<boolean> => {
    //skip checking external locations
    if (!url || !url.startsWith("/")) {
        return true;
    }
    const response = await fetchFile({
        url,
        fetchOptions: {
            method: METHOD_HEAD
        }
    });
    return !!response.ok;
};

const validate = async (action: Action, context: ActionContext) => {
    await loadFeature("validations");
    const result = validateTargets(action.targets, context);
    updateValidationState(action.targets || [], result, context);
    if (Object.keys(result).length) {
        console.log("Validation failed:");
        throw new Error("validation failed");
    }
};

const update = async (action: Action, context: ActionContext) => {
    refreshSession().then();
    let fetchOptions = {};
    const url = getActionUrl(action, context);
    if (action.method === METHOD_POST) {
        const sources = action.sources || [];
        const entities: Entity[] = [];
        sources.forEach((source) => {
            const foundEntity = getEntity({
                widgetId: context.widgetId,
                entityPath: source,
                currentEntityPath: context.currentEntityPath
            });
            if (foundEntity) {
                entities.push(foundEntity);
            } else {
                console.error("Entity not found,", source);
            }
        });
        const formData = generateFormData(entities);
        if (formData.files) {
            const body = createMultipartFormData(formData);
            fetchOptions = {
                method: action.method,
                body,
                headers: {
                    "Content-Type": undefined
                }
            };
        } else {
            fetchOptions = { method: action.method, body: JSON.stringify(formData) };
        }
    }

    const parentEntityPath = context.currentEntityPath && getParentPath(context.currentEntityPath);
    if (parentEntityPath) {
        const errorsPath = parentEntityPath + "." + ERRORS_ENTITY;
        const successPath = parentEntityPath + "." + SUCCESS_ENTITY;
        //clear previous error messages
        deleteStoreEntity({
            widgetId: context.widgetId,
            entityPath: errorsPath,
            currentEntityPath: context.currentEntityPath
        });
        deleteStoreEntity({
            widgetId: context.widgetId,
            entityPath: successPath,
            currentEntityPath: context.currentEntityPath
        });
    }

    const result = await fetchData({
        url,
        fetchOptions,
        actionContext: context
    });
    if (result) {
        //Add unique key like timestamp to track different updates
        result.properties = result.properties || {};
        result.properties.key = Date.now();
    }
    if (isExceptionResponse(result)) {
        let errorsTarget = "errors";
        if (action.targets && action.targets[0].includes("errors")) {
            errorsTarget = action.targets[0];
        }
        const errorMessage = generateErrorsEntity();
        updateStoreEntity({
            widgetId: context.widgetId,
            entityPath: errorsTarget,
            currentEntityPath: context.currentEntityPath,
            entity: errorMessage
        });
        return;
    }
    if (isMultiTargetResponse(result)) {
        Object.keys(result).forEach((target) => {
            updateStoreEntity({
                widgetId: context.widgetId,
                entityPath: target,
                currentEntityPath: context.currentEntityPath,
                entity: fromSirenEntityToEntity(result[target])
            });
        });
        return;
    }
    //check for missing content
    //if there is not content no data is saved
    if (action.targets && result) {
        action.targets.forEach((target) => {
            updateStoreEntity({
                widgetId: context.widgetId,
                entityPath: target,
                currentEntityPath: context.currentEntityPath,
                entity: fromSirenEntityToEntity(result)
            });
        });
    }
};

const updateEntity = async (action: Action, context: ActionContext) => {
    if (action.targets) {
        action.targets.forEach((target) => {
            updateEntityProps({
                widgetId: context.widgetId,
                entityPath: target,
                currentEntityPath: context.currentEntityPath,
                props: action.properties?.targetProperties || {}
            });
        });
    }
};

const openCookieConsentModal = async () => {
    const { tarteaucitron }: any = window;
    if (tarteaucitron) {
        tarteaucitron.userInterface.openPanel();
    }
};

export const actionsMap = {
    switchContainer,
    openTarget,
    validate,
    update,
    loadData: update,
    updateEntity,
    showMessage,
    removeMessage,
    downloadBinary,
    openModal,
    closeModal,
    evaluateCondition,
    toggleQuestion,
    openCookieConsentModal,
    "no-action": () => {
        console.log("no-action");
    },
    "form-submit": formSubmit,
    updateCoverageSelection,
    refresh
};

function updateValidationState(
    targets: string[],
    result: ValidationResult,
    context: ActionContext
) {
    targets.forEach((target) => {
        traverseEntityTree({
            widgetId: context.widgetId,
            entityPath: target,
            fn: (entity) => {
                if (result[entity.contextPath as string]) {
                    updateEntityUiState({
                        widgetId: context.widgetId,
                        entityPath: entity.contextPath as string,
                        uiState: {
                            valid: false,
                            failedValidations: result[entity.contextPath as string]
                        }
                    });
                } else if (entity.uiState?.valid === false && entity.uiState?.failedValidations) {
                    updateEntityUiState({
                        widgetId: context.widgetId,
                        entityPath: entity.contextPath as string,
                        uiState: {
                            valid: true,
                            failedValidations: []
                        }
                    });
                }
            }
        });
    });
}

//Example for multi target response
// {
//     form.success-messages: {
//         class: ["success-messages"],
//         ...
//     }
// }
const isMultiTargetResponse = (response): boolean => {
    const isObject = typeof response === "object";
    if (!isObject) {
        return false;
    }
    const hasKeys = Object.keys(response).length > 0;
    const hasClass = Object.prototype.hasOwnProperty.call(response, "class");
    const allKeysAreObjects = Object.keys(response).every(
        (key) => typeof response[key] === "object"
    );
    return hasKeys && allKeysAreObjects && !hasClass;
};

//PWABuilder adds specific cookie fot the iOS App that we can use for different behavior regarding external links.
//There is an issue with the domains not set in the mobile app App-Bound Domains - https://webkit.org/blog/10882/app-bound-domains/
const isIOSMobileApp = (): boolean => {
    return decodeURIComponent(document.cookie).indexOf("iOS App Store") >= 0;
};

const openExternalLinkFallback = ({
    //Url to be opened
    url = "",
    target = "_blank"
} = {}) => {
    const link: HTMLAnchorElement = document.createElement("a");
    link.target = target;
    link.href = url;
    link.style.display = "none";
    setTimeout(() => {
        link.click();
    }, 100);
};
