"use client";
import React, { useEffect } from "react";
import { useFetchFeature } from "@uneo/platform-commons/platform/features";
import "@tinqin/design-uneo-pf/css/faciliti.css";

let scriptCreationPromise: Promise<void> | null = null;

export default function FacilitiButton({ className }: { className?: string }) {
    const { enabled, settings } = useFetchFeature("faciliti");

    useEffect(() => {
        if (!scriptCreationPromise && enabled) {
            scriptCreationPromise = addScript(settings).catch((error) => {
                console.error(error);
            });
        }
    }, [enabled, settings]);

    if (!enabled) {
        return <></>;
    }

    return (
        <button
            type="button"
            className={className || "un-faciliti-btn"}
            aria-label="FACIL'iti : Adapt the display"
            data-faciliti-popin
        >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src="/img/faciliti-icon.svg" alt="FACIL'iti" />
            <span className="un-faciliti-text">Adaptez l’affichage</span>
        </button>
    );
}

const addScript = async (settings) => {
    const src = settings?.["script-url"];
    const identifier = settings?.["pf-key"];

    if (identifier && src) {
        // Setup Facil'iti
        const fs = document.createElement("script");
        fs.type = "text/javascript";
        fs.src = src;
        fs.dataset.applicationIdentifier = identifier;
        fs.dataset.popinPosition = "left";
        document.head.appendChild(fs);

        return Promise.resolve();
    }
    return Promise.reject();
};
