"use client";
import React from "react";
import PropTypes from "prop-types";
import Calendar from "./Calendar/Calendar";
import DateUtils from "@tinqin/tinqin-utils/src/date";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import ObjectUtils from "@tinqin/tinqin-utils/src/object";
import { getLabelProps, isEqual } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "../Labeled/Labeled";
import Popover from "../../popover/Popover";
import { IBaseComponentPropsKeys, IEventHandlersKeys, ILabeledPropsKeys } from "../../../types";

const supportedFormats = [
    "DD/MM/YYYY",
    "DD-MM-YYYY",
    "DD.MM.YYYY",
    "MM/DD/YYYY",
    "MM-DD-YYYY",
    "MM.DD.YYYY",
    "YYYY/MM/DD",
    "YYYY-MM-DD",
    "YYYY.MM.DD",
    "D/M/YYYY",
    "D-M-YYYY",
    "D.M.YYYY",
    "M/D/YYYY",
    "M-D-YYYY",
    "M.D.YYYY",
    "MM/YYYY",
    "YYYY"
];

export default class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        //lazy loading momentJS
        import(
            /* webpackChunkName: "webui.moment" */
            "@tinqin/tinqin-utils/src/date/guessUserInputDate"
        ).then(({ default: module }) => {
            this.guessUserInputDate = module;
        });
        const value = props.value || [];
        let inputValue = "";
        if (value.length) {
            inputValue = DateUtils.simpleFormat(value[0], props.format);
        }
        this.anchor = React.createRef();
        this.calendar = React.createRef();

        this.state = {
            active: false,
            value: value,
            inputValue: inputValue,
            valid: props.valid,
            failedValidations: props.failedValidations || {}
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onOpenCalendar = this.onOpenCalendar.bind(this);
        this.onBlurInput = this.onBlurInput.bind(this);
        this.onCloseCalendar = this.onCloseCalendar.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const stateToSet = {};
        if (!isEqual(newProps.failedValidations, this.state.failedValidations)) {
            stateToSet.failedValidations = newProps.failedValidations;
        }
        if (newProps.value && !ArrayUtils.shallowEquals(this.state.value, newProps.value)) {
            stateToSet.value = newProps.value;
            if (newProps.value[0]) {
                stateToSet.inputValue = DateUtils.simpleFormat(
                    newProps.value[0],
                    this.props.format
                );
            } else {
                stateToSet.inputValue = "";
            }
        }
        if (newProps.valid !== this.state.valid) {
            stateToSet.valid = newProps.valid;
        } else if (stateToSet.value) {
            //Note: if parent changes our value without taking care of the validations,
            //we set the new value validity to undefined, since it was not validated yet!
            stateToSet.valid = undefined;
            stateToSet.failedValidations = {};
        }
        if (!ObjectUtils.isEmpty(stateToSet)) {
            this.setState(stateToSet);
        }
    }

    componentDidUpdate() {
        if (this.state.active && this.calendar && this.calendar.current) {
            this.calendar.current.focus();
        }
    }

    onOpenCalendar() {
        if (!this.props.disabled) {
            if (!this.state.active) {
                this.setState({ active: true }, () => {
                    if (this.props.onOpen) {
                        this.props.onOpen(this.state);
                    }
                });
            }
        }
    }

    onCloseCalendar(calendarState) {
        const selection = calendarState.selection;
        const stateToSet = { active: false };
        if (selection.length && this.state.valid === false) {
            stateToSet.failedValidations = {};
            stateToSet.valid = undefined;
        }
        if (!ArrayUtils.shallowEquals(this.state.value, selection)) {
            stateToSet.value = selection;
            if (selection[0]) {
                stateToSet.inputValue = DateUtils.simpleFormat(selection[0], this.props.format);
            } else {
                stateToSet.inputValue = "";
            }
        }
        this.setState(stateToSet, () => {
            if (this.props.onBlur) {
                this.props.onBlur(this.state);
            }
            if (this.props.onClose) {
                this.props.onClose(this.state);
            }
        });
    }

    onInputChange(event) {
        const newValue = event.target.value;
        this.setState(
            {
                inputValue: newValue,
                valid: undefined, //Undefined during manually inputting date!
                failedValidations: {}
            },
            () => {
                if (this.props.onInputChange) {
                    this.props.onInputChange(this.state);
                }
            }
        );
    }

    callPropsBlur() {
        if (this.props.onBlur) {
            this.props.onBlur(this.state);
        }
    }

    onBlurInput(event) {
        const stateToSet = {};
        const currentInputValue = event.target.value || null;
        const newValue = this.guessUserInputDate(currentInputValue, this.props.format);
        if (newValue) {
            //We have changed the value with good parsable new date!
            stateToSet.inputValue = DateUtils.simpleFormat(newValue, this.props.format);
            stateToSet.value = [newValue];
            this.setState(stateToSet, this.callPropsBlur);
        } else {
            //For some reason the user input was unable to be parsed to date!
            stateToSet.value = this.state.value;
            if (currentInputValue === null || !this.state.value.length) {
                //The new user input is "" (empty string)
                stateToSet.inputValue = "";
                stateToSet.value = [];
                this.setState(stateToSet, this.callPropsBlur);
            } else if (this.state.value.length) {
                //The user input is something that cannot be parsed to date.
                stateToSet.inputValue = DateUtils.simpleFormat(
                    this.state.value[0],
                    this.props.format
                );
                this.setState(stateToSet);
            }
        }
    }

    render() {
        //Deal with Datepicker CSS classes
        const valueContainerClass = "tq-datepicker-input tq-has-action";
        //Deal with Labeled component props.
        const labelProps = getLabelProps(this.props, {
            ...this.state,
            valueContainerClass: valueContainerClass
        });

        //Deal with calendar props
        const shouldCloseOnSelection = this.props.lazyClose !== false;
        const onSelectCalendarDate = shouldCloseOnSelection ? this.onCloseCalendar : null;
        const calendarProps = {
            width: "250px",
            selectable: true,
            extraClasses: "un-ds-calendar",
            selection: Array.from(this.state.value),
            singleSelection: true,
            onBlur: this.onCloseCalendar,
            onSelectDate: onSelectCalendarDate,
            locale: this.props.locale,
            referenceDate: this.state.value.length ? undefined : this.props.referenceDate,
            mode: this.props.calendarMode,
            type: this.props.calendarType,
            filterAllowedDates: this.props.filterAllowedDates
        };

        return (
            <Labeled {...labelProps}>
                <input
                    type="text"
                    className="tq-input"
                    disabled={this.props.disabled}
                    value={this.state.inputValue}
                    onChange={this.onInputChange}
                    onBlur={this.onBlurInput}
                    placeholder={this.props.placeholder}
                />
                <Popover
                    extraClasses={this.props.popoverClasses}
                    preferredPosition="bottom-left"
                    anchor={this.anchor.current}
                    active={this.state.active}
                >
                    <div className="tq-popover">
                        <Calendar calendarRef={this.calendar} {...calendarProps} />
                    </div>
                </Popover>
                <span
                    ref={this.anchor}
                    onClick={this.onOpenCalendar}
                    className="tq-input-feedback tq-datepicker-trigger"
                >
                    <i className="tq-icon-calendar-2" />
                </span>
            </Labeled>
        );
    }
}

Datepicker.displayName = "Datepicker";

Datepicker.propTypes = Object.assign({}, Labeled.propTypes, {
    extraClasses: PropTypes.string,
    popoverClasses: PropTypes.string,
    lazyClose: PropTypes.bool,
    locale: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.string),
    format: PropTypes.oneOf(supportedFormats),
    referenceDate: PropTypes.string,
    calendarMode: PropTypes.oneOf(["days", "months", "years"]),
    calendarType: PropTypes.oneOf(["dayPicker", "monthPicker", "yearPicker"]),
    filterAllowedDates: PropTypes.shape({ func: PropTypes.func, options: PropTypes.object }),
    valid: PropTypes.bool,
    required: PropTypes.bool,
    failedValidations: PropTypes.object,
    smartBlur: PropTypes.bool,
    onFocus: PropTypes.func
});

//* ---- Prepared interface
// const formatList = supportedFormats as const;
// interface Datepicker extends IBaseComponentProps, iILabeledProps, IEventHandlers, IValidationProps {
//     popoverClasses?: string;
//     lazyClose?: boolean;
//     locale?: string;
//     placeholder?: string;
//     value: string[];
//     format: typeof formatList[number];
//     referenceDate?: string;
//     calendarMode?: "days" | "months" | "years";
//     calendarType?: "dayPicker" | "monthPicker" | "yearPicker";
//     filterAllowedDates?: { func: (args?:any) => AnyAction, options: Record<string,any> };
// }

export const IDatePickerPropsKeys = [
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    ...ILabeledPropsKeys,
    "popoverClasses",
    "lazyClose",
    "locale",
    "placeholder",
    "value",
    "format",
    "referenceDate",
    "calendarMode",
    "calendarType",
    "filterAllowedDates"
];
