const MODAL_TRANSITION_TIME = 300;
export const showModal = (ref): void | Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            if (ref?.current) {
                ref.current.classList.add("tq-modal-visible");
            }
            resolve();
        }, 3);
    });
};

export const hideModal = (ref): Promise<void> => {
    return new Promise((resolve) => {
        if (ref?.current) {
            ref.current.classList.remove("tq-modal-visible");
            setTimeout(() => {
                resolve();
            }, MODAL_TRANSITION_TIME);
        }
    });
};
