import { useEffect } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import { getEntityProps } from "@uneo/platform-commons/webui/search";
import {
    getEntity,
    getParentEntityPath,
    traverseEntityTree,
    updateEntityProps
} from "@uneo/platform-commons/webui/store/actions";
import { CONTENT_ROOT_WIDGET_ID } from "@uneo/platform-commons/platform/constants";
const PROFILE_MENU_WIDGET_ID = "profile-menu";
const MENU_LINKS_ID = "links";
const HEADER_WIDGET_ID = "portal-header";

const NavigationMenuHandler = ({ data }: { data: Entity }) => {
    const navigationInfo = getEntityProps(data, "navigation") || {};
    const { headerMenuLinkId, profileMenuLinkId } = navigationInfo;
    useEffect(() => {
        //trigger update of main menus, based on content of "navigation" entity in content-root widget
        updateNavigationMenus();
    }, [headerMenuLinkId, profileMenuLinkId]);
    return null;
};

const updateNavigationMenus = () => {
    const navigationInfo = getEntity({
        widgetId: CONTENT_ROOT_WIDGET_ID,
        entityPath: "navigation"
    });
    if (!navigationInfo || !navigationInfo.properties) {
        return;
    }
    const { headerMenuLinkId, profileMenuLinkId } = navigationInfo.properties;
    updateMenuLinks(PROFILE_MENU_WIDGET_ID, profileMenuLinkId);
    updateMenuLinks(HEADER_WIDGET_ID, headerMenuLinkId);

    //Fix as per Jira task -> https://jira.tinqin.com/browse/UPF-417
    if (profileMenuLinkId) {
        updateEntityProps({
            widgetId: HEADER_WIDGET_ID,
            entityPath: "personal-space-link",
            props: { active: true }
        });
    }
};

const updateMenuLinks = (targetWidget: string, linkId: string): void => {
    const targetPaths: string[] = [];
    //clear selected menu items
    traverseEntityTree({
        widgetId: targetWidget,
        entityPath: MENU_LINKS_ID,
        fn: (entity) => {
            if (entity.id === linkId) {
                // add the paths of all links which need to be active to array
                // for the case when there are more than one links with the same linkId
                targetPaths.push(entity.contextPath || "unknown");
            }
            if (entity.properties?.active === true) {
                updateEntityProps({
                    widgetId: targetWidget,
                    entityPath: entity.id,
                    props: { active: false }
                });
            }
        }
    });
    //in case there is no link to be activated, clearing all active links is enough
    if (!linkId) {
        return;
    }

    // add "active" flag for selected menu items and its parents (up to "links")
    targetPaths.forEach((targetPath) => {
        while (targetPath && !targetPath.endsWith(MENU_LINKS_ID)) {
            updateEntityProps({
                widgetId: targetWidget,
                entityPath: targetPath,
                props: { active: true }
            });
            targetPath =
                getParentEntityPath({
                    widgetId: targetWidget,
                    entityPath: targetPath
                }) || "";
        }
    });
};

export default NavigationMenuHandler;
