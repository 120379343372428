import { isElementInsideContainer } from "./index";
export default function isEventOutsideContainer(event, container: HTMLElement | Document): boolean {
    let eventTarget = event.target; //No default - assuming events always have target!
    if (event.type === "blur") {
        //For blur events IE11 does not support relatedTarget, instead they support proper value of activeElement.
        //However normal browsers support related target and do not support activeElement.
        eventTarget = event.relatedTarget || document.activeElement;
    }

    return !isElementInsideContainer(eventTarget, container);
}
