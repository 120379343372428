import { Entity, UpdateWidgetState, Widget } from "../types";
import { parseEntityPath } from "../utils/paths";
import {
    deleteWidget as deleteStoreWidget,
    selectEntityByPath,
    createWidget as storeCreateWidget,
    updateWidget as storeUpdateWidget,
    addWidgetLoadingState as storeAddWidgetLoadingAnimation,
    removeWidgetLoadingState as storeRemoveWidgetLoadingAnimation,
    updateEntity as storeUpdateEntity,
    updateEntityProps as storeUpdateEntityProps,
    updateEntityActions as storeUpdateEntityActions,
    updateEntityUiState as storeUpdateEntityUiState,
    setWidgetData as storeSetWidgetData,
    deleteWidgetData as storeDeleteWidgetData,
    selectWidget,
    deleteEntity as storeDeleteEntity
} from "./widgetsSlice";
import { getStore } from "./store";

export const createWidget = (widgetDescription: Widget) => {
    getStore().dispatch(storeCreateWidget(widgetDescription));
};

export const deleteWidget = (widgetId: string) => {
    getStore().dispatch(deleteStoreWidget(widgetId));
};

export const setWidgetData = (id: string, data: Entity) => {
    getStore().dispatch(storeSetWidgetData({ id, data }));
};

export const deleteWidgetData = (widgetId: string) => {
    getStore().dispatch(storeDeleteWidgetData(widgetId));
};

export const updateWidget = (updateWidgetProps: UpdateWidgetState) => {
    getStore().dispatch(storeUpdateWidget(updateWidgetProps));
};

export const addWidgetLoadingState = (widgetId: string) => {
    getStore().dispatch(storeAddWidgetLoadingAnimation({ widgetId }));
};
export const removeWidgetLoadingState = (widgetId: string) => {
    getStore().dispatch(storeRemoveWidgetLoadingAnimation({ widgetId }));
};

export const getWidget = (widgetId: string) => {
    return selectWidget(getStore().getState(), widgetId);
};
export const getEntity = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath?: string;
}): Entity | false => {
    const { storeEntityPath } = parseEntityPath({ widgetId, entityPath, currentEntityPath });
    if (!storeEntityPath) {
        return false;
    }
    return selectEntityByPath(getStore().getState(), widgetId, storeEntityPath);
};

export const getFullEntityPath = ({
    widgetId,
    entityPath
}: {
    widgetId: string;
    entityPath: string;
}): string | false => {
    const { storeEntityPath } = parseEntityPath({ widgetId, entityPath });
    if (!storeEntityPath) {
        return false;
    }
    return storeEntityPath;
};

export const getParentEntityPath = ({
    widgetId,
    entityPath
}: {
    widgetId: string;
    entityPath: string;
}): string | false => {
    const { storeEntityPath } = parseEntityPath({ widgetId, entityPath });
    if (!storeEntityPath) {
        return false;
    }
    if (storeEntityPath.includes(".")) {
        return storeEntityPath.split(".").slice(0, -1).join(".");
    }
    return false;
};

export const traverseEntityTree = ({
    widgetId,
    entityPath,
    fn
}: {
    widgetId: string;
    entityPath: string;
    //TODO add correct type
    fn: (entity: Entity) => void;
}) => {
    const rootEntity = getEntity({
        widgetId,
        entityPath
    });
    if (!rootEntity) {
        return;
    }
    const applyFn = (entity: Entity) => {
        fn(entity);
        if (entity.entities) {
            entity.entities.forEach((innerEntity) => {
                applyFn(innerEntity);
            });
        }
    };
    applyFn(rootEntity);
};

export const updateEntity = ({
    widgetId,
    entityPath,
    entity,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    entity: Entity;
    currentEntityPath?: string;
}) => {
    const {
        widgetId: targetWidgetId,
        storeEntityPath,
        parsedEntityPath
    } = parseEntityPath({
        widgetId,
        entityPath,
        currentEntityPath
    });
    let entityStorePath = storeEntityPath;
    //in case no such entity exists, create e new one based on parsedEntityPath
    if (!entityStorePath) {
        entityStorePath = parsedEntityPath;
    }
    return getStore().dispatch(
        storeUpdateEntity({ widgetId: targetWidgetId, entityStorePath, entity })
    );
};

export const updateEntityProps = ({
    widgetId,
    entityPath,
    props,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    props: Record<string, any>;
    currentEntityPath?: string;
}) => {
    const { widgetId: targetWidgetId, storeEntityPath } = parseEntityPath({
        widgetId,
        entityPath,
        currentEntityPath
    });
    if (!storeEntityPath) {
        return;
    }
    return getStore().dispatch(
        storeUpdateEntityProps({
            widgetId: targetWidgetId,
            entityStorePath: storeEntityPath,
            props
        })
    );
};

export const updateEntityActions = ({
    widgetId,
    entityPath,
    actions,
    currentEntityPath,
    override
}: {
    widgetId: string;
    entityPath: string;
    actions: any[];
    currentEntityPath?: string;
    override?: boolean;
}) => {
    const { widgetId: targetWidgetId, storeEntityPath } = parseEntityPath({
        widgetId,
        entityPath,
        currentEntityPath
    });
    if (!storeEntityPath) {
        return;
    }
    return getStore().dispatch(
        storeUpdateEntityActions({
            widgetId: targetWidgetId,
            entityStorePath: storeEntityPath,
            actions,
            override
        })
    );
};

export const updateEntityUiState = ({
    widgetId,
    entityPath,
    uiState,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    uiState: Record<string, any>;
    currentEntityPath?: string;
}) => {
    const { widgetId: targetWidgetId, storeEntityPath } = parseEntityPath({
        widgetId,
        entityPath,
        currentEntityPath
    });
    if (!storeEntityPath) {
        return;
    }
    return getStore().dispatch(
        storeUpdateEntityUiState({
            widgetId: targetWidgetId,
            entityStorePath: storeEntityPath,
            uiState
        })
    );
};

export const deleteEntity = ({
    widgetId,
    entityPath,
    currentEntityPath
}: {
    widgetId: string;
    entityPath: string;
    currentEntityPath?: string;
}) => {
    const { widgetId: targetWidgetId, storeEntityPath } = parseEntityPath({
        widgetId,
        entityPath,
        currentEntityPath
    });
    if (!storeEntityPath) {
        return;
    }
    return getStore().dispatch(
        storeDeleteEntity({ widgetId: targetWidgetId, entityStorePath: storeEntityPath })
    );
};

const clearValidations = (entity: Entity): void => {
    if (entity.uiState?.valid === false) {
        entity.uiState = Object.assign({}, entity.uiState, {
            valid: undefined,
            failedValidations: []
        });
    }
};
export const clearEntityValidationState = ({
    widgetId,
    entityPath,
    recursive = true
}: {
    widgetId: string;
    entityPath: string;
    recursive?: boolean;
}): void => {
    const rootEntity: Entity | false = getEntity({
        widgetId,
        entityPath
    });
    if (!rootEntity) {
        return;
    }
    if (recursive) {
        const applyFn = (entity: Entity) => {
            clearValidations(entity);
            if (entity.entities) {
                entity.entities.forEach((innerEntity) => {
                    applyFn(innerEntity);
                });
            }
        };
        applyFn(rootEntity);
    } else {
        clearValidations(rootEntity);
    }
    updateEntity({ widgetId, entityPath, entity: rootEntity });
};
