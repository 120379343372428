"use client";
import React from "react";
import { useWidgetContext } from "../../../webui/widget/WidgetContext";
import { xssFilter } from "../../utils/xssFilter";

export default function Alert(props: {
    children?: string | JSX.Element | JSX.Element[];
    text: string;
    type?: string;
    actions?: any;
    extraClasses?: string;
}) {
    const { children, text, type, actions } = props;
    const { triggerActions } = useWidgetContext();
    const ClickFn = (event) => {
        if (actions && actions.length) {
            event.preventDefault();
            const target = event.target;
            if (target.tagName === "A") {
                triggerActions(actions);
            }
        }
    };
    return (
        <div
            className={`un-ds-message-wrapper-mini ${props.extraClasses ? props.extraClasses : ""}`}
        >
            <div className={`tq-alert tq-flex un-ds-message-mini-${type || "warning"}`}>
                <div className="un-message-content">
                    <div className="tq-alert-icon" />
                    <div className="un-text-message">
                        <div>
                            <div className="tq-section tq-text-center tq-text-color un-ds-text-1">
                                <div
                                    onClick={ClickFn}
                                    className="tq-text-color un-font-size-preserve un-content-expand"
                                    dangerouslySetInnerHTML={{ __html: xssFilter.process(text) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {children && (
                    <div className="un-ds-info-actions tq-no-margin un-mb-2 tq-text-center tq-flex">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
}
