import React from "react";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import MenuItem from "./MenuItem";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";
export default function MainSidebar() {
    const { data } = useWidgetDataSelector("portal-header");
    const metadata = getEntity({ widgetId: "content-root", entityPath: "metadata" });
    if (!data) {
        return <></>;
    }
    const links = findEntityById(data, "links");
    if (!links || !links.entities) {
        return <></>;
    }
    //showing inner items of the main active link from header menu
    const activeMainLink = links.entities.find((entity) => entity.properties?.active);
    return (
        <>
            {activeMainLink &&
                activeMainLink.entities?.map((link) => (
                    <MenuItem
                        link={link}
                        key={link.id}
                        matomoContext={metadata ? metadata?.properties?.title : undefined}
                    ></MenuItem>
                ))}
        </>
    );
}
