"use client";
import React from "react";
import { IBaseComponentProps, IEventHandlers } from "@uneo/platform-commons/platform/types";

const CheckboxItem = (props: ICheckboxItemProps) => {
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            if (props.onClick) {
                props.onClick(props.value);
            }
        }
    };

    const extraClasses = props.extraClasses || "";
    let containerClass = extraClasses + " tq-checkbox-option";
    containerClass += props.active ? " tq-selected" : "";
    containerClass += props.disabled ? " tq-disabled" : "";
    containerClass += props.valid === false ? " tq-has-error" : "";
    const label = props.label ? props.label : props.value;
    const onClick = !props.disabled ? () => props.onClick && props.onClick(props.value) : undefined;
    const tabIndex = !props.disabled ? 0 : undefined;

    return (
        <div className={containerClass} tabIndex={tabIndex} onKeyDown={onKeyDown} onClick={onClick}>
            <div className="tq-checkbox">
                <input
                    className="tq-checkbox"
                    type="checkbox"
                    disabled={props.disabled}
                    value={props.value}
                    tabIndex={-1}
                />
            </div>
            <span className="tq-text-node">{label}</span>
            {props.required && <i className="tq-required" />}
        </div>
    );
};

export default CheckboxItem;

interface ICheckboxItemProps extends IBaseComponentProps, IEventHandlers {
    valid?: boolean;
    required?: boolean;
    disabled?: boolean;
    active?: boolean;
    label?: string;
    indeterminate?: boolean; // do we use it at all
}
