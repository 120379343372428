"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import "@tinqin/design-uneo-pf/css/skyrock.css";
import { Entity } from "@uneo/platform-commons/webui/types";
import { fromStoreEntityToEntity } from "@uneo/platform-commons/webui/adapters";
import { xssFilter } from "@uneo/platform-commons/platform/utils/xssFilter";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";

declare global {
    interface Window {
        audio: any;
        playResult: any;
    }

    interface Document {
        documentMode?: any;
    }
}

type RadioPlayerProps = {
    value?: number;
    settings: Record<string, any> | undefined;
};
const isClient = typeof window !== "undefined";

const iOSMobile = () => isClient && navigator.userAgent.includes("Mac") && "ontouchend" in document;

const VolumeSlider = ({ min, max, step = 0.1, initialValue, onChange }) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (event) => {
        const newValue = Number(event.target.value);
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="tq-form-element un-ds-form-element tq-no-label un-volume-control">
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
            />
            <div className="range-slider-progress" style={{ width: `${value * 10}%` }}></div>
        </div>
    );
};

export const RadioPlayer: React.FC<RadioPlayerProps> = ({
    value = 0.6,
    settings = {}
}: RadioPlayerProps) => {
    const requestedStreamRef = useRef<boolean>(false);
    const radioRef = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(false);
    const canPlayThroughRef = useRef<(() => Promise<void>) | null>(null);
    const [isRemotePlay, setIsRemotePlay] = useState<boolean>(false);
    const { widgetId } = useWidgetContext();
    const url: string = settings["radio-url"] || "";
    const description: string = settings["radio-description"] || "";
    const imageSrc: string = settings["radio-logo"] || "";
    const {
        methods: { updateEntityProps, updateEntity }
    } = useWidgetContext();

    const data = useSelector((state: { widgets: Record<string, Entity> }) => {
        const path = `${widgetId}.radio`;
        if (isMounted.current) {
            return fromStoreEntityToEntity(
                state.widgets.widgets[widgetId].data[path],
                state.widgets.widgets[widgetId],
                path
            );
        }
    });

    let active: boolean = false;
    let isPlaying: boolean = false;
    let remotePlay: boolean = false;

    if (data?.properties) {
        active = data.properties?.active;
        isPlaying = data.properties?.isPlaying;
        remotePlay = data.properties?.remotePlay;
    }

    useEffect(() => {
        if (!isMounted.current) {
            //Create radio entity and on props change - rerender component
            //We need to do this with props in the store to be able to manipulate the radio
            //from other places by prop change (play/stop from burger)
            updateEntity({
                widgetId,
                entityPath: `${widgetId}.radio`,
                entity: {
                    id: "radio",
                    properties: { active: false, isPlaying: false, remotePlay: false }
                }
            });
            isMounted.current = true;
        }
    }, [updateEntity, widgetId]);

    const setRadioProp = useCallback(
        (radioProps: { isPlaying?: boolean; active?: boolean; remotePlay?: boolean }) => {
            updateEntityProps({ entityPath: "radio", props: radioProps });
        },
        [updateEntityProps]
    );

    const onPlay = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!isClient || requestedStreamRef.current) return;
            if (!window.audio) {
                window.audio = new Audio(url);
                requestedStreamRef.current = true;
            }
            e?.stopPropagation();

            if (requestedStreamRef.current) {
                canPlayThroughRef.current = async () => {
                    /* the audio is now playable; play it if permissions allow */
                    await new Promise((r) => setTimeout(r, 500)); //iOS issue with browser permission - https://github.com/scottschiller/SoundManager2/issues/178
                    const propsToSet = { isPlaying: true, active };
                    if (!active) propsToSet.active = true;
                    setRadioProp(propsToSet);
                    requestedStreamRef.current = false;
                    window.playResult = window.audio.play(); //Note: in some browsers play does not return promise -> we don't use it.
                };
                window.audio.addEventListener("canplaythrough", canPlayThroughRef.current);
                window.audio.volume = value;
            } else {
                const propsToSet = { isPlaying: true, active };
                if (!active) propsToSet.active = true;
                setRadioProp(propsToSet);
                window.playResult = window.audio.play();
            }
            if (remotePlay) setRadioProp({ remotePlay: false });

            // https://jira.tinqin.com/browse/UCP-859
            //apparently needed for iOS Safari & iOS Chrome
            //used to update the audio element after changing the source or other settings
            if (iOSMobile()) {
                window.audio.load();
            }
            triggerMatomoEvent("Skyrock", "[Skyrock] - Play", "Cliquer");
        },
        [value, url, active, remotePlay, setRadioProp]
    );

    useEffect(() => {
        //Start the radio from outer action
        if (remotePlay && !isRemotePlay) {
            onPlay();
            setIsRemotePlay(true);
        }
    }, [remotePlay, isRemotePlay, onPlay]);

    const onPause = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e?.stopPropagation();
            if (isClient && window.playResult) {
                window.playResult
                    .then(() => {
                        setRadioProp({ isPlaying: false });
                        window.audio.pause();
                        triggerMatomoEvent("Skyrock", "[Skyrock] - Pause", "Cliquer");
                    })
                    .catch((error: any) => {
                        console.warn(error);
                    });
            }
        },
        [setRadioProp]
    );

    const onClose = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e?.stopPropagation();
            if (window.playResult) {
                window.playResult
                    .then(() => {
                        setRadioProp({ active: false, isPlaying: false });
                        window.audio.pause();
                        if (canPlayThroughRef.current) {
                            window.audio.removeEventListener(
                                "canplaythrough",
                                canPlayThroughRef.current
                            );
                        }
                        delete window.audio;
                        window.playResult = undefined;
                        setIsRemotePlay(false);
                        triggerMatomoEvent("Skyrock", "[Skyrock] - Close", "Cliquer");
                    })
                    .catch((error: any) => {
                        console.warn(error);
                    });
            }
        },
        [setRadioProp]
    );

    useEffect(() => {
        return () => {
            onClose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isClient) return;
        const volSlider = document.querySelector(".tq-range-slider");
        if (volSlider) {
            volSlider.className += volSlider.className.includes("tq-range-slider-alternative")
                ? ""
                : " tq-range-slider-alternative";
        }
    }, [active]);

    const handleChangeComplete = useCallback((value: number) => {
        if (!isClient) return;
        let volume: number = value;
        if (value === 10) {
            volume = 1;
        } else {
            volume /= 10;
        }
        window.audio.volume = volume;
    }, []);

    const className = "un-skyrock-container" + (active ? " un-skyrock-started" : "");

    useEffect(() => {
        //Because of the position sticky and the portal div we need the classNames on the portal div, which is parent of the ref div
        if (isClient && radioRef.current?.parentElement) {
            radioRef.current.parentElement.className = className;
        }
    }, [className]);

    return (
        <div ref={radioRef} className="un-skyrock-elements">
            {imageSrc && (
                <span className="tq-image">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={imageSrc} alt={"radio logo"} />
                </span>
            )}
            {description && (
                <div
                    className="un-skyrock-description"
                    dangerouslySetInnerHTML={{
                        __html: xssFilter.process(description)
                    }}
                ></div>
            )}
            <div className="un-skyrock-options">
                {active && (
                    <>
                        {isPlaying && (
                            <button
                                type="button"
                                onClick={(e) => onPause(e)}
                                className="tq-button un-ds-button-only-icon un-button-pause"
                                aria-label="pause"
                            >
                                <i className="un-ds-s-icon-pause" />
                            </button>
                        )}
                        {!isPlaying && (
                            <button
                                type="button"
                                onClick={(e) => onPlay(e)}
                                className="tq-button un-ds-button-only-icon un-button-pause un-button-no-pause"
                                aria-label="play"
                            >
                                <i className="un-ds-s-icon-pause" />
                            </button>
                        )}
                        <button
                            aria-label="volume"
                            className="tq-button un-ds-button-only-icon un-button-volume"
                        >
                            <i className="un-ds-s-icon-volume" />
                        </button>
                        <VolumeSlider
                            initialValue={window.audio?.volume * 10 || 6}
                            onChange={handleChangeComplete}
                            min={0}
                            max={10}
                        />
                    </>
                )}
                {isPlaying && !active && (
                    <button
                        type="button"
                        onClick={(e) => onPause(e)}
                        aria-label="play"
                        className="tq-button un-ds-button-only-icon un-button-pause un-button-no-pause"
                    >
                        <i className="un-ds-s-icon-pause" />
                    </button>
                )}
            </div>
            {!isPlaying && !active && (
                <button
                    type="button"
                    onClick={(e) => onPlay(e)}
                    className="tq-button un-ds-button-only-icon un-button-play"
                    aria-label="play"
                >
                    <i className="un-ds-s-icon-play" />
                </button>
            )}
            {active && (
                <button
                    type="button"
                    onClick={(e) => onClose(e)}
                    className="tq-button un-ds-button-only-icon un-button-play"
                    aria-label="close"
                >
                    <i className="un-ds-s-icon-play" />
                </button>
            )}
        </div>
    );
};

export default RadioPlayer;
