import applicationRules from "./applicationRules";
import { EvaluationResult } from "../evaluation/types";
import { ApplicationRule } from "./types";
import { STEPS_PATH } from "../../utils/constants";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";
import { getEntityChildren } from "@uneo/platform-commons/webui/search";
import { Entity } from "@uneo/platform-commons/webui/types";

export type ApplicationRulesFactoryElement = {
    evaluate: (applicationRules: ApplicationRule[]) => EvaluationResult | Promise<EvaluationResult>;
};

const factoryMethod = (): ApplicationRulesFactoryElement => {
    const evaluate = (
        appRules: ApplicationRule[]
    ): Promise<EvaluationResult> | EvaluationResult => {
        const questionnaireEntity = getEntity({
            widgetId: "content-root",
            entityPath: "content-root"
        });
        let steps: Entity[] = [];
        if (questionnaireEntity) {
            steps = getEntityChildren(questionnaireEntity, STEPS_PATH);
        }
        const rulesResults: EvaluationResult[] = appRules.map((appRule: ApplicationRule) =>
            applicationRules.evaluate(appRule, steps)
        );
        const result: EvaluationResult = rulesResults.reduce(
            (prev: EvaluationResult, current: EvaluationResult): boolean => !!prev && !!current,
            true
        );
        return result;
    };
    return {
        evaluate
    };
};

export default factoryMethod;
