export default function isOutOfWindowViewport(anchorDimensions, contentDimensions) {
    let anchorTop = anchorDimensions.top;
    let anchorLeft = anchorDimensions.left;
    let anchorBottom = anchorTop + anchorDimensions.height;
    let anchorRight = anchorLeft + anchorDimensions.width;
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let contentHeight = contentDimensions.height;
    let contentWidth = contentDimensions.width;

    //     |------------|
    // - - |- - - - - - | - -
    // |   |____________|   |
    // |       |___|        |
    let outOfBoundsTop = anchorTop - contentHeight - 5 < 0;

    //      |-----------------------|
    //- - - | - - - - - - - - - - - | - - - - |
    //      |                       |         |
    //|----||                       |         |
    //|____||_______________________|         |
    let outOfBoundsAnchorTop = anchorBottom - contentHeight - 5 < 0;

    //      |-------------------------------|
    //- - - | - - - - - - - - - - - - - - - | - - - |
    //      |                               |       |
    // |---||                               |       |
    // |___||                               |       |
    //      |                               |       |
    //      |_______________________________|       |
    let outOfBoundsHalfTop = anchorTop - contentHeight / 2 - 5 < 0;

    let outOfBoundsBottom = anchorBottom + contentHeight + 5 > windowHeight;
    let outOfBoundsAnchorBottom = anchorTop + contentHeight + 5 > windowHeight;
    let outOfBoundsHalfBottom = anchorBottom + contentHeight / 2 + 5 > windowHeight;

    let outOfBoundsRight = anchorRight + contentWidth + 5 > windowWidth;
    let outOfBoundsAnchorRight = anchorLeft + contentWidth + 5 > windowWidth;
    let outOfBoundsHalfRight = anchorRight + contentWidth / 2 + 5 > windowWidth;

    let outOfBoundsLeft = anchorLeft - contentWidth - 5 < 0;
    let outOfBoundsAnchorLeft = anchorRight - contentWidth - 5 < 0;
    let outOfBoundsHalfLeft = anchorLeft - contentWidth / 2 - 5 < 0;

    return {
        outOfBoundsTop,
        outOfBoundsAnchorTop,
        outOfBoundsHalfTop,
        outOfBoundsBottom,
        outOfBoundsAnchorBottom,
        outOfBoundsHalfBottom,
        outOfBoundsRight,
        outOfBoundsAnchorRight,
        outOfBoundsHalfRight,
        outOfBoundsLeft,
        outOfBoundsAnchorLeft,
        outOfBoundsHalfLeft
    };
}
