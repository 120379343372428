"use client";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { usePageMetaContext } from "@uneo/platform-portal/widgets/content-root/PageMetaContext";
import RichText from "../richText/RichText";
import "@tinqin/design-uneo-pf/css/modal.css";
import { tabulateArea } from "@uneo/platform-commons/platform/utils/tabulateArea";
import { hideModal, showModal } from "@uneo/platform-commons/platform/utils/modalUtils";

export default function ConfirmModal({
    onClose,
    onConfirm,
    closeOnClickOutside = false,
    hideIcon = true,
    extraClasses = "",
    closeButtonLabel = "Non, je veux continuer",
    confirmButtonLabel = "Oui, je veux abandonner",
    title = "Attention",
    text = "Vous êtes sur le point d'annuler votre demande. Êtes-vous sûr(e) ?"
}: {
    onClose: (event) => void;
    onConfirm: (event) => void;
    closeOnClickOutside?: boolean;
    hideIcon?: boolean;
    extraClasses?: string;
    closeButtonLabel?: string;
    confirmButtonLabel?: string;
    title?: string;
    text?: string;
}) {
    const { triggerPageEvent } = usePageMetaContext();
    const modalRef = useRef(null);

    const onKeyDown = (event) => {
        const isTabPressed = event.key === "Tab";
        const isEscapePressed = event.key === "Escape";

        let tabulateBy = 1;
        if (event.shiftKey && isTabPressed) {
            tabulateBy = -1;
        }

        if (isEscapePressed) {
            return onCloseFn(event);
        }

        if (isTabPressed) {
            event.preventDefault();
            tabulateArea(modalRef, tabulateBy);
            return;
        }
    };

    const onCloseFn = (event) => {
        triggerPageEvent({ name: title, customAction: closeButtonLabel });
        hideModal(modalRef).then(() => {
            onClose(event);
        });
    };
    const onConfirmFn = (event) => {
        triggerPageEvent({ name: title, customAction: confirmButtonLabel });
        onConfirm(event);
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        showModal(modalRef);
        return function cleanup() {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    const content = (
        <div
            ref={modalRef}
            className={`tq-modal un-ds-modal un-ds-modal-generic un-modal-abandon ${
                extraClasses || ""
            }`}
        >
            <div
                className="tq-modal-overlay"
                role="dialog"
                aria-modal="true"
                onClick={
                    closeOnClickOutside
                        ? onCloseFn
                        : () => {
                              return;
                          }
                }
            />
            <div className="tq-modal-container tq-modal-l">
                <div className=" tq-modal-header">
                    <h3 className="tq-panel-title tq-light-splitter tq-with-right-menu">
                        <span className="tq-text-node" />
                        <ul className=" tq-menu tq-selectable">
                            <li
                                className=""
                                tabIndex={0}
                                role="button"
                                onClick={onCloseFn}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        onCloseFn(event);
                                    }
                                }}
                            >
                                <div className="tq-item">
                                    <i className="tq-icon-close" />
                                </div>
                            </li>
                        </ul>
                    </h3>
                </div>

                <div className="tq-modal-scroll">
                    <div className="tq-modal-content">
                        <p className="tq-main-color un-ds-heading-3 tq-text-center">{title}</p>
                        <RichText
                            tag="p"
                            extraClasses="tq-text-color un-ds-text-1 un-mb-1 tq-text-center tq-block"
                            value={text}
                        />
                    </div>
                </div>
                <footer className="tq-modal-footer un-mb-1 tq-text-center tq-flex un-ds-action-section-center">
                    <button
                        className="tq-button un-ds-button un-ds-button-solid  un-ds-component-sm"
                        onClick={onCloseFn}
                    >
                        <span className="tq-text-node">{closeButtonLabel}</span>
                    </button>
                    <button
                        className="tq-button un-ds-button un-ds-button-outline un-ds-component-sm"
                        onClick={onConfirmFn}
                    >
                        <span className="tq-text-node">{confirmButtonLabel}</span>
                    </button>
                </footer>
            </div>
        </div>
    );

    return createPortal(content, document.body);
}
