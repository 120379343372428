import { hasValue } from "./required";

//The field under validation must be present and not empty only when all of the other specified fields are empty or not present.
export const requiredWithout = (value, ...otherFieldsValues) => {
    let otherFieldsValueAreEmpty = true;
    otherFieldsValues.forEach((otherFieldValue) => {
        if (hasValue(otherFieldValue)) {
            otherFieldsValueAreEmpty = false;
        }
    });
    if (otherFieldsValueAreEmpty && !hasValue(value)) {
        return false;
    }
    return true;
};
