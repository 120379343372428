import { hasValue } from "./required";

//The field under validation must be present and not empty if the another field field is equal to any value.
export const requiredIf = (value, otherFieldValue, otherFieldTargetValue) => {
    if (hasValue(value)) {
        return true;
    }
    if (otherFieldTargetValue !== undefined) {
        if (otherFieldValue === otherFieldTargetValue) {
            return false;
        } else {
            return true;
        }
    }
    if (hasValue(otherFieldValue)) {
        return true;
    }
    return false;
};
