"use client";
import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import "@tinqin/design-uneo-pf/css/modal.css";
import { tabulateArea } from "@uneo/platform-commons/platform/utils/tabulateArea";
import { hideModal, showModal } from "@uneo/platform-commons/platform/utils/modalUtils";

export default function ConfirmationModal({
    children,
    extraClasses = "",
    onConfirm,
    confirmText,
    closeText,
    opened,
    onClose
}: {
    onClose: (event) => void;
    onConfirm: (event) => void;
    confirmText?: string;
    closeText?: string;
    extraClasses?: string;
    opened: boolean;
    children?: React.ReactNode | React.ReactNode[];
}) {
    const modalRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const closeFn = (event) => {
        hideModal(modalRef).then(() => {
            onClose(event);
        });
    };
    const onKeyDown = useCallback(
        (event) => {
            const isTabPressed = event.key === "Tab";
            const isEscapePressed = event.key === "Escape";

            let tabulateBy = 1;
            if (event.shiftKey && isTabPressed) {
                tabulateBy = -1;
            }

            if (isEscapePressed) {
                return closeFn(event);
            }
            if (isTabPressed) {
                event.preventDefault();
                tabulateArea(modalRef, tabulateBy);
            }
        },
        [opened, onClose, onConfirm]
    );

    useEffect(() => {
        if (opened) {
            document.addEventListener("keydown", onKeyDown);
            showModal(modalRef);
        }
        return function cleanup() {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [opened, onKeyDown]);

    const content = (
        <div
            ref={modalRef}
            className={`tq-modal un-ds-modal un-ds-modal-generic ${extraClasses || ""}`}
        >
            <div className="tq-modal-overlay" role="dialog" aria-modal="true" onClick={closeFn} />
            <div className="tq-modal-container tq-modal-l">
                <div className=" tq-modal-header">
                    <h3 className="tq-panel-title tq-light-splitter tq-with-right-menu">
                        <span className="tq-text-node" />
                        <ul className=" tq-menu tq-selectable">
                            <li
                                className=""
                                tabIndex={0}
                                role="button"
                                onClick={closeFn}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        closeFn(event);
                                    }
                                }}
                            >
                                <div className="tq-item">
                                    <i className="tq-icon-close" />
                                </div>
                            </li>
                        </ul>
                    </h3>
                </div>
                <div className="tq-modal-scroll">
                    <div className="tq-modal-content">{children}</div>
                </div>
                <footer className="tq-modal-footer un-mb-1">
                    <div className="un-ds-action-section-center">
                        <button
                            className="tq-button un-ds-button un-ds-button-outline un-ds-component-sm"
                            onClick={closeFn}
                        >
                            <span className="tq-text-node">{closeText || t("quote.no")}</span>
                        </button>
                        <button
                            className="tq-button  un-ds-button un-ds-button-solid un-ds-component-sm"
                            onClick={(e) => onConfirm(e)}
                        >
                            <span className="tq-text-node">{confirmText || t("quote.yes")}</span>
                        </button>
                    </div>
                </footer>
            </div>
        </div>
    );
    if (opened) {
        return createPortal(content, document.body);
    }
}
