export type SubmitEntity = Record<string, any>;

export interface FormSubmitData {
    content: Record<string, SubmitEntity>;
    files?: any[];
}

export interface IBaseComponentProps {
    extraClasses?: string;
    value?: string;
}
export const IBaseComponentPropsKeys = ["extraClasses", "value"];
export interface IValidationProps {
    valid: boolean;
    required?: boolean;
    failedValidations: Record<string, any>;
}

export interface ILabeledProps {
    containerClass?: string;
    labelSuffix?: string;
    valueContainerClass?: string;
    disabled?: boolean;
    vertical?: boolean;
    label?: string;
    labelButton?: string;
    skipLabel?: boolean;
    dataTqId?: string;
    role?: string;
    valid?: boolean;
    tooltip?: string;
    required?: boolean;
    labeledRef?: React.MutableRefObject<HTMLElement | null>;
    extraClasses?: string;
    hasFeedback?: boolean;
    value?: string;
    animated?: boolean;
    onOpenTooltip?: (...args: any[]) => any | void;
}

export const ILabeledPropsKeys = [
    "containerClass",
    "valueContainerClass",
    "disabled",
    "vertical",
    "label",
    "labelButton",
    "labelSuffix",
    "skipLabel",
    "dataTqId",
    "role",
    "valid",
    "tooltip",
    "required",
    "labeledRef",
    "extraClasses",
    "hasFeedback",
    "value",
    "animated",
    "onOpenTooltip"
];

export interface IEventHandlers {
    onClick?: (...args: any[]) => any | void;
    onChange?: (...args: any[]) => any | void;
    onFocus?: (...args: any[]) => any | void;
    onBlur?: (...args: any[]) => any | void;
    onOpen?: (...args: any[]) => any | void;
    onClose?: (...args: any[]) => any | void;
    onKeyDown?: (...args: any[]) => any | void;
}

export const IEventHandlersKeys = [
    "onClick",
    "onChange",
    "onFocus",
    "onBlur",
    "onOpen",
    "onClose",
    "onKeyDown"
];

export interface IDebounce {
    debounce?: {
        wait?: number;
        immediate?: boolean;
    };
}
