"use client";
import "@tinqin/design-uneo-pf/css/accordion.css";
import "@tinqin/design-uneo-pf/css/badge.css";
import "@tinqin/design-uneo-pf/css/buttons.css";
import "@tinqin/design-uneo-pf/css/fonts.css";
import "@tinqin/design-uneo-pf/css/form-elements.css";
import "@tinqin/design-uneo-pf/css/footer.css";
import "@tinqin/design-uneo-pf/css/global.css";
import "@tinqin/design-uneo-pf/css/header.css";
import "@tinqin/design-uneo-pf/css/icons.css";
import "@tinqin/design-uneo-pf/css/info-messages.css";
import "@tinqin/design-uneo-pf/css/layout.css";
import "@tinqin/design-uneo-pf/css/navigation.css";
import "@tinqin/design-uneo-pf/css/paragraph.css";
import "@tinqin/design-uneo-pf/css/typography.css";
import "@tinqin/design-uneo-pf/css/pf-theme.css";
import "@tinqin/design-uneo-pf/css/sections.css";
import "@tinqin/design-uneo-pf/css/quick-links.css";
import "@tinqin/design-uneo-pf/css/ribbon-messages.css";
import "@tinqin/design-uneo-pf/css/preloader.css";
import "./tempCssFix";

import { ApplicationLoadingAnimation } from "@uneo/platform-commons/platform/components";
import { Widget as WidgetType } from "@uneo/platform-commons/webui/types";
import { WidgetContextProvider } from "@uneo/platform-commons/webui/widget/WidgetContext";
import React from "react";
import defaultWidgetViews from "@uneo/platform-commons/defaultWidgetViews";
import TarteaucitronLoaderComponent from "../../tarteaucitron/TarteaucitronLoaderComponent";
import { getWidgetUrl } from "@uneo/platform-commons/platform/services/urlService";
import { PORTAL_WIDGET_ID } from "@uneo/platform-commons/platform/constants";

import { ErrorBoundary } from "react-error-boundary";
import StaticErrorPage from "../../components/StaticErrorPage";

export default function PortalWidget({ info }: { info: WidgetType }) {
    if (!info?.data) {
        return <></>;
    }
    return (
        <ErrorBoundary
            fallback={<StaticErrorPage />}
            onError={(error, errorInfo) => {
                console.log("Thrown error in PortalWidget: ", error, errorInfo);
            }}
        >
            <ApplicationLoadingAnimation isLoading={!!info?.loadingState}>
                {info.data?.entities?.map((widgetEntity) => {
                    const innerWidgetId = widgetEntity.id;
                    const WidgetComponent = defaultWidgetViews[innerWidgetId];
                    const widgetUrl = getWidgetUrl(widgetEntity, { widgetId: PORTAL_WIDGET_ID });
                    if (!WidgetComponent) {
                        return <>{"Unknown Widget " + innerWidgetId}</>;
                    }
                    return (
                        <WidgetContextProvider
                            widgetId={innerWidgetId}
                            key={`${innerWidgetId}-${widgetUrl}-${widgetEntity.properties?.dataCacheId}`}
                        >
                            <WidgetComponent
                                info={{
                                    id: innerWidgetId,
                                    url: widgetUrl,
                                    parentId: info.id
                                }}
                            />
                        </WidgetContextProvider>
                    );
                })}
                <TarteaucitronLoaderComponent />
            </ApplicationLoadingAnimation>
        </ErrorBoundary>
    );
}
