import React, { useEffect, useRef, useState } from "react";
import { findEntityById, getEntityProps } from "@uneo/platform-commons/webui/search";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import { useTranslation } from "react-i18next";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { Hyperlink } from "@uneo/platform-commons/platform/components";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
export const INBOX_ID = "user-inbox";
export const NOTIFICATIONS_ID = "user-notifications";
export const LOGOUT_ID = "logout-page";
export const TITLE_ID = "title";
export default function ProfileMenu() {
    const { triggerEntityActions } = useWidgetContext();
    const { t } = useTranslation();
    const { data } = useWidgetDataSelector("profile-menu");
    const [opened, setOpened] = useState(false);
    const dropdownMenu = useRef(null);
    const profileMenuBtn = useRef(null);
    const title = findEntityById(data, TITLE_ID);
    const initials = getEntityProps(data, "title", "initials");
    const inbox = findEntityById(data, INBOX_ID);
    const inboxActive = getEntityProps(inbox, INBOX_ID, "active");
    const notifications = findEntityById(data, NOTIFICATIONS_ID);
    const logout = findEntityById(data, LOGOUT_ID);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownMenu.current &&
                !dropdownMenu?.current?.contains(event.target) &&
                !profileMenuBtn?.current?.contains(event.target)
            ) {
                setOpened(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    return (
        <>
            {/* Messages btn  */}
            {inbox && (
                <Hyperlink
                    entity={inbox}
                    extraClasses={
                        "tq-hyperlink un-ds-hyperlink un-ds-button-icon tq-text-center tq-main-color " +
                        (inboxActive ? "un-active" : "")
                    }
                    onClick={() => {
                        triggerMatomoEvent("Header", "[Header] - Mes messages", "Cliquer");
                    }}
                    tabIndex={0}
                >
                    <i className="un-ds-m-icon-mail" />
                </Hyperlink>
            )}
            {/* Notification btn  */}
            {notifications && (
                <button
                    className="tq-button un-ds-button-only-icon tq-main-color un-button-notification un-has-notification"
                    type="button"
                    aria-label="Mes alertes"
                    onClick={() => {
                        triggerMatomoEvent("Header", "[Header] - Mes alertes", "Cliquer");
                    }}
                >
                    <i className=" un-ds-m-icon-bell" />
                </button>
            )}
            {/* Profile dropdown button - only on desktop  */}
            <div
                className={"un-header-btn-dropdown" + (opened ? " un-expanded" : "")}
                onClick={() => setOpened(!opened)}
                ref={profileMenuBtn}
                role={"navigation"}
            >
                <button className="tq-button un-ds-button un-ds-component-sm un-ds-button-solid un-ds-button-solid-color1 un-button-dropdown">
                    <span className="tq-text-node">{initials}</span>
                    <i className=" tq-icon-arrow-down-big" />
                </button>
            </div>
            <div className="un-profile-dropdown" ref={dropdownMenu} role={"navigation"}>
                <button
                    className="tq-hyperlink un-ds-hyperlink un-my-profile-link tq-text-left tq-main-color"
                    onClick={(e) => {
                        e.preventDefault();
                        triggerEntityActions(title);
                        setOpened(false);
                        triggerMatomoEvent("Header", "[Header] - Gérer mon profil", "Cliquer");
                    }}
                >
                    <span className="tq-hyperlink-z-index">{t(`profileMenu.myProfileLink`)}</span>
                </button>
                {logout && (
                    <button
                        className="tq-hyperlink un-ds-hyperlink un-disconnect-link tq-text-left tq-main-color"
                        onClick={() => {
                            triggerEntityActions(logout);
                            setOpened(false);
                            triggerMatomoEvent("Header", "[Header] - Déconnexion", "Cliquer");
                        }}
                    >
                        <span className="tq-hyperlink-z-index">{logout.properties?.value}</span>
                    </button>
                )}
            </div>
        </>
    );
}
