import i18n from "i18next";
import { ILabeledProps, ILabeledPropsKeys } from "../types";

export const getLabelProps = (props, additionalProps: ILabeledProps = {}) => {
    const mergedProps = { ...props, ...additionalProps };
    const result: ILabeledProps = ILabeledPropsKeys.reduce((acc, key) => {
        acc[key] = mergedProps[key];
        return acc;
    }, {});
    result.containerClass = "tq-form-element un-ds-form-element " + (result.containerClass || "");
    result.valueContainerClass = "tq-input-container " + (result.valueContainerClass || "");
    return result;
};

export const buildEnumData = (props) => {
    let itemsData = props.entities || [];
    //todo review the need of this check
    if (itemsData.length) {
        if (itemsData[0].class && itemsData[0].class.includes("enum")) {
            itemsData = itemsData[0].entities || [];
        }
    }
    return itemsData;
};
export const extractComponentProps = (
    props,
    options: {
        componentProps: string[];
        additionalProps?: string[];
        propsDefaults?: Record<string, any>;
    }
): any => {
    const data = props || {};
    const propsDefaults = options?.propsDefaults || {};
    const resultProps: Record<string, any> = {};
    const additionalProps = options.additionalProps || [];
    const propsToExtract = [...options.componentProps, ...additionalProps];
    for (const prop of propsToExtract) {
        if (data[prop] !== undefined) {
            resultProps[prop] = data[prop];
        } else if (propsDefaults[prop] !== undefined) {
            resultProps[prop] = propsDefaults[prop];
        }
    }
    return resultProps;
};

export const formatCurrency = (string) => {
    return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(string);
};

export const ibanNullCheck = (iban?: string) => {
    if (!iban || iban.endsWith("null")) {
        return i18n.t("memberPersonalDetails.values.notSpecified");
    }
    return iban;
};

export const formatContractDetails = (contactType, contractBusinessLine, contractNumber) => {
    return contactType + "/" + contractBusinessLine + " - " + contractNumber;
};

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
        ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
};

export const isEqual = (obj1: any, obj2: any): boolean => {
    // Check if both arguments are objects
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        return obj1 === obj2; // If not objects, perform simple comparison
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, objects are not equal
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Iterate through keys and compare values
    for (let key of keys1) {
        // If the other object doesn't have the same key, objects are not equal
        if (!obj2.hasOwnProperty(key)) {
            return false;
        }

        // Recursively compare nested objects
        if (!isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    // If all properties and nested properties are equal, objects are equal
    return true;
};

export const deepCopy = (obj, visited = new Map()) => {
    // If obj is a primitive value or null, return it directly
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    // If obj has already been visited, return its copy from the visited map
    if (visited.has(obj)) {
        return visited.get(obj);
    }

    // Create a new object or array to hold the copied properties
    const newObj = Array.isArray(obj) ? [] : {};

    // Mark obj as visited and store its copy in the visited map
    visited.set(obj, newObj);

    // Recursively deep copy each property of obj
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key] = deepCopy(obj[key], visited);
        }
    }

    return newObj;
};
