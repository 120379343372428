"use client";
import React from "react";
import { Entity } from "../../../webui/types";
import { ValidationResult, ValidationRule } from "../../validations/interfaces";
import matchingPaths from "../../utils/matchingPaths";
import { getEntityChildren } from "../../../webui/search";
import Message from "../messages/Message";
import RichText from "../richText/RichText";
import RemountContainer from "./RemountContainer";
//Component for errors
//FE errors are extracted from each entity uiState
//BE errors are extracted from "errors" entity (can be specified with "errorEntityPath", by default "error" is used)
export default function FormErrors(props: {
    data: Entity;
    labels?: Record<string, string>;
    errorsEntityPath?: string;
    onClick?: () => undefined; //used for tracking ex:UPF-1790
}) {
    const { data, labels, errorsEntityPath = "errors" } = props;
    const feValidationMessages = getErrors(buildFailedFEValidations(data as Entity, labels));
    const beValidationMessagesEntities: Entity[] = getEntityChildren(data, errorsEntityPath);

    if (!feValidationMessages.length && !beValidationMessagesEntities.length) {
        return null;
    }
    return (
        <RemountContainer entityPathToTrack={errorsEntityPath} data={data}>
            <Message type="error" extraClasses="un-mt-3 un-animation-shaking">
                {feValidationMessages.map((item, index) => (
                    <RichText
                        key={index + (item.message || "")}
                        value={item.message}
                        onClick={props.onClick}
                    />
                ))}
                {beValidationMessagesEntities.map((entity: Entity, index) => (
                    <RichText key={`error-${index}`} entity={entity} onClick={props.onClick} />
                ))}
            </Message>
        </RemountContainer>
    );
}
const getErrors = (errorState) => {
    const failedFields = Object.keys(errorState);
    const failedRules: ValidationRule[] = [];
    if (failedFields.length) {
        failedFields.forEach((field) => {
            const requiredValidation = errorState[field].failedValidations.find(
                (failedValidation) => failedValidation.type === "required"
            );
            const requiredMessageIsDisplayed =
                requiredValidation &&
                failedRules.find((i) => i.message === requiredValidation.message);
            if (requiredValidation && !requiredMessageIsDisplayed) {
                failedRules.push(requiredValidation);
            } else if (!requiredValidation) {
                errorState[field].failedValidations.forEach((validationRule) => {
                    if (!failedRules.find((i) => i && i.message === validationRule.message)) {
                        failedRules.push(validationRule);
                    }
                });
            }
        });
    }
    return failedRules;
};

export const getFailedValidationsByEntity = (errorEntity: Entity) => {
    if (errorEntity && errorEntity.entities?.length) {
        const failedBEValidations = {};
        errorEntity.entities.forEach((failedBeValidationEntity) => {
            const { properties } = failedBeValidationEntity;
            const { targets, value } = properties || {};
            const failedValidation = {
                type: "BE",
                message: value,
                level: "error",
                parameters: []
            };
            if (targets && targets.length) {
                targets.forEach((entityPath) => {
                    if (failedBEValidations[entityPath]) {
                        failedBEValidations[entityPath].failedValidations.push(failedValidation);
                    } else {
                        failedBEValidations[entityPath] = {
                            failedValidations: [failedValidation]
                        };
                    }
                });
            } else {
                if (
                    failedBEValidations["form-error"] &&
                    failedBEValidations["form-error"].failedValidations
                ) {
                    failedBEValidations["form-error"].failedValidations.push(failedValidation);
                } else {
                    failedBEValidations["form-error"] = {
                        failedValidations: [failedValidation]
                    };
                }
            }
        });
        return failedBEValidations;
    }
    return {};
};

const buildFailedFEValidations = (
    data: Entity,
    labels?: Record<string, string>
): ValidationResult => {
    const failedValidations = {};
    const applyFn = (entity: Entity) => {
        if (entity.uiState?.failedValidations) {
            failedValidations[entity.contextPath || entity.id] = {
                //adding labels to error messages
                failedValidations: entity.uiState.failedValidations.map((rule) =>
                    updateLabelMessage(rule, entity, labels)
                )
            };
        }
        if (entity.entities) {
            entity.entities.forEach((innerEntity) => {
                applyFn(innerEntity);
            });
        }
    };
    applyFn(data);
    return failedValidations;
};

//Add support for FE labels
const updateLabelMessage = (
    validationRule: ValidationRule,
    entity: Entity,
    labels: Record<string, string> = {}
): ValidationRule => {
    const label =
        entity.properties?.label || getMatchingLabel(entity.contextPath || entity.id, labels);
    let newMessage = "";
    if (label) {
        newMessage = addLabel(validationRule.message || "", label);
    } else {
        newMessage = formatMessage(validationRule.message);
    }
    const result = Object.assign({}, validationRule, { message: newMessage });
    return result;
};

const getMatchingLabel = (
    entityPath: string,
    labels: Record<string, string> = {}
): string | false => {
    const matchingLabelKey = Object.keys(labels).find((labelKey) =>
        matchingPaths(entityPath, labelKey)
    );
    if (matchingLabelKey) {
        return labels[matchingLabelKey];
    }
    return false;
};
//add required enclosing <p>
//add ":" if necessary
const addLabel = (message: string, label: string = ""): string => {
    let formattedLabel = label;
    if (!label.endsWith(":")) {
        formattedLabel = formattedLabel + ":";
    }
    if (message.includes("<p>")) {
        return message.replace("<p>", `<p>${formattedLabel} `);
    } else {
        return `<p>${formattedLabel} ${message}</p>`;
    }
};

const formatMessage = (message: string = ""): string => {
    if (message.includes("<p>")) {
        return message;
    } else {
        return `<p>${message}</p>`;
    }
};
