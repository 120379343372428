import { maxLength } from "./maxLength";
import { minLength } from "./minLength";
import { required } from "./required";
import { regex } from "./regex";
import { requiredIf } from "./requiredIf";
import { requiredWithout } from "./requiredWithout";
import { same } from "./same";
import { notSame } from "./notSame";
import { email } from "./email";
import { personName } from "./personName";
import { maxFilesNumber } from "./maxFilesNumber";
import { minFileNumber } from "./minFileNumber";
import { maxFileSize } from "./maxFileSize";
import { minFileSize } from "./minFileSize";
import { allowedFileTypes } from "./allowedFileTypes";
import { exactLength } from "./exactLength";
import { greaterThan } from "./greaterThan";
import { lessThan } from "./lessThan";
import { between } from "./between";
import { lengthBetween } from "./lengthBetween";
import { minPasswordStrength } from "./minPasswordStrength";

export default {
    "max-length": maxLength,
    "min-length": minLength,
    "exact-length": exactLength,
    required,
    regex,
    "required-if": requiredIf,
    "required-without": requiredWithout,
    same,
    "not-same": notSame,
    "less-than": lessThan,
    "greater-than": greaterThan,
    email,
    between,
    "length-between": lengthBetween,
    "person-name": personName,
    "max-files-number": maxFilesNumber,
    "min-file-number": minFileNumber,
    "max-file-size": maxFileSize,
    "min-file-size": minFileSize,
    "allowed-file-types": allowedFileTypes,
    "min-password-strength": minPasswordStrength
};
