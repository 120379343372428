"use client";
import React from "react";
import "@tinqin/design-uneo-pf/css/progress-bars.css";
import "@tinqin/design-uneo-pf/css/sticky-container.css";
import { Entity } from "../../../webui/types";

export default function ProgressBar({ entity }: { entity: Entity; withPortal?: boolean }) {
    if (!entity) {
        return <></>;
    }
    const entityProperties = entity.properties || {};
    const { currentStep = 0, totalSteps = 0 } = entityProperties;
    let progress = 100;
    if (totalSteps) {
        progress = Math.abs(Math.floor((currentStep / totalSteps) * 100));
    }
    const progressBar = (
        <div className="un-ds-progress-placeholder">
            <div className="un-step-progress" style={{ width: `${progress}%` }} />
        </div>
    );
    return progressBar;
}
