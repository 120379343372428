import { Action, ActionContext, Entity } from "../../webui/types";
import {
    deleteEntity,
    getEntity,
    updateEntity as updateStoreEntity
} from "../../webui/store/actions";
import { fetchFile } from "../httpClient/httpClient";
import { createMultipartFormData } from "../utils/serialization/createMultipartFormData";
import { ERRORS_ENTITY, METHOD_GET, METHOD_POST, SUCCESS_ENTITY } from "./constants";
import { generateFormData } from "../utils/serialization/formData";
import { fromSirenEntityToEntity } from "../../webui/adapters";
import { getActionUrl } from "../services/urlService";

export const downloadBinary = async (action: Action, context: ActionContext) => {
    const { method, sources } = action;
    const fetchOptions: any = {
        method: method || METHOD_GET,
        headers: { "Content-Type": "application/json" }
    };
    if (fetchOptions.method === METHOD_POST) {
        if (sources) {
            const entities: Entity[] = [];
            sources.forEach((source) => {
                const foundEntity = getEntity({
                    widgetId: context.widgetId,
                    entityPath: source,
                    currentEntityPath: context.currentEntityPath
                });
                if (foundEntity) {
                    entities.push(foundEntity);
                } else {
                    console.error("Entity not found,", source);
                }
            });
            const formData = generateFormData(entities);
            if (formData.files) {
                fetchOptions.body = createMultipartFormData(formData);
                fetchOptions.headers = {
                    "Content-Type": undefined
                };
            } else {
                fetchOptions.body = JSON.stringify(formData);
            }
        }
    }
    deleteEntity({
        widgetId: context?.widgetId,
        entityPath: context?.currentEntityPath + "." + SUCCESS_ENTITY,
        currentEntityPath: context?.currentEntityPath
    });
    deleteEntity({
        widgetId: context?.widgetId,
        entityPath: context?.currentEntityPath + "." + ERRORS_ENTITY,
        currentEntityPath: context?.currentEntityPath
    });
    const response = await fetchFile({
        url: getActionUrl(action, context),
        fetchOptions
    });
    if (response.headers.get("content-type") === "application/json") {
        response.json().then((data) => {
            const result = data.content;
            const resultId = getResponseId(result);
            if (resultId === ERRORS_ENTITY) {
                updateStoreEntity({
                    widgetId: context?.widgetId,
                    entityPath: context?.currentEntityPath + "." + ERRORS_ENTITY,
                    currentEntityPath: context?.currentEntityPath,
                    entity: fromSirenEntityToEntity(result)
                });
            }
        });
        return;
    }
    saveFile(response);
    updateStoreEntity({
        widgetId: context.widgetId,
        entityPath: context?.currentEntityPath + "." + SUCCESS_ENTITY,
        currentEntityPath: context.currentEntityPath,
        entity: { id: "success", properties: { value: "true" } }
    });
};

function saveFile(response) {
    if (!response.ok) {
        return Promise.reject(response);
    }
    const headers = response.headers;
    //get the headers' content disposition
    const contentDisposition = headers.get("content-disposition") || "";
    //determine the content type from the header or default to octet stream
    const contentType = headers.get("content-type") || "application/octet-stream";
    //get the file name with regex
    //match - content-disposition: inline;  filename*=UTF-8''newOffer.pdf
    //content-disposition: attachment; filename=content.txt
    const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
    const match = regex.exec(contentDisposition);
    //is there a file name
    let fileName = (match && match[3]) || "fileName";
    fileName = fileName.replace(/"/g, "");
    try {
        response.arrayBuffer().then((data) => {
            const blob = new Blob([data], { type: contentType });
            const sizeInBytes = blob.size;
            if (!sizeInBytes) {
                return Promise.resolve();
            }
            //downloading the file depends on the browser
            //IE handles it differently than chrome/webkit
            if (
                window.navigator &&
                window.navigator["msSaveOrOpenBlob"] &&
                typeof window.navigator["msSaveOrOpenBlob"] === "function"
            ) {
                window.navigator["msSaveOrOpenBlob"](blob, fileName);
            } else {
                openFileLink({
                    blob,
                    fileName,
                    downloadFlag: !contentDisposition.includes("inline"),
                    windowFeatures: ""
                });
            }
        });
        return Promise.resolve();
    } catch (error) {
        console.error("Save Blob method failed with the following exception.");
        console.error(error);
    }
}

// creates a link for a file and clicks on it
// this can trigger window.open()
// or can just download the file
/*
    Some browsers may block window.open() calls if they are not triggered by user interactions, such as mouse clicks or keyboard events. This is often done for security reasons to prevent pop-ups and other unwanted behavior.
    In our code we trigger window.open programmatically with element.click(), which is not recognized by some browser as user interaction.
    Workaround is a check for safari userAgent version < 16, and if this is the case we do not add click listener with window.open and rely on browsers native behavior.
    Tested in browserstack on Safari 15.* the file is opened in the same tab, and in below 15 it shows download prompt.
    Tested on Safari 16, 15, 14, 13 with 3 seconds delay of the server response and is OK. In version 12.* it does not work because of "WebKitBlobResourse error 1" but we need to support versions >=13
*/
function openFileLink({
    //input file as a blob
    blob = undefined,
    //target: if equals "_window" new window will be opened with the file in it
    //if different than "_window", target will be used an identification for the new tab
    // if left as "_blank", each new opened file will create a new tab in the browser
    target = "_blank",
    //options for window.open if new window is to be opened (like position and dimensions)
    //more info at: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_features
    windowFeatures = "",
    //
    fileName = "fileName",
    //downloadFlag: if true, will skip window.open and only download the file when the link is clicked
    downloadFlag = false,
    //how long to keep object reference in the browser
    //references are cleaned up on document unload from the browser
    // defaults to 1 hour
    cleanupDelay = 3600000
} = {}) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    // For Safari version below 16 we cannot open blob in new tab, so open it in the same, version below 15 shows download prompt (tested in browserStack.com)
    const isOldSafari = () => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const safariVersionMatch = navigator.userAgent.match(/Version\/([\d.]+)/);
        const safariVersion = safariVersionMatch ? safariVersionMatch[1] : undefined;
        return isSafari && safariVersion && parseFloat(safariVersion) < 16;
    };
    if (!downloadFlag && !isOldSafari()) {
        link.onclick = function () {
            //Specifying "windowFeatures" to the "open" call,
            //forcing the browser to open a new window, instead of the default behavior of opening a new tab
            //window.open will open new window if third argument is a non empty string
            if (target === "_window") {
                window.open(link.href, "_blank", windowFeatures ? windowFeatures : "location=yes");
            } else {
                window.open(link.href, target);
            }
            return false;
        };
    }
    //Workaround for iOS Safari issue with window.open from async requests
    //Safari blocks all window.open requests if that request is made from an async call
    //using this method (setTimeout) we force the browser to run window.open on the main thread.
    //more info: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    setTimeout(() => {
        link.click();
        //when downloading the file, it is ok to revoke the object immediately
        if (downloadFlag) {
            URL.revokeObjectURL(link.href);
        } else {
            //if opened in another tab, revoking the object too soon will result in user not able to download the file
            //default cleanupDelay = 1 hour
            //object are revoked automatically on document unload
            //more info: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
            if (cleanupDelay) {
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                }, cleanupDelay);
            }
        }
    }, 100);
}

function getResponseId(result: any) {
    return result && result.class && result.class[0];
}
