import * as xss from "xss";

const defaultWhiteList: Record<string, string[]> = xss.getDefaultWhiteList();
export const whiteList: Record<string, string[]> = Object.assign({}, defaultWhiteList);

Object.keys(whiteList).forEach((key) => {
    //no "class" attribute is allowed
    whiteList[key].push("style");
    whiteList[key].push("class");
});

export const xssFilter: xss.FilterXSS = new xss.FilterXSS({
    whiteList,
    css: false
});
