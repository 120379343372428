import { FunctionElementFactory, LiteralFunctionElementFactory } from "../types";
import { AdvFunctionElementNames, dictionary as advanced } from "./advanced";
import { BasicFunctionElementNames, dictionary as basic } from "./basic";
import { dictionary as literal, LiteralFunctionElementNames } from "./literal";

export type FactoryMethodsDictionary = {
    [key in AdvFunctionElementNames]: FunctionElementFactory;
} & { [key in BasicFunctionElementNames]: FunctionElementFactory } & {
    [key in LiteralFunctionElementNames]: LiteralFunctionElementFactory;
};

export const dictionary: FactoryMethodsDictionary = Object.assign({}, advanced, basic, literal);
