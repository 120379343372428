import { getEntity } from "../../webui/store/actions";
import { Entity } from "../../webui/types";
import { CONTENT_ROOT_WIDGET_ID, PORTAL_WIDGET_ID } from "../constants";
//extract widget view id inside content-root widget
export const getContentRootInnerWidgetViewId = () => {
    const contentRootWidgetDescription = getEntity({
        widgetId: PORTAL_WIDGET_ID,
        entityPath: CONTENT_ROOT_WIDGET_ID
    }) as Entity;
    return contentRootWidgetDescription?.properties?.widgetId;
};
