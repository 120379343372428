import { Entity, SirenAction, SirenEntity } from "@uneo/platform-commons/webui/types";
import { findAllEntityPathsById, getEntityChildren } from "@uneo/platform-commons/webui/search";
import {
    getEntity,
    getParentEntityPath,
    updateEntity,
    updateEntityActions,
    updateEntityProps
} from "@uneo/platform-commons/webui/store/actions";
import {
    ICoveragesData,
    ISelectionCoverageList,
    manageCoverageDependencyRules
} from "./manageCoverageDependencyRules";
import i18n from "i18next";
import { fromSirenEntityToEntity } from "../../../../commons/webui/adapters";
import { triggerActions } from "@uneo/platform-commons/webui/actions/actions";
import { getParentPath } from "@uneo/platform-commons/webui/utils/paths";

const CONTENT_ROOT_WIDGET_ID = "content-root";

interface IDependentsList {
    select?: SirenEntity[];
    deselect?: SirenEntity[];
}

/**
 * This method is responsible for manipulating, reading and managing the coverage selection from the user.
 * It could show modal with a message if some dependency rule requires it.
 * The message informs the user which coverages will be impacted from his selection, respectively selected and/or deselected.
 * The message for the modal is builded on the FE from hardcoded texts in the translations file.
 * The final job is to send user's selection to the BE.
 *
 * There are two types of coverages - optional and comparable.
 * Optional can be selected and deselected independently form each other in same group or in different groups.
 * Comparable can be selected and deselected by itself, but also two or more in a group behave as a radio buttons.
 * Also we have reset functionality which deselects all selected coverages from a section no matter the type.
 */
/**
 * @param {boolean} options.notifyBE - {boolean} - when update action needs to be sent to BE on popup confirm
 * @param {string} options.labelPath - {string} - relative path inside coverage data in order get the label from
 * @param {string} options.coveragesEntity - {string} - entityPath where to search for all coverages
 * @param {boolean} options.preventDeselection - {boolean} - prevents complete deselection of "comparable" coverages
 * @returns
 */

export const manageCoverageSelection = (data: Entity, options: Record<string, any> = {}) => {
    //We need to know the current group to extract the groupType, coverages from the same group,
    //the initiator (the entity initiated the current action to be executed) which we need later
    const initiator = data.id;
    const coverageSelection: ISelectionCoverageList = {
        select: [],
        deselect: []
    };
    const widgetId: string = CONTENT_ROOT_WIDGET_ID;

    //It is important to know if the initiator action is selection or deselection to determine the modal message in case we show it
    let isInitiatorSelected = false;
    if (data.properties?.type !== "reset") {
        const cardGroupPath = getParentEntityPath({
            widgetId,
            entityPath: data.contextPath!
        });
        if (!cardGroupPath) {
            console.warn("Can not find coverage group entity path");
            return;
        }
        const cardGroupEntity = getEntity({
            widgetId,
            entityPath: cardGroupPath
        });
        if (!cardGroupEntity) {
            console.warn("Can not find coverage group entity");
            return;
        }
        const siblings = cardGroupEntity.entities;
        const groupType = cardGroupEntity.properties?.type;
        const groupSelection = cardGroupEntity.properties?.value?.split(",") || [];

        if (groupType === "comparable") {
            //For comparable coverages behaving as radio buttons we get if the user made deselection of selected coverage
            const deselected = siblings?.find((sibling) => groupSelection.includes(sibling.id));
            //If we do not have deselection or the initiator is not the deselected coverage we can say that the initiator action is selection,
            //as we are in comparable group type
            if (!deselected || initiator !== deselected.id) {
                coverageSelection.select.push(initiator);
                isInitiatorSelected = true;
            }
            // Other case we we have deselection and we store it to the coverageSelection object
            if (deselected) {
                coverageSelection.deselect.push(deselected.id);
            }
            if (options.preventDeselection && groupSelection.includes(initiator)) {
                return;
            }
        } else if (groupType === "optional") {
            const isSelected = groupSelection.includes(data.id) || false;
            if (isSelected) {
                //if is selected - user wants to deselect
                coverageSelection.deselect.push(initiator);
            } else {
                //if is deselected - user wants to select
                coverageSelection.select.push(initiator);
                isInitiatorSelected = true;
            }
        }
    } else {
        const groups = getEntityChildren(data, "secondary-info.coverages");
        groups.forEach((group: Entity) => {
            const coverages: Entity[] = group.entities || [];
            coverages?.forEach((coverage: Entity) => {
                if (group.properties?.value.split(",").includes(coverage.id)) {
                    coverageSelection.deselect.push(coverage.id);
                }
            });
        });
    }
    if (coverageSelection.select !== coverageSelection.deselect) {
        //Here we get all dependent selections from the dependency rules
        const coveragesData: ICoveragesData = manageCoverageDependencyRules(
            { ...coverageSelection },
            widgetId,
            options
        );

        if (coveragesData?.data?.length) {
            //Set parameters coverage data for BE
            const submitEntity = getEntity({
                widgetId,
                entityPath: "warning.submit"
            });
            const actions: any[] = [];

            //send update action with the changed selection
            if (options.notifyBE) {
                if (!submitEntity) {
                    console.warn("No modal submit action found");
                    return;
                }
                const actionSources = submitEntity?.actions?.[0]?.sources;
                //Build the update action which is executed in any case
                actions.push({
                    id: "popupSubmit",
                    type: "update",
                    method: "POST",
                    url: "/webui/widget/oav/oav-quote-coverage-selection",
                    contentType: "application/json",
                    parameters: {
                        coverageRules: {
                            coveragesState: coveragesData.data
                        }
                    },
                    sources: actionSources
                });
            } else {
                if (!submitEntity) {
                    updateEntity({
                        widgetId,
                        entityPath: "warning.submit",
                        entity: fromSirenEntityToEntity({
                            class: ["submit"],
                            actions: []
                        })
                    });
                }
            }

            //We check if we have flag from the dependency rules logic to show modal
            if (coveragesData.showWarningPopup) {
                actions.push(
                    //Call the action on popup submit
                    {
                        type: "updateCoverageSelection",
                        method: "POST",
                        contentType: "application/json",
                        properties: {
                            coveragesState: coveragesData.changedCoverages
                        },
                        targets: [options.coveragesEntity || widgetId]
                    }
                );

                //Set update action parameters data and call update selection action
                updateEntityActions({
                    widgetId,
                    entityPath: "warning.submit",
                    actions: actions,
                    override: true
                });

                //Get dependent coverages names and list it in the popup
                //Here we build the modal message that informs the user which coverages will be selected and/or deselected
                const coverageLabelsEntities: IDependentsList =
                    extractCoverageLabels(coveragesData);
                const dependentsEntity: SirenEntity[] = [];
                //Depending on the initiator action type we show the corresponding message from the translations
                const selectionTypeText = isInitiatorSelected
                    ? i18n.t("quote.coverages.coverageSelect")
                    : i18n.t("quote.coverages.coverageDeselect");
                if (coveragesData.changedCoverages.select.includes(initiator))
                    if (coverageLabelsEntities.select?.length) {
                        dependentsEntity.push({
                            class: ["selectionText"],
                            properties: {
                                value: `${selectionTypeText} ${i18n.t(
                                    "quote.coverages.coverageSelectionText"
                                )}`
                            }
                        });
                        dependentsEntity.push({
                            class: ["select"],
                            entities: coverageLabelsEntities.select
                        });
                    }
                if (coverageLabelsEntities.deselect?.length) {
                    dependentsEntity.push({
                        class: ["deselectionText"],
                        properties: {
                            value: `${selectionTypeText} ${i18n.t(
                                "quote.coverages.coverageDeselectionText"
                            )}`
                        }
                    });
                    dependentsEntity.push({
                        class: ["deselect"],
                        entities: coverageLabelsEntities.deselect
                    });
                }

                updateEntity({
                    widgetId,
                    entityPath: "warning.dependents",
                    entity: fromSirenEntityToEntity({
                        class: ["dependents"],
                        entities: dependentsEntity
                    })
                });

                // Open modal finally
                triggerActions(
                    [
                        {
                            type: "openModal",
                            targets: ["warning"]
                        }
                    ],
                    { widgetId }
                );
            } else {
                //In no popup to show - send data to BE and than update coverage selection
                if (options.notifyBE) {
                    updateEntityActions({
                        widgetId,
                        entityPath: "warning.submit",
                        actions: actions
                    });
                    triggerActions(actions, { widgetId: "content-root" });
                }
                updateCoverageSelection(
                    {
                        actionType: "updateCoverageSelection",
                        properties: { coveragesState: coveragesData.changedCoverages },
                        targets: [options.coveragesEntity || widgetId]
                    },
                    { widgetId }
                );
            }
        }
    }
};

export const updateCoverageSelection = (
    action: SirenAction,
    { widgetId }: { widgetId: string }
) => {
    if (!action) return;
    console.error("No actions for update coverages selection");
    const coverages = action.properties?.coveragesState;
    // Doing the select/deselect process
    const entity = getEntity({
        widgetId,
        entityPath: (action.targets && action.targets[0]) || widgetId
    });
    if (entity) {
        for (const selectionType of Object.keys(coverages)) {
            for (const coverage of coverages[selectionType]) {
                const coveragePath = extractCoveragePath(entity, coverage);
                const coverageGroupPath = getParentPath(coveragePath);
                const coverageGroup = getEntity({ widgetId, entityPath: coverageGroupPath });
                if (coverageGroup) {
                    const currentValue = coverageGroup.properties?.value || "";
                    let newValue = currentValue ? currentValue.split(",") : [];
                    if (selectionType === "select") {
                        newValue.push(coverage);
                    } else {
                        newValue = newValue.filter((value) => value !== coverage);
                    }

                    updateEntityProps({
                        widgetId,
                        entityPath: coverageGroupPath,
                        props: { value: newValue.join(",") }
                    });
                }
            }
        }
    }
};
//Extract most specific path, because there are nested identifiers like
/*
    In some cases when the entity identifier is the same as its parent identifier for example
    coverages -> name-1 -> name-1
    the getEntityPaths("name-1") method will return 2 or more paths
    coverages.name-1
    coverages.name-1.name-1
    because it searches for entity with identifier name-1
    So we need to find the longest one which should be the most descriptive and most accurate path
*/
const extractCoveragePath = (entity: Entity, coverage: string) => {
    const paths = findAllEntityPathsById(entity, coverage);
    if (paths.length > 1) {
        return paths.reduce((a: string, b: string) =>
            a.split(".").length > b.split(".").length ? a : b
        );
    }
    return paths[0];
};

const extractCoverageLabels = (coveragesData: ICoveragesData) => {
    const result: IDependentsList = {
        select: [],
        deselect: []
    };
    Object.keys(coveragesData.changedCoverages).forEach((key) =>
        coveragesData.data
            .filter((element) => coveragesData.changedCoverages[key].includes(element.id))
            .forEach((element) => {
                result[key].push({
                    class: [element.id],
                    properties: {
                        label: element.label
                    }
                });
            })
    );
    return result;
};
