"use client";
import React, { useState, useRef, useEffect } from "react";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import { IBaseComponentProps, IEventHandlers, ILabeledProps } from "../../../types";
import { SirenEnumEntity } from "../../../utils/commonTypes";

export default function Switcher(props: ISwitcherProps) {
    const entities = props.entities;

    const initialValue = props.value ? props.value.split(",") : [];
    const [value, setValue] = useState<string[]>(props.value === undefined ? [] : initialValue);
    const [activeDimension, setActiveDimension] = useState<{ left: string; width: string }>();

    const itemRefs = useRef({});

    const updateValue = (newValue) => {
        setValue(newValue);
        props.onChange({ selection: newValue });
    };

    const updateActiveDimensions = (ref?) => {
        setActiveDimension({
            left: `${ref.current.offsetLeft}px`,
            width: `${ref.current.getBoundingClientRect().width}px`
        });
    };

    const handleItemClick = (entity) => {
        if (props.disabled) {
            return;
        }

        const val = entity.properties.value;
        if (value.find((item) => item === val)) {
            if (!props.deselection) {
                return;
            }
            if (props.singleSelection) {
                updateValue([]);
                return;
            }
            updateValue(value.filter((item) => item !== val));
        } else {
            if (props.singleSelection) {
                updateValue([val]);
                return;
            }
            updateValue([...value, val]);
        }
    };

    const useCase = props.singleSelection ? "radio" : "checkbox";
    let valueClass = "tq-" + useCase + "-switch";
    if (props.verticalItemContent) {
        valueClass += " tq-vertical-content";
    }
    if (props.verticalValue) {
        valueClass += " tq-vertical-layout";
    }
    if (props.boolean) {
        valueClass = "tq-radio-onoff";
    }
    const animated = props.animated !== false; //animated by default!
    const labelProps = getLabelProps(props, {
        valid: props.valid,
        valueContainerClass: valueClass,
        vertical: props.verticalLabel,
        animated: animated
    }) as any;
    labelProps.errorMessage = props.errorMessage;

    /* eslint-disable react-hooks/rules-of-hooks */
    const addToRefsArray = (entityValue) => {
        const ref = useRef();
        itemRefs.current[entityValue] = ref;
        return ref;
    };
    /* eslint-enable react-hooks/rules-of-hooks */

    useEffect(() => {
        if (!props.separated && value?.length > 0) {
            const activeItem = entities.find((entity) => value.includes(entity.properties.value));
            if (activeItem) {
                updateActiveDimensions(itemRefs.current[activeItem.properties.value]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Labeled {...labelProps}>
            {value &&
                !props.separated &&
                value.length > 0 &&
                activeDimension &&
                activeDimension.left &&
                activeDimension.width && (
                    <div
                        className={`tq-active ${
                            value.includes(entities[0]?.properties?.value)
                                ? "tq-has-selected-first"
                                : "tq-has-selected-last"
                        }`}
                        style={{
                            left: activeDimension.left,
                            width: activeDimension.width
                        }}
                    ></div>
                )}
            {entities.map((entity) => {
                const label = entity.properties.label;
                const active = value.find((val) => val === entity.properties.value) ? true : false;

                return (
                    <div
                        className={`tq-radio-option ${active ? "tq-selected" : ""}`}
                        tabIndex={0}
                        onClick={() => {
                            handleItemClick(entity);
                        }}
                        key={entity.properties.value}
                        ref={addToRefsArray(entity.properties.value)}
                    >
                        <div className="tq-radio"></div>

                        {entity.properties?.icon && <i className={entity.properties.icon}></i>}
                        {label && <span className="tq-text-node">{label}</span>}
                        {entity.properties.jsx && entity.properties.jsx}
                    </div>
                );
            })}
        </Labeled>
    );
}

interface ISwitcherProps extends ILabeledProps, IBaseComponentProps, IEventHandlers {
    verticalLabel: boolean;
    verticalValue?: boolean;
    animated?: boolean;
    singleSelection: boolean;
    boolean?: boolean;
    selection: string[];
    verticalItemContent?: boolean;
    onItemChange: (state: Record<string, any>) => void;
    propagateEvents?: boolean;
    deselection: boolean;
    separated: boolean;
    errorMessage: string;
    entities: SirenEnumEntity[];
}
