"use client";
import React, { useState, useRef, useEffect } from "react";
import IBAN from "@tinqin/tinqin-utils/src/iban";
import DomUtils from "@tinqin/tinqin-utils/src/dom";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import {
    ILabeledProps,
    IBaseComponentProps,
    IEventHandlers,
    ILabeledPropsKeys,
    IBaseComponentPropsKeys,
    IEventHandlersKeys
} from "../../../types";

const getValue = (propValue: string | undefined, separator: string): string => {
    let value = "";
    if (propValue) {
        value = propValue.replace(/[^0-9a-z]/gi, "");
        value = IBAN.electronicFormat(value);
        value = IBAN.printFormat(value, separator);
    }

    return value;
};

const IbanInput = (props: IIbanInputProps) => {
    const [value, setValue] = useState<string>(getValue(props.value, props.separator));
    const [valid, setValid] = useState<boolean>(props.valid);
    const input = useRef<HTMLInputElement>(null);

    let selectionStart: number, selectionEnd: number;

    useEffect(() => {
        if (props.valid !== valid) {
            setValid(props.valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.valid]);

    const onFocus = (event) => {
        if (props.onFocus) {
            props.onFocus(event);
        }
    };

    const onChange = (event) => {
        selectionStart = event.currentTarget.selectionStart;
        selectionEnd = event.currentTarget.selectionEnd;
        const inputValue = event.currentTarget.value;
        let newValue = getValue(inputValue, props.separator);

        if (
            event.currentTarget.selectionStart % 5 === 0 &&
            event.currentTarget.selectionStart !== 0
        ) {
            if (value.length < newValue.length) {
                selectionStart += 1;
                selectionEnd += 1;
            } else {
                selectionStart -= 1;
                selectionEnd -= 1;
            }
        }

        // we form the newState object in order to be sure that we will pass the new state to the props.onChange handler
        const newState: Record<string, any> = {
            value: newValue,
            valid
        };
        setValue(newState.value);
        if (newValue !== "") {
            newState.valid = true;
            setValid(true);
        }
        //Inform consumer
        //manage manually the cursor position since toUpperCase() transforms the value as a whole new one, which causes the cursor to go to the end after setState
        input.current?.setSelectionRange(selectionStart, selectionEnd);
        if (props.onChange) {
            props.onChange(newState);
        }
    };

    const onBlur = (event) => {
        const isClickOutside = DomUtils.isClickOutside(event, input.current?.parentElement);
        if (!isClickOutside) {
            input.current?.focus();
            return;
        }
        if (props.onBlur) {
            props.onBlur();
        }
    };

    let inputClass = "tq-input";
    if (props.valueAlignment) {
        inputClass += " tq-text-" + props.valueAlignment;
    }
    //Form label props
    const labelProps = getLabelProps(props, {
        value,
        valid,
        valueContainerClass: "tq-text-input",
        hasFeedback: false
    });
    const inputType = "text";
    const valueToDisplay = value;
    return (
        <Labeled {...labelProps}>
            <input
                type={inputType}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                className={inputClass}
                value={valueToDisplay}
                placeholder={props.placeholder}
                disabled={props.disabled}
                ref={input}
                autoComplete="off"
            />
        </Labeled>
    );
};

IbanInput.displayName = "IbanInput";

interface IIbanInputProps extends ILabeledProps, IBaseComponentProps, IEventHandlers {
    placeholder?: string;
    separator: string;
    valueAlignment?: "left" | "center" | "right";
}

export const IbanInputPropKeys = [
    ...ILabeledPropsKeys,
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    "placeholder",
    "separator",
    "valueAlignment"
];
export default IbanInput;
