import React from "react";
import TextArea, { ITextAreaProps, ITextAreaPropsKeys } from "./TextArea";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";
import { IDebounce } from "../../../types";
import debounce from "../../../utils/debounce";

export default function TextAreaContainer(props) {
    const extractedProps: ITextAreaProps & IDebounce = extractComponentProps(props, {
        componentProps: ITextAreaPropsKeys,
        additionalProps: ["debounce"],
        propsDefaults: {
            extraClasses: "",
            autoHeight: true
        }
    });

    if (extractedProps.debounce) {
        const debounceTimeout = extractedProps.debounce.wait || 200;
        const debounceImmediate = !!extractedProps.debounce.immediate;
        extractedProps.onChange = debounce(
            (state) => {
                if (props.onChange) {
                    props.onChange(state);
                }
            },
            debounceTimeout,
            debounceImmediate
        );
    }
    return <TextArea {...extractedProps} />;
}
