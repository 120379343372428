/* eslint-disable @next/next/no-img-element */
"use client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "@tinqin/design-uneo-pf/css/smart-banner.css";

declare global {
    interface Navigator {
        standalone?: boolean;
    }
}
const STORE_URL = {
    iOS: "https://apps.apple.com/fr/app/un%C3%A9oquotidien/id1434310612",
    Android: "https://play.google.com/store/apps/details?id=com.uneo_mobile"
};

const getDeviceInfo = () => {
    const { userAgent } = window.navigator;
    let browser: string = "";
    let os: "iOS" | "Android" | "" = "";

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        os = "iOS";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    }

    if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "SamsungBrowser";
    } else if (userAgent.indexOf("Edg") > -1) {
        browser = "Edg";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
        browser = "Firefox";
    } else if (userAgent.indexOf("CriOS") > -1) {
        browser = "CriOS";
    } else if (userAgent.indexOf("FxiOS") > -1) {
        browser = "FxiOS";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Safari";
    }
    return { browser, os };
};

//Working on Chrome and Android - https://medium.com/dev-channel/detect-if-your-native-app-is-installed-from-your-web-site-2e690b7cb6fb
const isAppInstalledAndroid = async () => {
    if ("getInstalledRelatedApps" in navigator) {
        try {
            const apps = await (navigator as any).getInstalledRelatedApps();
            return apps.some((app) => app.id === "com.uneo_mobile" && app.platform === "play");
        } catch (error) {
            console.warn("Error checking getInstalledRelatedApps", error);
        }
    }
    return false;
};

const setCookie = (cName: string, cValue: string | boolean, expDays: number) => {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
};

const getCookie = (cName: string) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    let res: string = "";
    cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
};

export default function SmartBanner() {
    const [isClient, setIsClient] = useState(false);
    const [closed, setClosed] = useState(false);
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        async function checkAppInstallation() {
            const installed = await isAppInstalledAndroid();
            setIsAppInstalled(installed);
            setIsClient(true);
        }
        checkAppInstallation();
    }, []);

    if (!isClient || closed) return <></>;
    const { browser, os } = getDeviceInfo();
    const action: string = isAppInstalled ? "open" : "view";
    const isPWA: boolean = window.matchMedia("(display-mode: standalone)").matches;

    //If the app is not installed open the store to install it
    //If we are sure it is installed change the text and in most cases open the app or fallback through the store.
    //Links related to TWA bubblewrap build issue with the activity launcher. In future it will be good to be researched of ot is fixed.
    //https://github.com/GoogleChromeLabs/bubblewrap/issues/700
    //https://bugs.chromium.org/p/chromium/issues/detail?id=1419651
    const handleClick = () => {
        if (action === "view") {
            setCookie("smartbanner-installed", true, 180);
            setClosed(true);
            window.open(STORE_URL[os]);
        } else {
            window.open(
                `intent://#Intent;scheme=https;package=com.uneo_mobile;S.browser_fallback_url=${STORE_URL[os]};end;`
            );
        }
    };

    const handleClose = () => {
        setCookie("smartbanner-closed", true, 30);
        setClosed(true);
    };

    // Don't show banner when:
    // 1) if device isn't iOS or Android
    // 2) if device is iOS with Safari browser
    // 3) website is loaded in app,
    // 4) user dismissed banner,
    if (
        isPWA ||
        window.navigator.standalone ||
        getCookie("smartbanner-closed") ||
        getCookie("smartbanner-installed") ||
        !os ||
        (os === "iOS" && browser === "Safari")
    ) {
        return <></>;
    }
    const bannerText = t(
        action === "view" ? `smartBanner.view.text.${os}` : "smartBanner.open.text"
    );

    return (
        <div id="un-smart-banner">
            <div className="un-smart-banner-info">
                <i className="tq-icon-close" role="button" onClick={handleClose}></i>
                <div className="un-smart-banner-logo">
                    <img src="/img/uneo-contextual.png" alt="" />
                </div>
                <div className="un-smart-banner-details">
                    <span className="un-app-title">Unéo&apos;quotidien</span>
                    <span className="un-app-subtitle">Bien plus qu&apos;une mutuelle</span>
                    <span className="un-app-extra-text">{bannerText}</span>
                </div>
            </div>
            <button className="tq-button un-ds-button" type="button" onClick={handleClick}>
                <span style={{ whiteSpace: "nowrap" }} className="tq-text-node">
                    {t(`smartBanner.${action}.button`)}
                </span>
            </button>
        </div>
    );
}
