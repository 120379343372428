"use client";
import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "@tinqin/design-uneo-pf/css/progress-bars.css";
import "@tinqin/design-uneo-pf/css/sticky-container.css";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

const STICKY_HEADER_ID = "js-sticky-header";
const HEADER_CLASS = "tq-header";
const RIBBONS_CLASS = "un-ribbons";

//This is done because of the ribbon container which overlays the progressbar
//We have to render the progress bar from inner components into the header - https://jira.tinqin.com/browse/UPF-1321
export default function StickyHeaderContainer(props: { children: ReactNode | ReactNode[] }) {
    const [stickyHeaderElement, setStickyHeaderElement] = useState<HTMLElement | null>(null);
    const { children } = props;
    useEffect(() => {
        const stickyHeader: HTMLElement | null = document.getElementById(STICKY_HEADER_ID);
        const ribbonsContainer: HTMLElement = document.getElementsByClassName(
            RIBBONS_CLASS
        )?.[0] as HTMLElement;
        const header: HTMLElement = document.getElementsByClassName(
            HEADER_CLASS
        )?.[0] as HTMLElement;
        const calculateTop = (): void => {
            if (stickyHeader) {
                //Calculate css top property only for mobile
                if (window.innerWidth <= 980) {
                    const stickyTop = header.offsetHeight - (ribbonsContainer?.offsetHeight || 0);
                    stickyHeader.style.setProperty("top", stickyTop + "px");
                } else {
                    //Return to default css property
                    stickyHeader.style.setProperty("top", "");
                }
            }
        };
        const debouncedCalculateTop = debounce(calculateTop, 100);
        if (stickyHeader) {
            setStickyHeaderElement(stickyHeader);
            calculateTop();
            window.addEventListener("resize", debouncedCalculateTop);
        }
        return () => window.removeEventListener("resize", debouncedCalculateTop);
    }, []);

    if (!stickyHeaderElement) {
        return <></>;
    }

    return createPortal(<>{children}</>, stickyHeaderElement);
}
