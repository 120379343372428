import { Action, ActionContext, Entity } from "../../webui/types";
import { getEntity } from "../../webui/store/actions";

const MISSING_URL = "MISSING-URL";

export const getActionUrl = (action: Action, context: ActionContext): string => {
    return constructUrl({
        rawUrl: action.url || MISSING_URL,
        parameters: action.parameters || {},
        context,
        skipEncoding: action.properties?.skipEncoding
    });
};

export const getWidgetUrl = (widgetInfo: Entity, context: ActionContext): string => {
    return constructUrl({
        rawUrl: widgetInfo?.properties?.href,
        parameters: widgetInfo?.properties?.parameters,
        context,
        skipEncoding: widgetInfo.properties?.skipEncoding
    });
};
//calculates query parameters
//action parameters can be special strings in the format "{filter.age}" which are evaluated
//encodes result string by default
export const constructUrl = ({
    rawUrl = "",
    parameters = {},
    context,
    skipEncoding = false
}: {
    rawUrl: string;
    parameters: Record<string, string>;
    context: ActionContext;
    skipEncoding?: boolean;
}): string => {
    const { path, search } = splitUrl(rawUrl);
    let resultSearchString = "";
    const evaluatedParameters = evaluateParameters(parameters, context);
    //encoding result can be skipped #UPF-1792
    if (skipEncoding === true) {
        const parametersMap = getQueryParametersObject(search);
        Object.keys(evaluatedParameters).forEach((key) => {
            parametersMap[key] = evaluatedParameters[key];
        });
        //creating the result search
        resultSearchString = "";
        Object.keys(parametersMap).forEach((key, index) => {
            if (index !== 0) {
                resultSearchString += "&";
            }
            resultSearchString += key + "=" + parametersMap[key];
        });
    } else {
        const newSearch = new URLSearchParams(search);
        Object.keys(evaluatedParameters).forEach((key) => {
            if (newSearch.has(key)) {
                newSearch.set(key, evaluatedParameters[key]);
            } else {
                newSearch.append(key, evaluatedParameters[key]);
            }
        });
        resultSearchString = newSearch.toString();
    }
    if (!resultSearchString.length) {
        return path;
    }
    return `${path}?${resultSearchString}`;
};

export const evaluateParameters = (
    params: Record<string, string> = {},
    actionContext: ActionContext
): Record<string, string> => {
    const result = {};
    Object.keys(params).forEach((key) => {
        if (typeof params[key] === "string" && isDynamicParameter(params[key])) {
            const entityPath = parseDynamicParameter(params[key]);
            const entity = getEntity({
                widgetId: actionContext.widgetId,
                entityPath,
                currentEntityPath: actionContext.currentEntityPath
            });
            let value = "";
            if (entity) {
                value = entity.properties?.value;
            }
            result[key] = value;
        } else {
            result[key] = params[key];
        }
    });
    return result;
};

export const isDynamicParameter = (value: string): boolean =>
    value.startsWith("{") && value.endsWith("}");

export const parseDynamicParameter = (value: string): string =>
    value.substring(1, value.length - 1);

export const splitUrl = (rawUrl: string): { path: string; search: string } => {
    let path = rawUrl;
    let search = "";
    const markPosition = rawUrl.indexOf("?");
    if (markPosition !== -1) {
        search = rawUrl.substring(markPosition + 1);
        path = rawUrl.substring(0, markPosition);
    }
    return { path, search };
};

export const getQueryParametersObject = (search: string = ""): Record<string, string> =>
    search.split("&").reduce(function (result, query) {
        if (query !== "") {
            const chunks = query.split("=");
            const key = chunks[0];
            const value = decodeURIComponent(chunks[1] || "");
            result[key] = value;
        }
        return result;
    }, {});
