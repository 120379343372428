import { updateEntityProps } from "../../webui/store/actions";
import { Action, ActionContext } from "../../webui/types";

//updating modal entity with "opened": false
//modal entity can be specified in targets, there is a default target "modal-section"
export const closeModal = (action: Action, actionContext: ActionContext) => {
    if (!actionContext) {
        console.error("Missing action context.");
        return;
    }
    const widgetId = actionContext.widgetId;
    return updateEntityProps({
        widgetId,
        entityPath: action.targets?.[0] || "modal-section",
        props: { opened: false },
        currentEntityPath: actionContext.currentEntityPath
    });
};

//updating modal entity with "opened": true
//modal entity can be specified in targets, there is a default target "modal-section"
export const openModal = (action: Action, actionContext: ActionContext) => {
    if (!actionContext) {
        console.error("Missing action context.");
        return;
    }
    const widgetId = actionContext.widgetId;
    return updateEntityProps({
        widgetId,
        entityPath: action.targets?.[0] || "modal-section",
        props: { opened: true },
        currentEntityPath: actionContext.currentEntityPath
    });
};
