import { Action, ActionContext, ActionResult } from "../types";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import { actionsMap } from "../../platform/actions/webuiActions";
import promiseAllSettled from "../utils/promiseAllSettled";

export const ACTIONS_RESPONSE = "Actions response is received.";
export const TARGETED_ERROR_RESPONSE = "Targeted error response is received.";
const ACTION_FAILS_GRACEFULLY = "Action was unsuccessful.";
const EVENT_NAME_DEFAULT = "default";
const EVENT_NAME_SUCCESS = "success";
const EVENT_NAME_FAILURE = "failure";
const EVENT_NAME_FINAL = "final";
const UNKNOWN_CONTEXT_WIDGET = "unknown-widget";
const UNKNOWN_CONTEXT_PATH = "unknown-path";

export const triggerActions = async (
    actions: Action[] = [],
    context?: ActionContext,
    events?: string[]
): Promise<ActionResult> => {
    const triggerEvents: string[] = events?.length
        ? events
        : [EVENT_NAME_SUCCESS, EVENT_NAME_DEFAULT];
    const filteredActions = filterActions(actions, triggerEvents);
    const result: ActionResult = { results: [], errors: [], execution: [] };
    await promiseAllSettled(
        filteredActions.map((action) => triggerActionChain({ action, context, result }))
    );
    return result;
};

export const triggerAction = async (
    action: Action,
    context?: ActionContext
): Promise<ActionResult> => {
    const result: ActionResult = { results: [], errors: [], execution: [] };
    await triggerActionChain({ action, context, result });
    return result;
};

function filterActions(actions: Action[] = [], events: string[] = ["default"]) {
    return actions.filter((action) =>
        ArrayUtils.includesAtLeastOneOf(action.events || ["default"], events)
    );
}

async function triggerActionChain({
    action,
    context = {
        widgetId: UNKNOWN_CONTEXT_WIDGET,
        currentEntityPath: UNKNOWN_CONTEXT_PATH
    },
    result
}: {
    action: Action;
    context?: ActionContext;
    result: ActionResult;
}) {
    const innerActions = action.actions || [];
    const successActions = filterActions(innerActions, [EVENT_NAME_SUCCESS, EVENT_NAME_DEFAULT]);
    const failActions = filterActions(innerActions, [EVENT_NAME_FAILURE]);
    const finalActions = filterActions(innerActions, [EVENT_NAME_FINAL]);
    if (!actionsMap[action.type]) {
        console.log("No actions found for", action.type);
        return;
    }
    console.log("Executing action", action.type, action);
    try {
        const actionMethod = actionsMap[action.type];
        const actionResult = await actionMethod(action, context);
        result.results.push(actionResult);
        result.execution.push({
            action,
            result: actionResult
        });
        await promiseAllSettled(
            successActions.map((innerAction) =>
                triggerActionChain({ action: innerAction, context, result })
            )
        );
    } catch (error) {
        if (
            error.message !== ACTION_FAILS_GRACEFULLY &&
            error.message !== ACTIONS_RESPONSE &&
            error.message !== TARGETED_ERROR_RESPONSE
        ) {
            console.error(error);
        }
        result.errors.push(error);
        result.results.push(error);
        result.execution.push({
            action,
            result: error
        });
        await promiseAllSettled(
            failActions.map((innerAction) =>
                triggerActionChain({ action: innerAction, context, result })
            )
        );
    }
    await promiseAllSettled(
        finalActions.map((innerAction) =>
            triggerActionChain({ action: innerAction, context, result })
        )
    );
}
