"use client";
import React, { useState } from "react";
import InfoModal from "../../infoModal/InfoModal";
import { ILabeledProps } from "../../../types";

export default function Labeled(props: ILabeledComponentProps) {
    const [modalOpened, setModalOpened] = useState(false);

    let containerClass = props.containerClass || "";
    if (props.vertical) containerClass += " tq-vertical";
    if (props.extraClasses) containerClass += ` ${props.extraClasses} `;
    if (props.disabled) containerClass += " tq-disabled";
    if (props.skipLabel) containerClass += " tq-no-label";
    if (props.valid === false) containerClass += " tq-has-error";

    let valueContainerClass = props.valueContainerClass || "";
    if (props.valid === false) valueContainerClass += " tq-has-error";

    return (
        <div className={containerClass} data-tq-id={props.dataTqId} role={props.role}>
            {!props.skipLabel && (
                <div className={"tq-label-container"}>
                    <label className="tq-label">
                        <span className="tq-text-node">
                            {props.label || ""}
                            {props.labelSuffix && (
                                <span className="un-ds-secondary-label">{props.labelSuffix}</span>
                            )}
                            {!props.required && <span className="un-ds-optional">(Optionnel)</span>}
                            {props.tooltip && (
                                <span className="tq-input-feedback">
                                    {modalOpened && (
                                        <InfoModal
                                            value={props.tooltip}
                                            onClose={() => setModalOpened(false)}
                                        />
                                    )}
                                    <i
                                        className={
                                            "tq-feedback-icon " +
                                            (props.labelButton || "tq-o-icon-circle-info")
                                        }
                                        tabIndex={0}
                                        role="button"
                                        onClick={() => {
                                            setModalOpened(true);
                                            if (props.onOpenTooltip) {
                                                props.onOpenTooltip();
                                            }
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter" || event.code === "Enter") {
                                                setModalOpened(true);
                                                if (props.onOpenTooltip) {
                                                    props.onOpenTooltip();
                                                }
                                            }
                                        }}
                                    />
                                </span>
                            )}
                        </span>
                    </label>
                </div>
            )}
            <div className={valueContainerClass}>{props.children}</div>
        </div>
    );
}

interface ILabeledComponentProps extends ILabeledProps {
    children: any;
}
