"use client";
import { loadFeature } from "../features";
import { updateEntity } from "@uneo/platform-commons/webui/store/actions";
import { fromSirenEntityToEntity } from "@uneo/platform-commons/webui/adapters";
let expireTimeout: string | number | NodeJS.Timeout, idleTimeout: string | number | NodeJS.Timeout;

export const refreshSession = async (triggeredFromModalMount = false) => {
    let sessionInfo;
    try {
        sessionInfo = await loadFeature("session");
    } catch (e) {
        console.error(`Failed to fetch session feature - ${e.message}`);
        return;
    }
    if (triggeredFromModalMount && (expireTimeout || idleTimeout)) {
        return;
    }
    //In case we got falsy or no value for idleTime or expireTimestamp - do not show the modal
    if (
        sessionInfo.enabled &&
        sessionInfo.settings?.idleTime &&
        sessionInfo.settings?.expireTimestamp
    ) {
        clearTimeout(expireTimeout);
        clearTimeout(idleTimeout);
        const now = new Date().getTime();
        expireTimeout = setTimeout(() => {
            openSessionModal(sessionInfo.settings.resetUrl);
        }, sessionInfo.settings.expireTimestamp - now);
        idleTimeout = setTimeout(() => {
            openSessionModal(sessionInfo.settings.resetUrl);
        }, sessionInfo.settings.idleTime * 60 * 1000);
    }
};

const openSessionModal = (url) => {
    updateEntity({
        widgetId: "content-root",
        entityPath: "content-root.modals.session",
        currentEntityPath: "content-root",
        entity: fromSirenEntityToEntity({
            class: ["session"],
            properties: {
                opened: true
            },
            entities: [
                {
                    class: ["link"],
                    actions: [
                        {
                            actionType: "openTarget",
                            href: url
                        }
                    ]
                }
            ]
        })
    });
};
