import { useEffect, useState } from "react";
import {
    ACTIONS_RESPONSE,
    TARGETED_ERROR_RESPONSE,
    triggerActions
} from "../../webui/actions/actions";
import { fromSirenActionToAction, fromSirenEntityToEntity } from "../../webui/adapters";
import { ActionContext } from "../../webui/types";
import { getToken } from "@uneo/platform-commons/platform/hooks/useRecaptcha";
import { updateEntity as updateStoreEntity } from "@uneo/platform-commons/webui/store/actions";

const DEFAULT_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-origin": "Web"
};
//regular fetch with additional check for response with webui actions
export const fetchData = async ({
    url,
    fetchOptions = {},
    actionContext
}: {
    url: string;
    fetchOptions?: Record<string, any>;
    actionContext?: ActionContext;
}) => {
    console.log("fetch ", url);
    //adding default headers if none specified
    if (!fetchOptions.headers) {
        fetchOptions.headers = { ...DEFAULT_HEADERS };
    } else {
        fetchOptions.headers = { ...DEFAULT_HEADERS, ...fetchOptions.headers };
    }
    //Custom header for BE to recognize request coming from web or PWA/mobile app
    if (
        ("standalone" in navigator && navigator.standalone) ||
        window.matchMedia("(display-mode: standalone)").matches
    ) {
        fetchOptions.headers["x-origin"] = "Application mobile";
    }
    if (actionContext?.currentEntityPath) {
        try {
            const token = await getToken(actionContext.currentEntityPath);
            if (token) {
                fetchOptions.headers = Object.assign(fetchOptions.headers, {
                    "x-captcha-token": token
                });
            }
        } catch (recaptchaError) {
            console.error(recaptchaError);
        }
    }
    Object.keys(fetchOptions.headers).forEach((item) => {
        if (fetchOptions.headers[item] === undefined) delete fetchOptions.headers[item];
    });
    if (!fetchOptions.redirect) {
        fetchOptions.redirect = "manual";
    }
    if (!fetchOptions.credentials) {
        fetchOptions.credentials = "include";
    }
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    return handleWebuiResponse(data, actionContext);
};

export const fetchFile = async ({
    url,
    fetchOptions = {}
}: {
    url: string;
    fetchOptions?: Record<string, any>;
}) => {
    console.log("fetchFile ", url);
    //adding default headers if none specified
    if (!fetchOptions.headers) {
        fetchOptions.headers = Object.assign({}, DEFAULT_HEADERS);
    } else {
        fetchOptions.headers = Object.assign({}, DEFAULT_HEADERS, fetchOptions.headers);
    }
    Object.keys(fetchOptions.headers).forEach((item) => {
        if (fetchOptions.headers[item] === undefined) delete fetchOptions.headers[item];
    });
    if (!fetchOptions.redirect) {
        fetchOptions.redirect = "manual";
    }
    if (!fetchOptions.credentials) {
        fetchOptions.credentials = "include";
    }
    return await fetch(url, fetchOptions);
};

//returns { isLoading, data, error}
//also can execute webui actions in the response if any
export const useFetch = ({
    url,
    skipFetching,
    successCallback,
    actionContext
}: {
    url: string;
    skipFetching?: boolean | (() => boolean);
    successCallback?: (data: JSON) => unknown;
    actionContext?: ActionContext;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const innerFetchData = (url: string) => {
        setIsLoading(true);
        fetchData({ url, actionContext })
            .then((data) => {
                setData(data);
                setIsLoading(false);
                if (successCallback) {
                    successCallback(data);
                }
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        if (!skipFetching || (typeof skipFetching === "function" && !skipFetching())) {
            innerFetchData(url);
        }
    }, []);
    return { isLoading, data, error };
};

const isContentFormatResponse = (data: Record<string, any>) => {
    return !!data.status;
};
const isTargetErrorResponse = (result) => {
    const entityClass = result?.class && result?.class[0];
    const target = result?.properties?.target;
    return !!(entityClass === "errors" && target);
};
const handleWebuiResponse = async (data, actionContext) => {
    if (isContentFormatResponse(data)) {
        if (data.actions) {
            //if there is an actions response, result content is ignored
            console.log("Actions response received:");
            await triggerActions(
                data.actions.map((action) => fromSirenActionToAction(action)),
                actionContext
            );
            throw new Error(ACTIONS_RESPONSE);
        }

        if (isTargetErrorResponse(data.content)) {
            console.log("Targeted error response received:");
            updateStoreEntity({
                widgetId: actionContext.widgetId,
                entityPath: data.content.properties.target + ".errors",
                currentEntityPath: actionContext.currentEntityPath,
                entity: fromSirenEntityToEntity(data.content)
            });
            throw new Error(TARGETED_ERROR_RESPONSE);
        }

        return data.content;
    }
    return data;
};
