"use client";
import React, { lazy } from "react";
import { Widget } from "@uneo/platform-commons/webui/types";
import { useFetchWidget } from "@uneo/platform-commons/platform/hooks";

import ContentRoot from "@uneo/platform-portal/widgets/content-root/ContentRoot";
import PortalWidget from "@uneo/platform-portal/widgets/portal/PortalWidget";

import PortalHeader from "./widgets/portal-header/PortalHeader";
import PortalFooter from "./widgets/portal-footer/PortalFooter";

const PortalGuidePage = lazy(
    () => import("./widgets/portal-guide-page/PortalGuidePage")
) as React.FC;
const PortalSuccessPage = lazy(
    () => import("./widgets/portal-success-page/PortalSuccessPage")
) as React.FC;
const PortalErrorPage = lazy(
    () => import("./widgets/portal-error-page/PortalErrorPage")
) as React.FC;
const PortalUnsubscribe = lazy(
    () => import("./widgets/portal-unsubscribe/PortalUnsubscribe")
) as React.FC;
const DefaultWidgetLoader = ({ info }: { info: Widget }): JSX.Element => {
    useFetchWidget(info);
    return <></>;
};

export const PortalWidgets = {
    portal: PortalWidget,
    "portal-header": PortalHeader,
    "portal-footer": PortalFooter,
    "content-root": ContentRoot,
    "portal-guide-page": PortalGuidePage,
    "portal-success-page": PortalSuccessPage,
    "portal-logo": DefaultWidgetLoader,
    "profile-menu": DefaultWidgetLoader,
    "portal-error-page": PortalErrorPage,
    "portal-unsubscribe": PortalUnsubscribe
};
