"use client";
import React, { useState } from "react";
import { resolveSlots } from "@uneo/platform-oav/utils/utils";

export default function AccordionWithSlots(props: {
    children: React.ReactNode | React.ReactNode[];
    extraClasses?: string;
    initialExpandedState?: boolean;
    onExpand?: any;
    onFirstExpand?: any;
}) {
    const { extraClasses, children, initialExpandedState, onFirstExpand, onExpand } = props;
    const [expanded, setExpanded] = useState(
        initialExpandedState !== undefined ? initialExpandedState : false
    );
    const [expandedOnce, setExpandedOnce] = useState(
        initialExpandedState !== undefined ? initialExpandedState : false
    );
    const toggle = () => {
        setExpanded((prevState) => !prevState);
        if (onFirstExpand && !expanded && !expandedOnce) {
            setExpandedOnce(true);
            onFirstExpand();
        }
        if (onExpand && !expanded) {
            onExpand();
        }
    };
    const childrenBySlots: { [key: string]: JSX.Element[] } = resolveSlots(children);

    return (
        <div className={`tq-accordion un-ds-accordion ${extraClasses}`}>
            <div className="un-section-color-indicator"></div>
            <div className={`tq-section tq-expandable ${expanded ? "tq-expanded" : ""}`}>
                <div className="tq-section-header" role="button" onClick={toggle}>
                    <div className="tq-splitbox">
                        <div className="tq-splitbox-primary">{childrenBySlots["header"]}</div>
                    </div>
                </div>
                <div className="tq-section-content">{childrenBySlots["default"]}</div>
            </div>
        </div>
    );
}
