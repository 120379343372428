"use client";

import React, { useEffect, useState } from "react";

import Dropdown, { IDropdownProps, IDropdownPropsKeys } from "../Dropdown/Dropdown";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import { MonthChecks } from "./types";
import { MONTHS } from "@uneo/platform-commons/platform/utils/months";
import { createDate } from "@uneo/platform-commons/platform/utils/dateUtils";
import { IDropdownOptionProps } from "../Dropdown/DropdownOption";
interface additionalProps {
    maxYears: number;
    startYear: number;
}
export default function DropdownDatepickerContainer(props) {
    const initialData = extractValue(props);
    const [day, setDay] = useState(initialData.day);
    const [month, setMonth] = useState(initialData.month);
    const [year, setYear] = useState(initialData.year);
    const [isLeap, setIsLeap] = useState(isLeapYear(year));

    const onDayChange = (value: string): void => {
        const selectedDay: string = value || "";
        if (selectedDay !== day) {
            setDay(selectedDay);
        }
    };

    const onMonthChange = (value: string): void => {
        const selectedMonth: string = value || "";
        if (selectedMonth !== month) {
            const checks: MonthChecks = checkNumberOfDays(selectedMonth);
            if (checks.isFebruary) {
                if (!isLeap && Number(day) > 28) {
                    setDay("28");
                } else if (isLeap && Number(day) > 29) {
                    setDay("29");
                }
            } else {
                if (!checks.has31Days && Number(day) > 30) {
                    setDay("30");
                }
            }
            setMonth(selectedMonth);
        }
    };

    const onYearChange = (value): void => {
        const selectedYear: string = value || "";
        if (selectedYear !== year) {
            const newIsLeap = isLeapYear(selectedYear);
            if (!newIsLeap && month === "02" && day === "29") {
                setDay("28");
            }
            if (newIsLeap !== isLeap) {
                setIsLeap(newIsLeap);
            }
            setYear(selectedYear);
        }
    };

    useEffect(() => {
        if (props.onChange) {
            if (day && month && year && props.value !== `${year}-${month}-${day}`) {
                props.onChange({
                    value: `${year}-${month}-${day}`
                });
            } else if (props.value !== "") {
                if (!day || !month || !year) {
                    props.onChange({ value: "" });
                }
            }
        }
    }, [day, month, year]);

    useEffect(() => {
        // Render was triggered by a prop change
        if (props.value) {
            const value = extractValue(props);
            if (day !== value.day) {
                setDay(value.day);
            }
            if (month !== value.month) {
                setMonth(value.month);
            }
            if (year !== value.year) {
                setYear(value.year);
            }
            if (isLeap !== isLeapYear(value.year)) {
                setIsLeap(isLeapYear(value.year));
            }
        } else if (day !== null || month !== null || year !== null || isLeap !== true) {
            setDay(null);
            setMonth(null);
            setYear(null);
            setIsLeap(true);
        }
    }, [props.value]);

    const extractedProps: IDropdownProps & additionalProps = extractComponentProps(props, {
        componentProps: IDropdownPropsKeys,
        additionalProps: ["maxYears", "startYear"],
        propsDefaults: {
            maxYears: 100,
            extraClasses: "",
            filterStrategy: "startsWith",
            popoverMinWidthToAnchor: true
        }
    });

    const commonProps: any = {};

    commonProps.autosuggestion = true;
    commonProps.popoverClasses = "un-oav-dropdown";
    commonProps.skipLabel = true;
    commonProps.valid = extractedProps.valid;
    commonProps.disabled = extractedProps.disabled;
    commonProps.extraClasses = "un-autocomplete-dropdown un-dropdown un-outline";
    commonProps.filterStrategy = extractedProps.filterStrategy;
    commonProps.popoverMinWidthToAnchor = extractedProps.popoverMinWidthToAnchor;
    commonProps.selectHighlightedOptionOnBlur = true;
    commonProps.clearSuggestionInputOnFocus = true;
    commonProps.dataTqId = extractedProps.dataTqId;

    if (commonProps.valid === false) {
        commonProps.extraClasses += " tq-has-error";
    }

    const dayOptions: IDropdownOptionProps[] = buildDayOptions(isLeap, month);
    const monthsOptions: IDropdownOptionProps[] = createMonths(MONTHS);
    const yearsBack = extractedProps.maxYears;
    const startingYear = extractedProps.startYear ? Number(extractedProps.startYear) : undefined;

    const yearOptions: IDropdownOptionProps[] = createYears(yearsBack, startingYear);

    const daySelection: string = day || "";
    const monthSelection: string = month || "";
    const yearSelection: string = year || "";

    const labelProps = {
        label: extractedProps.label,
        disabled: extractedProps.disabled,
        labelButton: extractedProps.labelButton,
        required: extractedProps.required,
        tooltip: extractedProps.tooltip,
        containerClass: `un-ds-form-wrapper tq-form-element un-ds-form-element ${extractedProps.extraClasses}`,
        valueContainerClass:
            "un-ds-grid-wrapper un-ds-grid-wrapper-1-1-1 un-ds-grid-preserve un-dropdown-container",
        dataTqId: extractedProps.dataTqId
    };
    return (
        <Labeled {...labelProps}>
            <Dropdown
                {...commonProps}
                onChange={onDayChange}
                options={dayOptions}
                value={daySelection}
                placeholder={DATE_PLACEHOLDERS.day}
            />
            <Dropdown
                {...commonProps}
                onChange={onMonthChange}
                options={monthsOptions}
                value={monthSelection}
                placeholder={DATE_PLACEHOLDERS.month}
            />
            <Dropdown
                {...commonProps}
                onChange={onYearChange}
                options={yearOptions}
                value={yearSelection}
                placeholder={DATE_PLACEHOLDERS.year}
            />
        </Labeled>
    );
}

const DATE_PLACEHOLDERS = {
    day: "Jour",
    month: "Mois",
    year: "Année"
};

const createDays = (maxDays: number): IDropdownOptionProps[] => {
    const result: IDropdownOptionProps[] = [];
    for (let i = 1; i <= maxDays; i++) {
        const id = i < 10 ? `0${i}` : `${i}`;
        result.push({ value: id, label: `${i}` });
    }
    return result;
};

const createMonths = (months: string[]): IDropdownOptionProps[] => {
    const result: IDropdownOptionProps[] = [];
    months.forEach((month, idx) => {
        const id = idx + 1 < 10 ? `0${idx + 1}` : `${idx + 1}`;
        result.push({ value: id, label: month });
    });
    return result;
};

const createYears = (
    years: number,
    currentYear: number = createDate().getUTCFullYear()
): IDropdownOptionProps[] => {
    const result: IDropdownOptionProps[] = [];
    for (let i = currentYear; i > currentYear - years; i--) {
        result.push({ value: `${i}`, label: `${i}` });
    }
    return result;
};

const checkNumberOfDays = (month: string): MonthChecks => {
    let isFebruary = false;
    let has31Days = true;
    switch (month) {
        case "04":
        case "06":
        case "09":
        case "11": {
            has31Days = false;
            break;
        }
        case "02": {
            isFebruary = true;
            has31Days = false;
            break;
        }
    }
    return {
        isFebruary,
        has31Days
    };
};

const buildDayOptions = (isLeap: boolean, month: string): IDropdownOptionProps[] => {
    const checks: MonthChecks = checkNumberOfDays(month);
    if (isLeap && checks.isFebruary) {
        return createDays(29);
        // } else if (!isLeap && month === "02") {
    } else if (!isLeap && checks.isFebruary) {
        return createDays(28);
    } else if (checks.has31Days) {
        return createDays(31);
    } else {
        return createDays(30);
    }
};

const isLeapYear = (year) => {
    if (!year) {
        return true;
    }
    // A year is a leap year if it is evenly divisible by 4
    // except for end-of-century years (divisible by 100),
    // unless they are also divisible by 400.
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const extractValue = (componentProps: any) => {
    const { value } = componentProps;
    if (value) {
        const [year, month, day] = value.split("-");
        return {
            year,
            month,
            day
        };
    }
    return {
        year: null,
        month: null,
        day: null
    };
};
