export const allowedFileTypes = (value, types: string[]) => {
    const files = (value && value.files) || [];
    const typesLowered = types.map((type) => type.toLowerCase());
    return files.every((file) =>
        typesLowered.some((extension) => {
            const fileName = file.name.toLowerCase();
            return fileName.endsWith(`.${extension}`);
        })
    );
};
