let zxcvbn: any;

// Preload the zxcvbn module
import("zxcvbn").then((module) => {
    zxcvbn = module.default;
});
export const minPasswordStrength = (value: number, strength: number) => {
    if (value === undefined || value === null) {
        return false;
    }
    if (!zxcvbn) {
        throw new Error("zxcvbn is not loaded yet");
    }
    const passwordStrength = zxcvbn(value);
    return passwordStrength.score >= strength;
};
