import { Entity } from "../../../webui/types";
import { FormSubmitData, SubmitEntity } from "../../types";

export const generateFormData = (entities: Entity[]): FormSubmitData => {
    const result = {
        content: {}
    };
    const files = [];
    entities.forEach((entity) => {
        //by default create record for each source entity
        result.content[entity.id] = {};
        const entityFormData = entityToFormDataEntity(entity, files);
        if (entityFormData !== null) {
            //remove one level of nesting of the form data
            if (entities.length === 1) {
                if (typeof entityFormData === "string") {
                    result.content[entity.id] = entityFormData;
                } else {
                    result.content = entityFormData;
                }
            } else {
                if (typeof entityFormData === "string") {
                    result.content[entity.id] = entityFormData;
                } else {
                    result.content[entity.id] = entityFormData[entity.id];
                }
            }
        }
    });
    if (files.length) {
        result["files"] = files;
    }
    return result;
};

//TODO fix issue with entities that have value/data and have entities with submit data also
export const entityToFormDataEntity = (entity: Entity, files = []): SubmitEntity | null => {
    if (entity.properties?.data !== undefined && entity.properties?.data !== null) {
        return entity.properties.data;
    } else if (entity.properties?.value !== undefined && entity.properties?.value !== null) {
        if (entity.properties.value.files) {
            entity.properties.value.files.forEach((file) => {
                files.push(file);
            });
            return entity.properties.value.files.map((file) => {
                if (file instanceof File) {
                    return {
                        lastModified: file.lastModified,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    };
                } else {
                    return file;
                }
            });
        }
        return entity.properties.value;
    } else if (entity.entities) {
        const innerEntitiesFormData = {};
        const innerEntities = entity.entities;
        innerEntities
            .map((innerEntity) => entityToFormDataEntity(innerEntity, files))
            .forEach((innerFormDataEntity, index) => {
                //TODO
                // temporary fix for errors entity submitting
                if (innerFormDataEntity !== null && innerEntities[index].id !== "errors") {
                    if (
                        innerFormDataEntity[innerEntities[index].id] &&
                        typeof innerFormDataEntity === "object"
                    ) {
                        innerEntitiesFormData[innerEntities[index].id] =
                            innerFormDataEntity[innerEntities[index].id];
                    } else {
                        innerEntitiesFormData[innerEntities[index].id] = innerFormDataEntity;
                    }
                }
            });
        // check if there are inner entities with submit data
        if (Object.keys(innerEntitiesFormData).length) {
            const result = {};
            result[entity.id] = innerEntitiesFormData;
            return result;
        }
    }
    return null;
};
