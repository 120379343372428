"use client";
import React, { useMemo, useRef } from "react";
import { xssFilter } from "../../utils/xssFilter";
import { Entity } from "@uneo/platform-commons/webui/types";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";

type richTextProps = {
    value?: string;
    entity?: Entity;
    tag?: string;
    extraClasses?: string;
    onClick?: () => void; //used for tracking
};
export default function RichText(props: richTextProps) {
    const content = useRef<HTMLElement>(null);
    const { tag = "div", extraClasses = "", entity }: richTextProps = props;
    let { value = "" } = props;
    if (!value) {
        value = entity?.properties?.value || "";
    }
    const innerHTML = useMemo(() => {
        return xssFilter.process(value);
    }, [value]);

    const { triggerEntityActions } = useWidgetContext();

    if (!value && !entity) {
        return <></>;
    }

    const ClickFn = (event) => {
        if (props.onClick) {
            props.onClick();
        }
        if (entity?.actions?.length) {
            // if the entity have actions - prevent default behavior (redirection to href)
            event.preventDefault();
            const target = event.target;
            if (target.tagName === "A") {
                const currentTag = content && content.current;
                const anchors = currentTag && currentTag.getElementsByTagName("a");
                // if there are multiple anchors
                if (anchors && anchors.length > 1) {
                    // find the index of the clicked anchor and trigger the action with the same index if exist
                    const clickedAnchorIndex = Array.from(anchors).findIndex(
                        (anchor) => anchor === target
                    );
                    triggerEntityActions({
                        ...entity,
                        actions: [entity.actions[clickedAnchorIndex]].filter(Boolean)
                    });
                } else {
                    //trigger all actions
                    triggerEntityActions(entity);
                }
            }
        }
    };

    return React.createElement(tag, {
        className: extraClasses,
        dangerouslySetInnerHTML: {
            __html: innerHTML
        },
        ref: content,
        onClick: ClickFn
    });
}
