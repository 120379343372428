"use client";
import { Provider } from "react-redux";
import { createStore } from "../store";
import React, { useEffect } from "react";
import { fromEntityToStoreEntityTree, fromSirenActionToAction } from "../../adapters";
import { SirenAction, Widget } from "../../types";
import { triggerActions } from "../../actions/actions";

export default function WebuiStoreProvider({
    initialWidgets,
    actionResponses,
    children
}: {
    children: React.ReactNode;
    actionResponses: Record<string, SirenAction[]>;
    initialWidgets?: Record<string, Widget>;
}) {
    const initialStoreWidgets = {};
    Object.keys(initialWidgets).forEach((widgetId) => {
        initialStoreWidgets[widgetId] = {
            id: initialWidgets[widgetId].id,
            parentId: initialWidgets[widgetId].parentId,
            url: initialWidgets[widgetId].url
        };
        if (initialWidgets[widgetId].data) {
            initialStoreWidgets[widgetId].data = fromEntityToStoreEntityTree(
                initialWidgets[widgetId].data
            );
        }
    });
    return (
        <Provider store={createStore({ widgets: { widgets: initialStoreWidgets } })}>
            <ActionResponseTrigger actionResponses={actionResponses} />
            {children}
        </Provider>
    );
}
//Component for triggering actions which have been returned on widget requests, while server rendering
const ActionResponseTrigger = ({
    actionResponses
}: {
    actionResponses: Record<string, SirenAction[]>;
}) => {
    useEffect(() => {
        Object.keys(actionResponses).forEach((widgetId) => {
            try {
                triggerActions(actionResponses[widgetId].map(fromSirenActionToAction), {
                    widgetId
                });
            } catch (error) {
                console.log("Errors in action responses from Server Rendering.");
                console.error(error);
            }
        });
    }, [actionResponses]);
    return <></>;
};
