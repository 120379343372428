export const tabulateArea = (modalContent, tabulateBy, closeButton?) => {
    const focusableItems = modalContent.current
        ? modalContent.current?.querySelectorAll(
              `a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]), [role="button"]`
          )
        : [];
    const trulyFocusableItems = Array.from(focusableItems).filter(
        (item: HTMLElement) =>
            (!item.tabIndex || item.tabIndex != -1) && !item.hasAttribute("disabled")
    );
    let allFocusableItems = [];
    if (trulyFocusableItems.length) {
        if (closeButton) {
            allFocusableItems = [closeButton.current, ...trulyFocusableItems];
        } else {
            allFocusableItems = trulyFocusableItems;
        }
    } else {
        allFocusableItems = [closeButton.current];
    }
    let activeItemIndex = -1;
    allFocusableItems?.every((item, index) => {
        if (item === document.activeElement) {
            activeItemIndex = index + tabulateBy;
            if (activeItemIndex >= allFocusableItems.length) {
                activeItemIndex = 0;
            }
            if (activeItemIndex < 0) {
                activeItemIndex = allFocusableItems.length - 1;
            }

            return false;
        }
        return true;
    });
    if (activeItemIndex >= 0) {
        (allFocusableItems[activeItemIndex] as HTMLElement)?.focus();
    } else {
        (allFocusableItems[0] as HTMLElement)?.focus();
    }
};
