import React from "react";
import { Entity } from "../../../webui/types";
import RichText from "../richText/RichText";
//Message box with different styles ("info" | "warning" | "error" | "success")
//can be passes message or children for more complex scenarios
export default function Message({
    messageEntity,
    multipleMessagesEntity,
    type = "info",
    extraClasses = "un-mt-3",
    onEmbeddedClick,
    children
}: {
    messageEntity?: Entity;
    multipleMessagesEntity?: Entity;
    type: "info" | "warning" | "error" | "success";
    extraClasses?: string;
    onEmbeddedClick?: (e: any) => void;
    children?: React.ReactNode | React.ReactNode[];
}) {
    let content;
    if (messageEntity) {
        content = <RichText entity={messageEntity} />;
    }
    if (multipleMessagesEntity) {
        content = (
            <>
                {multipleMessagesEntity.entities?.map((item) => (
                    <RichText entity={item} key={item.id} />
                ))}
            </>
        );
    }
    if (children) {
        content = children;
    }
    return (
        <div
            onClick={onEmbeddedClick}
            className={`un-ds-message-wrapper-mini ${extraClasses}`}
            role={"alert"}
        >
            <div className={`tq-alert tq-flex un-ds-message-mini-${type}`}>
                <div className="un-message-content">
                    <div className="tq-alert-icon" />
                    <div className="un-text-message">
                        <div className="tq-section tq-text-center tq-text-color un-ds-text-1">
                            <div className="tq-text-color un-font-size-preserve un-content-expand">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
