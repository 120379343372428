export default function getRoundedBoundingClientRect(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    for (let key in rect) {
        if (Object.prototype.hasOwnProperty.call(rect, key)) {
            rect[key] = Math.ceil(rect[key]);
        }
    }

    return rect;
}
