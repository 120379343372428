const AFFILIATION_SOLO_LABEL = "Militaire Solo";
const AFFILIATION_FAMILY_LABEL = "Militaire avec des ayants droits";
const MODIFY_PERSONAL_INFO_LABEL = "Profil - Modifier les informations personnelles";
const UPDATE_IBAN_LABEL = "Déclarer un nouveau RIB";
const CHANGE_USERNAME_LABEL = "Modifier Mon Login";
const CHANGE_PASSWORD_LABEL = "Changer mon mot de passe";
const MFA_SMS_ACTIVATION_LABEL = "Je confirme mon identité";
const MFA_EMAIL_ACTIVATION_LABEL = "Je confirme mon identité";

const processCases = {
    MFA_SELECTION: {
        MODIFY_HOME_PHONE: "",
        MODIFY_MOBILE_PHONE: "Profil - Modifier les informations personnelles",
        MODIFY_ADDRESS: "Profil - Modifier les informations personnelles",
        MODIFY_EMAIL: "Profil - Modifier les informations personnelles",
        UPDATE_IBAN: "Déclarer un nouveau RIB",
        CHANGE_USERNAME: "Modifier Mon Login",
        CHANGE_PASSWORD: "Changer mon mot de passe",
        MFA_ACTIVATION: ""
    },
    MFA_SMS_AUTHENTICATION: {
        MODIFY_HOME_PHONE: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_MOBILE_PHONE: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_ADDRESS: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_EMAIL: MODIFY_PERSONAL_INFO_LABEL,
        UPDATE_IBAN: UPDATE_IBAN_LABEL,
        CHANGE_USERNAME: CHANGE_USERNAME_LABEL,
        CHANGE_PASSWORD: CHANGE_PASSWORD_LABEL,
        MFA_SMS_ACTIVATION: MFA_SMS_ACTIVATION_LABEL
    },
    MFA_EMAIL_AUTHENTICATION: {
        MODIFY_HOME_PHONE: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_MOBILE_PHONE: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_ADDRESS: MODIFY_PERSONAL_INFO_LABEL,
        MODIFY_EMAIL: MODIFY_PERSONAL_INFO_LABEL,
        UPDATE_IBAN: UPDATE_IBAN_LABEL,
        CHANGE_USERNAME: CHANGE_USERNAME_LABEL,
        CHANGE_PASSWORD: CHANGE_PASSWORD_LABEL,
        MFA_EMAIL_ACTIVATION: MFA_EMAIL_ACTIVATION_LABEL
    },
    AFFILIATION_NEW_CALEDONIA: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_INTERNATIONAL: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_REFERENCE: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_ENGAGEMENT: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_ADD_BENEFICIARIES: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_ADJUST_COVERAGES: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_FINALIZATION: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_CANCEL_CONCURRENT_CONTRACT: {},
    AFFILIATION_ADJUSTMENT: {
        SOLO: AFFILIATION_SOLO_LABEL,
        FAMILY: AFFILIATION_FAMILY_LABEL
    },
    AFFILIATION_COMPLETE: {}
};

export const computeProcessCategory = (category, processId, businessCase) => {
    if (
        processId &&
        businessCase &&
        processCases[processId] &&
        processCases[processId][businessCase]
    ) {
        if (
            processId === "MFA_SMS_AUTHENTICATION" ||
            processId === "MFA_EMAIL_AUTHENTICATION" ||
            processId === "MFA_SELECTION"
        ) {
            return processCases[processId][businessCase];
        }
        return `${category}/ ${processCases[processId][businessCase]}`;
    }
    return category;
};
