import { EvaluationResult, FunctionElement } from "../types";

export const basicToStringMethod = (name: string, args: FunctionElement[]) => (): string =>
    `["${name}", ${args.map((item) => item.toString()).join(", ")}]`;

export const checkPrimitiveType = (args: EvaluationResult[], type: string): void => {
    const unknownItemIndex: number = args.findIndex((item) => typeof item !== type);
    //using indexes since values "undefined" can not be found correctly with Array.prototype.find
    if (unknownItemIndex !== -1) {
        const unknownItem: EvaluationResult = args[unknownItemIndex];
        throw new Error(`${printPrimitiveValue(unknownItem)} is not a ${type}.`);
    }
};

export const checkExactNumberOfParameters = (args: EvaluationResult[], number: number): void => {
    if (args === null || args === undefined) {
        args = [];
    }
    if (number < 0) {
        throw new Error(
            `Number of parameters is expected to be a positive number, provided ${number}.`
        );
    }
    if (args.length !== number) {
        throw new Error(`Expected number of parameters: ${number}, provided ${args.length}.`);
    }
};

export const checkMinNumberOfParameters = (
    args: FunctionElement[] | EvaluationResult[] = [],
    number = 1
): void => {
    if (args === null) {
        args = [];
    }
    if (number < 0) {
        throw new Error(
            `Number of parameters  is expected to be a positive number, provided ${number}.`
        );
    }
    if (args.length < number) {
        throw new Error(
            `Expected number of parameters at least: ${number}, provided ${args.length}.`
        );
    }
};

export const printPrimitiveValue = (value: EvaluationResult): string => {
    const type = typeof value;
    if (type === "number" || type === "boolean") {
        return "" + value;
    }
    if (type === "string") {
        return `"${value}"`;
    }
    if (value === null) {
        return "null";
    }
    if (value === undefined) {
        return "undefined";
    }
    if (type === "object") {
        return JSON.stringify(value);
    }
};

export const isInvalid = (value: EvaluationResult): boolean =>
    typeof value === "undefined" || value === null || Number.isNaN(value);
