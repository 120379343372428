import { Action, Entity } from "./types";
//TODO add Empty Entity
//TODO rename to getEntity
export const findEntityById = (entity: Entity, searchId: string): Entity | false | any => {
    if (!entity || !searchId) {
        return false;
    }
    return findEntityHelper(entity, searchId.split("."), 0);
};

/**
 * Recursively searches for paths in an entity tree where the path ends with the specified key.
 *
 * @param entity - The root entity to start the search.
 * @param searchId - The substring to search for at the end of paths.
 * @returns An array of paths ending with the specified key.
 */
export const findAllEntityPathsById = (entity: Entity, searchId: string): Array<string> => {
    const resultPaths: Array<string> = [];
    const searchStore = (storeEntity: Entity) => {
        if (storeEntity.contextPath) {
            const parts = storeEntity.contextPath.split(".");
            const lastPart = parts[parts.length - 1];
            if (lastPart === searchId) {
                resultPaths.push(storeEntity.contextPath);
            }
        }
        if (storeEntity.entities) {
            storeEntity.entities.forEach((innerEntity) => {
                searchStore(innerEntity);
            });
        }
    };
    searchStore(entity);
    return resultPaths;
};

const findEntityHelper = (
    entity: Entity,
    searchPath: string[],
    searchIndex: number
): Entity | false | any => {
    let newSearchIndex = 0;
    if (entity.id === searchPath[searchIndex]) {
        if (searchIndex === searchPath.length - 1) {
            return entity;
        }
        newSearchIndex = searchIndex + 1;
    }
    return (
        entity.entities?.reduce((found, innerEntity) => {
            if (found) {
                return found;
            }
            return findEntityHelper(innerEntity, searchPath, newSearchIndex);
        }, false) || false
    );
};

export const getEntityProps = (entity: Entity, searchId?: string, propId?: string) => {
    const foundEntity = searchId ? findEntityById(entity, searchId) : entity;
    if (foundEntity) {
        if (propId && foundEntity.properties) {
            return foundEntity.properties[propId];
        }
        if (propId && !foundEntity.properties) {
            return false;
        }
        return foundEntity.properties;
    }
    return false;
};

export const getEntityValue = (entity: Entity, searchId?: string): any => {
    const foundEntity = searchId ? findEntityById(entity, searchId) : entity;
    if (foundEntity && foundEntity.properties) {
        return foundEntity.properties.value;
    }
    return false;
};

export const getEntityActions = (entity: Entity, searchId?: string): Action[] => {
    const foundEntity = searchId ? findEntityById(entity, searchId) : entity;
    if (foundEntity) {
        return foundEntity.actions || [];
    }
    return [];
};

export const getEntityChildren = (entity: Entity, searchId?: string): Entity[] => {
    const foundEntity = searchId ? findEntityById(entity, searchId) : entity;
    if (foundEntity) {
        return foundEntity.entities || [];
    }
    return [];
};
