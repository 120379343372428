"use client";
import React, { useCallback, useState } from "react";
import FileUpload, { IFileUploadPropsKeys } from "./FileUpload";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";
import "@tinqin/design-uneo-pf/css/upload-area.css";
import { ValidationErrors } from "../../../validations/interfaces";

export default function FileUploadContainer(props) {
    const [failedValidations, setFailedValidations] = useState<ValidationErrors>([]);
    const onChange = useCallback(
        (state): void => {
            if (props.onChange) {
                props.onChange(state);
            }
            if (props.onChangeValidationMethod) {
                setFailedValidations(props.onChangeValidationMethod(state));
            }
        },
        [props]
    );

    const onFileRemoved = useCallback(
        (state): void => {
            if (props.onFileRemoved) {
                props.onFileRemoved(state);
            }
            if (props.onChangeValidationMethod) {
                setFailedValidations(props.onChangeValidationMethod(state));
            }
        },
        [props]
    );

    const onExceedMaxUploads = useCallback((): void => {
        if (props.onExceedMaxUploads) {
            props.onExceedMaxUploads();
        }
    }, [props]);

    const onBlur = useCallback((): void => {
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props]);

    const onFocus = useCallback((): void => {
        if (props.onFocus) {
            props.onFocus();
        }
    }, [props]);

    const onOpen = useCallback((): void => {
        if (props.onOpen) {
            props.onOpen();
        }
    }, [props]);

    const onClose = useCallback((): void => {
        if (props.onClose) {
            props.onClose();
        }
    }, [props]);

    const fileUploadProps: any = extractComponentProps(props, {
        componentProps: IFileUploadPropsKeys,
        propsDefaults: {
            placeholder: "",
            title: "",
            singleFile: true
        }
    });

    fileUploadProps.onChange = onChange;
    fileUploadProps.onExceedMaxUploads = onExceedMaxUploads;
    fileUploadProps.onBlur = onBlur;
    fileUploadProps.onFocus = onFocus;
    fileUploadProps.onOpen = onOpen;
    fileUploadProps.onClose = onClose;
    fileUploadProps.onFileRemoved = onFileRemoved;

    if (props.value) {
        fileUploadProps.files = props.value.files;
    } else {
        fileUploadProps.files = [];
    }

    if (props.featureSettings) {
        fileUploadProps.maxFiles = props.featureSettings["max-files-number"];
        fileUploadProps.singleFile = props.featureSettings["max-files-number"] === 1;
        fileUploadProps.accept = props.featureSettings["allowed-file-types"]
            ?.map((i) => "." + i)
            .join(",");
    }
    fileUploadProps.failedValidations = failedValidations.map((rule) => rule.message);

    return <FileUpload {...fileUploadProps} />;
}

FileUploadContainer.displayName = "FileUploadContainer"; //minification concern
