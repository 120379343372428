"use client";
import React, { useCallback, useEffect, useState } from "react";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

const LOADING_ANIMATION_DELAY = 0;

type LoaderLinkType = {
    label?: string;
    extraClasses?: string;
    children?: React.ReactNode | React.ReactNode[];
    href?: string;
    onClick?: (event) => void;
};

// Link with delayed loading animation
// If actions triggered by the link finish before LOADING_ANIMATION_DELAY, loading animation shouldn't be shown

export default function LoaderLink(props: LoaderLinkType) {
    const label = props.label;

    const [waiting, setWaiting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        startLoading(waiting);
        stopLoading(waiting);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waiting]);

    // loading animation should be delayed X milliseconds
    // debounce prevents multiple click triggering
    const startLoading = useCallback(
        debounce((wait) => {
            wait && setLoading(true);
        }, LOADING_ANIMATION_DELAY),
        []
    );

    // loading animation is interrupted when waiting is over
    const stopLoading = useCallback((wait) => {
        !wait && setLoading(false);
    }, []);

    const onClick = async (event) => {
        event.preventDefault();
        setWaiting(true);
        props.onClick && (await props.onClick(event));
        setTimeout(() => {
            setWaiting(false);
        }, 1000);
    };

    const className = ["un-link-animated", "tq-hyperlink", "un-ds-hyperlink"];

    loading && className.push("tq-loading");

    return (
        <a
            href={props.href || ""}
            label={label}
            {...props}
            className={`${className.join(" ")} ${props.extraClasses || ""}`}
            onClick={onClick}
        >
            {props.label && props.label}
            {props.children}
        </a>
    );
}
