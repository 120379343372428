// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
type CallbackFunction = (...args: any[]) => any;

export default function debounce(
    func: CallbackFunction,
    wait: number,
    immediate: boolean = false
): CallbackFunction {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return function (this: any, ...args: any[]) {
        const later = () => {
            timeoutId = null;
            if (!immediate) {
                func.apply(this, args);
            }
        };

        const callNow = immediate && timeoutId === null;

        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(later, wait);

        if (callNow) {
            func.apply(this, args);
        }
    };
}
