import { Widget } from "../../webui/types";
import { useFetchWidget } from "./index";

export default function useFetchWidgetData(inputInfo: Widget) {
    const { isLoading, error, info } = useFetchWidget(inputInfo);
    return {
        isLoading: isLoading || !!info?.loadingState,
        error,
        data: info ? info.data : null
    };
}
