"use client";
import { SirenEntity } from "@uneo/platform-commons/webui/types";
import { useEffect } from "react";
//components used for logging custom request logs in the browser on initial render
export default function RequestLogger({ requestLog }: { requestLog: Record<string, SirenEntity> }) {
    useEffect(() => {
        console.log("Request log for initial rendering on the server: ", requestLog);
    }, [requestLog]);
    return <></>;
}
