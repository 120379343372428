import React from "react";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import { INBOX_ID, LOGOUT_ID, NOTIFICATIONS_ID } from "./ProfileMenu";
import MenuItem from "../portal-header/MenuItem";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import { useTranslation } from "react-i18next";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { Entity } from "@uneo/platform-commons/webui/types";

export default function ProfileMenuMobile({ onNavigation }) {
    const { triggerEntityActions } = useWidgetContext();
    const { t } = useTranslation();
    const { data } = useWidgetDataSelector("profile-menu");
    if (!data) {
        return <></>;
    }
    const links = findEntityById(data, "links");
    if (!links?.entities) {
        return <></>;
    }
    const logout = findEntityById(data, LOGOUT_ID);
    //render all links except special links, like logout, inbox and notifications
    const linkItems = links.entities
        .filter(
            (link: Entity) =>
                link.id !== "title" &&
                link.id !== LOGOUT_ID &&
                link.id !== NOTIFICATIONS_ID &&
                link.id !== INBOX_ID
        )
        .map((link: Entity) => (
            <MenuItem
                link={link}
                key={link.id}
                onNavigation={onNavigation}
                matomoContext="Header"
            ></MenuItem>
        ));
    return (
        <>
            <p className="tq-paragraph un-ds-heading-2">{t(`profileMenu.myProfile`)}</p>
            <div className="un-scrollable-area">
                <ul className="tq-list tq-borders tq-selectable un-secondary-menu">{linkItems}</ul>
                {logout && (
                    <button
                        className="tq-hyperlink un-ds-hyperlink un-disconnect-link tq-text-left tq-main-color"
                        onClick={() => {
                            triggerEntityActions(logout);
                            triggerMatomoEvent("Header", "[Header] - Déconnexion", "Cliquer");
                            if (onNavigation) {
                                onNavigation();
                            }
                        }}
                    >
                        <span className="tq-hyperlink-z-index">{logout.properties?.value}</span>
                    </button>
                )}
            </div>
        </>
    );
}
