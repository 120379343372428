"use client";
import React, { useState, useEffect } from "react";
import InputContainer from "./InputContainer";
import "@tinqin/design-uneo-pf/css/page-sso.css";
const BAR_LENGTH = 3;
const STATUSES = ["", "Faible", "Faible", "Moyen", "Fort"];
const BAR_CLASSES = ["", " un-pass-weak", " un-pass-weak", " un-pass-medium", " un-pass-good"];

export default function InputPasswordContainer(props) {
    const [score, setScore] = useState(-1);
    const zxcvbnPromise = import("zxcvbn");

    useEffect(() => {
        if (props.onCheckPasswordStrength && props.minPasswordStrength !== undefined) {
            props.onCheckPasswordStrength(score >= props.minPasswordStrength);
        }
    }, [score, props]);

    const onChange = async (state): Promise<void> => {
        const { default: zxcvbn } = await zxcvbnPromise;
        if (state.value) {
            const passwordStrength = zxcvbn(state.value);
            setScore(passwordStrength.score);
        } else {
            setScore(-1);
        }
        if (props.onChange) {
            props.onChange(state);
        }
    };
    return (
        <>
            <InputContainer {...props} onChange={onChange} password />
            {props.onCheckPasswordStrength && passwordStrengthBar(score)}
        </>
    );
}
InputPasswordContainer.displayName = "UneoInputPassword";

const passwordStrengthBar = (score: number) => {
    const items = Array.from({ length: BAR_LENGTH }, (_, index) => (
        <div key={index} className="un-pass-line"></div>
    ));
    const index = score < 0 ? 0 : score;
    return (
        <div className="un-password-strength">
            <div className={`un-pass-lines${BAR_CLASSES[index]}`}>{items}</div>
            <div>
                <span className="tq-text-node">{STATUSES[index]}</span>
            </div>
        </div>
    );
};
