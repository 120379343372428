//comparing paths, returns true of one path is an ending subpath of another
const matchingPaths = (entityPath: string, otherPath: string): boolean => {
    if (entityPath === otherPath) {
        return true;
    }
    if (entityPath.length > otherPath.length) {
        return entityPath.endsWith(`.${otherPath}`);
    } else {
        return otherPath.endsWith(`.${entityPath}`);
    }
};
export default matchingPaths;
