"use client";
import React, { useCallback, useEffect, useState } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import { getEntityValue } from "@uneo/platform-commons/webui/search";
import { matomoActions } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { usePageMetaContext } from "@uneo/platform-portal/widgets/content-root/PageMetaContext";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";
import Button, { ButtonType } from "./Button";
import { useWidgetContext } from "../../../webui/widget/WidgetContext";

const LOADING_ANIMATION_DELAY = 500;

type TrackedButtonType = {
    eventName?: string;
    eventAction?: matomoActions;
    eventCustomAction?: string;
    eventCategory?: string;
};

type LoaderButtonType =
    | {
          onClick: (event) => void;
          entity?: Entity | undefined | false;
          onSubmit?: undefined;
          fallbackLabel?: string;
          ariaExpanded?: string;
      }
    | {
          onSubmit?: (Entity) => void;
          entity: Entity | false;
          onClick?: undefined;
          fallbackLabel?: string;
          ariaExpanded?: string;
      };

// Button with delayed loading animation
// If actions triggered by the button finish before LOADING_ANIMATION_DELAY, loading animation shouldn't be shown

export default function LoaderButton(props: ButtonType & TrackedButtonType & LoaderButtonType) {
    const { triggerPageEvent } = usePageMetaContext();
    const { triggerEntityActions } = useWidgetContext();
    const label =
        props.label ||
        (props.entity && getEntityValue(props.entity, props.entity.id)) ||
        props.fallbackLabel;

    const { ariaLabel } = props;

    const [waiting, setWaiting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        startLoading(waiting);
        stopLoading(waiting);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waiting]);

    // loading animation should be delayed X milliseconds
    // debounce prevents multiple click triggering
    const startLoading = useCallback(
        debounce((wait) => {
            wait && setLoading(true);
        }, LOADING_ANIMATION_DELAY),
        []
    );

    // loading animation is interrupted when waiting is over
    const stopLoading = useCallback((wait) => {
        !wait && setLoading(false);
    }, []);

    if (props.entity === false) {
        return <></>;
    }

    const onClick = async (event) => {
        event.preventDefault();
        setWaiting(true);

        if (props.onClick) {
            await props.onClick(event);
            setWaiting(false);
            return;
        }

        if (props.onSubmit) {
            await props.onSubmit(props.entity);
        } else {
            await triggerEntityActions(props.entity);
        }

        const pageEvent = { customAction: props.eventCustomAction || label };
        if (props.eventName) {
            pageEvent["name"] = props.eventName;
        }
        if (props.eventAction) {
            pageEvent["action"] = props.eventAction;
        }
        if (props.eventCategory) {
            pageEvent["category"] = props.eventCategory;
        }
        triggerPageEvent(pageEvent);

        setWaiting(false);
    };
    return (
        <Button
            label={label}
            ariaLabel={ariaLabel}
            data-tq-id={`${props.entity?.contextPath || label}`}
            {...props}
            extraClasses={`${props.disabled && !waiting && !loading ? "tq-disabled" : ""} ${
                props.extraClasses || ""
            }`}
            loader={loading}
            disabled={waiting ? waiting : props.disabled}
            onClick={onClick}
        />
    );
}
