import { triggerExplicitValidations } from "@uneo/platform-commons/platform/validations/ValidateEntity";
import { createRule } from "@uneo/platform-commons/platform/validations/rules";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import {
    getEntity,
    traverseEntityTree,
    updateEntityProps
} from "@uneo/platform-commons/webui/store/actions";

export const checkRequiredFields = (widgetId, path) => {
    const failedRequiredValidations = [];
    traverseEntityTree({
        widgetId: widgetId,
        entityPath: path,
        fn: (entity) => {
            const splitPath = entity.contextPath ? entity.contextPath.split(".") : [entity.id];
            const questionsIndex = splitPath.lastIndexOf("questions");
            if (questionsIndex > -1) {
                const questionPath = splitPath.slice(0, questionsIndex + 2).join(".");
                const question = getEntity({ widgetId, entityPath: questionPath });
                let shouldValidate = true;
                if (question) {
                    shouldValidate = question.properties?.validate !== false;
                }
                const value = entity.properties?.value;
                if (entity.properties?.required && shouldValidate) {
                    failedRequiredValidations.push(
                        ...triggerExplicitValidations(value, [createRule({ type: "required" })])
                    );
                }
            }
        }
    });
    return failedRequiredValidations;
};

export const manageButtonState = (widgetId) => {
    const rootEntity = getEntity({ widgetId, entityPath: widgetId });
    if (rootEntity) {
        const stepsEntity = findEntityById(rootEntity, "flow.steps");
        if (stepsEntity.properties?.currentStep) {
            const requiredValidationsResult = checkRequiredFields(
                widgetId,
                `${stepsEntity.contextPath}.${stepsEntity.properties?.currentStep}`
            );
            const submit = findEntityById(rootEntity, "submit");
            if (submit) {
                const currentDisableState = !!submit.properties?.disabled;
                const newDisabledState = !!requiredValidationsResult.length;
                if (currentDisableState !== newDisabledState) {
                    updateEntityProps({
                        widgetId,
                        entityPath: submit.contextPath,
                        props: { disabled: newDisabledState }
                    });
                }
            }
        }
    }
};
