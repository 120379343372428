import React, { Fragment, HTMLAttributes, FC } from "react";
import { Entity } from "../../../webui/types";
import { getEntityProps } from "../../../webui/search";

interface IRemountContainerProps extends HTMLAttributes<HTMLOrSVGElement> {
    data: Entity;
    entityPathToTrack: string;
}

/**
 * Container that tracks for given data changes and remounts its children in the DOM
 * @param data - data to get entity key from
 * @param entityPathToTrack - entity path to track key as unique identifier for remount
 * @param children - children components to be remounted into the container
 * @returns Children with updated key to trigger remount of the component tree
 */
const RemountContainer: FC<IRemountContainerProps> = ({ data, entityPathToTrack, children }) => {
    const key = getEntityProps(data, entityPathToTrack, "key") || "unknownKey";
    return <Fragment key={key}>{children}</Fragment>;
};
export default RemountContainer;
