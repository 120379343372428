import React from "react";
import CheckboxGroup, { ICheckboxGroupPropsKeys, ICheckboxGroupProps } from "./CheckboxGroup";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";

export default function CheckboxGroupContainer(props) {
    const checkBoxGroupProps: ICheckboxGroupProps = extractComponentProps(props, {
        componentProps: ICheckboxGroupPropsKeys,
        propsDefaults: {
            value: ""
        }
    });
    return <CheckboxGroup {...checkBoxGroupProps} />;
}
