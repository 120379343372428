"use client";
import React from "react";

export type ButtonType = {
    label?: string;
    onClick?: (event) => void;
    extraClasses?: string;
    disabled?: boolean;
    icon?: string;
    leftBadge?: string | number;
    loader?: boolean;
    success?: boolean;
    ariaLabel?: string;
    ariaExpanded?: string;
    "data-tq-id"?: string;
};

export default function Button({
    label,
    onClick,
    extraClasses,
    disabled,
    icon,
    leftBadge,
    loader,
    success,
    ariaLabel,
    ariaExpanded,
    "data-tq-id": dataTqId
}: ButtonType) {
    const className = ["tq-button", "un-ds-button"];

    loader && className.push("un-ds-loading");
    success && className.push("un-ds-success");
    icon && label && className.push("un-ds-button-with-icon");
    icon && !label && className.push("un-ds-button-only-icon");
    icon && !label && success && className.push("un-ds-button-only-icon-feedback");

    if (loader === undefined) {
        disabled && className.push("tq-disabled");
    }
    const additionalProps: Record<string, any> = {};
    if (ariaExpanded) {
        additionalProps["aria-expanded"] = ariaExpanded;
    }
    return (
        <button
            aria-label={ariaLabel}
            className={`${className.join(" ")} ${extraClasses}`}
            disabled={disabled}
            {...additionalProps}
            onClick={!disabled ? onClick : undefined}
            data-tq-id={dataTqId ?? undefined}
        >
            {leftBadge?.toString() && <span className="un-ds-button-badge">{leftBadge}</span>}
            {icon && <i className={icon} />}
            {label && <span className="tq-text-node">{label}</span>}
            {loader && <span className="tq-loader"></span>}
            {success && <span className="tq-feedback"></span>}
        </button>
    );
}
