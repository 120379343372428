"use client";
import React, { useEffect, useState } from "react";

export function debounce(fn, ms) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, args);
        }, ms);
    };
}

interface ResponsiveOption {
    maxWidth?: number;
    component: JSX.Element;
}

export default function ResponsiveContainer({ options }: { options: ResponsiveOption[] }) {
    //Resize Logic
    // set SSR screen width to 10000 (in order to visualize the desktop version)
    const [screenWidth, setScreenWidth] = useState(10000);
    const debouncedHandleResize = debounce(function handleResize() {
        setScreenWidth(window.innerWidth);
    }, 100);
    useEffect(() => {
        setScreenWidth(window.innerWidth);
        window?.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    if (!options) {
        return <></>;
    }
    const defaultOption = options.find((option) => !option.maxWidth);

    //Responsive Logic
    const responsiveOptions = options
        .filter((option) => !!option.maxWidth && option.maxWidth > screenWidth)
        .sort((a, b) => a.maxWidth! - b.maxWidth!);
    if (responsiveOptions.length === 0) {
        return defaultOption?.component || <></>;
    }

    return responsiveOptions[0].component;
}
