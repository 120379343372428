"use client";
import React from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import MfaModal from "../MfaModal/MfaModal";
import MfaBlockModal from "../MfaBlockModal/MfaBlockModal";
import SessionModal from "../SessionModal/SessionModal";

export default function Modals({ data }: { data: Entity }) {
    const modalsEntity: Entity = findEntityById(data, "modals");
    return (
        <>
            <MfaModal data={modalsEntity} />
            <MfaBlockModal data={modalsEntity} />
            <SessionModal data={modalsEntity} />
        </>
    );
}
