"use client";
import "@tinqin/design-uneo-pf/css/cookies.css";
import { useEffect, useState } from "react";
import { useFetchFeature } from "@uneo/platform-commons/platform/features";
import { load } from "./tarteaucitron";
export default function TarteaucitronLoaderComponent() {
    const [loaded, setLoaded] = useState(false);
    const { enabled, settings } = useFetchFeature("tarteaucitron");

    useEffect(() => {
        const isWebCrawler = window.navigator.userAgent.includes("Prerender");
        if (!loaded && enabled && !isWebCrawler) {
            load(settings);
            setLoaded(true);
        }
    }, [enabled, settings]);

    return <></>;
}
