"use client";
import { useEffect } from "react";
import { triggerActions } from "../../../webui/actions/actions";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";
//adding debounce to allow quickly moving multiple times through history
const handler = debounce(() => {
    triggerActions([
        {
            type: "switchContainer",
            url: "/webui/portal",
            //on browser actions, history state is already changed
            parameters: { address: window.location.pathname + window.location.search }
        }
    ]);
}, 200);
// trigger switchContainer on browser buttons
export default function NavigationHandler() {
    useEffect(() => {
        addEventListener("popstate", handler);
        return () => {
            removeEventListener("popstate", handler);
        };
    }, []);
    return null;
}
