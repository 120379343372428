import React, { useState } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import { Hyperlink } from "@uneo/platform-commons/platform/components";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";

export default function MenuItem({
    link,
    onNavigation,
    matomoContext = "MenuSidebar"
}: {
    link: Entity;
    onNavigation?;
    matomoContext?: string;
}) {
    const active = link.properties?.active;
    if (!link.entities) {
        return (
            <li className={active ? " tq-current" : ""}>
                <Hyperlink
                    entity={link}
                    extraClasses="tq-hyperlink un-ds-hyperlink"
                    onClick={() => {
                        triggerMatomoEvent(
                            matomoContext,
                            `[${matomoContext}] - ${link.properties?.value}`,
                            "Cliquer"
                        );
                        if (onNavigation) {
                            onNavigation();
                        }
                    }}
                >
                    <span className="tq-text-node">{link.properties?.value}</span>
                </Hyperlink>
            </li>
        );
    } else {
        return (
            <ExpandableMenuItem
                link={link}
                //using key to reset component initial state when "active" flag is changed
                key={link.id + active}
                active={active}
                expandedInitial={active}
                onNavigation={onNavigation}
                matomoContext={matomoContext}
            />
        );
    }
}

function ExpandableMenuItem({
    link,
    active,
    expandedInitial,
    onNavigation,
    matomoContext = "Sous menuSidebar"
}: {
    link: Entity;
    active: boolean;
    expandedInitial: boolean;
    onNavigation?: () => void;
    matomoContext?: string;
}) {
    const [expanded, setExpanded] = useState(expandedInitial);
    const className =
        "un-menu-expandable" +
        (active ? " tq-current" : "") +
        (expanded ? " un-menu-expanded" : "");
    return (
        <li className={className} aria-expanded={`${expanded}`}>
            <div
                className="un-expandable-section"
                role="button"
                onClick={() => {
                    setExpanded(!expanded);
                    triggerMatomoEvent(
                        matomoContext,
                        `[${matomoContext}] - ${link.properties?.value}`,
                        `Cliquer`
                    );
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        setExpanded(!expanded);
                    }
                }}
                tabIndex={0}
            >
                <span className="tq-text-node">{link.properties?.value}</span>
                <div className="tq-right-menu-items">
                    <i className="tq-icon-arrow-down-big" />
                </div>
            </div>
            <ul className="un-expanded-area">
                {link.entities?.map((innerLink) => (
                    <li key={innerLink.id}>
                        <Hyperlink
                            entity={innerLink}
                            extraClasses={
                                "tq-hyperlink un-ds-hyperlink" +
                                (innerLink.properties?.active ? " tq-current" : "")
                            }
                            onClick={() => {
                                triggerMatomoEvent(
                                    matomoContext,
                                    `[${matomoContext} - ${link.properties?.value}] - ${innerLink.properties?.value}`,
                                    "Cliquer"
                                );
                                if (onNavigation) {
                                    onNavigation();
                                }
                            }}
                        >
                            <span className="tq-hyperlink-z-index">
                                {innerLink.properties?.value}
                            </span>
                        </Hyperlink>
                    </li>
                ))}
            </ul>
        </li>
    );
}
