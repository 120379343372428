import { useEffect, useRef, useState } from "react";
import { useWidgetContext } from "../../webui/widget/WidgetContext";
import { updateEntity, updateEntityProps } from "../../webui/store/actions";
import { Entity } from "../../webui/types";
import { getFailedValidationsByEntity } from "../components/FormErrors/FormErrors";
import { findEntityById } from "../../webui/search";
import matchingPaths from "../utils/matchingPaths";
import { FormOutput } from "@uneo/platform-commons/platform/utils/commonTypes";
import {
    debouncedTriggerExplicitValidations,
    triggerExplicitValidations
} from "@uneo/platform-commons/platform/validations/ValidateEntity";

type FormState = {
    values: Record<string, any>;
    data: Record<string, any>;
    state: Record<string, any>;
    validations: Record<string, any>;
    emptyRequiredFields: string[];
    validationsPassed: Record<string, boolean>;
};

//Goals
//handle:
//- Be failed validations -> by propagating invalid state if a component is not "touched"
//- inactive submit button, by keeping "allRequiredFieldsHaveValue" flag
//TODO add in onChange and onBlur callbacks check for actions (in which case all form data to be submitted before any actions are triggered)
//Note: dynamic validation rules are not supported
export default function useForm({
    data,
    triggerExplicitFormValidations
}: {
    data: Entity;
    triggerExplicitFormValidations?: boolean;
}): FormOutput {
    const initialState: FormState = {
        values: {},
        data: {},
        state: {},
        validations: {},
        emptyRequiredFields: [],
        validationsPassed: {}
    };
    const stateRef = useRef(initialState);
    const [valueState, setValueState] = useState<Record<string, any>>({});
    const [allRequiredFieldsHaveValue, setAllRequiredFieldsHaveValue] = useState(true);
    const [allFieldsValidationsPassed, setAllFieldsValidationsPassed] = useState(true);
    const { triggerEntityActions, widgetId } = useWidgetContext();
    const BEerrors = getFailedValidationsByEntity(findEntityById(data, "errors"));

    //TODO export entity props to component
    const register = (registerAs: string = "", extraProps: any = {}) => {
        const entity = findEntityById(data, registerAs) as Entity;
        const isValidInStore = entity ? entity.uiState?.valid !== false : true;
        const entityProps = entity?.properties || {};
        const mergedProps = Object.assign({}, entityProps, extraProps);
        const fieldName = registerAs;
        if (!stateRef.current.state[fieldName]) {
            stateRef.current.state[fieldName] = {
                touched: false,
                required: mergedProps.required,
                initialValue: mergedProps.value
            };
        }
        stateRef.current.validations[fieldName] = mergedProps.validations;

        if (
            Object.prototype.hasOwnProperty.call(mergedProps, "value") &&
            !Object.prototype.hasOwnProperty.call(valueState, fieldName)
        ) {
            stateRef.current.values[fieldName] = mergedProps.value;
        }
        let haveValue = !!stateRef.current.values[fieldName];
        if (haveValue && stateRef.current.values[fieldName].files) {
            haveValue = !!stateRef.current.values[fieldName].files.length;
        }
        if (
            !haveValue &&
            mergedProps.required &&
            !stateRef.current.emptyRequiredFields.includes(fieldName)
        ) {
            stateRef.current.emptyRequiredFields.push(fieldName);
        }
        //Note: dynamic validation rules are not supported
        if (
            triggerExplicitFormValidations &&
            mergedProps.validations &&
            !Object.prototype.hasOwnProperty.call(stateRef.current.validationsPassed, fieldName)
        ) {
            const failedValidationsArray = triggerExplicitValidations(
                stateRef.current.values[fieldName],
                stateRef.current.validations[fieldName]
            );
            const validationsPassed = !failedValidationsArray.length;
            stateRef.current.validationsPassed[fieldName] = validationsPassed;
            if (!validationsPassed && allFieldsValidationsPassed) {
                setAllFieldsValidationsPassed(validationsPassed);
            }
        }

        if (hasBEError(BEerrors, fieldName)) {
            if (
                stateRef.current.state[fieldName].valid !== false &&
                !stateRef.current.state[fieldName].touched
            ) {
                stateRef.current.state[fieldName].valid = false;
            }
        } else {
            if (
                stateRef.current.state[fieldName].valid === false &&
                !stateRef.current.state[fieldName].touched &&
                isValidInStore
            ) {
                stateRef.current.state[fieldName].valid = true;
            }
        }
        const propsToReturn = {
            ...mergedProps,
            onChange: (state: {
                value?: string | number | boolean;
                files?: object[];
                selection?: string[];
            }) => {
                //TODO move hasValue and isRequired logic into validation code
                const requireField = (name: string): void => {
                    if (stateRef.current.state[name].required) {
                        stateRef.current.emptyRequiredFields.push(name);
                    }
                };
                let newValue: string | { files: object[] };

                // remove from emptyRequiredFields for re-evaluation
                stateRef.current.emptyRequiredFields = stateRef.current.emptyRequiredFields.filter(
                    (i) => i !== fieldName
                );

                if (state.value) {
                    newValue = state.value.toString();
                    newValue === "" && requireField(fieldName);
                } else if (state.files) {
                    newValue = { files: state.files };
                    !state.files.length && requireField(fieldName);
                } else {
                    newValue = (state.selection?.length && state.selection?.join(",")) || "";
                    newValue === "" && requireField(fieldName);
                }

                stateRef.current.values[fieldName] = newValue;
                stateRef.current.state[fieldName].touched = true;
                stateRef.current.state[fieldName].valid = true;
                if (valueState[fieldName] !== newValue) {
                    setValueState((prevState) => ({ ...prevState, [fieldName]: newValue }));
                }
                if (allRequiredFieldsHaveValue !== !stateRef.current.emptyRequiredFields.length) {
                    setAllRequiredFieldsHaveValue(!stateRef.current.emptyRequiredFields.length);
                }
                //set flag if all fields validations are passed and export the state
                if (
                    triggerExplicitFormValidations &&
                    stateRef.current?.validations?.[fieldName]?.length
                ) {
                    let fieldValidationsPassed: boolean;
                    debouncedTriggerExplicitValidations(
                        newValue,
                        stateRef.current.validations[fieldName]
                    ).then((errors) => {
                        fieldValidationsPassed = !errors.length;
                        stateRef.current.validationsPassed[fieldName] = fieldValidationsPassed;
                        const allFieldsAreValid = Object.values(
                            stateRef.current.validationsPassed
                        ).every((value) => value === true);
                        if (allFieldsAreValid !== allFieldsValidationsPassed) {
                            setAllFieldsValidationsPassed(allFieldsAreValid);
                        }
                    });
                }
                mergedProps.onChange && mergedProps.onChange(state);
            },
            onBlur: (state) => {
                stateRef.current.state[fieldName].touched = true;
                mergedProps.onBlur && mergedProps.onBlur(state);
            },
            onFocus: (state) => {
                stateRef.current.state[fieldName].touched = true;
                mergedProps.onFocus && mergedProps.onFocus(state);
            },
            dataTqId: fieldName,
            valid:
                !isValidInStore && !stateRef.current.state[fieldName].touched
                    ? isValidInStore
                    : stateRef.current.state[fieldName].valid
        };
        if (Object.prototype.hasOwnProperty.call(valueState, fieldName)) {
            propsToReturn.value = valueState[fieldName];
        }
        return propsToReturn;
    };
    const unregister = (registerAs: string = "") => {
        const fieldName = registerAs;
        delete stateRef.current.state[fieldName];
        delete stateRef.current.values[fieldName];
        delete stateRef.current.data[fieldName];
        delete stateRef.current.validations[fieldName];
        stateRef.current.emptyRequiredFields = stateRef.current.emptyRequiredFields.filter(
            (field) => field !== fieldName
        );
        if (valueState[registerAs]) {
            setValueState((prevState) => {
                const { [registerAs]: key, ...rest } = prevState;
                return rest;
            });
        }
    };
    useEffect(() => {
        if (!stateRef.current.emptyRequiredFields.length && !allRequiredFieldsHaveValue) {
            setAllRequiredFieldsHaveValue(true);
        } else if (stateRef.current.emptyRequiredFields.length && allRequiredFieldsHaveValue) {
            setAllRequiredFieldsHaveValue(false);
        }
    });

    const onSubmit: FormOutput["onSubmit"] = async (submitEntity: Entity) => {
        updateStoreData();
        resetState(stateRef.current.state, "touched");
        const result = await triggerEntityActions(submitEntity);
        //if action "form-submit" returns success, reset the form
        if (result.results.includes("form-submit success")) {
            reset();
        }
        return result;
    };

    const updateStoreData = (fields?: string[]): void => {
        const fieldValues = stateRef.current.values;
        const fieldsToUpdate = fields ? fields : Object.keys(fieldValues);
        fieldsToUpdate.forEach(updateEntityStoreData);
        setValueState({});
    };
    const updateEntityStoreData = (path: string) => {
        stateRef.current.state[path].touched = false;
        const propsToSet = transformRefValueToStoreValue(path);
        const entity = findEntityById(data, path) as Entity;
        if (!entity) {
            updateEntity({
                widgetId,
                entityPath: path,
                entity: { id: path.split(".").pop() as string, properties: propsToSet }
            });
        } else {
            updateEntityProps({
                widgetId,
                entityPath: entity.contextPath || path,
                props: propsToSet
            });
        }
    };
    const transformRefValueToStoreValue = (path) => {
        let value = stateRef.current.values[path];
        const data = stateRef.current.data[path];
        const validations = stateRef.current.validations[path];
        if (value?.constructor?.name === "Array") {
            value = value.join(",");
        }
        const result: Record<string, any> = { value };
        if (data) {
            result.data = data;
        }
        if (validations) {
            result.validations = validations;
        }
        return result;
    };

    const getValue = (searchId: string) => {
        const registeredPath = Object.keys(stateRef.current.values).find((i) =>
            i.endsWith(searchId)
        ) as string;
        return stateRef.current.values[registeredPath];
    };

    const setAdditionalData = (registeredAs, data) => {
        stateRef.current.data[registeredAs] = data;
    };
    const removeAdditionalData = (registeredAs) => {
        delete stateRef.current.data[registeredAs];
        const entity = findEntityById(data, registeredAs) as Entity;
        if (entity && entity?.properties?.data) {
            updateEntityProps({
                widgetId,
                entityPath: entity.contextPath || registeredAs,
                props: { data: undefined }
            });
        }
    };

    const hasChanges = () => {
        return !Object.keys(stateRef.current.values).every((path) => {
            return stateRef.current.values[path] === findEntityById(data, path).properties.value;
        });
    };

    const forceSetValue = (registeredAs, value) => {
        stateRef.current.values[registeredAs] = value;
        if (!stateRef.current.state[registeredAs]) {
            stateRef.current.state[registeredAs] = {
                touched: false
            };
        }
        if (valueState[registeredAs] !== value) {
            setValueState((prevState) => ({ ...prevState, [registeredAs]: value }));
        }
    };

    const setToRefState = (registeredAs, toSet) => {
        Object.assign(stateRef.current.state[registeredAs], toSet);
    };
    const getFromRefState = (registeredAs) => {
        return stateRef.current.state[registeredAs] || {};
    };
    const reset = () => {
        Object.keys(stateRef.current.values).forEach((path) => {
            stateRef.current.state[path].touched = false;
            stateRef.current.values[path] = stateRef.current.state[path].initialValue;
            const propsToSet: Record<string, any> = {
                value: stateRef.current.state[path].initialValue
            };
            if (stateRef.current.data[path]) {
                propsToSet.data = undefined;
            }
            const entity = findEntityById(data, path) as Entity;
            if (entity) {
                updateEntityProps({
                    widgetId,
                    entityPath: entity.contextPath || path,
                    props: propsToSet
                });
            }
        });
        setValueState({});
    };
    return {
        register,
        unregister,
        onSubmit,
        reset,
        getValue,
        allRequiredFieldsHaveValue,
        allFieldsValidationsPassed,
        setAdditionalData,
        removeAdditionalData,
        forceSetValue,
        setToRefState,
        getFromRefState,
        updateStoreData,
        hasChanges
    } as FormOutput;
}

const resetState = (state, targetProperty) => {
    Object.keys(state).forEach((key) => (state[key][targetProperty] = false));
};
//check if any path in BE errors matches current fieldName
const hasBEError = (BEErrors: Record<string, any>, fieldName: string): boolean => {
    if (BEErrors[fieldName]) {
        return true;
    }
    return !!Object.keys(BEErrors).some((target) => matchingPaths(target, fieldName));
};
