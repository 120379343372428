import React from "react";
import { findEntityById, getEntityValue } from "@uneo/platform-commons/webui/search";
import { Entity } from "@uneo/platform-commons/webui/types";
import { LoaderButton, Message } from "@uneo/platform-commons/platform/components";
//Renders content with only header/message and a cancel button
export default function PageNotAvailable({
    data,
    children
}: {
    data: Entity;
    children: React.ReactNode | React.ReactNode[];
}) {
    const isNotAvailableEntity = findEntityById(data, "page-not-available-message");
    if (isNotAvailableEntity) {
        const mainTitle = getEntityValue(data, "title");
        return (
            <div className="un-main-content">
                <h1 className="tq-title tq-text-center tq-main-color un-ds-heading-1 un-mt-2">
                    <span>{mainTitle}</span>
                </h1>
                <Message messageEntity={isNotAvailableEntity} type="error"></Message>
                <div className="un-ds-action-section-center">
                    <LoaderButton
                        extraClasses="un-ds-button-outline"
                        entity={findEntityById(data, "cancel")}
                    />
                </div>
            </div>
        );
    }
    return <>{children}</>;
}
