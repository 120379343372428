import { Action, ActionContext, Entity } from "../../webui/types";
import { fetchData } from "../httpClient/httpClient";
import { generateErrorsEntity, isExceptionResponse } from "../utils/exceptions";
import { createMultipartFormData } from "../utils/serialization/createMultipartFormData";
import { generateFormData } from "../utils/serialization/formData";
import { ERRORS_ENTITY, METHOD_POST, SUCCESS_MESSAGES_ENTITY } from "./constants";
import {
    deleteEntity,
    getEntity,
    updateEntity as updateStoreEntity
} from "../../webui/store/actions";
import { getParentPath } from "../../webui/utils/paths";
import { fromSirenEntityToEntity } from "../../webui/adapters";
import { triggerAction } from "../../webui/actions/actions";
import { getActionUrl } from "../services/urlService";

//same as update but sets errors or success-messages
export default async function formSubmit(action: Action, context: ActionContext) {
    let fetchOptions = {};
    if (action.method === METHOD_POST) {
        const sources = action.sources || [];
        const entities: Entity[] = [];
        sources.forEach((source) => {
            const foundEntity = getEntity({
                widgetId: context.widgetId,
                entityPath: source,
                currentEntityPath: context.currentEntityPath
            });
            if (foundEntity) {
                entities.push(foundEntity);
            } else {
                console.error("Entity not found,", source);
            }
        });
        const formData = generateFormData(entities);
        if (formData.files) {
            const body = createMultipartFormData(formData);
            fetchOptions = {
                method: action.method,
                body,
                headers: {
                    "Content-Type": undefined
                }
            };
        } else {
            fetchOptions = { method: action.method, body: JSON.stringify(formData) };
        }
    }
    const errorsPath = getParentPath(context.currentEntityPath) + "." + ERRORS_ENTITY;
    const successPath = getParentPath(context.currentEntityPath) + "." + SUCCESS_MESSAGES_ENTITY;
    //clear previous error messages
    deleteEntity({
        widgetId: context.widgetId,
        entityPath: errorsPath,
        currentEntityPath: context.currentEntityPath
    });
    deleteEntity({
        widgetId: context.widgetId,
        entityPath: successPath,
        currentEntityPath: context.currentEntityPath
    });
    const result = await fetchData({
        url: getActionUrl(action, context),
        fetchOptions,
        actionContext: context
    });
    if (isExceptionResponse(result)) {
        let errorsTarget = "errors";
        if (action.targets && action.targets[0].includes("errors")) {
            errorsTarget = action.targets[0];
        }
        const errorMessage = generateErrorsEntity();
        updateStoreEntity({
            widgetId: context.widgetId,
            entityPath: errorsTarget,
            currentEntityPath: context.currentEntityPath,
            entity: errorMessage
        });
        return;
    }
    const resultId = getResponseId(result);
    //in case of errors only show errors
    if (resultId === ERRORS_ENTITY) {
        updateStoreEntity({
            widgetId: context.widgetId,
            entityPath: errorsPath,
            currentEntityPath: context.currentEntityPath,
            entity: fromSirenEntityToEntity(result)
        });
        return;
    }
    updateStoreEntity({
        widgetId: context.widgetId,
        entityPath: successPath,
        currentEntityPath: context.currentEntityPath,
        entity: fromSirenEntityToEntity(result)
    });
    //close modal if the form is part of a generic modal
    //TODO call closeModal method instead of triggering closeModal
    if (getEntity({ widgetId: context.widgetId, entityPath: "modal-section" })) {
        triggerAction({ type: "closeModal" }, context);
    }
    //returning fixed string, hookForm is checking for this string in order to reset form on submit
    return "form-submit success";
}

function getResponseId(result: any) {
    return result && result.class && result.class[0];
}
