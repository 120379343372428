import { ApplicationRule } from "./types";
import { CHILD_ROLE, PARTNER_ROLE } from "../../utils/constants";
import { Entity } from "@uneo/platform-commons/webui/types";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";
import { getEntityValue } from "@uneo/platform-commons/webui/search";

export const searchInDepth = (entities: Entity[] = [], result = false): boolean => {
    return !!entities.find((entity) => {
        const role = entity.properties?.entity?.context?.role || [];
        if ("entities" in entity && entity.entities?.length) {
            if (role.includes(PARTNER_ROLE) || role.includes(CHILD_ROLE)) {
                return true;
            }
            return searchInDepth(entity.entities, result);
        }
        return false;
    });
};

export const availableCoInsured = (steps: Entity[]): boolean => searchInDepth(steps);

/**
 *  --------------- "showOnValues"  description ----------------------
 * ex.
 * "applicationRules": [
 {
                                "id": "showOnValues",
                                "source": "personalDetails.professionalCategory",
                                "values": ["civil", "military"]
                            }
 ]
 *  evaluates to true, if "source" path's "value" property contains one of the values specified in "values" of the applicationRule
 * the equality can be achieved by just passing array with only one sting inside for applicationRule's "values"
 */
export const showOnValues = (applicationRule: ApplicationRule): boolean => {
    const { source, values } = applicationRule;
    if (source) {
        const sourceEntity = getEntity({ widgetId: "content-root", entityPath: source });
        if (sourceEntity) {
            let sourceValue = getEntityValue(sourceEntity, source);
            if (!Array.isArray(sourceValue)) {
                sourceValue = [sourceValue];
            }
            return sourceValue.some((value) => values?.includes(value));
        }
    }
    return false;
};

export const unknown = (name: string): boolean => {
    console.warn(`unknown application rule id: ${name}`);
    return false;
};

export default {
    availableCoInsured,
    showOnValues,
    unknown
};
