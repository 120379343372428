"use client";
import { useEffect, useState } from "react";

import { FeatureData, useFetchFeature } from "@uneo/platform-commons/platform/features";
import { Entity } from "@uneo/platform-commons/webui/types";
import { findEntityById } from "@uneo/platform-commons/webui/search";

declare global {
    interface Window {
        _paq: {
            push(...args: any[]): void;
        };
    }
}

export const allowedMatomoAction = [
    "Cliquer",
    "Modifier",
    "Valider",
    "Annuler",
    "Télécharger",
    "Envoyer",
    "Filtrer",
    "Affichage"
] as const;
export type matomoActions = (typeof allowedMatomoAction)[number];

export function triggerMatomoEvent(category: string, name: string, action: matomoActions) {
    if (category === "" || name === "") {
        return;
    }

    if (window._paq) {
        window._paq.push(["trackEvent", category, action, name]);
    }
}

export default function MatomoTracker({ data }: { data: Entity }) {
    const { enabled, settings } = useFetchFeature("matomo");

    const user = settings.user;
    const metadata = findEntityById(data, "metadata");
    const innerWidgetData = data?.entities ? data?.entities[0] : null;
    const innerWidgetId = innerWidgetData?.id || "";

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Set the initial consent state
        const initialConsent = !!(window as any)?.tarteaucitronConsents?.matomo?.accepted;
        if (initialConsent !== consentGiven) {
            setConsentGiven(initialConsent);
        }

        // Define the event listener callback function
        function handleConsentChange(event) {
            if (event.detail.matomo && event.detail.matomo.accepted !== consentGiven) {
                setConsentGiven(event.detail.matomo.accepted);
            }
        }

        // Add the event listener when the component mounts
        document.addEventListener("tarteaucitronConsentChange", handleConsentChange);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("tarteaucitronConsentChange", handleConsentChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (enabled && settings.url && data && !scriptLoaded && consentGiven) {
            const scriptUrl = `//cdn.matomo.cloud/${settings.url}/matomo.js`;
            if (isScriptInDOM(scriptUrl)) {
                setScriptLoaded(true);
                return;
            }

            const initializeScript = document.createElement("script");
            initializeScript.innerText = `\
                var _paq = window._paq = window._paq || [];\
                _paq.push(["setDoNotTrack", true]);\
                _paq.push(["disableCookies"]);\
                _paq.push(["HeatmapSessionRecording::disable"]);
                (function() {\
                    var u="https://${settings.url}";\
                    _paq.push(['setTrackerUrl', u+'/matomo.php']);\
                    _paq.push(['setSiteId', '${settings["site-id"]}']);\
                })();\
            `;
            const mainScript = document.createElement("script");
            mainScript.src = scriptUrl;
            mainScript.onload = () => setScriptLoaded(true);

            const headElement = document.getElementsByTagName("head")[0];
            headElement.appendChild(initializeScript);
            headElement.appendChild(mainScript);
        }
    }, [enabled, settings, data, scriptLoaded, consentGiven]);

    useEffect(() => {
        if (data && window._paq && scriptLoaded) {
            const currentUrl =
                innerWidgetId === "auth-login"
                    ? `${window.location.origin}/se-connecter`
                    : window.location.href;

            user["connected"] && window._paq.push(["setCustomDimension", 1, user["connected"]]);
            user["contract-type"] &&
                window._paq.push(["setCustomDimension", 2, user["contract-type"]]);
            user["contract-role"] &&
                window._paq.push(["setCustomDimension", 3, user["contract-role"]]);

            //set the Referrer. (https://matomo.org/faq/troubleshooting/faq_51/)
            // Matomo only record the first referer of a visit. When testing Matomo and search engine detection, make sure you make the test more than 30 minutes after your last page view on your website.
            window._paq.push(["setReferrerUrl", currentUrl]);
            window._paq.push(["setCustomUrl", currentUrl]);
            window._paq.push(["setDocumentTitle", metadata?.properties?.title]);
            window._paq.push(["trackPageView"]);

            const content = document.getElementsByTagName("body")[0];
            window._paq.push(["MediaAnalytics::scanForMedia", content]);
            window._paq.push(["FormAnalytics::scanForForms", content]);
            window._paq.push(["enableLinkTracking"]);
        }

        return () => {
            if (window._paq) {
                window._paq.push(["HeatmapSessionRecording::disable"]);
                window._paq.push(["MediaAnalytics::disableMediaAnalytics"]);
                window._paq.push(["FormAnalytics::disableFormAnalytics"]);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptLoaded, innerWidgetId]);

    useEffect(() => {
        //do tracking only once when pwa is installed (working in Chrome only)
        //triggers right after the pwa installation process is completed
        const trackAndroidPWAInstallation = () => {
            triggerMatomoEvent(
                "PWA android",
                "[PWA android] - Téléchargement réussi",
                "Télécharger"
            );
        };
        window.addEventListener("appinstalled", trackAndroidPWAInstallation);
        //For Safari iOS the approach is different - user needs to open the application from the pwa icon
        //It is in standalone mode, so on first load we do tracking and set a flag in the localStorage to prevent next tracking
        if ((window.navigator as any).standalone) {
            // Check if the installation flag is already set
            if (!localStorage.getItem("pwaInstalled")) {
                // PWA installation detected
                // Send installation data to matomo
                //setTimeout is used because on iOS window._paq is possibly not initialized at this moment
                setTimeout(() => {
                    triggerMatomoEvent("PWA ios", "[PWA ios] - Ouverture réussie", "Télécharger");
                }, 2000);
                // Set the installation flag in localStorage
                localStorage.setItem("pwaInstalled", "true");
            }
        }
        return () => {
            window.removeEventListener("appinstalled", trackAndroidPWAInstallation);
        };
    }, []);

    function isScriptInDOM(url: string) {
        return !!document.querySelector(`script[src="${url}"]`);
    }

    return null;
}
//UPF-2434 confirmed types of user
export const getUserType = (matomoFeatureInfo: FeatureData) => {
    return matomoFeatureInfo?.settings?.user?.["contract-type"] ? "Adherent" : "Unéonaute";
};
