export default function areParentsVisible(element) {
    let targetElement = element;
    let visible = true;
    if (targetElement) {
        while (targetElement.parentNode && visible) {
            let computedStyle = window.getComputedStyle(targetElement);
            let cssOpacity = computedStyle.getPropertyValue("opacity");
            let cssDisplay = computedStyle.getPropertyValue("display");
            visible = cssOpacity !== "0" && cssDisplay !== "none";
            targetElement = targetElement.parentNode;
        }
    }
    return visible;
}
