import { getFeatureData } from "../features";
import { ValidationRule } from "./interfaces";

export const createRule = ({
    type,
    parameters,
    message,
    level
}: {
    type: string;
    parameters?: any[];
    message?: string;
    level?: ValidationRule["level"];
}): ValidationRule => {
    const newRule: ValidationRule = { type };
    if (parameters) {
        newRule.parameters = parameters;
    }
    if (message) {
        newRule.message = message;
    }
    newRule.level = level || "error";
    return applyRuleSettings(newRule);
};

export const applyRuleSettings = (validationRule: ValidationRule): ValidationRule => {
    const ruleSettings = getRuleSettings(validationRule.type);
    if (ruleSettings) {
        return Object.assign({}, ruleSettings, removeEmptyProps(validationRule));
    }
    return validationRule;
};

export const getRuleSettings = (type: string) => {
    const featureData = getFeatureData("validations");
    const settings = featureData ? featureData.settings : null;
    if (settings) {
        const defaultMessage = settings["invalid-format-message"] || "";
        if (type === "email" && settings["email"]) {
            return { parameters: [settings["email"]], message: defaultMessage };
        }
        if (type === "required" && settings["required-input-message"]) {
            return { message: settings["required-input-message"] };
        }
        if (type === "person-name" && settings["person-name"]) {
            return { parameters: [settings["person-name"]], message: defaultMessage };
        }
        return { message: defaultMessage };
    }
};
//filtering null and undefined "message" and "parameters"
const removeEmptyProps = (rule: ValidationRule): ValidationRule => {
    const result: ValidationRule = { type: rule.type };
    Object.keys(rule).forEach((key) => {
        if (rule[key] !== undefined && rule[key] !== null) {
            result[key] = rule[key];
        }
    });
    return result;
};
