"use client";
import React, { useEffect, useRef } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import {
    findEntityById,
    getEntityChildren,
    getEntityProps,
    getEntityValue
} from "@uneo/platform-commons/webui/search";
import { LoaderButton } from "@uneo/platform-commons/platform/components";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { tabulateArea } from "@uneo/platform-commons/platform/utils/tabulateArea";
import { xssFilter } from "@uneo/platform-commons/platform/utils/xssFilter";
import { hideModal, showModal } from "@uneo/platform-commons/platform/utils/modalUtils";
import { usePageMetaContext } from "@uneo/platform-portal/widgets/content-root/PageMetaContext";

type ModalContentProps = {
    mfaModalEntity: Entity;
};
export default function MfaModal({ data }: { data: Entity }) {
    const mfaModalEntity: Entity = findEntityById(data, "mfa-modal");
    const opened = getEntityProps(mfaModalEntity, mfaModalEntity.id, "opened");

    if (!mfaModalEntity || !opened) return <></>;
    return <ModalContent mfaModalEntity={mfaModalEntity} />;
}

function ModalContent({ mfaModalEntity }: ModalContentProps) {
    const modalRef = useRef<HTMLDivElement>(null);
    const { triggerEntityActions, triggerActions } = useWidgetContext();
    const { triggerPageEvent } = usePageMetaContext();

    const onClose = (fromSubmit = false) => {
        hideModal(modalRef).then(() => {
            if (!fromSubmit) {
                triggerPageEvent({
                    name: "Validation en deux étapes (MFA)",
                    customAction: "Fermer"
                });
            }
            triggerActions([
                {
                    type: "closeModal",
                    targets: [mfaModalEntity.id]
                }
            ]);
        });
    };

    const closeModal = () => {
        triggerEntityActions(cancelEntity);
        onClose();
    };

    const onKeyDown = (event) => {
        const isTabPressed = event.key === "Tab";
        const isEscapePressed = event.key === "Escape";

        let tabulateBy = 1;
        if (event.shiftKey && isTabPressed) {
            tabulateBy = -1;
        }

        if (isEscapePressed) {
            closeModal();
        }
        if (isTabPressed) {
            event.preventDefault();
            tabulateArea(modalRef, tabulateBy);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        showModal(modalRef);
        return function cleanup() {
            document.removeEventListener("keydown", onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const link: Entity = findEntityById(mfaModalEntity, "link");
    const title: string = getEntityValue(mfaModalEntity, "title");
    const description: string = getEntityValue(mfaModalEntity, "text");
    const benefits: Entity[] = getEntityChildren(mfaModalEntity, "benefits");
    const cancelEntity: Entity = findEntityById(mfaModalEntity, "cancel");
    return (
        link && (
            <div ref={modalRef} className="tq-modal un-ds-modal un-ds-modal-generic un-mfa-modal">
                <div className="tq-modal-overlay" />
                <div className="tq-modal-container tq-modal-l">
                    <div className=" tq-modal-header">
                        <h3 className=" tq-panel-title  tq-light-splitter tq-with-right-menu">
                            <span className="tq-text-node" />
                            <ul className=" tq-menu tq-selectable">
                                <li className="">
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className="tq-item"
                                        aria-label="Close"
                                        onClick={closeModal}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                closeModal();
                                            }
                                        }}
                                    >
                                        <i className="tq-icon-close" />
                                    </div>
                                </li>
                            </ul>
                        </h3>
                    </div>
                    <div className="tq-modal-scroll">
                        <div className="tq-modal-content">
                            {title && <p className="tq-main-color un-ds-heading-3">{title}</p>}
                            {description && (
                                <div
                                    className="tq-text-color un-modal-mfa-subtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: xssFilter.process(description)
                                    }}
                                ></div>
                            )}
                            {benefits?.map((item, i) => {
                                const icon: string = getEntityValue(item, "icon");
                                const title: string = getEntityValue(item, "title");
                                const text: string = getEntityValue(item, "text");
                                return (
                                    <div key={item.id} className="un-modal-section-mfa">
                                        {icon && <i className={icon} />}
                                        <div className="un-modal-mfa-benefit">
                                            {title && <p>{`${i + 1}. ${title}`}</p>}
                                            {text && <p>{text}</p>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="un-ds-action-section-center">
                            <LoaderButton
                                entity={link}
                                extraClasses="un-ds-button-solid un-ds-component-sm"
                                onClick={() => {
                                    triggerPageEvent({
                                        name: "Validation en deux étapes (MFA)",
                                        customAction: getEntityValue(link, link.id)
                                    });
                                    triggerEntityActions(link);
                                    onClose(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
