"use client";
import React, { useState } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import {
    findEntityById,
    getEntityChildren,
    getEntityProps,
    getEntityValue
} from "@uneo/platform-commons/webui/search";
import ProgressBar from "../ProgressBar/ProgressBar";
import StickyHeaderContainer from "../StickyHeaderContainer/StickyHeaderContainer";
import ProcessTitle from "./ProcessTitle";
import ProcessSubtitle from "./ProcessSubtitle";
import MobileSummarySteps from "./MobileSummarySteps";
import SummarySteps from "./SummarySteps";

import "@tinqin/design-uneo-pf/css/processes.css";

export default function ProcessContainer({
    data,
    extraClasses = "",
    navigationClasses = "",
    contentClasses = "",
    children,
    showSteps = true
}: {
    data: Entity;
    extraClasses?: string;
    navigationClasses?: string;
    contentClasses?: string;
    children?: React.ReactNode | React.ReactNode[];
    showSteps?: boolean;
}) {
    const [mobileSummaryExpanded, setMobileSummaryExpanded] = useState(false);
    const processName = getEntityValue(data, "process-name");
    const title = getEntityValue(data, "title");
    const subtitle = findEntityById(data, "subtitle");
    const currentStep = getEntityProps(data, "progress-bar", "currentStep") || 1;
    const flowSteps = getEntityChildren(data, "steps-summary");

    return (
        <main>
            <div className={`tq-main-content tq-main-content-no-sidebar ${extraClasses}`}>
                <div
                    className={`un-flow-main ${mobileSummaryExpanded ? "un-show-mobile-menu" : ""}`}
                >
                    <div
                        className={`un-flow-navigation ${navigationClasses} ${
                            mobileSummaryExpanded ? "un-hide" : ""
                        }`}
                    >
                        <StickyHeaderContainer>
                            <ProgressBar entity={findEntityById(data, "progress-bar")} />
                        </StickyHeaderContainer>
                        <ProcessTitle title={title} extraClasses="tq-text-center" />
                        <ProcessSubtitle entity={subtitle} extraClasses="tq-text-center" />
                    </div>
                    <div className={`un-flow-content ${contentClasses}`}>
                        {showSteps && (
                            <MobileSummarySteps
                                title={processName}
                                currentStep={currentStep}
                                expanded={mobileSummaryExpanded}
                                steps={flowSteps}
                                onToggle={() => {
                                    setMobileSummaryExpanded(!mobileSummaryExpanded);
                                }}
                            />
                        )}
                        <div
                            className={`un-step-main-content ${
                                mobileSummaryExpanded ? "un-step-main-content-hide" : ""
                            }`}
                        >
                            <div className="tq-flex-container un-flex-container-with-scroll-element">
                                <div className="tq-flex-item un-flow-content-left">{children}</div>
                                {showSteps && (
                                    <div className="tq-flex-item un-flow-progress">
                                        <SummarySteps currentStep={currentStep} steps={flowSteps} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
