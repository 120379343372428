"use client";
import React, { ReactNode } from "react";

export default function Preloader(props: { loading: boolean; children: ReactNode | ReactNode[] }) {
    const { loading, children } = props;

    return (
        <div
            className={`un-ds-preloader-container ${
                loading ? "tq-preloader-container tq-preloader-active" : ""
            }`}
        >
            {loading && (
                <>
                    <div className="tq-preloader-mask tq-load"></div>
                    <div className="tq-preloader-item tq-load">
                        <div className="un-preloader-main">
                            <div className="un-preloader-dot"></div>
                            <div className="un-preloader-dot"></div>
                            <div className="un-preloader-dot"></div>
                        </div>
                    </div>
                </>
            )}

            {!loading && children}
        </div>
    );
}
