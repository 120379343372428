import { useEffect, useState } from "react";
import { FEATURE_ENDPOINT } from "@uneo/platform-next/src/constants";
const cache = new Map<FeatureID, Promise<FeatureData>>();
const loadedFeatures = new Map<FeatureID, FeatureData>();
type FeatureID =
    | "hi-pay"
    | "upload-zone"
    | "msh-card"
    | "matomo"
    | "validations"
    | "google-recaptcha"
    | "tarteaucitron"
    | "terms-and-conditions"
    | "marketing-banners"
    | "radio"
    | "sitemap"
    | "linkedin-insight"
    | "meta-pixel"
    | "session"
    | "faciliti";

//Feature data should immutable
//This method prevents object recursively to be non-writable
const deepFreeze = (obj: any): any => {
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === "object" && !Object.isFrozen(value)) {
            deepFreeze(value);
        }
    });
    return Object.freeze(obj);
};
//Readonly at top level and for settings object
export type FeatureData = {
    readonly feature: string;
    readonly enabled: boolean;
    readonly settings: Readonly<Record<string, any>>;
};

// Type guard function for FeatureData
const isFeatureData = (obj: any): obj is FeatureData => {
    return (
        typeof obj === "object" && // Check if it's an object
        typeof obj.feature === "string" && // Check if 'feature' is a string
        typeof obj.enabled === "boolean" && // Check if 'enabled' is a boolean
        (obj.settings === undefined || typeof obj.settings === "object") // Check if 'settings' is undefined or an object
    );
};

const defaultFeatureObject: FeatureData = {
    feature: "",
    enabled: false,
    settings: {}
};

const fetchFeature = async (featureId: FeatureID): Promise<FeatureData> => {
    const response = await fetch(`/${FEATURE_ENDPOINT}/${featureId}`, {
        headers: {
            Accept: "application/json"
        }
    });
    const result = await response.json();
    if (isFeatureData(result)) {
        loadedFeatures.set(featureId, result);
        return result;
    }
    return defaultFeatureObject;
};

//for direct fetching of features
export const loadFeature = (featureId: FeatureID): Promise<FeatureData> => {
    // use cache if available
    if (!cache.get(featureId)) {
        cache.set(featureId, fetchFeature(featureId));
    }
    return deepFreeze(cache.get(featureId));
};

export const getFeatureData = (featureId: FeatureID): FeatureData | undefined => {
    return loadedFeatures.get(featureId);
};

//for usage in components
export const useFetchFeature = (featureId: FeatureID) => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<FeatureData>(defaultFeatureObject);
    useEffect(() => {
        // use cache if available
        if (!cache.get(featureId)) {
            cache.set(featureId, fetchFeature(featureId));
        }
        cache
            .get(featureId)
            .then((value) => {
                setInfo(value);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [featureId]);
    return deepFreeze({ ...info, loading });
};
