"use client";
import React, { lazy } from "react";

const OavNewbornIdentityDocuments = lazy(
    () => import("./widgets/newborn/oav-newborn-identity-documents/OavNewbornIdentityDocuments")
) as React.FC;
const OavNewbornIdentityPersonDetails = lazy(
    () =>
        import(
            "./widgets/newborn/oav-newborn-identity-person-details/OavNewbornIdentityPersonDetails"
        )
) as React.FC;
const OavNewbornCoverageSelection = lazy(
    () => import("./widgets/newborn/oav-newborn-coverage-selection/OavNewbornCoverageSelection")
) as React.FC;
const OavNewbornCoverageOther = lazy(
    () => import("./widgets/newborn/oav-newborn-coverage-other/OavNewbornCoverageOther")
) as React.FC;
const OavNewbornSocialSecuritySelection = lazy(
    () =>
        import(
            "./widgets/newborn/oav-newborn-social-security-selection/OavNewbornSocialSecuritySelection"
        )
) as React.FC;
const OavNewbornSocialSecurityDocuments = lazy(
    () =>
        import(
            "./widgets/newborn/oav-newborn-social-security-documents/OavNewbornSocialSecurityDocuments"
        )
) as React.FC;
const OavNewbornSocialSecurityPersonDetails = lazy(
    () =>
        import(
            "./widgets/newborn/oav-newborn-social-security-person-details/OavNewbornSocialSecurityPersonDetails"
        )
) as React.FC;
const OavNewbornEffectiveDate = lazy(
    () => import("./widgets/newborn/oav-newborn-effective-date/OavNewbornEffectiveDate")
) as React.FC;
const OavNewbornSummary = lazy(
    () => import("./widgets/newborn/oav-newborn-summary/OavNewbornSummary")
) as React.FC;
const OavNewbornSuccess = lazy(
    () => import("./widgets/newborn/oav-newborn-success/OavNewbornSuccess")
) as React.FC;
const OavNewbornError = lazy(
    () => import("./widgets/newborn/oav-newborn-error/OavNewbornError")
) as React.FC;
//--- Quote process --- //
const OavQuoteStart = lazy(
    () => import("./widgets/quote/oav-quote-start/OavQuoteStart")
) as React.FC;
const OavQuoteType = lazy(() => import("./widgets/quote/oav-quote-type/OavQuoteType")) as React.FC;
const OavQuoteCoverageSelection = lazy(
    () => import("./widgets/quote/oav-quote-coverage-selection/OavQuoteCoverageSelection")
) as React.FC;
const OavQuoteQuestionnaire = lazy(
    () => import("./widgets/quote/oav-quote-questionnaire/OavQuoteQuestionnaire")
) as React.FC;
const OavQuoteMemberScore = lazy(
    () => import("./widgets/quote/oav-quote-member-score/OavQuoteMemberScore")
) as React.FC;
const OavQuoteSummary = lazy(
    () => import("./widgets/quote/oav-quote-summary/OavQuoteSummary")
) as React.FC;
const OavQuoteContactForm = lazy(
    () => import("./widgets/quote/oav-quote-contact-form/OavQuoteContactForm")
) as React.FC;
const OavQuoteThankYou = lazy(
    () => import("./widgets/quote/oav-quote-thank-you/OavQuoteThankYou")
) as React.FC;
const OavQuoteError = lazy(
    () => import("./widgets/quote/oav-quote-error/OavQuoteError")
) as React.FC;
const OavMyQuotes = lazy(() => import("./widgets/oav-my-quotes/OavMyQuotes")) as React.FC;
//--- Affiliation process --- //
const OavAffiliationIdentityDocumentsChild = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-documents-child/OavAffiliationIdentityDocumentsChild"
        )
) as React.FC;
const OavAffiliationIntroduction = lazy(
    () => import("./widgets/affiliation/oav-affiliation-introduction/OavAffiliationIntroduction")
) as React.FC;
const OavAffiliationIdentityDocumentsEmployee = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-documents-employee/OavAffiliationIdentityDocumentsEmployee"
        )
) as React.FC;
const OavAffiliationIdentityDocumentsSpouse = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-documents-spouse/OavAffiliationIdentityDocumentsSpouse"
        )
) as React.FC;
const OavAffiliationIdentityPersonDetailsSpouse = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-person-details-spouse/OavAffiliationIdentityPersonDetailsSpouse"
        )
) as React.FC;
const OavAffiliationIdentityPersonDetailsChild = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-person-details-child/OavAffiliationIdentityPersonDetailsChild"
        )
) as React.FC;
const OavAffiliationIdentityCoverages = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-coverages/OavAffiliationIdentityCoverages"
        )
) as React.FC;
const OavAffiliationIdentityPersonDetailsEmployee = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-identity-person-details-employee/OavAffiliationIdentityPersonDetailsEmployee"
        )
) as React.FC;
const OavAffiliationAddBeneficiaries = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-add-beneficiaries/OavAffiliationAddBeneficiaries"
        )
) as React.FC;
const OavAffiliationSocialSecurityAttachment = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-social-security-attachment/OavAffiliationSocialSecurityAttachment"
        )
) as React.FC;
const OavAffiliationOwnSocialSecurityDetails = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-own-social-security-details/OavAffiliationOwnSocialSecurityDetails"
        )
) as React.FC;
const OavAffiliationSocialSecurityDocuments = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-social-security-documents/OavAffiliationSocialSecurityDocuments"
        )
) as React.FC;
const OavAffiliationSocialSecurityPersonDetails = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-social-security-person-details/OavAffiliationSocialSecurityPersonDetails"
        )
) as React.FC;
const OavAffiliationSummary = lazy(
    () => import("./widgets/affiliation/oav-affiliation-summary/OavAffiliationSummary")
) as React.FC;
const OavAffiliationSuccess = lazy(
    () => import("./widgets/affiliation/oav-affiliation-success/OavAffiliationSuccess")
) as React.FC;
const OavAffiliationSignature = lazy(
    () => import("./widgets/affiliation/oav-affiliation-signature/OavAffiliationSignature")
) as React.FC;
const OavAffiliationSignatureLoading = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-signature-loading/OavAffiliationSignatureLoading"
        )
) as React.FC;
const OavAddBeneficiariesEffectiveDate = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-add-beneficiaries-effective-date/OavAddBeneficiariesEffectiveDate"
        )
) as React.FC;
const OavAffiliationAdjustCoverages = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-adjust-coverages/OavAffiliationAdjustCoverages"
        )
) as React.FC;
const OavTariffInformationSummary = lazy(
    () => import("./widgets/affiliation/oav-tariff-information-summary/OavTariffInformationSummary")
) as React.FC;
const OavAddBeneficiariesRang = lazy(
    () => import("./widgets/affiliation/oav-add-beneficiaries-rang/OavAddBeneficiariesRang")
) as React.FC;
const OavAddBeneficiariesSelection = lazy(
    () =>
        import("./widgets/affiliation/oav-add-beneficiaries-selection/OavAddBeneficiariesSelection")
) as React.FC;

const OavCancelIndividualContract = lazy(
    () => import("./widgets/affiliation/oav-cancel-individual-contract/OavCancelIndividualContract")
) as React.FC;

const OavAffiliationCancellationDate = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-cancellation-date/OavAffiliationCancellationDate"
        )
) as React.FC;

const OavAffiliationInsuranceSelection = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-insurance-selection/OavAffiliationInsuranceSelection"
        )
) as React.FC;
const OavAffiliationComparison = lazy(
    () => import("./widgets/affiliation/oav-affiliation-comparison/OavAffiliationComparison")
) as React.FC;

const OavAffiliationAddBeneficiariesInternationalMilitary = lazy(
    () =>
        import(
            "./widgets/affiliation/oav-affiliation-add-beneficiaries-international-military/OavAffiliationAddBeneficiariesInternationalMilitary"
        )
) as React.FC;
const OavAffiliationEligibility = lazy(
    () => import("./widgets/affiliation/oav-affiliation-eligibility/OavAffiliationEligibility")
) as React.FC;

export const OavWidgets = {
    "oav-newborn-identity-documents": OavNewbornIdentityDocuments,
    "oav-newborn-identity-person-details": OavNewbornIdentityPersonDetails,
    "oav-newborn-coverage-selection": OavNewbornCoverageSelection,
    "oav-newborn-coverage-other": OavNewbornCoverageOther,
    "oav-newborn-social-security-selection": OavNewbornSocialSecuritySelection,
    "oav-newborn-social-security-documents": OavNewbornSocialSecurityDocuments,
    "oav-newborn-social-security-person-details": OavNewbornSocialSecurityPersonDetails,
    "oav-newborn-effective-date": OavNewbornEffectiveDate,
    "oav-newborn-summary": OavNewbornSummary,
    "oav-newborn-success": OavNewbornSuccess,
    "oav-newborn-error": OavNewbornError,
    "oav-quote-start": OavQuoteStart,
    "oav-quote-type": OavQuoteType,
    "oav-quote-coverage-selection": OavQuoteCoverageSelection,
    "oav-quote-questionnaire": OavQuoteQuestionnaire,
    "oav-quote-member-score": OavQuoteMemberScore,
    "oav-quote-summary": OavQuoteSummary,
    "oav-quote-contact-form": OavQuoteContactForm,
    "oav-quote-thank-you": OavQuoteThankYou,
    "oav-quote-error": OavQuoteError,
    "oav-my-quotes": OavMyQuotes,
    "oav-affiliation-identity-documents-child": OavAffiliationIdentityDocumentsChild,
    "oav-affiliation-identity-documents-employee": OavAffiliationIdentityDocumentsEmployee,
    "oav-affiliation-introduction": OavAffiliationIntroduction,
    "oav-affiliation-identity-documents-spouse": OavAffiliationIdentityDocumentsSpouse,
    "oav-affiliation-identity-person-details-spouse": OavAffiliationIdentityPersonDetailsSpouse,
    "oav-affiliation-identity-person-details-child": OavAffiliationIdentityPersonDetailsChild,
    "oav-affiliation-identity-coverages": OavAffiliationIdentityCoverages,
    "oav-affiliation-identity-person-details-employee": OavAffiliationIdentityPersonDetailsEmployee,
    "oav-affiliation-add-beneficiaries": OavAffiliationAddBeneficiaries,
    "oav-affiliation-social-security-attachment": OavAffiliationSocialSecurityAttachment,
    "oav-affiliation-own-social-security-details": OavAffiliationOwnSocialSecurityDetails,
    "oav-affiliation-social-security-documents": OavAffiliationSocialSecurityDocuments,
    "oav-affiliation-social-security-person-details": OavAffiliationSocialSecurityPersonDetails,
    "oav-affiliation-summary": OavAffiliationSummary,
    "oav-affiliation-success": OavAffiliationSuccess,
    "oav-affiliation-signature": OavAffiliationSignature,
    "oav-affiliation-signature-loading": OavAffiliationSignatureLoading,
    "oav-affiliation-adjust-coverages": OavAffiliationAdjustCoverages,
    "oav-add-beneficiaries-effective-date": OavAddBeneficiariesEffectiveDate,
    "oav-tariff-information-summary": OavTariffInformationSummary,
    "oav-add-beneficiaries-rang": OavAddBeneficiariesRang,
    "oav-add-beneficiaries-selection": OavAddBeneficiariesSelection,
    "oav-cancel-individual-contract": OavCancelIndividualContract,
    "oav-affiliation-cancellation-date": OavAffiliationCancellationDate,
    "oav-affiliation-insurance-selection": OavAffiliationInsuranceSelection,
    "oav-affiliation-comparison": OavAffiliationComparison,
    "oav-affiliation-add-beneficiaries-international-military":
        OavAffiliationAddBeneficiariesInternationalMilitary,
    "oav-affiliation-eligibility": OavAffiliationEligibility
};
