"use client";
import { useEffect, useRef } from "react";

const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;
declare global {
    interface Window {
        pf_logs: boolean;
    }
}

const LoggerComponent = () => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            if (process.env.NODE_ENV === "development") {
                window.pf_logs = true;
            }
            console.error = function (...args) {
                if (window.pf_logs) {
                    originalConsoleError.apply(console, args);
                }
            };

            console.warn = function (...args) {
                if (window.pf_logs) {
                    originalConsoleWarn.apply(console, args);
                }
            };
            mounted.current = true;
        }
    });

    return null;
};

export default LoggerComponent;
