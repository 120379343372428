"use client";
import { useEffect } from "react";
import { loadFeature } from "@uneo/platform-commons/platform/features";

const RECAPTCHA_WIDGETS = [
    "content-root.auth-login.submit",
    "content-root.auth-login.submit",
    "content-root.auth-login-email.submit",
    "content-root.auth-forgot-password-request.submit",
    "content-root.auth-forgot-password-request.submit",
    "content-root.auth-forgot-username-request.submit",
    "content-root.auth-forgot-username-request.submit",
    "content-root.auth-forgot-password-reset.submit",
    "content-root.auth-user-onboarding-identification.submit",
    "content-root.auth-user-onboarding-identification.submit",
    "content-root.auth-member-onboarding-identification.submit",
    "content-root.auth-member-onboarding-identification.submit",
    "content-root.auth-member-onboarding-credentials.submit",
    "content-root.auth-user-onboarding-credentials.submit",
    "content-root.auth-member-onboarding-multiple-beneficiaries.submit",
    "content-root.member-contact-us.send-email-section.submit",
    "content-root.member-contact-us.send-claim-section.submit",
    "content-root.oav-quote-contact-form.submit",
    "content-root.auth-onboarding-process-selection.submit",
    "content-root.auth-code-validation.submit"
];

declare global {
    interface Window {
        grecaptcha: {
            ready(...args: any[]): void;
            execute(...args: any[]): Promise<string | null>;
        };
    }
}

type recaptchaOutput = {
    getToken: (widgetId: string) => Promise<string | null>;
};

export default function useRecaptcha(): recaptchaOutput {
    useEffect(() => {
        loadFeature("google-recaptcha").then((data) =>
            initScript(data).catch((error) => console.warn(error))
        );
    }, []);
    return {
        getToken
    };
}

export const getToken = (widgetId: string): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
        if (!RECAPTCHA_WIDGETS.includes(widgetId)) {
            return resolve(null);
        }

        loadFeature("google-recaptcha").then((data) => {
            if (!data?.enabled || !window?.grecaptcha) {
                return resolve(null);
            }
            window?.grecaptcha.ready(function () {
                window.grecaptcha
                    .execute(data.settings["site-key"], { action: "submit" })
                    .then((token) => {
                        resolve(token);
                    })
                    .catch((error) => reject(error));
            });
        });
    });
};

const initScript = (config) => {
    return new Promise<void>((resolve, reject) => {
        const settings = config?.settings || null;

        if (!config?.enabled || !settings) {
            return reject("Recaptcha disabled or missing configuration");
        }

        if (config?.enabled && settings) {
            const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${settings["site-key"]}`;
            if (isScriptInDOM(scriptUrl)) {
                return resolve();
            }
            const mainScript = document.createElement("script");
            mainScript.src = scriptUrl;
            mainScript.onload = () => resolve();
            mainScript.onerror = () => reject();
            const headElement = document.getElementsByTagName("head")[0];
            headElement.appendChild(mainScript);
        }
    });
};

function isScriptInDOM(url) {
    return !!document.querySelector(`script[src="${url}"]`);
}
