import functionalContext from "./functionalContext";
import { Dialect, EvaluationResult, Expression, FunctionElement } from "./types";

export type FactoryFunctionElement = {
    evaluate: (
        expression: Expression,
        dialect?: Dialect
    ) => EvaluationResult | Promise<EvaluationResult>;
    log: (expression: Expression, dialect: Dialect) => Promise<void>;
};
export type EvaluationState = any;

const factoryMethod = (state: Record<string, any>): FactoryFunctionElement => {
    const evaluate = (
        expression: Expression,
        dialect: Dialect
    ): Promise<EvaluationResult> | EvaluationResult => {
        const functionalElement: FunctionElement = functionalContext.factory(expression, dialect);
        return functionalElement.evaluate(state);
    };
    //for testing purposes
    const log = async (expression: Expression, dialect: Dialect): Promise<void> => {
        const functionalElement: FunctionElement = functionalContext.factory(expression, dialect);
        const expressionString: string | undefined =
            functionalElement.toString && functionalElement.toString();
        let resultString: EvaluationResult;
        try {
            const result: EvaluationResult = await functionalElement.evaluate(state);
            resultString = result;
            if (Array.isArray(result)) {
                resultString = printArray(result);
            }
            console.log(`${expressionString} -> ${resultString}`);
        } catch (error) {
            resultString = error;
            console.warn(`${expressionString} -> ${resultString}`);
        }
    };
    return {
        evaluate,
        log
    };
};

const printArray = (arr: EvaluationResult[] = []): string => {
    const itemsString: string = arr
        .map((item) => {
            if (item === undefined) return "undefined";
            if (item === null) return "null";
            return item;
        })
        .join();
    return `[${itemsString}]`;
};

export default {
    of: factoryMethod
};
