import { dictionary as defaultDictionary } from "./functionElements";
import { Dialect, Expression, FunctionElement, FunctionElementFactory } from "./types";

export const factoryMethodsDictionary = Object.assign({}, defaultDictionary);

export const addFunctionElements = (
    elements: Record<string, FunctionElementFactory> = {}
): void => {
    Object.assign(factoryMethodsDictionary, elements);
};

export const createFunctionElement = (
    name: string,
    args: any[] = [],
    dialect?: Dialect
): FunctionElement => {
    if (name && factoryMethodsDictionary[name]) {
        args = args.map((item) => factory(item, dialect, factoryMethodsDictionary));
        return factoryMethodsDictionary[name](name, args);
    } else {
        console.warn(`unknown function element name: ${name}`);
        return factoryMethodsDictionary["unknown"]();
    }
};

const resolveDialect = (expression) => {
    if (Array.isArray(expression)) {
        return "list";
    } else {
        return "object";
    }
};
//Expression => null | string | number | boolean | undefined
export const factory = (
    expression: Expression,
    dialect: Dialect | undefined,
    dictionary: any = factoryMethodsDictionary
): FunctionElement => {
    let name: string = "";
    let args: any[] = [];
    const expressionType = typeof expression;
    if (
        expressionType === "string" ||
        expressionType === "number" ||
        expressionType === "boolean"
    ) {
        return dictionary[expressionType](expression);
    }

    if (expression === null) {
        return dictionary["null"]();
    }

    if (expression === undefined) {
        return dictionary["undefined"]();
    }

    if (dialect === undefined) {
        dialect = resolveDialect(expression);
    }
    if (dialect === "list" && Array.isArray(expression)) {
        name = expression[0] || "";
        args = expression.slice(1);
    } else if (dialect === "object" && typeof expression === "object" && expression.name) {
        name = expression.name || "";
        args = expression.args;
    }
    if (name && dictionary[name]) {
        return createFunctionElement(name, args, dialect);
    } else {
        console.warn(`unknown function element name: ${name}`);
        return createFunctionElement("unknown");
    }
};

export default {
    addFunctionElements,
    factory,
    createFunctionElement,
    factoryMethodsDictionary
};
