"use client";
import React from "react";
import PropTypes from "prop-types";
import {
    createDate,
    toIsoDateString,
    getLocaleMonths,
    getLocaleWeeks,
    getLocalizedMonthCalendarDataToDisplay,
    isWeekday
} from "@uneo/platform-commons/platform/utils/dateUtils";

export default class CalendarBody extends React.Component {
    isSelected(date) {
        let dateString = toIsoDateString(date);
        return !!this.props.selection.find((item) => {
            let itemString = toIsoDateString(item);
            return itemString === dateString;
        });
    }

    calculateDateClass(targetDate, allDates, allowed) {
        let dayClass = "";
        if (this.isSelected(targetDate)) {
            let previousDate = createDate(targetDate).setUTCDate(targetDate.getUTCDate() - 1);
            let nextDate = createDate(targetDate).setUTCDate(targetDate.getUTCDate() - 1);

            if (this.isSelected(previousDate) && this.isSelected(nextDate)) {
                dayClass = "tq-selected";
            } else if (this.isSelected(previousDate)) {
                dayClass = "tq-selected tq-end";
            } else if (this.isSelected(nextDate)) {
                dayClass = "tq-selected tq-start";
            } else {
                dayClass = "tq-selected tq-single";
            }
        }
        if (
            targetDate.getUTCMonth() === allDates[0].getUTCMonth() ||
            targetDate.getUTCMonth() === allDates[allDates.length - 1].getUTCMonth()
        ) {
            dayClass += " tq-out-of-reach";
        } else {
            if (!isWeekday(targetDate)) {
                dayClass += " tq-holiday";
            }
            const today = new Date();
            if (
                targetDate.getUTCFullYear() === today.getFullYear() &&
                targetDate.getUTCMonth() === today.getMonth() &&
                targetDate.getUTCDate() === today.getDate()
            ) {
                dayClass += " tq-today";
            }
        }
        if (!allowed) {
            dayClass += " tq-disabled";
        }

        return dayClass;
    }

    calculateMonthItemClass(monthStart, monthEnd, isAllowed) {
        let result = "tq-calendar-button";

        if (!isAllowed) {
            result += " tq-disabled";
        }
        let selectionInMonth = this.props.selection.filter((item) => {
            let itemDate = createDate(item);
            return itemDate <= monthEnd && itemDate >= monthStart;
        });
        if (selectionInMonth.length) {
            result += " tq-calendar-button-events";
        }

        return result;
    }

    calculateYearItemClass(yearStart, yearEnd, isAllowed) {
        let result = "tq-calendar-button";
        if (!isAllowed) {
            result += " tq-disabled";
        }
        let selectionInYear = this.props.selection.filter((item) => {
            let itemDate = createDate(item);
            return itemDate <= yearEnd && itemDate >= yearStart;
        });
        if (selectionInYear.length) {
            result += " tq-calendar-button-events";
        }

        return result;
    }

    buildContent() {
        let content;
        if (this.props.calendarMode === "days") {
            let localizedMonthData = getLocalizedMonthCalendarDataToDisplay(
                this.props.locale,
                this.props.referenceDate
            );

            let weekNames = getLocaleWeeks(this.props.locale, "short");

            weekNames = weekNames.map((day, index) => {
                let dayClass = "";
                if (!day.isWeekday) {
                    dayClass = "tq-holiday";
                }
                return (
                    <th className={dayClass} key={index}>
                        <span className="tq-text-node ">{day.name}</span>
                    </th>
                );
            });
            let currentMonthAdjustment = -1;
            let weekDates = localizedMonthData.map((week) => {
                return week.map((weekDay) => {
                    if (weekDay === "1") {
                        currentMonthAdjustment++;
                    }
                    let weekDate = createDate(this.props.referenceDate);
                    weekDate.setUTCMonth(weekDate.getUTCMonth() + currentMonthAdjustment);
                    weekDate.setUTCDate(weekDay);
                    weekDate = createDate(weekDate);
                    return weekDate;
                });
            });
            let weekFlat = weekDates.flat();
            let allowedDates = Array.from(weekFlat);
            if (this.props.filterAllowedDates) {
                const filter = this.props.filterAllowedDates.func;
                const filterOptions = this.props.filterAllowedDates.options;
                allowedDates = filter(allowedDates, filterOptions, "day");
            }
            let weeks = weekDates.map((week, weekIndex) => {
                let weekJSX = week.map((weekDate, dayIndex) => {
                    let allowed = !!allowedDates.find(
                        (date) => toIsoDateString(date) === toIsoDateString(weekDate)
                    );
                    let dayClass = this.calculateDateClass(weekDate, weekFlat, allowed);
                    return (
                        <td
                            className={dayClass}
                            key={dayIndex}
                            data-id={toIsoDateString(weekDate)}
                            onClick={allowed ? this.props.selectDate : undefined}
                        >
                            <span className="tq-text-node ">{weekDate.getUTCDate()}</span>
                        </td>
                    );
                });

                return <tr key={weekIndex}>{weekJSX}</tr>;
            });
            content = (
                <div className="tq-flex-item">
                    <table>
                        <thead>
                            <tr>{weekNames}</tr>
                        </thead>
                        <tbody>{weeks}</tbody>
                    </table>
                </div>
            );
        }

        if (this.props.calendarMode === "months") {
            let monthNamesLength = "short";
            if (this.props.longMonthNames) {
                monthNamesLength = "long";
            }
            let months = getLocaleMonths(this.props.locale, monthNamesLength);

            let monthStartArray = [];
            let monthEndArray = [];
            for (let i = 0; i < 12; i++) {
                let monthStart = createDate(this.props.referenceDate);
                monthStart.setUTCMonth(i);
                monthStart.setUTCDate(1);
                let monthEnd = createDate(monthStart);
                monthEnd.setUTCMonth(i + 1);
                monthEnd.setUTCDate(0);
                monthStartArray.push(monthStart);
                monthEndArray.push(monthEnd);
            }

            content = monthStartArray.map((firstDayOfMonth, index) => {
                let monthStart = monthStartArray[index];
                let monthEnd = monthEndArray[index];
                let isAllowed = true;
                if (this.props.filterAllowedDates) {
                    const filter = this.props.filterAllowedDates.func;
                    const filterOptions = this.props.filterAllowedDates.options;
                    isAllowed = filter([monthStart, monthEnd], filterOptions, "month");
                }
                let buttonClass = this.calculateMonthItemClass(monthStart, monthEnd, isAllowed);

                return (
                    <div
                        className="tq-flex-item"
                        key={"month" + index}
                        data-id={toIsoDateString(firstDayOfMonth)}
                        onClick={isAllowed ? this.props.selectMonth : undefined}
                    >
                        <button className={buttonClass}>{months[index]}</button>
                    </div>
                );
            });
        }

        if (this.props.calendarMode === "years") {
            let startingFrom = this.props.referenceDate.getUTCFullYear() - 5;

            let yearStartArray = [];
            let yearEndArray = [];
            for (let year = startingFrom; year < startingFrom + 12; year++) {
                let yearStart = createDate(this.props.referenceDate);
                yearStart.setUTCFullYear(year);
                yearStart.setUTCMonth(0);
                yearStart.setUTCDate(1);
                yearStartArray.push(yearStart);
                let yearEnd = createDate(yearStart);
                yearEnd.setUTCFullYear(year + 1);
                yearEnd.setUTCMonth(0);
                yearEnd.setUTCDate(0);
                yearEndArray.push(yearEnd);
            }

            content = yearStartArray.map((firstDayOfYear, index) => {
                let year = firstDayOfYear.getUTCFullYear();
                let yearStart = yearStartArray[index];
                let yearEnd = yearEndArray[index];
                let isAllowed = true;
                if (this.props.filterAllowedDates) {
                    const filter = this.props.filterAllowedDates.func;
                    const filterOptions = this.props.filterAllowedDates.options;
                    isAllowed = filter([yearStart, yearEnd], filterOptions, "year");
                }
                let buttonClass = this.calculateYearItemClass(yearStart, yearEnd, isAllowed);

                return (
                    <div
                        className="tq-flex-item"
                        key={"year" + index}
                        data-id={toIsoDateString(firstDayOfYear)}
                        onClick={isAllowed ? this.props.selectYear : undefined}
                    >
                        <button className={buttonClass}>{year}</button>
                    </div>
                );
            });
        }

        return content;
    }

    render() {
        let content = this.buildContent();
        return (
            <div className="tq-dates">
                <div className="tq-flex-container tq-flex-item-l-4 tq-flex-item-m-2 tq-flex-item-s-1 tq-flex-item-stretch">
                    {content}
                </div>
            </div>
        );
    }
}

CalendarBody.displayName = "CalendarBody";

CalendarBody.propsTypes = {
    locale: PropTypes.string,
    referenceDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    calendarMode: PropTypes.oneOf(["days", "months", "years"]),
    selection: PropTypes.array,
    selectDate: PropTypes.func,
    selectMonth: PropTypes.func,
    selectYear: PropTypes.func,
    filterAllowedDates: PropTypes.shape({ func: PropTypes.func, options: PropTypes.object }),
    longMonthNames: PropTypes.bool
};

//* --- Prepared Interface
// interface ICalendarBody {
//     locale?: string;
//     referenceDate: string | Date;
//     calendarMode?: "days" | "months" | "years";
//     selection: string[];
//     selectDate: (...args: any[]) => any | void;
//     selectMonth: (event: React.event) => void;
//     selectYear: (event: React.event) => void;
//     filterAllowedDates?: { func: (...args: any[]) => any | void, options: Record<string, any> };
//     longMonthNames?: boolean;
// }
