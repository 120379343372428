import React from "react";
import Checkbox, { ICheckboxPropKeys } from "./Checkbox";
import { extractComponentProps } from "../../../utils/utils";

/**
 * Component that renders only SINGLE checkbox, which supports rich text in it and triggers actions on a given entity
 *
 */
export default function CheckboxContainer(props) {
    const onClickRichLabel = () => {
        if (props.onClickRichLabel) {
            props.onClickRichLabel();
        }
    };

    const checkBoxProps: any = extractComponentProps(props, {
        componentProps: ICheckboxPropKeys,
        propsDefaults: {
            value: "false"
        }
    });
    checkBoxProps.onClickRichLabel = onClickRichLabel;
    return <Checkbox {...checkBoxProps} />;
}
