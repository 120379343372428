import React from "react";
import Dropdown, { IDropdownPropsKeys, IDropdownProps } from "./Dropdown";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";
import { SirenEntity } from "../../../utils/commonTypes";
import { IDebounce } from "../../../types";

export default function DropdownContainer(props) {
    const extractedProps: IDropdownProps & IDebounce = extractComponentProps(props, {
        componentProps: IDropdownPropsKeys,
        additionalProps: ["debounce"],
        propsDefaults: {
            extraClasses: "",
            filterStrategy: "includes",
            multiSelection: false,
            optionsWithCheckboxes: true,
            value: "",
            popoverMinWidthToAnchor: true,
            clearSuggestionInputOnFocus: true
        }
    });

    const debounceOptions = extractedProps.debounce || {};
    const debounceTimeout: number = debounceOptions.wait || 200;
    const debounceImmediate: boolean = !!debounceOptions.immediate;

    const onChangeSuggestionDebounced = debounce(
        (state): void => {
            if (props.onChangeSuggestionValue) {
                props.onChangeSuggestionValue(state);
            }
        },
        debounceTimeout,
        debounceImmediate
    );

    const onChange = (value): void => {
        if (props.onChange) {
            props.onChange({ value });
        }
    };

    const onChangeSuggestionValue = (state): void => {
        if (extractedProps.debounce) {
            onChangeSuggestionDebounced(state);
        } else if (props.onChangeSuggestionValue) {
            props.onChangeSuggestionValue(state);
        }
    };

    const onBlur = (state): void => {
        if (props.onBlur) {
            props.onBlur(state);
        }
    };

    const onFocus = (state): void => {
        if (props.onFocus) {
            props.onFocus(state);
        }
    };

    const onOpen = (state): void => {
        if (props.onOpen) {
            props.onOpen(state);
        }
    };

    const onClose = (state): void => {
        if (props.onClose) {
            props.onClose(state);
        }
    };

    const buildItems: any = () => {
        const itemsData: SirenEntity[] = props.entities || [];
        return itemsData.map((item) => item.properties);
    };

    extractedProps.options = buildItems();

    extractedProps.onChangeSuggestionValue = onChangeSuggestionValue;
    extractedProps.onChange = onChange;
    extractedProps.onBlur = onBlur;
    extractedProps.onFocus = onFocus;
    extractedProps.onOpen = onOpen;
    extractedProps.onClose = onClose;

    return <Dropdown {...extractedProps} />;
}
