"use client";
import React from "react";
import { xssFilter } from "../../../utils/xssFilter";
import {
    IBaseComponentProps,
    IBaseComponentPropsKeys,
    IEventHandlers,
    IEventHandlersKeys
} from "../../../types";

export default function Checkbox(props: ICheckboxProps) {
    const value = props.value === "true";
    function onKeyDown(event) {
        if (event.key === "Enter") {
            onChange(event);
        }
    }

    function onChange(event) {
        if (!props.disabled) {
            event.stopPropagation();
            props.onChange && props.onChange({ value: value ? "false" : "true" });
        }
    }

    function onClickRichLabel(event) {
        const target = event.target;
        const href = target.getAttribute("href");
        if (target.tagName === "A" && href !== null) {
            event.preventDefault();
            if (props.onClickRichLabel) {
                props.onClickRichLabel(event);
            }
        } else {
            if (!props.disabled) {
                onChange(event);
            }
        }
    }

    const extraClasses = props.extraClasses || "";
    let containerClass = extraClasses + " tq-checkbox-option";
    containerClass += value ? " tq-selected" : "";
    containerClass += props.disabled ? " tq-disabled" : "";
    containerClass += props.valid === false ? " tq-has-error" : "";
    const label = props.label ? props.label : props.value;
    const richLabel = props.richLabel && xssFilter.process(props.richLabel);
    let tabIndex;
    if (!props.disabled) {
        tabIndex = "0";
    }

    return (
        <div
            className={containerClass}
            tabIndex={tabIndex}
            onKeyDown={onKeyDown}
            onClick={richLabel ? onClickRichLabel : onChange}
        >
            <div className="tq-checkbox">
                <input
                    className="tq-checkbox"
                    type="checkbox"
                    disabled={props.disabled}
                    value={value.toString()}
                    tabIndex={-1}
                />
            </div>
            {richLabel && (
                <span className="tq-text-node" dangerouslySetInnerHTML={{ __html: richLabel }} />
            )}
            {!richLabel && <span className="tq-text-node">{label}</span>}
        </div>
    );
}

interface ICheckboxProps extends IBaseComponentProps, IEventHandlers {
    valid?: boolean;
    disabled?: boolean;
    label?: string;
    richLabel?: string;
    onClickRichLabel?: (...args: any[]) => any | void;
}

export const ICheckboxPropKeys = [
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    "valid",
    "disabled",
    "label",
    "richLabel",
    "onClickRichLabel"
];
