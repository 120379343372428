import React from "react";

import { AuthWidgets } from "@uneo/platform-auth/AuthWidgets";
import { OavWidgets } from "@uneo/platform-oav/OavWidgets";
import { PortalWidgets } from "@uneo/platform-portal/PortalWidgets";
import { MemberWidgets } from "@uneo/platform-member/MemberWidgets";
import { CypressWidgets } from "../cypress/CypressWidgets";

const widgetViews: Record<string, React.FC<any>> = {
    ...PortalWidgets,
    ...AuthWidgets,
    ...OavWidgets,
    ...MemberWidgets,
    ...CypressWidgets
};

export default widgetViews;
