export function createMultipartFormData(data) {
    const body = new FormData();
    body.append("content", JSON.stringify(data.content));
    data.files.forEach((file) => {
        if (file instanceof File) {
            body.append("files[]", file, file.name);
        }
    });
    return body;
}
