import { getEntity, getParentEntityPath } from "@uneo/platform-commons/webui/store/actions";
import { Action, ActionContext, Entity } from "@uneo/platform-commons/webui/types";
import { callAction } from "../actions/callAction";
import {
    getEntityChildren,
    getEntityProps,
    getEntityValue
} from "@uneo/platform-commons/webui/search";
import { EvaluationModel, EvaluationResult } from "./../services/evaluation/types";
import evaluationContext, {
    FactoryFunctionElement
} from "./../services/evaluation/evaluationContext";
import getApplicationRulesEvaluation, {
    ApplicationRulesFactoryElement
} from "./../services/applicationRules/evaluateApplicationRules";
import { ApplicationRule } from "../services/applicationRules/types";
/**  ---- Type definitions ----- */
//TODO: to be placesd in separate files

export type Answer = string | number | boolean;

export type QuestionsModel = Record<string, Record<string, Answer>>;
/**  ---- Type definitions ----- */

const equalContexts = (a: Record<string, any> | undefined, b: Record<string, any> | undefined) => {
    if (
        (!a && !b) ||
        (a?.entityId &&
            b?.entityId &&
            a.entityId === b.entityId &&
            a.context?.role[0] &&
            b.context?.role[0] &&
            a.context?.role[0] === b.context?.role[0])
    ) {
        return true;
    } else {
        return false;
    }
};
const getAllPaths = (entity, path) => {
    const resultPaths = [];
    const searchStore = (storeEntity) => {
        if (storeEntity.contextPath?.includes(path)) {
            resultPaths.push(storeEntity.contextPath);
        }
        if (storeEntity.entities) {
            storeEntity.entities.forEach((innerEntity) => {
                searchStore(innerEntity);
            });
        }
    };
    searchStore(entity);

    return resultPaths;
};

export const getEvaluationModel = (
    widgetId: string,
    entityContext?: Record<string, any>
): EvaluationModel<QuestionsModel> => {
    const questions: QuestionsModel = {};
    const model: EvaluationModel<QuestionsModel> = { context: questions };
    const entity = getEntity({ widgetId, entityPath: widgetId });
    const allQuestionPaths: string[] = getAllPaths(entity, "questions");
    if (allQuestionPaths.length) {
        const questionsData: Entity[] = allQuestionPaths
            .filter((path) => path.includes(".flow."))
            .filter((path) => {
                // const parentPath = webui.getActionByName("utils.getParentPath")(path);
                const parentPath = getParentEntityPath({ widgetId, entityPath: path });
                const itemContext =
                    entity && parentPath && getEntityProps(entity, parentPath, "entity");

                return equalContexts(itemContext, entityContext);
            })
            // .map((path) => dispatch(webui.getActionByName("utils.getEntityChildren")(path)))
            .map((path) => entity && getEntityChildren(entity, path))
            .flat();

        questionsData.forEach((questionData: Entity) => {
            if (questionData.properties?.hiddenQuestion === true) {
                return;
            }
            const answers: Record<string, Answer> = {};
            getEntityChildren(questionData, `${questionData.id}.answers`).forEach((childEntity) => {
                answers[childEntity.id] = getEntityValue(childEntity, childEntity.id);
            });
            questions[questionData.id] = answers;
        });
    }
    return model;
};

// export const evaluateCondition = async (sirenAction, widgetId, currentPath) => {
export const evaluateCondition = async (action: Action, context?: ActionContext) => {
    let entityContext: Record<string, any> | undefined;
    const widgetId: string = context.widgetId;
    const currentPath: string = context.currentEntityPath;
    const params: [string, any?] = [widgetId];
    if (currentPath) {
        let path: string = currentPath;
        // Traverse the tree searching for entityContext
        while (!entityContext && path.split(".").length > 1) {
            path = getParentEntityPath({ widgetId, entityPath: path }) || "";
            const entity: Entity | false = getEntity({ widgetId, entityPath: path });
            entityContext = entity && entity.properties?.entity;
            if (entityContext) {
                params.push(entityContext);
            }
        }
    }
    const widgetContext: EvaluationModel<QuestionsModel> = getEvaluationModel(...params);

    const newEvaluation: FactoryFunctionElement = evaluationContext.of(widgetContext);
    const newApplicationRulesEvaluation: ApplicationRulesFactoryElement =
        getApplicationRulesEvaluation();
    let expression = null;
    if (action?.properties && action?.properties.expression !== undefined) {
        expression = action.properties.expression;
    }
    const result: EvaluationResult = await newEvaluation.evaluate(expression);

    const applicationRules: ApplicationRule[] = action.properties?.applicationRules || null;
    let applicationRulesResult: EvaluationResult;
    if (applicationRules) {
        applicationRulesResult = await newApplicationRulesEvaluation.evaluate(applicationRules);
    } else {
        applicationRulesResult = true;
    }
    if (!result) {
        throw new Error("evaluateCondition returns: " + result);
    }
    if (!applicationRulesResult) {
        throw new Error("evaluateCondition => applicationRule returns: " + applicationRulesResult);
    }

    return !!result && !!applicationRulesResult;
};
//executes questionnaire logic, after each iteration checks whether any changes ocurred
//continues repeating questionnaire logic until no more changes are made or MAX_LOGIC_ITERATIONS reached
const MAX_LOGIC_ITERATIONS = 50;

function isObject(object) {
    return object !== null && typeof object === "object";
}

const areDeepEqual = (first, second): boolean => {
    const keys1: string[] = Object.keys(first);
    const keys2: string[] = Object.keys(second);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const firstValue: any = first[key];
        const secondValue: any = second[key];
        const areObjects: boolean = isObject(firstValue) && isObject(secondValue);
        if (
            (areObjects && !areDeepEqual(firstValue, secondValue)) ||
            (!areObjects && firstValue !== secondValue)
        ) {
            return false;
        }
    }

    return true;
};
export const applyQuestionnaireLogic = async (widgetId, path) => {
    let statusChanges = true;
    let iterations = 0;
    while (statusChanges && iterations < MAX_LOGIC_ITERATIONS) {
        console.log("Questionnaire logic iteration: " + iterations);
        //extract current data state of questionnaire widget
        const currentState = getEntity({ widgetId, entityPath: widgetId });
        await callAction(widgetId, currentState, {
            targets: [path],
            events: ["evaluate"],
            recursive: true
        });
        const newState = getEntity({ widgetId, entityPath: widgetId });
        //comparing both states (prev entity and the changed entity) using direct comparison of Immutable objects
        statusChanges = !areDeepEqual(currentState, newState);
        ++iterations;
    }
};
