"use client";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { findEntityById, getEntityValue } from "@uneo/platform-commons/webui/search";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { Entity } from "@uneo/platform-commons/webui/types";
import ConfirmModal from "../confirmModal/ConfirmModal";
import LoaderButton from "../Button/LoaderButton";

export interface ActionSectionConfiguration {
    onSubmit?: any;
    onBack?: any;
    onCancel?: any;
    readyForSubmit?: boolean;
    extraClasses?: string;
    submitLabel?: any;
    children?: React.ReactNode | React.ReactNode[];
    closeModalLabel?: string;
    confirmModalLabel?: string;
    modalTitle?: string;
    modalText?: string;
    submitButtonExtraClasses?: string;
}
interface ActionSectionProps extends ActionSectionConfiguration {
    data: Entity;
}
export default function ActionSection({
    data,
    onSubmit,
    onBack,
    onCancel,
    readyForSubmit = true,
    extraClasses = "",
    submitLabel,
    closeModalLabel,
    confirmModalLabel,
    modalTitle,
    modalText,
    submitButtonExtraClasses = "",
    children
}: ActionSectionProps) {
    const [confirmOnCancelModal, setConfirmOnCancelModal] = useState(false);
    const { t } = useTranslation();
    const confirmOnCancel = !!findEntityById(data, "process-cancellation-modal");
    const cancelButton = findEntityById(data, "cancel");
    const backButton = findEntityById(data, "back");
    const submitButton = findEntityById(data, "submit");
    const { triggerEntityActions } = useWidgetContext();
    const modalRealTitle = modalTitle || getEntityValue(data, "process-cancellation-modal.title");
    const modalRealText = modalText || getEntityValue(data, "process-cancellation-modal.text");
    const closeModalRealLabel =
        closeModalLabel ||
        getEntityValue(data, "process-cancellation-modal.do-not-abandon-process");
    const confirmModalRealLabel =
        confirmModalLabel || getEntityValue(data, "process-cancellation-modal.abandon-process");
    return (
        <div className={"un-flow-actions"}>
            <div className={`un-ds-action-section-center ${extraClasses}`}>
                {cancelButton && (
                    <button
                        className="tq-hyperlink un-ds-hyperlink tq-main-color tq-text-center"
                        onClick={(event) => {
                            event.preventDefault();
                            if (confirmOnCancel) {
                                setConfirmOnCancelModal(true);
                            } else {
                                triggerEntityActions(cancelButton);
                            }
                            if (onCancel) {
                                onCancel();
                            }
                        }}
                    >
                        <span className="tq-hyperlink-z-index">
                            {cancelButton.properties?.value || t("flow.cancel")}
                        </span>
                    </button>
                )}

                {confirmOnCancelModal && (
                    <ConfirmModal
                        onClose={() => setConfirmOnCancelModal(false)}
                        onConfirm={() => {
                            triggerEntityActions(cancelButton);
                        }}
                        closeButtonLabel={closeModalRealLabel}
                        confirmButtonLabel={confirmModalRealLabel}
                        title={modalRealTitle}
                        text={modalRealText}
                    />
                )}
                <LoaderButton
                    entity={backButton}
                    label={backButton?.properties?.value || t("flow.back")}
                    extraClasses="un-ds-button-outline"
                    onClick={() => {
                        if (onBack) {
                            onBack();
                        }
                        return triggerEntityActions(backButton);
                    }}
                />
                {children}
                <LoaderButton
                    entity={submitButton}
                    label={
                        submitButton.properties?.value ||
                        (submitLabel ? submitLabel : t("flow.submit"))
                    }
                    disabled={!readyForSubmit}
                    extraClasses={submitButtonExtraClasses || "un-ds-button-solid"}
                    onClick={onSubmit ? onSubmit : () => triggerEntityActions(submitButton)}
                />
            </div>
        </div>
    );
}
