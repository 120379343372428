import React from "react";
import { getEntityProps } from "@uneo/platform-commons/webui/search";
import ProfileMenuSidebar from "../profile-menu/ProfileMenuSidebar";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";
import MainSidebar from "./MainSidebar";
import { CONTENT_ROOT_WIDGET_ID } from "@uneo/platform-commons/platform/constants";

export default function PortalSidebar() {
    const { data: contentRootData } = useWidgetDataSelector(CONTENT_ROOT_WIDGET_ID);
    const navigationInfo = getEntityProps(contentRootData, "navigation") || {};
    const { headerMenuLinkId, profileMenuLinkId } = navigationInfo;
    const profileMenuShowOnlyTitle = !profileMenuLinkId && !!headerMenuLinkId;
    return (
        <nav className="un-sidebar-nav" role="navigation">
            <ul
                className="tq-list tq-borders tq-selectable un-secondary-menu"
                // TODO: move to styles
                style={{ marginTop: "1rem" }}
            >
                <ProfileMenuSidebar showOnlyTitle={profileMenuShowOnlyTitle} />
                {!!headerMenuLinkId && <MainSidebar />}
            </ul>
        </nav>
    );
}
