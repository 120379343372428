"use client";
import React from "react";
import PropTypes from "prop-types";
import { getLocaleMonth } from "@uneo/platform-commons/platform/utils/dateUtils";

export default class CalendarHeader extends React.Component {
    buildContent() {
        let content;
        switch (this.props.calendarMode) {
            case "days":
                content = [];
                content.push(
                    <h4 key={"upperHeader"}>
                        <button className="text-node" onClick={this.props.switchToYears}>
                            {this.props.referenceDate.getUTCFullYear()}
                        </button>
                        <span className="tq-prev" onClick={this.props.decrementYears} />
                        <span className="tq-next" onClick={this.props.incrementYears} />
                    </h4>
                );
                content.push(
                    <h3 key={"lowerHeader"}>
                        <button className="text-node" onClick={this.props.switchToMonths}>
                            {getLocaleMonth(this.props.locale, this.props.referenceDate)}
                        </button>
                        <span className="tq-prev" onClick={this.props.decrementMonths} />
                        <span className="tq-next" onClick={this.props.incrementMonths} />
                    </h3>
                );
                break;
            case "months":
                content = (
                    <h3>
                        <button className="text-node" onClick={this.props.switchToYears}>
                            {this.props.referenceDate.getUTCFullYear()}
                        </button>
                        <span className="tq-prev" onClick={this.props.decrementYears} />
                        <span className="tq-next" onClick={this.props.incrementYears} />
                    </h3>
                );
                break;
            case "years":
                content = (
                    <h3>
                        <button className="text-node">
                            {this.props.referenceDate.getUTCFullYear() - 5}
                            {" - "}
                            {this.props.referenceDate.getUTCFullYear() + 6}
                        </button>
                        <span className="tq-prev" onClick={this.props.decrementYearsPage} />
                        <span className="tq-next" onClick={this.props.incrementYearsPage} />
                    </h3>
                );
                break;
            default:
                break;
        }
        return content;
    }

    render() {
        let content = this.buildContent();
        return <header className="tq-datepicker-header">{content}</header>;
    }
}

CalendarHeader.displayName = "CalendarHeader";

CalendarHeader.propsTypes = {
    locale: PropTypes.string,
    referenceDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    calendarMode: PropTypes.oneOf(["days", "months", "years"]),
    switchToYears: PropTypes.func,
    decrementYears: PropTypes.func,
    incrementYears: PropTypes.func,
    switchToMonths: PropTypes.func,
    decrementMonths: PropTypes.func,
    incrementMonths: PropTypes.func,
    decrementYearsPage: PropTypes.func,
    incrementYearsPage: PropTypes.func
};

//* --- Prepared Interface
// interface ICalendarHead {
//     locale?: string;
//     referenceDate: string | Date;
//     calendarMode: "days" | "months" | "years";
//     switchToYears: () => void;
//     decrementYears: () => void;
//     incrementYears: () => void;
//     switchToMonths: () => void;
//     decrementMonths: () => void;
//     incrementMonths: () => void;
//     decrementYearsPage: () => void;
//     incrementYearsPage: () => void;
// }
