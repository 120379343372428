window.tarteaucitronConsents = {};
const tarteaucitron = window.tarteaucitron || { services: {} };
const giveConsent = (key, accepted) => {
    window.tarteaucitronConsents[key] = {
        accepted
    };
    const event = new CustomEvent("tarteaucitronConsentChange", {
        detail: window.tarteaucitronConsents
    });
    document.dispatchEvent(event);
};

tarteaucitron.services.googlemaps = {
    key: "googlemaps",
    type: "api",
    name: "Google Maps",
    uri: "#",
    text: tarteaucitron.lang["engage-addthis"],
    needConsent: true,
    cookies: [1],
    js: function () {
        giveConsent("googlemaps", true);
    },
    fallback: function () {
        giveConsent("googlemaps", false);
    }
};

tarteaucitron.services.matomo = {
    key: "matomo",
    type: "analytic",
    name: "Matomo (précédemment connu comme Piwik)",
    uri: "#",
    text: tarteaucitron.lang["engage-addtoanyfeed"],
    needConsent: true,
    cookies: ["_pk_ref", "_pk_cvar", "_pk_id", "_pk_ses", "_pk_hsr", "piwik_ignore", "_pk_uid"],
    js: function () {
        giveConsent("matomo", true);
    },
    fallback: function () {
        giveConsent("matomo", false);
    }
};

tarteaucitron.services.youtube = {
    key: "youtube",
    type: "video",
    name: "YouTube",
    uri: "#",
    text: tarteaucitron.lang["engage-addtoanyshare"],
    needConsent: true,
    cookies: ["VISITOR_INFO1_LIVE", "YSC", "PREF", "GEUP"],
    js: function () {
        giveConsent("youtube", true);
    },
    fallback: function () {
        giveConsent("youtube", false);
    }
};

tarteaucitron.services.linkedininsighttag = {
    key: "linkedininsighttag",
    type: "video",
    name: "LinkedIn Insight",
    uri: "#",
    text: tarteaucitron.lang["engage-calameo"],
    needConsent: true,
    cookies: ["li_fat_id"],
    js: function () {
        giveConsent("linkedininsighttag", true);
    },
    fallback: function () {
        giveConsent("linkedininsighttag", false);
    }
};

tarteaucitron.services.facebookpixel = {
    key: "facebookpixel",
    type: "video",
    name: "Meta Pixel",
    uri: "#",
    text: tarteaucitron.lang["engage-clicmanager"],
    needConsent: true,
    cookies: [
        "datr",
        "fr",
        "reg_ext_ref",
        "reg_fb_gate",
        "reg_fb_ref",
        "sb",
        "wd",
        "x-src",
        "_fbp"
    ],
    js: function () {
        giveConsent("facebookpixel", true);
    },
    fallback: function () {
        giveConsent("facebookpixel", false);
    }
};
