import React from "react";

export const resolveSlots = (
    propsChildren: React.ReactNode | React.ReactNode[] = []
): Record<string, any> => {
    const slots: Record<string, any> = { default: [] };
    let children: React.ReactNode[];
    if (!Array.isArray(propsChildren)) {
        children = [propsChildren];
    } else {
        children = propsChildren;
    }
    for (let i = 0; i < children.length; i++) {
        let childSlot;
        const child = children[i];
        if (React.isValidElement(child) && child?.props?.slotId) {
            childSlot = child.props.slotId;
        }
        if (childSlot) {
            if (!slots[childSlot]) {
                slots[childSlot] = [child];
            } else {
                slots[childSlot].push(child);
            }
        } else {
            slots["default"].push(child);
        }
    }
    return slots;
};
