export default function isInModal(child) {
    if (child) {
        let node = child.parentNode;
        while (node !== null) {
            if ((" " + node.className + " ").indexOf(" tq-modal-container ") > -1) {
                return true;
            }
            node = node.parentNode;
        }
    }
    return false;
}
