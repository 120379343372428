import React from "react";
//Design example at pf\examples\loading-animations\preloader-page
export default function ContentLoadingAnimation({ isLoading, children }) {
    return (
        <div
            className={
                "un-ds-preloader-container " +
                (isLoading ? "tq-preloader-active tq-preloader-container" : "")
            }
        >
            <div className="tq-preloader-mask tq-load"></div>
            <div className="tq-preloader-item tq-load">
                <div className="un-preloader-main">
                    <div className="un-preloader-dot"></div>
                    <div className="un-preloader-dot"></div>
                    <div className="un-preloader-dot"></div>
                </div>
            </div>
            {children}
        </div>
    );
}
