let tarteaucitron;
const tarteaucitronGroupState = {
    //  group: { enabled: true, services: [{ name: "serviceName", enabled: false }] }
};

export function load(settings) {
    import("./tarteaucitron.20230203.css").then(() => {
        const tarteaucitronScript = document.createElement("script");
        tarteaucitronScript.src = `https://tarteaucitron.io/load.js?locale=fr&domain=${settings.domain}&uuid=${settings.uuid}`;
        tarteaucitronScript.addEventListener("load", function () {
            tarteaucitron = window.tarteaucitron;
            if (tarteaucitron && tarteaucitron.addScript) {
                tarteaucitron.addScript(
                    `${tarteaucitron.cdn}lang/tarteaucitron.${tarteaucitron.getLanguage()}.js?v=${
                        tarteaucitron.version
                    }`,
                    "fr-lang",
                    () => {
                        if (
                            document.readyState === "complete" &&
                            window.alreadyLaunch === 1 &&
                            !document.getElementById("tarteaucitronServices")
                        ) {
                            if (window.addEventListener) {
                                tarteaucitron.initEvents.loadEvent(false);
                            } else {
                                tarteaucitron.initEvents.loadEvent(true);
                            }
                        }

                        afterLoad(settings);
                    }
                );
            }
        });
        document.head.append(tarteaucitronScript);
    });
}

function afterLoad(settings) {
    const afterRenderInterval = setInterval(() => {
        const tarteaucitronServicesDiv = document.getElementById("tarteaucitronServices");
        const translationsArrived = tarteaucitron.lang["engage-addthis"];
        if (tarteaucitronServicesDiv && afterRenderInterval && translationsArrived) {
            clearInterval(afterRenderInterval);
            require("./tarteaucitron.services.js");
            Object.keys(tarteaucitron.services).forEach((service) => {
                tarteaucitron.job.push(tarteaucitron.services[service].key);
            });
            attachFetch(settings);
            removeExtraElements();
            replaceElements();
            addElements();
            fixGroupedLines();
            addClickEvents();
            addGroups();
            addStickyButtons();
            overrideOpenPanel();
        }
    }, 30);
}

function fixGroupedLines() {
    const serviceDiv = document.getElementById("tarteaucitronServices");
    serviceDiv.querySelectorAll('[id$="Line"]').forEach((el) => {
        const key = el.id.replace("Line", "");
        const anchor = el.querySelector("a.tarteaucitronReadmoreInfo");
        anchor.href += `#${key}`;
    });
}

function addClickEvents() {
    tarteaucitron.addClickEventToId("tarteaucitronAllDenied", function () {
        tarteaucitron.userInterface.closePanel();
        setTimeout(() => {
            if (tarteaucitron.reloadThePage === true) {
                window.location.reload();
            }
        }, 50);
    });
    tarteaucitron.addClickEventToId("tarteaucitronAllAllowed", function () {
        tarteaucitron.userInterface.closePanel();
    });
}

function replaceElements() {
    let el1, el2;
    // swap accept all and personalize buttons in first time modal
    el1 = document.getElementById("tarteaucitronPersonalize2");
    el2 = document.getElementById("tarteaucitronCloseAlert");
    insertAfter(el1, el2);

    // swap accept all and deny all buttons
    el1 = document.getElementById("tarteaucitronAllAllowed");
    el2 = document.getElementById("tarteaucitronAllDenied");
    insertAfter(el1, el2);

    // swap allow and deny buttons
    Array.from(document.querySelectorAll("li.tarteaucitronLine .tarteaucitronAsk")).forEach(
        (line) => {
            el1 = line.children[0];
            el2 = line.children[1];
            insertAfter(el1, el2);
        }
    );
}

function removeExtraElements() {
    document
        .querySelectorAll("#tarteaucitronServices ul li .tarteaucitronTitle")
        .forEach((i) => i.remove());
    document
        .querySelector("#tarteaucitronServices .tarteaucitronBorder ul")
        .querySelectorAll("button.tarteaucitron-toggle-group")
        .forEach((i) => (i.innerText = ""));
    document
        .querySelectorAll(".tarteaucitronName .tarteaucitronReadmoreSeparator")
        .forEach((i) => i.remove());
    document.getElementById("tarteaucitronBack").title = "";

    //remove mandatory section
    document.getElementById("tarteaucitronServicesTitle_mandatory").remove();
}

function addGroups() {
    const addSwitcherLabel = (group) => {
        const labelText = tarteaucitron.lang["all"] || "Tout autoriser";
        const groupParent = group.parentNode;
        const label = document.createElement("div");
        label.className = "un-cookies-generic-box";
        label.innerHTML = `<span class="tq-main-color">${labelText}</span>`;
        groupParent.insertBefore(label, group);
    };
    const cloneGroup = (id) => {
        tarteaucitronGroupState[id] = { enabled: undefined, services: [] };
        const wrapperClass = `tarteaucitronServicesTitle_${id}`;
        const wrapper = document.getElementById(wrapperClass);
        const groupId = `tarteaucitron-group-${id}`;
        const group = wrapper.querySelector(`#${groupId}`);
        const clonedGroup = group.cloneNode(true);
        addSwitcherLabel(group);
        clonedGroup.id = `cloned-${clonedGroup.id}`;
        const [clonedGroupDenyBtn, clonedGroupAllowBtn] = Array.from(clonedGroup.children);
        group.style.setProperty("display", "none", "important");
        clonedGroupAllowBtn.id = `cloned-${clonedGroupAllowBtn.id}`;
        clonedGroupAllowBtn.className = `${clonedGroupAllowBtn.className}Cloned`;
        if (
            clonedGroupDenyBtn.ariaPressed === "false" &&
            clonedGroupAllowBtn.ariaPressed === "false"
        ) {
            clonedGroupDenyBtn.ariaPressed = "true";
            clonedGroup.className += " tarteaucitronIsDenied";
            tarteaucitronGroupState[id].enabled = false;
        } else if (clonedGroupAllowBtn.ariaPressed === "true") {
            tarteaucitronGroupState[id].enabled = true;
        }

        clonedGroupDenyBtn.id = `cloned-${clonedGroupDenyBtn.id}`;
        clonedGroupDenyBtn.className = `${clonedGroupDenyBtn.className}Cloned`;

        const groupItemsWrapper = wrapper.querySelector(`#tarteaucitronServices_${id}`);
        Array.from(groupItemsWrapper.children).forEach((item) => {
            const clonedItem = item.cloneNode(true);
            item.style.display = "none";
            clonedItem.id = `cloned-${item.id}`;
            const serviceName = item.id.slice(0, -4);
            const [clonedServiceDenyBtn, clonedServiceAllowBtn] = Array.from(
                clonedItem.querySelector(".tarteaucitronAsk").children
            );
            if (
                !clonedItem.classList.contains("tarteaucitronIsAllowed") &&
                !clonedItem.classList.contains("tarteaucitronIsDenied")
            ) {
                clonedItem.className = "tarteaucitronLine tarteaucitronIsDenied";
                clonedServiceDenyBtn.ariaPressed = "true";
            }
            clonedServiceAllowBtn.className = `${clonedServiceAllowBtn.className}Cloned`;
            clonedServiceDenyBtn.className = `${clonedServiceDenyBtn.className}Cloned`;
            tarteaucitronGroupState[id].services.push({
                name: serviceName,
                enabled: clonedServiceAllowBtn.ariaPressed === "true"
            });

            const onToggle = () => {
                const allow = clonedServiceDenyBtn.ariaPressed === "true";
                if (allow) {
                    clonedItem.className = "tarteaucitronLine tarteaucitronIsAllowed";
                    clonedServiceAllowBtn.ariaPressed = "true";
                    clonedServiceDenyBtn.ariaPressed = "false";
                } else {
                    clonedItem.className = "tarteaucitronLine tarteaucitronIsDenied";
                    clonedServiceAllowBtn.ariaPressed = "false";
                    clonedServiceDenyBtn.ariaPressed = "true";
                }
                tarteaucitronGroupState[id].services.find(
                    (service) => service.name === serviceName
                ).enabled = clonedServiceAllowBtn.ariaPressed === "true";

                const newGroupState = tarteaucitronGroupState[id].services.every(
                    (service) => service.enabled
                );
                tarteaucitronGroupState[id].enabled = newGroupState;
                if (newGroupState) {
                    clonedGroup.className = "tarteaucitronAsk tarteaucitronIsAllowed";
                    clonedGroupAllowBtn.ariaPressed = "true";
                    clonedGroupDenyBtn.ariaPressed = "false";
                } else {
                    clonedGroup.className = "tarteaucitronAsk tarteaucitronIsDenied";
                    clonedGroupDenyBtn.ariaPressed = "true";
                    clonedGroupAllowBtn.ariaPressed = "false";
                }
            };
            clonedServiceAllowBtn.addEventListener("click", onToggle);
            clonedServiceDenyBtn.addEventListener("click", onToggle);

            groupItemsWrapper.appendChild(clonedItem);
        });

        const onToggleGroup = () => {
            const newGroupState = clonedGroupAllowBtn.ariaPressed !== "true";
            if (newGroupState) {
                clonedGroup.className = "tarteaucitronAsk tarteaucitronIsAllowed";
                clonedGroupAllowBtn.ariaPressed = "true";
                clonedGroupDenyBtn.ariaPressed = "false";
                tarteaucitronGroupState[id].enabled = newGroupState;
                tarteaucitronGroupState[id].services.forEach((service) => {
                    service.enabled = newGroupState;
                    const serviceWrapper = groupItemsWrapper.querySelector(
                        `#cloned-${service.name}Line`
                    );
                    serviceWrapper.className = "tarteaucitronLine tarteaucitronIsAllowed";
                    const [clonedServiceDenyBtn, clonedServiceAllowBtn] = Array.from(
                        serviceWrapper.querySelector(".tarteaucitronAsk").children
                    );
                    clonedServiceAllowBtn.ariaPressed = "true";
                    clonedServiceDenyBtn.ariaPressed = "false";
                });
            } else {
                clonedGroup.className = "tarteaucitronAsk tarteaucitronIsDenied";
                clonedGroupDenyBtn.ariaPressed = "true";
                clonedGroupAllowBtn.ariaPressed = "false";
                tarteaucitronGroupState[id].enabled = newGroupState;
                tarteaucitronGroupState[id].services.forEach((service) => {
                    service.enabled = newGroupState;
                    const serviceWrapper = groupItemsWrapper.querySelector(
                        `#cloned-${service.name}Line`
                    );
                    serviceWrapper.className = "tarteaucitronLine tarteaucitronIsDenied";
                    const [clonedServiceDenyBtn, clonedServiceAllowBtn] = Array.from(
                        serviceWrapper.querySelector(".tarteaucitronAsk").children
                    );
                    clonedServiceAllowBtn.ariaPressed = "false";
                    clonedServiceDenyBtn.ariaPressed = "true";
                });
            }
        };
        clonedGroupAllowBtn.addEventListener("click", onToggleGroup);
        clonedGroupDenyBtn.addEventListener("click", onToggleGroup);
        group.parentElement.appendChild(clonedGroup);
    };

    cloneGroup("video");
    cloneGroup("api");
    cloneGroup("analytic");
}

function computeClonedGroupButtonsClasses() {
    const computeGroupClasses = (id) => {
        const wrapperClass = `tarteaucitronServicesTitle_${id}`;
        const wrapper = document.getElementById(wrapperClass);
        const clonedGroup = wrapper.querySelector(`#cloned-tarteaucitron-group-${id}`);
        const [clonedDenyBtn, clonedAllowBtn] = Array.from(clonedGroup.children);

        const groupItemsWrapper = wrapper.querySelector(`#tarteaucitronServices_${id}`);
        tarteaucitronGroupState[id].services.forEach((service) => {
            const realItem = groupItemsWrapper.querySelector(`#${service.name}Line`);
            const [, realServiceAllowBtn] = Array.from(
                realItem.querySelector(".tarteaucitronAsk").children
            );
            const clonedItem = groupItemsWrapper.querySelector(`#cloned-${service.name}Line`);
            const [clonedServiceDenyBtn, clonedServiceAllowBtn] = Array.from(
                clonedItem.querySelector(".tarteaucitronAsk").children
            );
            if (realServiceAllowBtn.ariaPressed === "true") {
                clonedItem.className = "tarteaucitronLine tarteaucitronIsAllowed";
                clonedServiceAllowBtn.ariaPressed = "true";
                clonedServiceDenyBtn.ariaPressed = "false";
                service.enabled = true;
            } else {
                clonedItem.className = "tarteaucitronLine tarteaucitronIsDenied";
                clonedServiceAllowBtn.ariaPressed = "false";
                clonedServiceDenyBtn.ariaPressed = "true";
                service.enabled = false;
            }
        });
        const groupStateBool = tarteaucitronGroupState[id].services.every(
            (service) => service.enabled
        );
        if (groupStateBool) {
            clonedAllowBtn.ariaPressed = "true";
            clonedDenyBtn.ariaPressed = "false";
            clonedGroup.className = "tarteaucitronAsk tarteaucitronIsAllowed";
        } else {
            clonedAllowBtn.ariaPressed = "false";
            clonedDenyBtn.ariaPressed = "true";
            clonedGroup.className = "tarteaucitronAsk tarteaucitronIsDenied";
        }
    };
    computeGroupClasses("video");
    computeGroupClasses("api");
    computeGroupClasses("analytic");
}

function addStickyButtons() {
    const stickyContainer = document.createElement("div");
    stickyContainer.classList.add("un-sticky-btn-cookie-container");
    const submitButton = document.createElement("button");
    const serviceDiv = document.getElementById("tarteaucitronServices");
    submitButton.type = "button";
    submitButton.className = "tq-button un-ds-button un-btn-cookie-choice";
    submitButton.innerText = tarteaucitron.lang["engage-amazon"] || "Enregistrer mes choix";
    submitButton.addEventListener("click", () => {
        Object.keys(tarteaucitronGroupState).forEach((groupId) => {
            tarteaucitronGroupState[groupId].services.forEach((service) => {
                if (service.enabled) {
                    serviceDiv.querySelector(`#${service.name}Line .tarteaucitronAllow`).click();
                } else {
                    serviceDiv.querySelector(`#${service.name}Line .tarteaucitronDeny`).click();
                }
            });
        });
        tarteaucitron.userInterface.closePanel();
        setTimeout(() => {
            if (tarteaucitron.reloadThePage === true) {
                window.location.reload();
            }
        }, 50);
    });
    const allowAllBtn = document.getElementById("tarteaucitronAllAllowed");
    const denyAllBtn = document.getElementById("tarteaucitronAllDenied");
    denyAllBtn.innerText =
        tarteaucitron.lang["engage-aduptech_conversion"] || "AUTORISER LES COOKIES ESSENTIELS";

    stickyContainer.appendChild(denyAllBtn);
    stickyContainer.appendChild(submitButton);
    stickyContainer.appendChild(allowAllBtn);

    serviceDiv.appendChild(stickyContainer);
}

function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function overrideOpenPanel() {
    const openPanel = tarteaucitron.userInterface.openPanel;
    tarteaucitron.userInterface.openPanel = () => {
        computeClonedGroupButtonsClasses();
        openPanel();
    };
}

function attachFetch(settings) {
    const respond = tarteaucitron.userInterface.respond;
    let lastElement;
    const updateUrl = settings["update-url"];
    const sendRequest = () => {
        fetch(updateUrl, {
            method: "post",
            mode: "no-cors",
            credentials: "include"
        }).then();
    };
    let sendRequestTimeout;
    const addRequest = () => {
        if (sendRequestTimeout !== undefined) {
            clearTimeout(sendRequestTimeout);
        }
        sendRequestTimeout = setTimeout(() => {
            sendRequest();
        }, 20);
    };
    window.tarteaucitron.userInterface.respond = function () {
        respond(arguments[0], arguments[1]);
        if (arguments[0] !== lastElement && updateUrl) {
            addRequest();
            lastElement = arguments[0];
        }
    };
}

function addElements() {
    Object.keys(tarteaucitron.services).forEach((service) => {
        const statusDiv = document.querySelector(`#${service}Line .tarteaucitronStatusInfo`);
        const textContent = document.createElement("p");
        textContent.innerHTML = tarteaucitron.services[service].text;
        insertAfter(textContent, statusDiv);
    });
}
