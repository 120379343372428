"use client";
import React, { useEffect } from "react";
import { findEntityById } from "@uneo/platform-commons/webui/search";
import { Entity, Widget } from "@uneo/platform-commons/webui/types";
import { useFetchWidgetData } from "@uneo/platform-commons/platform/hooks";
import Hyperlink from "@uneo/platform-commons/platform/components/Hyperlink/Hyperlink";

export default function AuthHeader({ info }: { info: Widget }) {
    const { data } = useFetchWidgetData(info);

    useEffect(() => {
        document.body.classList.add("auth-module");
        return function cleanup() {
            document.body.classList.remove("auth-module");
        };
    }, []);

    const logo: Entity = findEntityById(data, "logo");
    return (
        <header id="auth-header" className="tq-header un-header-sso tq-flex">
            <div className="tq-logo-box">
                {logo.properties && (
                    <Hyperlink entity={logo} extraClasses="tq-logo">
                        {/*<Image {...logoProps} />*/}
                        <img {...logo.properties} />
                    </Hyperlink>
                )}
            </div>
        </header>
    );
}
