import React from "react";

export default function DropdownSelection(props) {
    function getItemText() {
        if (props.autosuggestion) {
            let valueToDisplay = "";
            if (props.suggestionValue !== null) {
                valueToDisplay = props.suggestionValue;
            } else if (props.value.length) {
                const option = props.options.find((item) => item.value === props.value[0]) || {};
                valueToDisplay =
                    option.selectedLabel || option.label || option.value || props.value[0];
            }
            return (
                <input
                    tabIndex={-1}
                    value={valueToDisplay}
                    placeholder={props.placeholder}
                    onChange={props.onChangeSuggestionValue}
                    ref={props.suggestionRef}
                    disabled={props.disabled}
                    type="text"
                    className="tq-input"
                />
            );
        } else {
            let text = props.placeholder || "";
            let className = "tq-selected-item";
            const options = props.options;
            if (props.value.length) {
                const option = options.find((item) => item.value === props.value[0]) || {};
                text = option.selectedLabel || option.label || option.value || "";
                if (text === "") {
                    console.error("Missing option " + props.value[0]);
                }
            } else {
                className = `${className} tq-no-selection-dropdown`;
            }
            return <span className={className}>{text}</span>;
        }
    }

    function getSelectedItems() {
        return props.value.map((itemCode) => {
            const optionItem = props.options.find((item) => item.value === itemCode);
            if (optionItem?.value) {
                return (
                    <li
                        key={"pill-" + optionItem.value}
                        className="tq-pill"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        {optionItem?.selectedLabel || optionItem?.label || optionItem.value}
                        <i
                            tabIndex={-1}
                            data-target="deleteX"
                            data-code={optionItem.value}
                            className="tq-icon-close"
                        />
                    </li>
                );
            }
        });
    }

    if (props.multiSelection) {
        if (props.value.length) {
            return (
                <ul ref={props.pillsRef} className="tq-pills-list">
                    {getSelectedItems()}
                </ul>
            );
        }
        return <span className="tq-selected-item">{props.placeholder || ""}</span>;
    }
    return getItemText();
}
