"use client";
import React, { lazy } from "react";

// const AuthHeader = lazy(() => import("./widgets/auth-header/AuthHeader")) as React.FC;
import AuthHeader from "./widgets/auth-header/AuthHeader";
const AuthError = lazy(() => import("./widgets/auth-error/AuthError")) as React.FC;
const AuthMemberOnboardingIdentification = lazy(
    () =>
        import(
            "./widgets/onboarding-member/auth-member-onboarding-identification/AuthMemberOnboardingIdentification"
        )
) as React.FC;
const AuthMemberOnboardingCredentials = lazy(
    () =>
        import(
            "./widgets/onboarding-member/auth-member-onboarding-credentials/AuthMemberOnboardingCredentials"
        )
) as React.FC;
const AuthMemberOnboardingMultipleBeneficiaries = lazy(
    () =>
        import(
            "./widgets/onboarding-member/auth-member-onboarding-multiple-beneficiaries/AuthMemberOnboardingMultipleBeneficiaries"
        )
) as React.FC;
const AuthUserOnboardingIdentification = lazy(
    () =>
        import(
            "./widgets/onboarding-user/auth-user-onboarding-identification/AuthUserOnboardingIdentification"
        )
) as React.FC;
const AuthUserOnboardingCredentials = lazy(
    () =>
        import(
            "./widgets/onboarding-user/auth-user-onboarding-credentials/AuthUserOnboardingCredentials"
        )
) as React.FC;
const AuthCodeVerification = lazy(
    () => import("./widgets/auth-code-verification/AuthCodeVerification")
) as React.FC;
const AuthLogin = lazy(() => import("./widgets/auth-login/AuthLogin")) as React.FC;
const AuthForgotPasswordRequest = lazy(
    () => import("./widgets/forgot-password/auth-forgot-password-request/AuthForgotPasswordRequest")
) as React.FC;
const AuthForgotPasswordReset = lazy(
    () => import("./widgets/forgot-password/auth-forgot-password-reset/AuthForgotPasswordReset")
) as React.FC;
const AuthForgotUsernameRequest = lazy(
    () => import("./widgets/forgot-username/auth-forgot-username-request/AuthForgotUsernameRequest")
) as React.FC;
const AuthOnboardingProcessSelection = lazy(
    () => import("./widgets/auth-onboarding-process-selection/AuthOnboardingProcessSelection")
) as React.FC;
const AuthLoginEmail = lazy(() => import("./widgets/auth-login-email/AuthLoginEmail")) as React.FC;
const AuthCodeValidation = lazy(
    () => import("./widgets/auth-code-validation/AuthCodeValidation")
) as React.FC;
const AuthMfaSelection = lazy(
    () => import("./widgets/auth-mfa-selection/AuthMfaSelection")
) as React.FC;
const AuthChangeUsernameRequest = lazy(
    () => import("./widgets/change-username/auth-change-username-request/AuthChangeUsernameRequest")
) as React.FC;
const AuthChangePasswordRequest = lazy(
    () => import("./widgets/change-password/auth-change-password-request/AuthChangePasswordRequest")
) as React.FC;
const AuthLoginQueue = lazy(() => import("./widgets/auth-login-queue/AuthLoginQueue"));

export const AuthWidgets = {
    "auth-header": AuthHeader,
    "auth-error": AuthError,
    "auth-member-onboarding-identification": AuthMemberOnboardingIdentification,
    "auth-member-onboarding-credentials": AuthMemberOnboardingCredentials,
    "auth-member-onboarding-multiple-beneficiaries": AuthMemberOnboardingMultipleBeneficiaries,
    "auth-user-onboarding-identification": AuthUserOnboardingIdentification,
    "auth-user-onboarding-credentials": AuthUserOnboardingCredentials,
    "auth-code-verification": AuthCodeVerification,
    "auth-login": AuthLogin,
    "auth-forgot-password-request": AuthForgotPasswordRequest,
    "auth-forgot-password-reset": AuthForgotPasswordReset,
    "auth-forgot-username-request": AuthForgotUsernameRequest,
    "auth-onboarding-process-selection": AuthOnboardingProcessSelection,
    "auth-login-email": AuthLoginEmail,
    "auth-code-validation": AuthCodeValidation,
    "auth-mfa-selection": AuthMfaSelection,
    "auth-change-username-request": AuthChangeUsernameRequest,
    "auth-change-password-request": AuthChangePasswordRequest,
    "auth-login-queue": AuthLoginQueue
};
