import { Entity } from "@uneo/platform-commons/webui/types";
import {
    findAllEntityPathsById,
    getEntityProps,
    getEntityValue
} from "@uneo/platform-commons/webui/search";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";

interface IDependencyRule {
    ruleType: "DEPENDENCY";
    dependencyType?: "forbidden" | "mandatory" | "available";
    dependentCoverages?: string[];
    dependencySelectionType?: "all" | "atLeastOne";
    sourceCoverages?: string[];
    //No implementation for "all", "atLeast", "noMoreThan"
    sourceSelectionType?: "any" | "all" | "atLeast" | "noMoreThan";
}

//No implementation for Combination Rule
interface ICombinationRule {
    ruleType: "COMBINATION";
    selectionNumber: number;
    selectionType: "any" | "all" | "atLeast" | "noMoreThan";
    coverages: string[];
}

export interface ISelectionCoverageList {
    select: string[];
    deselect: string[];
}

export interface ICoveragesData {
    data: any[];
    showWarningPopup: boolean;
    changedCoverages: ISelectionCoverageList;
}

export type RuleType = IDependencyRule | ICombinationRule;

const isRuleTypeDependencyWithFields = (
    ruleToDetermine: RuleType
): ruleToDetermine is IDependencyRule => {
    if (
        (ruleToDetermine as IDependencyRule).ruleType === "DEPENDENCY" &&
        (ruleToDetermine as IDependencyRule).sourceCoverages &&
        (ruleToDetermine as IDependencyRule).sourceSelectionType &&
        (ruleToDetermine as IDependencyRule).dependencyType &&
        (ruleToDetermine as IDependencyRule).dependencySelectionType
    ) {
        return true;
    }
    return false;
};

//EXAMPLE for dependency
/*EXAMPLE for dependency
const EXAMPLES = [
    {
        ruleType: "DEPENDENCY",
        dependencyType: "forbidden",
        dependentCoverages: ["600d739f-6b2f-0700-016e-4cf380d26eaa"],
        sourceCoverages: ["600d73ff-6b2f-0700-016e-5229c2550a99"],
        sourceSelectionType: "any",
        dependencySelectionType: "all"
    },
    {
        ruleType: "COMBINATION",
        selectionType: "atLeast",
        selectionNumber: 1,
        coverages: [
            "60204b02-6522-0500-01c1-fe6e4805b6ba",
            "60204b05-6522-0500-01c2-02949312d78b",
            "60204b0b-6522-0500-01c2-06c3390ca941"
        ]
    }
];
*/

//Тhe dependency rules are processed here
export const evaluateDependencies = (
    inputCoverages: ISelectionCoverageList,
    rules: RuleType[],
    totalDependentCoverages: ISelectionCoverageList,
    allCoverages: Record<string, Entity>
): ISelectionCoverageList => {
    const coverages = {
        deselect: [],
        select: []
    };
    if (!rules) return coverages;
    inputCoverages.select.forEach((id: string) => {
        rules.forEach((dependency) => {
            if (!isRuleTypeDependencyWithFields(dependency)) return;
            //For type DEPENDENCY
            if (
                dependency.sourceCoverages.includes(id) &&
                dependency.sourceSelectionType === "any"
            ) {
                if (dependency.dependencyType === "mandatory") {
                    //For selected and deselected coverages the logic is different
                    if (dependency.dependencySelectionType === "all") {
                        coverages.select.push(
                            ...dependency.dependentCoverages.filter(
                                (dependentCoverageId) =>
                                    !coverages.select.includes(dependentCoverageId)
                            )
                        );
                        return;
                    }
                    if (dependency.dependencySelectionType === "atLeastOne") {
                        //check if the required coverages exist
                        const availableCoverages = dependency.dependentCoverages.filter(
                            (coverage) => coverage in allCoverages
                        );
                        if (availableCoverages.length) {
                            //If there is more than 1 coverage we search for some already selected or if no such - we look for recommended, if no recommended get the first one available
                            if (availableCoverages.length > 1) {
                                const alreadySelected = totalDependentCoverages.select.find(
                                    (totalDependentCoverageId) =>
                                        dependency.dependentCoverages.includes(
                                            totalDependentCoverageId
                                        )
                                );
                                //search for already selected dependent coverage on previous user action
                                const previouslySelected = availableCoverages.find(
                                    (coverage) => allCoverages[coverage].properties.selected
                                );
                                if (alreadySelected || previouslySelected) {
                                    return;
                                }
                                const recommended = dependency.dependentCoverages.find(
                                    (coverage) => allCoverages[coverage]?.properties.recommended
                                );
                                if (recommended) {
                                    //If the recommended is not selected we should select it
                                    if (
                                        !allCoverages[recommended].properties.selected &&
                                        !coverages.select.includes(recommended)
                                    ) {
                                        coverages.select.push(recommended);
                                    }
                                    //If it is selected we do nothing
                                    return;
                                }
                            }
                            //If no recommended coverage to select we just select the first available if no already selected
                            if (
                                !allCoverages[availableCoverages[0]].properties.selected &&
                                !coverages.select.includes(availableCoverages[0])
                            ) {
                                coverages.select.push(availableCoverages[0]);
                            }
                        }
                    }
                    return;
                }
                if (dependency.dependencyType === "forbidden") {
                    //To be deselected
                    coverages.deselect.push(
                        ...dependency.dependentCoverages.filter(
                            (dependentCoverageId) =>
                                !coverages.deselect.includes(dependentCoverageId)
                        )
                    );
                }
            }
            if (
                dependency.dependencyType === "available" &&
                dependency.dependentCoverages.includes(id)
            ) {
                //The logic is the same as the mandatory atLeastOne but inverted
                //When dependent coverage is selected it requires at least one selected
                //from source coverages to be selected, if deselected it does nothing to source coverages.
                //At this moment dependencySelectionType is only "all"
                const availableCoverages = dependency.sourceCoverages.filter(
                    (coverage) => coverage in allCoverages
                );
                if (availableCoverages.length) {
                    //If there is more than 1 coverage we search for some already selected or if no such - we look for recommended, if no recommended get the first one available
                    if (availableCoverages.length > 1) {
                        //Check the coverage is not already in select list
                        const alreadySelected = totalDependentCoverages.select.find((key) =>
                            dependency.sourceCoverages.includes(key)
                        );
                        if (alreadySelected) return;

                        //Check if there is already selected coverage and will not be deselected
                        const hasSelected = availableCoverages.filter(
                            (coverage) =>
                                allCoverages[coverage]?.properties.selected &&
                                !totalDependentCoverages.deselect.includes(coverage)
                        );
                        if (hasSelected.length) return;

                        const recommended = dependency.sourceCoverages.find(
                            (coverage) => allCoverages[coverage]?.properties.recommended
                        );
                        if (recommended) {
                            //If the recommended is not selected we should select it
                            if (
                                !allCoverages[recommended].properties.selected &&
                                !coverages.select.includes(recommended)
                            ) {
                                coverages.select.push(recommended);
                            }
                            //If it is selected we do nothing
                            return;
                        }
                    }
                    //If no recommended coverage to select we just select the first available if no already selected
                    if (
                        !allCoverages[availableCoverages[0]].properties.selected &&
                        !coverages.select.includes(availableCoverages[0])
                    ) {
                        coverages.select.push(availableCoverages[0]);
                    }
                }
            }
        });
    });
    inputCoverages.deselect.forEach((id: string) => {
        rules.forEach((dependency) => {
            if (!isRuleTypeDependencyWithFields(dependency)) return;
            //For type DEPENDENCY
            if (
                dependency.sourceCoverages.includes(id) &&
                dependency.sourceSelectionType === "any"
            ) {
                if (dependency.dependencyType === "mandatory") {
                    //Check if i the coverage we want to deselect is not in the select list
                    const intersect = dependency.sourceCoverages.filter((sourceCoverageId) =>
                        totalDependentCoverages.select.includes(sourceCoverageId)
                    );
                    if (!intersect.length) {
                        coverages.deselect.push(
                            ...dependency.dependentCoverages.filter(
                                (dependentCoverageId) =>
                                    !coverages.deselect.includes(dependentCoverageId)
                            )
                        );
                    }
                }
                if (
                    dependency.dependencyType === "available" &&
                    dependency.sourceCoverages.includes(id)
                ) {
                    //Check if the coverage we want to deselect is not in the select list
                    const intersect = dependency.sourceCoverages.filter((key) =>
                        totalDependentCoverages.select.includes(key)
                    );
                    //Get available coverages from dependency existing on screen
                    const availableCoverages = dependency.sourceCoverages.filter(
                        (coverage) => coverage in allCoverages
                    );
                    //Check if there is at least one selected coverage from which the current one depends
                    const hasSelected = availableCoverages.filter(
                        (coverage) =>
                            allCoverages[coverage]?.properties.selected &&
                            !totalDependentCoverages.deselect.includes(coverage)
                    );
                    //If there is no sourceCoverage selected and will not be selected - deselect current
                    if (!hasSelected.length && !intersect.length) {
                        coverages.deselect.push(
                            ...dependency.dependentCoverages.filter(
                                (key) => !coverages.deselect.includes(key)
                            )
                        );
                    }
                }
            }
        });
    });
    return coverages;
};

export const manageCoverageDependencyRules = (
    data: ISelectionCoverageList,
    widgetId: string,
    options = {}
): ICoveragesData => {
    const entity = getEntity({ widgetId, entityPath: widgetId });
    if (!entity) {
        console.warn("Main entity not found");
        return;
    }
    let allCoveragesPaths: string[] = [];
    allCoveragesPaths = findAllEntityPathsById(entity, "coverages") || [];
    //In format { coverageId<string>: coverageData<Object> }
    const allCoverages = getCoverageEntitiesData(allCoveragesPaths, widgetId, options);

    const dependentCoverages: ISelectionCoverageList = {
        select: [],
        deselect: []
    };
    if (!allCoverages) {
        console.warn("No coverages found");
        return;
    }
    const rules: RuleType[] = getEntityProps(entity, "coverage-rule-section").coverageRules;

    // Coverages on select
    let iterations = 0;
    const MAX_ITERATIONS = 20;

    let result: ISelectionCoverageList = {
        select: [...data.select],
        deselect: [...data.deselect]
    };
    //Until we have results with dependent coverages to select/deselect
    //and the iterations limit is not exceeded - we proceed checking
    while ((result?.select.length || result?.deselect.length) && iterations < MAX_ITERATIONS) {
        const select = filterExistingCoverages(
            result.select,
            false,
            dependentCoverages,
            allCoverages
        ).filter((id: string) => !dependentCoverages.select.includes(id));
        const deselect = filterExistingCoverages(
            result.deselect,
            true,
            dependentCoverages,
            allCoverages
        ).filter((id: string) => !dependentCoverages.deselect.includes(id));
        dependentCoverages.select.push(...select);
        dependentCoverages.deselect.push(...deselect);
        //We pass again result coverages to be validated against rules
        result = evaluateDependencies(
            { select, deselect },
            rules,
            dependentCoverages,
            allCoverages
        );
        iterations++;
    }

    const coveragesData: ICoveragesData = {
        data: [],
        showWarningPopup: false,
        changedCoverages: dependentCoverages
    };

    //We should warn that there is an error and do not make any selection
    if (iterations === MAX_ITERATIONS) {
        console.warn("Max iterations limit exceeded! Check for errors!");
        return coveragesData;
    }
    //Format data for BE with specific structure
    coveragesData.data = Object.keys(allCoverages).map((id) => {
        const coverageProperties = allCoverages[id].properties;
        if (
            (coverageProperties.selected &&
                dependentCoverages.deselect.includes(id) &&
                !data.deselect.includes(id)) ||
            (!coverageProperties.selected &&
                dependentCoverages.select.includes(id) &&
                !data.select.includes(id))
        ) {
            //Show warning popup only if we must have to select and/or deselect
            coveragesData.showWarningPopup = true;
        }
        const activeFlag =
            dependentCoverages.select.includes(id) ||
            (coverageProperties.selected && !dependentCoverages.deselect.includes(id));
        return {
            id,
            code: coverageProperties.code,
            label: coverageProperties.label,
            activeFlag,
            recommendedFlag: !!coverageProperties.recommended
        };
    });

    return coveragesData;
};

/*
As we flatten data the result is in format { coverageId<string>: coverageData<SirenEntity> } like:
{
    61bb4ee0-b3cb-1f00-0157-ef67d049592e: {
        class: ['61bb4ee0-b3cb-1f00-0157-ef67d049592e']
        properties: {
            selected: false,
            recommended: true,
            score: 2,
            code: 'primeSpecialite',
            disabled: false
            recommended: true
            score: 2
            selected: false
        }
    }
*/
const getCoverages = (entity: Entity) => {
    if (entity.properties && ["comparable", "optional"].includes(entity.properties.type)) {
        const entitySelection = entity?.properties?.value?.split(",") || [];
        entity.entities?.forEach((coverage) => {
            coverage.properties = {
                selected: entitySelection.includes(coverage.id) || false
            };
        });
        return entity.entities;
    } else {
        return entity.entities?.map((childEntity) => getCoverages(childEntity));
    }
};
const getCoverageEntitiesData = (
    allCoveragesPaths: string[],
    widgetId: string,
    options: Record<string, any> = {}
): Record<string, Entity> => {
    const allCoverages: Record<string, Entity> = {};
    allCoveragesPaths
        .map((entityPath: string) => {
            const entity = getEntity({ widgetId, entityPath });
            if (entity) {
                const result = getCoverages(entity);
                return result.flat();
            }
        })
        .flat()
        .forEach((coverage: Entity) => {
            //Extract coverage label to be accessible later for popup
            const labelPath = options.labelPath || "header.title";
            const label = getEntityValue(coverage, labelPath);
            const modifiedCoverage = { ...coverage };
            modifiedCoverage.properties = { ...modifiedCoverage.properties, label };
            //deleting entities because we don't need them anymore to reduce data
            delete modifiedCoverage.entities;
            allCoverages[modifiedCoverage.id] = modifiedCoverage;
        });

    return allCoverages;
};

const filterExistingCoverages = (
    coverages: any[],
    selected: boolean,
    dependentCoverages: ISelectionCoverageList,
    allCoverages: Record<string, Entity>
) => {
    const selectionType = selected ? "select" : "deselect";
    return coverages.filter(
        (coverage) =>
            !dependentCoverages[selectionType].includes(coverage) &&
            coverage in allCoverages &&
            allCoverages[coverage].properties.selected === selected
    );
};
