import React, { createContext, useContext, useEffect } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import {
    allowedMatomoAction,
    matomoActions,
    triggerMatomoEvent
} from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { getEntityProps } from "@uneo/platform-commons/webui/search";
import { computeProcessCategory } from "@uneo/platform-commons/platform/components/MatomoTracker/matomoProcessLabels";
const APPLICATION_META_NAME = "application-version";
const SEO_DESCRIPTION = "description";
export interface PageMetaContextType {
    data: Record<string, any>;
    triggerPageEvent({
        name,
        action,
        customAction,
        category
    }: {
        name?: string;
        action?: matomoActions;
        customAction?: string;
        category?: string;
    }): void;
}

export const getPageMetaContext = ({ data }: { data: Entity | null }): PageMetaContextType => {
    const metaData = getEntityProps(data, "metadata") || {};
    const pageTitle = metaData.title || "unknown meta title"; // adding default page title
    const mainCategory = metaData.parentTitle || pageTitle;
    const processId = metaData.processId;
    const businessCase = metaData.businessCase;

    return {
        data: metaData,
        triggerPageEvent: ({ name, action = "Cliquer", customAction, category }) => {
            let embeddedAction = action === "Cliquer" ? "" : ` - ${action}`;
            let eventName = "";
            let eventAction = action;
            let eventCategory = category || mainCategory;
            eventCategory = computeProcessCategory(eventCategory, processId, businessCase);

            // [parentTitle - pageTitle]
            if (pageTitle !== eventCategory) {
                eventName += ` - ${pageTitle}`;
            }

            // by default - [parentTitle - pageTitle - name]
            if (name && name !== pageTitle) {
                eventName += ` - ${name}`;
            }

            if (customAction) {
                embeddedAction = ` - ${customAction}`;

                // customAction is allowed, also set it as action
                if ((allowedMatomoAction as ReadonlyArray<string>).includes(customAction)) {
                    eventAction = customAction as matomoActions;
                }
            }

            triggerMatomoEvent(
                eventCategory,
                `[${eventCategory}${eventName}]${embeddedAction}`,
                eventAction
            );
        }
    };
};

export const PageMetaContext = createContext(getPageMetaContext({ data: null }));

export const usePageMetaContext = () => {
    return useContext(PageMetaContext);
};

export const PageMetaContextProvider = ({ data, children }) => {
    const metaDataContext = getPageMetaContext({ data });
    const { data: metaData } = metaDataContext;

    useEffect(() => {
        if (document?.title) {
            document.title = metaData.title || "Unéo";
        }

        if (metaData.description) {
            const currentDescription = document.querySelector(`meta[name="${SEO_DESCRIPTION}"]`);
            if (currentDescription) {
                currentDescription.setAttribute("content", metaData.description);
            } else {
                const newMetaTag = document.createElement("meta");
                newMetaTag.setAttribute("name", SEO_DESCRIPTION);
                newMetaTag.setAttribute("content", metaData.description);
                document.head.appendChild(newMetaTag);
            }
        }

        const applicationVersion = metaData.pfApplicationVersion;
        //application-version
        if (applicationVersion) {
            const currentApplicationVersion = document.querySelector(
                `meta[name="${APPLICATION_META_NAME}"]`
            );
            if (currentApplicationVersion) {
                currentApplicationVersion.setAttribute("content", applicationVersion);
            } else {
                const newMetaTag = document.createElement("meta");
                newMetaTag.setAttribute("name", APPLICATION_META_NAME);
                newMetaTag.setAttribute("content", applicationVersion);
                document.head.appendChild(newMetaTag);
            }
        }
    }, [metaData.title, metaData.pfApplicationVersion]);

    return <PageMetaContext.Provider value={metaDataContext}>{children}</PageMetaContext.Provider>;
};

export default PageMetaContext;
