import weekData from "@tinqin/tinqin-utils/src/date/weekData";

export function createDate(...args: any[]): Date {
    if (!args.length) {
        return new Date(new Date().toISOString().split("T")[0]);
    } else if (typeof args[0] === "string") {
        return new Date(args[0]);
    } else if (args[0] instanceof Date) {
        if (!isNaN(args[0].getTime())) {
            return new Date(args[0].toISOString().split("T")[0]);
        }
        return args[0];
    } else if (typeof args[0] === "number") {
        return new Date(new Date(args[0]).toISOString().split("T")[0]);
    }
}

export function toIsoDateString(date?: Date | any) {
    if (date instanceof Date) {
        return date.toISOString().split("T")[0];
    } else {
        return createDate(date).toISOString().split("T")[0];
    }
}

export function formatDate(isoDate: string | Date | false) {
    let isoDateString = typeof isoDate === "string" ? isoDate : "";
    if (isoDate instanceof Date) {
        isoDateString = toIsoDateString(isoDate);
    }
    const parts = isoDateString.split("-");
    if (parts.length === 3) {
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
    return "";
}

export function getLocaleDistanceToWeekStart(locale = "en-US", referenceDate) {
    let dayIndex = referenceDate.getUTCDay();
    let localDayStart = getLocaleWeekFirstDayNumber(locale);
    let distance = dayIndex - localDayStart;
    if (distance < 0) {
        distance = 7 + distance;
    }
    return distance;
}

export function getLocaleMonth(locale = "en-US", referenceDate, length = "long") {
    let date = createDate(referenceDate);
    const newDate = adjustISODateToLocale(date);
    return newDate.toLocaleDateString(locale, { month: length });
}

export function getLocaleMonths(locale = "en-US", length = "long") {
    let workDate = createDate();
    let result = [];
    for (let i = 0; i < 12; i++) {
        workDate.setUTCMonth(i);
        workDate.setUTCDate(1);
        const date = adjustISODateToLocale(workDate);
        result.push(date.toLocaleDateString(locale, { month: length }));
    }
    return result;
}

export function getLocaleWeekFirstDayNumber(locale = "en-US") {
    const fallbackCountyCode = "fr"; //Monday
    const countryCode = locale.substr(locale.indexOf("-") + 1, 2);
    if (weekData.firstDay.hasOwnProperty(countryCode)) {
        return weekData.firstDay[countryCode];
    } else {
        return weekData.firstDay[fallbackCountyCode];
    }
}

export function getLocaleWeeks(locale = "en-US", size) {
    // monday createDate(`2000-02-20`) - getUTCDay === 0 (Sunday);
    let workDate;
    let result = [];
    for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
        workDate = createDate(`2000-02-2${dayIndex}`);
        const localeDate = adjustISODateToLocale(workDate);
        result.push({
            name: localeDate.toLocaleDateString(locale, { weekday: size }),
            isWeekday: isWeekday(workDate)
        });
    }
    return result;
}

export function getLocalizedMonthCalendarDataToDisplay(locale = "en-US", referenceDate) {
    let result = [];
    let firstDayOfTheMonth = createDate(referenceDate);
    firstDayOfTheMonth.setUTCDate(1);
    let distanceToTheWeekStart = getLocaleDistanceToWeekStart(locale, firstDayOfTheMonth);

    let firstWeekStartDay = createDate(firstDayOfTheMonth);
    firstWeekStartDay.setUTCDate(firstWeekStartDay.getUTCDate() - distanceToTheWeekStart);

    let workDay = createDate(firstWeekStartDay);
    if (distanceToTheWeekStart < 3) {
        workDay.setUTCDate(workDay.getUTCDate() - 7);
    }
    while (result.length < 6) {
        let week = [];
        for (let i = 0; i < 7; i++) {
            week.push(workDay.getUTCDate().toString());
            workDay.setUTCDate(workDay.getUTCDate() + 1);
        }
        result.push(week);
    }
    return result;
}

export function isWeekday(date) {
    let workDate = createDate(date);
    return !(workDate.getUTCDay() === 0 || workDate.getUTCDay() === 6);
}

function adjustISODateToLocale(date) {
    if (
        date.getFullYear() !== date.getUTCFullYear() ||
        date.getMonth() !== date.getUTCMonth() ||
        date.getDate() !== date.getUTCDate()
    ) {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }
    return date;
}
