"use client";
import React, { useEffect, useId, useRef, useState } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import { debounce } from "../responsive-container/ResponsiveContainer";
import { getEntityValue } from "../../../webui/search";
import { useFetchFeature } from "../../features";
import { elementIsVisibleInViewport } from "@uneo/platform-commons/platform/utils/utils";
import { triggerMatomoEvent } from "../MatomoTracker/MatomoTracker";

const MOBILE_ID_DEFAULT = "Mobile_EP_bas";
const DESKTOP_ID_DEFAULT = "Desk_EP_bas";

export default function MarketingBanner({
    data,
    mobileWidth,
    elementIdDesktop = DESKTOP_ID_DEFAULT,
    elementIdMobile = MOBILE_ID_DEFAULT
}: {
    data: Entity;
    mobileWidth: number;
    elementIdDesktop?: string;
    elementIdMobile?: string;
}) {
    const { enabled, settings } = useFetchFeature("marketing-banners");
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const instanceId = useId();
    const SCRIPT_URL = settings["script-url"];
    const USER_ID = settings["user-id"];
    const IDENTITY = settings["identity"];
    const [screenWidth, setScreenWidth] = useState<number>(10000);
    const prevBannerType = useRef<string | null>(null);
    const banner = useRef(null);
    const visibleBanner = useRef(false);
    const pageTitle = getEntityValue(data, "page-title");
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScreenWidth(document.body.clientWidth);
        }, 100);
        const debouncedHandleScroll = debounce(onScroll, 100);
        setScreenWidth(document.body.clientWidth);
        window?.addEventListener("resize", debouncedHandleResize);

        window?.addEventListener("scroll", debouncedHandleScroll);
        onScroll();

        return () => {
            window?.removeEventListener("resize", debouncedHandleResize);
            window?.removeEventListener("scroll", debouncedHandleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (enabled && SCRIPT_URL && !scriptLoaded) {
            if (document.querySelector(`script[src="${SCRIPT_URL}"]`)) {
                setScriptLoaded(true);
                return;
            }
            if (window) {
                window["cmServiceSettings"] = {
                    identity: IDENTITY,
                    hash: USER_ID
                };
            }
            const mainScript: HTMLScriptElement = document.createElement("script");
            mainScript.src = SCRIPT_URL;
            mainScript.onload = () => setScriptLoaded(true);

            const headElement: HTMLHeadElement = document.getElementsByTagName("head")[0];
            headElement.appendChild(mainScript);
        }
    }, [enabled, SCRIPT_URL, USER_ID, IDENTITY, scriptLoaded]);

    const onClick = () => {
        //matomo tracking
        triggerMatomoEvent("[Bannière display]", `[Display] - ${pageTitle} - Cliquer`, "Cliquer");
    };

    const onScroll = () => {
        //matomo tracking
        if (elementIsVisibleInViewport(banner.current)) {
            if (!visibleBanner.current) {
                triggerMatomoEvent(
                    "[Bannière display]",
                    `[Display] - ${pageTitle} - Affichage Bannière`,
                    "Affichage"
                );
                visibleBanner.current = true;
            }
        } else {
            if (visibleBanner.current) {
                visibleBanner.current = false;
            }
        }
    };

    const result: Record<string, any> = {};
    let bannerType: string;

    if (mobileWidth > screenWidth) {
        result.width = "300";
        result.height = "250";
        result.placementId = getEntityValue(data, "mobile");
        result.id = elementIdMobile;
        bannerType = "mobile";
    } else {
        result.width = "728";
        result.height = "90";
        result.placementId = getEntityValue(data, "desktop");
        result.id = elementIdDesktop;
        bannerType = "desktop";
    }
    if (scriptLoaded && prevBannerType.current !== bannerType) {
        if (typeof window !== "undefined" && window["cmService"]?.getContent) {
            window["cmService"].getContent({
                placementId: result.placementId,
                elementId: result.id,
                width: result.width,
                height: result.height
            });
            prevBannerType.current = bannerType;
        }
    }

    return (
        <div
            key={instanceId + bannerType + result.id}
            id={result.id}
            className="un-marketing-banner"
            onClick={onClick}
            ref={banner}
        ></div>
    );
}
