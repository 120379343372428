import React from "react";
import ProcessTitle from "./ProcessTitle";
import SummarySteps from "./SummarySteps";

export default function MobileSummarySteps({ title, currentStep, expanded, onToggle, steps }) {
    return (
        <MobileFlowSummary title={title} expanded={expanded} onToggle={onToggle}>
            <SummarySteps currentStep={currentStep} steps={steps} />
        </MobileFlowSummary>
    );
}

function MobileFlowSummary({ title, children, expanded, onToggle }) {
    return (
        <>
            <button
                className={`${
                    expanded ? "un-nav-button-open" : ""
                } tq-button un-ds-button un-ds-button-only-icon un-ds-button-solid un-nav-button`}
                onClick={onToggle}
            >
                <i className="un-ds-s-icon-navigation" />
                <i className="tq-icon-close-big" />
                {}
            </button>
            <div className={`${expanded ? "un-flow-mobile-menu-show" : ""} un-flow-mobile-menu`}>
                <ProcessTitle title={title} extraClasses="un-mt-2" />
                {children}
            </div>
        </>
    );
}
