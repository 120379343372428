"use client";
import React, { useEffect, useRef } from "react";

import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import {
    IBaseComponentProps,
    IBaseComponentPropsKeys,
    IEventHandlers,
    IEventHandlersKeys,
    ILabeledPropsKeys
} from "@uneo/platform-commons/platform/types";

export interface ITextAreaProps extends IBaseComponentProps, IEventHandlers {
    autoHeight: boolean;
    areaHeight?: number;
    placeholder?: string;
    maxCharacters?: number;
    disabled?: boolean;
    vertical?: boolean;
}

const TextArea: React.FC<ITextAreaProps> = (props) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const value = typeof props.value !== "undefined" ? props.value : "";

    useEffect(() => {
        if (value !== textareaRef.current.value) {
            textareaRef.current.value = value;
        }
    }, [value]);

    useEffect(() => {
        if (props.autoHeight && textareaRef.current) {
            computeAutoHeight();
        }
    }, [props.autoHeight, value]);

    const computeAutoHeight = () => {
        const textArea = textareaRef.current;
        if (textArea) {
            textArea.style.height = "auto";
            const padding = parseInt(window.getComputedStyle(textArea).paddingBottom) || 0;
            textArea.style.height = padding + textArea.scrollHeight + "px";
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.autoHeight && textareaRef.current) {
            computeAutoHeight();
        }
        if (props.onChange) {
            const newValue = event.target.value;
            props.onChange({ value: newValue });
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur({ value });
        }
    };

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus({ value });
        }
    };
    const labelProps = getLabelProps(props, {
        containerClass: props.extraClasses || ""
    });

    return (
        <Labeled {...labelProps}>
            <textarea
                ref={textareaRef}
                className="tq-textarea"
                maxLength={props.maxCharacters}
                rows={props.areaHeight || 3}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </Labeled>
    );
};

export default TextArea;

export const ITextAreaPropsKeys = [
    ...IEventHandlersKeys,
    ...IBaseComponentPropsKeys,
    ...ILabeledPropsKeys,
    "areaHeight",
    "autoHeight",
    "placeholder",
    "maxCharacters"
];
