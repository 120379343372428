"use client";
import { Widget as WidgetType } from "../types";
import widgetRegistry from "../../defaultWidgetViews";
import React from "react";
import { useFetchWidget } from "../store/hooks";
import "i18n";

export default function Widget({
    info,
    WidgetComponent
}: {
    info: WidgetType;
    WidgetComponent?: React.FC<any>;
}) {
    console.log("Rendering ", info.id);
    const { info: completeWidgetInfo } = useFetchWidget(info.id);
    if (!WidgetComponent) {
        WidgetComponent = widgetRegistry[info.id];
    }
    if (!WidgetComponent) {
        console.error("Missing component for ", info.id);
        return <></>;
    }
    return <WidgetComponent info={completeWidgetInfo}></WidgetComponent>;
}
