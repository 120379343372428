"use client";
import React from "react";
import PropTypes from "prop-types";
import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import DomUtils from "@tinqin/tinqin-utils/src/dom";
import "@tinqin/design-uneo-pf/css/datepicker.css";
import { createDate, toIsoDateString } from "@uneo/platform-commons/platform/utils/dateUtils";

export default class Calendar extends React.Component {
    constructor(props) {
        super(props);

        //This function is intended to work ONLY on component init (that is why it is here).
        function determineMode() {
            let mode = "days"; //default is days;
            if (props.selection && props.selection.length) {
                for (let index = 0; index < props.selection.length - 1; index++) {
                    let date1 = createDate(props.selection[index]);
                    let date2 = createDate(props.selection[index + 1]);
                    if (date1.getUTCFullYear() !== date2.getUTCFullYear()) {
                        mode = "years";
                        break;
                    }
                    if (date1.getUTCMonth() !== date2.getUTCMonth()) {
                        mode = "months";
                    }
                }
            }
            return mode;
        }

        function determineReferenceDate() {
            let highestSelectionDate = createDate();
            highestSelectionDate.setUTCDate(1);
            let selectionClone = Array.from(props.selection || []);
            selectionClone.sort((a, b) => {
                let date1 = createDate(a);
                let date2 = createDate(b);
                if (date1 > date2) return -1;
                if (date1 < date2) return 1;
                return 0; //date1 === date2
            });
            if (selectionClone.length) {
                let highestDayShifted = createDate(selectionClone[0]).setUTCDate(1);
                highestSelectionDate = createDate(highestDayShifted);
            }

            return highestSelectionDate;
        }

        let propsReferenceDate;
        if (props.referenceDate) {
            let referenceDateShifted = createDate(props.referenceDate).setUTCDate(1);
            propsReferenceDate = createDate(referenceDateShifted);
        }

        this.state = {
            selection: props.selection || [],
            mode: this.props.mode || determineMode(),
            referenceDate: propsReferenceDate || determineReferenceDate(),
            yearsUserOffset: 0,
            monthsUserOffset: 0
        };

        this.incrementYears = this.incrementYears.bind(this);
        this.decrementYears = this.decrementYears.bind(this);
        this.incrementMonths = this.incrementMonths.bind(this);
        this.decrementMonths = this.decrementMonths.bind(this);
        this.switchToYears = this.switchToYears.bind(this);
        this.switchToMonths = this.switchToMonths.bind(this);
        this.incrementYearsPage = this.incrementYearsPage.bind(this);
        this.decrementYearsPage = this.decrementYearsPage.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.selectMonth = this.selectMonth.bind(this);
        this.selectYear = this.selectYear.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let stateToSet = {};
        if (
            newProps.selection &&
            !ArrayUtils.shallowEquals(newProps.selection, this.state.selection)
        ) {
            stateToSet.selection = newProps.selection;
        }

        if (Object.keys(stateToSet).length) {
            this.setState(stateToSet);
        }
    }

    incrementYears() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCFullYear(newReferenceDate.getUTCFullYear() + 1);
        this.setState({ referenceDate: newReferenceDate });
    }

    decrementYears() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCFullYear(newReferenceDate.getUTCFullYear() - 1);
        this.setState({ referenceDate: newReferenceDate });
    }

    incrementMonths() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCMonth(newReferenceDate.getUTCMonth() + 1);
        this.setState({ referenceDate: newReferenceDate });
    }

    decrementMonths() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCMonth(newReferenceDate.getUTCMonth() - 1);
        this.setState({ referenceDate: newReferenceDate });
    }

    switchToYears() {
        this.setState({ mode: "years" });
    }

    switchToMonths() {
        this.setState({ mode: "months" });
    }

    incrementYearsPage() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCFullYear(newReferenceDate.getUTCFullYear() + 12);
        this.setState({ referenceDate: newReferenceDate });
    }

    decrementYearsPage() {
        let newReferenceDate = createDate(this.state.referenceDate);
        newReferenceDate.setUTCFullYear(newReferenceDate.getUTCFullYear() - 12);
        this.setState({ referenceDate: newReferenceDate });
    }

    selectDate(event) {
        let date = createDate(event.currentTarget.getAttribute("data-id"));
        let stateToSet = {};
        let selectionDate = toIsoDateString(date);
        if (this.props.singleSelection) {
            stateToSet.selection = [selectionDate];
        } else {
            let filteredSelection = this.state.selection.filter((item) => {
                let itemDate = toIsoDateString(createDate(item));
                return selectionDate !== itemDate;
            });
            if (filteredSelection.length !== this.state.selection.length) {
                stateToSet.selection = filteredSelection;
            } else {
                let selectionCopy = Array.from(this.state.selection);
                selectionCopy.push(selectionDate);
                stateToSet.selection = selectionCopy;
            }
        }

        this.setState(stateToSet, () => {
            if (this.props.onSelectDate) {
                this.props.onSelectDate(this.state);
            }
        });
    }

    selectMonth(event) {
        let selectedMonth = event.currentTarget.getAttribute("data-id");
        selectedMonth = createDate(selectedMonth);
        let state = {
            referenceDate: selectedMonth
        };
        if (this.props.type !== "monthPicker") {
            state.mode = "days";
            return this.setState(state);
        }

        state.selection = [toIsoDateString(selectedMonth)];
        this.setState(state, () => {
            if (this.props.onSelectDate) {
                this.props.onSelectDate(this.state);
            }
        });
    }

    selectYear(event) {
        let selectedYear = event.currentTarget.getAttribute("data-id");
        selectedYear = createDate(selectedYear);
        let state = {
            referenceDate: selectedYear
        };
        if (this.props.type !== "yearPicker") {
            state.mode = "months";
            return this.setState(state);
        }

        state.selection = [toIsoDateString(selectedYear)];
        this.setState(state, () => {
            if (this.props.onSelectDate) {
                this.props.onSelectDate(this.state);
            }
        });
    }

    onBlur(event) {
        const calendar = this.props.calendarRef && this.props.calendarRef.current;
        if (calendar) {
            if (DomUtils.isClickOutside(event, calendar)) {
                if (this.props.onBlur) {
                    this.props.onBlur(this.state);
                }
            } else {
                calendar.focus();
            }
        }
    }

    render() {
        let style;
        if (this.props.width) {
            style = {
                width: this.props.width,
                minWidth: 0 //at the moment designers set minWidth:100%. Until we resolve that, we shall keep that code here.
            };
        }

        let extraClasses = this.props.extraClasses || "";
        let className = "tq-calendar tq-calendar-inline " + extraClasses;
        if (!this.props.selectable) {
            className += " tq-disabled-selection";
        }
        return (
            <div
                tabIndex="-1"
                className={className}
                style={style}
                onBlur={this.onBlur}
                ref={this.props.calendarRef}
            >
                <CalendarHeader
                    locale={this.props.locale}
                    referenceDate={this.state.referenceDate}
                    calendarMode={this.state.mode}
                    switchToYears={this.switchToYears}
                    decrementYears={this.decrementYears}
                    incrementYears={this.incrementYears}
                    switchToMonths={this.switchToMonths}
                    decrementMonths={this.decrementMonths}
                    incrementMonths={this.incrementMonths}
                    decrementYearsPage={this.decrementYearsPage}
                    incrementYearsPage={this.incrementYearsPage}
                />
                <CalendarBody
                    locale={this.props.locale}
                    referenceDate={this.state.referenceDate}
                    calendarMode={this.state.mode}
                    selectDate={this.props.selectable && this.selectDate}
                    selectMonth={this.selectMonth}
                    selectYear={this.selectYear}
                    selection={this.state.selection}
                    longMonthNames={this.props.longMonthNames}
                    filterAllowedDates={this.props.filterAllowedDates}
                />
            </div>
        );
    }
}

Calendar.displayName = "Calendar";

Calendar.propTypes = {
    extraClasses: PropTypes.string,
    locale: PropTypes.string,
    width: PropTypes.string,
    longMonthNames: PropTypes.bool,
    selectable: PropTypes.bool,
    selection: PropTypes.array,
    singleSelection: PropTypes.bool,
    referenceDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    mode: PropTypes.oneOf(["days", "months", "years"]),
    type: PropTypes.oneOf(["dayPicker", "monthPicker", "yearPicker"]),
    monthsInDaysMode: PropTypes.oneOf(["single", "multiple"]), //May be later
    onSelectDate: PropTypes.func,
    onBlur: PropTypes.func,
    filterAllowedDates: PropTypes.shape({ func: PropTypes.func, options: PropTypes.object })
};

//* --- Prepared Interface
// interface ICalendarProps extends IBaseComponentProps, IEventHandlers {
//     locale?: string;
//     width?: string;
//     longMonthNames?: boolean;
//     selectable?: boolean;
//     selection?: string[];
//     singleSelection?: boolean;
//     referenceDate?: string | Date;
//     mode?: "days" | "months" | "years";
//     type?: "dayPicker" | "monthPicker" | "yearPicker";
//     monthsInDaysMode?: "single" | "multiple"; // do we use it?
//     onSelectDate?: (args?: any[]) => any;
//     filterAllowedDates?: { func: (args?: any[]) => any, options: Record<string, any> };
// }
