"use client";
import React, { useEffect, useState } from "react";
import widgetViews from "@uneo/platform-commons/defaultWidgetViews";
import PortalSidebar from "@uneo/platform-portal/widgets/portal-header/PortalSidebar";
import { useFetchWidgetData } from "@uneo/platform-commons/platform/hooks";
import { useWidgetDataSelector } from "@uneo/platform-commons/webui/store/hooks";

import { Entity, Widget } from "@uneo/platform-commons/webui/types";
import { AuthWidgets } from "@uneo/platform-auth/AuthWidgets";
import { OavWidgets } from "@uneo/platform-oav/OavWidgets";
import {
    ContentLoadingAnimation,
    MatomoTracker,
    Modals
} from "@uneo/platform-commons/platform/components";
import { PageMetaContextProvider } from "./PageMetaContext";
import PageNotAvailable from "./PageNotAvailable";
import NavigationMenuHandler from "./NavigationMenuHandler";
import { getEntityProps } from "@uneo/platform-commons/webui/search";
import LinkedInInsight from "@uneo/platform-commons/platform/components/LinkedIn/LinkedInInsight";
import MetaPixel from "@uneo/platform-commons/platform/components/MetaPixel/MetaPixel";
import { useManageScrollPosition } from "@uneo/platform-commons/platform/actions/manageScrollRestoration";
import { getEntity } from "@uneo/platform-commons/webui/store/actions";
import { getNonSpecialLinks } from "../profile-menu/ProfileMenuSidebar";
import { ErrorBoundary } from "react-error-boundary";
import StaticError from "../../components/StaticError";
//widgets that do not use sidebar and render the <main> content themselves
const FULL_CONTENT_WIDGETS = [
    ...Object.keys(AuthWidgets),
    ...Object.keys(OavWidgets),
    "member-declare-iban-certificate",
    "member-declare-iban-details",
    "member-declare-iban-validation",
    "member-declare-iban-certificate",
    "member-declare-iban-details",
    "member-contact-us",
    "member-follow-up-requests",
    "portal-error-page",
    "portal-guide-page",
    "portal-unsubscribe"
];

//renders inner data as a separate widget
export default function ContentRoot({ info }: { info: Widget }) {
    const { data, isLoading, error } = useFetchWidgetData(info);
    //rerender on profile-menu or portal-header store data update
    useWidgetDataSelector("profile-menu");
    useWidgetDataSelector("portal-header");
    const [lazyLoading, setLazyLoading] = useState(isLoading);
    //Scroll restoration logic is handled here
    useManageScrollPosition(!!data?.entities, isLoading);
    if (data?.properties?.exception || error) {
        return <StaticError />;
    }
    const innerWidgetData = data?.entities?.[0];
    const innerWidgetId = innerWidgetData?.id;
    const WidgetComponent = widgetViews[innerWidgetId];
    if (innerWidgetId && !WidgetComponent) {
        console.error(
            "Missing default WidgetView for ",
            innerWidgetId || "undefined innerWidgetId at ContentRoot"
        );
        return <StaticError />;
    }
    const activeSidebar = isActiveSidebar(innerWidgetId, data);
    return (
        <ErrorBoundary
            fallback={<StaticError />}
            onError={(error, errorInfo) => {
                console.error("Thrown error in ContentRoot: ", error, errorInfo);
            }}
        >
            <ContentLoadingAnimation isLoading={isLoading || lazyLoading || !data?.entities}>
                <React.Suspense fallback={<FallbackComponent setLazyLoading={setLazyLoading} />}>
                    <ContentWrapper activeSidebar={activeSidebar}>
                        <PageMetaContextProvider data={data}>
                            <MatomoTracker data={data} />
                            <LinkedInInsight />
                            <MetaPixel />
                            <Modals data={data} />
                            {activeSidebar && <PortalSidebar />}
                            <NavigationMenuHandler data={data} />
                            <PageNotAvailable data={data}>
                                {innerWidgetId && WidgetComponent && (
                                    <WidgetComponent data={data} />
                                )}
                            </PageNotAvailable>
                        </PageMetaContextProvider>
                    </ContentWrapper>
                </React.Suspense>
            </ContentLoadingAnimation>
        </ErrorBoundary>
    );
}

const ContentWrapper = ({
    activeSidebar,
    children
}: {
    activeSidebar: boolean;
    children: React.ReactNode | React.ReactNode[];
}) => {
    if (activeSidebar) {
        return <main className="tq-main-content">{children}</main>;
    } else {
        return <>{children}</>;
    }
};

const FallbackComponent = ({ setLazyLoading }) => {
    // Set loading flag to true when the suspense boundary is triggered
    useEffect(() => {
        setLazyLoading(true);
        // Return a cleanup function to set loading flag to false when suspense is over
        return () => {
            return setLazyLoading(false);
        };
    }, [setLazyLoading]);

    return null;
};

const isActiveSidebar = (innerWidgetId, data) => {
    const profileMenuLinks = getEntity({ widgetId: "profile-menu", entityPath: "links" });
    const profileMenuLogin = getEntity({ widgetId: "profile-menu", entityPath: "login" });
    const navigationInfo = getEntityProps(data, "navigation") || {};
    const { headerMenuLinkId, profileMenuLinkId } = navigationInfo;
    const headerMenuLinkExists =
        !!headerMenuLinkId &&
        getEntity({
            widgetId: "portal-header",
            entityPath: headerMenuLinkId
        });
    const profileMenuLinkExists = doesEntityExist(profileMenuLinks, profileMenuLinkId);
    if (headerMenuLinkExists || profileMenuLinkExists) {
        return true;
    }
    //most cases there is a sidebar along with the main content
    //authentication widgets render main content themselves
    if (FULL_CONTENT_WIDGETS.includes(innerWidgetId)) {
        return false;
    }
    //exception case
    const isAuthenticated = !profileMenuLogin;
    if (innerWidgetId === "member-request-confirmation" && !isAuthenticated) {
        return false;
    }
    //by default hide sidebar
    return false;
};

const doesEntityExist = (entity: Entity | false, searchId: string): boolean => {
    if (!entity || !searchId) {
        return false;
    }
    return !!getNonSpecialLinks(entity.entities).find((link) => link.id === searchId);
};
