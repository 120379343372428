import { useSelector } from "react-redux";
import { Entity, Widget } from "../types";
import { fromStoreWidgetToWidget } from "../adapters";

//TODO add return types
export function useFetchWidget(id: string): { info: Widget | null } {
    const storeData = useSelector((state: any) => state.widgets.widgets[id]);
    if (storeData) {
        return { info: fromStoreWidgetToWidget(storeData) };
    }
}

export function useWidgetSelector(id: string): { info: Widget | null } {
    const storeData = useSelector((state: any) => state.widgets.widgets[id]);
    if (storeData) {
        return { info: fromStoreWidgetToWidget(storeData) };
    }
    return { info: null };
}

export function useWidgetDataSelector(id: string): { data: Entity | null } {
    const { info } = useWidgetSelector(id);
    if (info && info.data) {
        return { data: info.data };
    }
    return {
        data: null
    };
}
