"use client";
import React from "react";
import Switcher from "./Switcher";
import { extractComponentProps } from "../../../utils/utils";
import { ILabeledPropsKeys, IEventHandlersKeys, IBaseComponentPropsKeys } from "../../../types";
import { usePageMetaContext } from "@uneo/platform-portal/widgets/content-root/PageMetaContext";
import { getEntityProps } from "@uneo/platform-commons/webui/search";

export default function SwitcherContainer(props) {
    const { triggerPageEvent } = usePageMetaContext();
    const componentProps = extractComponentProps(props, {
        componentProps: [
            ...ILabeledPropsKeys,
            ...IEventHandlersKeys,
            ...IBaseComponentPropsKeys,
            "entities",
            "separated",
            "singleSelection",
            "deselection",
            "errorMessage",
            "verticalItemContent",
            "verticalValue",
            "jsx",
            "trackChanges"
        ],
        propsDefaults: {
            separated: true,
            extraClasses: "",
            deselection: false,
            singleSelection: true,
            trackChanges: false
        }
    });

    componentProps.extraClasses = componentProps.separated
        ? `un-form-vertical un-form-label-with-icon un-ds-radio-switch-separated ${componentProps.extraClasses}`
        : `un-ds-radio-switch-normal ${componentProps.extraClasses}`;

    componentProps.singleSelection = componentProps.separated
        ? componentProps.singleSelection
        : true;
    if (componentProps.trackChanges) {
        const onChange = componentProps.onChange;
        componentProps.onChange = ({ selection }: { selection: string[] }) => {
            //trigger existing onChange
            if (onChange) {
                onChange({ selection });
            }
            //track each selection if any
            if (!selection.length) {
                return;
            }
            const selectedItems = componentProps.entities.filter((item) =>
                selection.includes(item.properties.value)
            );
            if (selectedItems.length) {
                //different format for single/multi selection
                if (componentProps.singleSelection) {
                    triggerPageEvent({
                        customAction: getEntityProps(selectedItems[0], null, "trackingLabel")
                    });
                } else {
                    triggerPageEvent({
                        customAction:
                            "[" +
                            selectedItems
                                .map((item) => getEntityProps(item, null, "trackingLabel"))
                                .join(", ") +
                            "]"
                    });
                }
            }
        };
    }
    return <Switcher {...componentProps} />;
}
