import { useWidgetSelector } from "../../webui/store/hooks";
import { createWidget, deleteWidget, getWidget, setWidgetData } from "../../webui/store/actions";
import { useFetch } from "../httpClient/httpClient";
import { fromSirenEntityToEntity } from "../../webui/adapters";
import { Entity, SirenEntity, Widget } from "../../webui/types";
import { useEffect } from "react";
import { generateErrorWidgetEntity, isExceptionResponse } from "../utils/exceptions";

export default function useFetchWidget(inputInfo: Widget, options?: { skipCleanup: boolean }) {
    useEffect(() => {
        const widgetInfo = getWidget(inputInfo.id);
        //creating widget before loading data into it
        if (!widgetInfo) {
            createWidget(inputInfo);
        }
        return () => {
            if (!options?.skipCleanup) {
                deleteWidget(inputInfo.id);
            }
        };
    }, []);
    const { info: storeInfo } = useWidgetSelector(inputInfo.id);
    const { isLoading, error } = useFetch({
        url: inputInfo.url,
        skipFetching: () => {
            //check if widget data is not already loaded
            const widgetInfo = getWidget(inputInfo.id);
            return !!widgetInfo && !!widgetInfo.data;
        },
        successCallback: (data) => {
            let webuiData: Entity;
            if (isExceptionResponse(data)) {
                webuiData = generateErrorWidgetEntity(inputInfo.id);
            } else {
                webuiData = fromSirenEntityToEntity(data as unknown as SirenEntity);
            }
            //need to check if widget is not deleted in the meantime
            if (getWidget(inputInfo.id)) {
                setWidgetData(inputInfo.id, webuiData);
            }
        },
        actionContext: { widgetId: inputInfo.id }
    });
    return {
        isLoading: isLoading,
        error,
        info: storeInfo
    };
}
