"use client";
import React, { createContext, useContext } from "react";
import { triggerActions } from "../actions/actions";
import { WidgetContext as WidgetContextType } from "../types";
import { updateEntity, updateEntityProps } from "../store/actions";

export const getWidgetContext = ({ widgetId }: { widgetId: string }): WidgetContextType => {
    return {
        widgetId,
        triggerActions: (actions, events) => triggerActions(actions, { widgetId }, events),
        triggerEntityActions: (entity, events) => {
            const entityActions = entity ? entity.actions : [];
            const contextPath = entity ? entity.contextPath : "";
            return triggerActions(
                entityActions,
                { widgetId, currentEntityPath: contextPath },
                events
            );
        },
        methods: {
            updateEntity: ({
                entityPath,
                entity,
                widgetId: explicitWidgetId,
                currentEntityPath
            }) => {
                const targetWidgetId = explicitWidgetId || widgetId;
                return updateEntity({
                    widgetId: targetWidgetId,
                    entityPath,
                    entity,
                    currentEntityPath
                });
            },
            updateEntityProps: ({
                entityPath,
                props,
                widgetId: explicitWidgetId,
                currentEntityPath
            }) => {
                const targetWidgetId = explicitWidgetId || widgetId;
                return updateEntityProps({
                    widgetId: targetWidgetId,
                    entityPath,
                    props,
                    currentEntityPath
                });
            }
        }
    };
};

export const WidgetContext = createContext(getWidgetContext({ widgetId: "unknown" }));

export const useWidgetContext = () => {
    return useContext(WidgetContext);
};

export const WidgetContextProvider = ({ widgetId, children }) => {
    return (
        <WidgetContext.Provider value={getWidgetContext({ widgetId })}>
            {children}
        </WidgetContext.Provider>
    );
};

export default WidgetContext;
