import React from "react";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";
import RadioGroup, { IRadioGroupPropKeys } from "./RadioGroup";

export default function RadioGroupContainer(props) {
    if (!props?.entities?.length) {
        return null;
    }
    let radioGroupProps: any = extractComponentProps(props, {
        componentProps: IRadioGroupPropKeys
    });
    return <RadioGroup {...radioGroupProps} />;
}
