import { getEntityActions } from "@uneo/platform-commons/webui/search";
import { triggerActions } from "@uneo/platform-commons/webui/actions/actions";
import { traverseEntityTree } from "@uneo/platform-commons/webui/store/actions";

export const callAction = (
    widgetId,
    entity,
    {
        targets,
        events,
        recursive
    }: {
        targets: string[];
        events: string[];
        recursive: boolean;
    }
) => {
    for (const target of targets) {
        if (recursive) {
            traverseEntityTree({
                widgetId: widgetId,
                entityPath: target,
                fn: (entity) =>
                    triggerActions(
                        getEntityActions(entity, entity.id),
                        {
                            widgetId,
                            currentEntityPath: entity.contextPath
                        },
                        events
                    )
            });
        } else {
            triggerActions(
                getEntityActions(entity, entity.id),
                {
                    widgetId,
                    currentEntityPath: entity.contextPath
                },
                events
            );
        }
    }
};
