"use client";
import React from "react";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import CheckboxItem from "./CheckboxItem";
import { ComponentEnumEntity } from "../../../utils/commonTypes";
import {
    IBaseComponentProps,
    IBaseComponentPropsKeys,
    IEventHandlers,
    IEventHandlersKeys,
    ILabeledProps,
    ILabeledPropsKeys
} from "@uneo/platform-commons/platform/types";

const CheckboxGroup = (props: ICheckboxGroupProps) => {
    const value = props.value ? props.value.split(",") : [];
    const buildItems = (items: ComponentEnumEntity[]) => {
        return items.map((item, index) => {
            const itemProperties: Partial<ComponentEnumEntity["properties"]> =
                item.properties! || {};
            let disabled = props.disabled;
            if (Object.prototype.hasOwnProperty.call(itemProperties, "disabled")) {
                disabled = itemProperties.disabled;
            }
            return (
                <CheckboxItem
                    value={itemProperties.value}
                    label={itemProperties.label}
                    active={value.includes(itemProperties.value)}
                    disabled={disabled}
                    extraClasses={itemProperties.extraClasses}
                    key={itemProperties.value || "item_" + index}
                    onClick={onChange}
                    valid={props.valid}
                />
            );
        });
    };

    const onChange = (clickedElementId) => {
        const newValue = [...value];
        if (newValue.includes(clickedElementId)) {
            newValue.splice(newValue.indexOf(clickedElementId), 1);
        } else {
            newValue.push(clickedElementId);
        }

        if (props.onChange) {
            props.onChange({ value: newValue.join(",") });
        }
    };

    const items = buildItems(props.entities || []);
    const vertical = props.vertical !== false; //true by default.
    let valueContainerClass = "tq-checkbox-group";
    if (!props.verticalOptions) {
        valueContainerClass += " tq-horizontal-group";
    }
    const labelProps = getLabelProps(props, {
        containerClass: props.extraClasses || "",
        valueContainerClass: valueContainerClass,
        skipLabel: props.skipLabel || !props.label, //CheckboxGroup historically worked in a bit different way - skip label if not provided!
        vertical: vertical
    });

    return <Labeled {...labelProps}>{items}</Labeled>;
};

export interface ICheckboxGroupProps extends IBaseComponentProps, IEventHandlers, ILabeledProps {
    verticalOptions?: boolean;
    entities?: ComponentEnumEntity[];
}

export const ICheckboxGroupPropsKeys = [
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    ...ILabeledPropsKeys,
    "verticalOptions",
    "entities"
];
export default CheckboxGroup;
