import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {}
            },
            fr: {
                translation: {
                    common: {
                        buttonModify: "Modifier",
                        buttonVerify: "Vérifier",
                        confirm: "Valider",
                        submit: "Valider",
                        cancel: "Annuler",
                        clickHere: "cliquez-ici",
                        moreActions: "Plus d’actions",
                        for: "pour"
                    },
                    profileMenu: {
                        myProfileLink: "Gérer mon profil",
                        myProfile: "Mon profil"
                    },
                    memberPersonalDetails: {
                        title: "My personal details",
                        labels: {
                            country: "Pays",
                            address: "Adresse",
                            mobilePhoneNumber: "Portable",
                            homePhoneNumber: "Domicile",
                            mailAddress: "Adresse e-mail"
                        },
                        values: {
                            notSpecified: "Non renseigné"
                        }
                    },
                    memberConnectionDetails: {
                        username: "Login :",
                        password: "Mot de passe :"
                    },
                    memberContactPreferences: {
                        options: {
                            sms: "SMS",
                            phone: "Téléphone",
                            "post-mail": "Courrier",
                            email: "E-mail"
                        }
                    },
                    memberContracts: {
                        contractsInfo: {
                            seeDocuments: "Voir documents",
                            contractNumber: "N° contrat/affiliation",
                            startDate: "Date d'effet",
                            terminationDate: "Date de résiliation",
                            suspensionDate: "Date de suspension"
                        },
                        premiumsInfo: {
                            nextPayment: "Prochaine cotisation",
                            paymentMethod: "Mode de paiement",
                            frequency: "Fréquence de paiement",
                            directDebit: "Compte bancaire de prélèvement",
                            accountOwner: "Titulaire du compte",
                            iban: "IBAN",
                            bic: "BIC"
                        },
                        beneficiariesInfo: {
                            from: "Depuis le",
                            future: "A compter du"
                        }
                    },
                    memberBeneficiaries: {
                        healthInfo: {
                            title: "Données santé",
                            socialSecurityNumber: "Numéro de Sécurité sociale",
                            socialSecurityRegime: "Régime de Sécurité sociale",
                            healthInsuranceFund: "Code caisse",
                            connectedPerson: "Personne rattachée à :"
                        },
                        benefitPaymentsInfo: {
                            title: "Versement des prestations",
                            paymentRecipient: "Destinataire des prestations",
                            subtitle: "Compte bancaire",
                            bankAccountOwner: "Titulaire du compte",
                            bankAccountIban: "IBAN",
                            bankAccountBic: "BIC"
                        }
                    },
                    memberTPCard: {
                        title: "Carte de Tiers Payant",
                        downloadLabel: "Télécharger",
                        sendMailLabel: "+ Partager par email",
                        addPhone: "+ Ajouter au téléphone",
                        help: "Besoin d'aide ?"
                    },
                    memberRequestConfirmation: {
                        message: "Votre demande a été enregistrée avec succès.",
                        return: "Retour à la page d'accueil",
                        startNew: "Faire une nouvelle demande"
                    },
                    memberMyDocuments: {
                        filter: "Filtres",
                        contract: "Contrat",
                        documentType: "Type de document",
                        date: "Date d'édition"
                    },
                    memberModifyPaymentFrequency: {
                        bankAccountOwner: "Titulaire du compte",
                        bankAccountIban: "IBAN",
                        currentFrequency: "Fréquence actuelle",
                        frequency: "Nouvelle fréquence"
                    },
                    memberHospitalRefund: {
                        stepRequestTitle: "Durée du séjour",
                        stepHospitalizationTitle: "Établissement hospitalier",
                        stepCostsTitle: "Frais concernés",
                        costs: {
                            coveredCostsTitle: "Inclus",
                            optionsTitle: "Options supplémentaires"
                        },
                        helpSectionTitle: "Une question sur vos remboursements santé ?"
                    },
                    fileUploadArea: {
                        documentTypes: "Documents pdf ou photos (jpg, jpeg, png)",
                        maxFiles: "{{limit}} fichiers maximum par envoi",
                        maxSize: "{{limit}} Mo maximum par fichier"
                    },
                    documentsAssistent: {
                        title: "Quel document envoyer ?",
                        careType: "Type de soin",
                        actType: "Type d'acte",
                        paymentType: {
                            fullPayment: "Vous avez payé la totalité des frais",
                            partialPayment: "Vous avez avancé uniquement la part mutuelle"
                        },
                        requiredDocuments:
                            "Les documents requis pour la constituation de votre demande sont :"
                    },
                    helpSection: {
                        memberRequestReimbursement: {
                            title: "Un problème pour envoyer votre demande ?"
                        },
                        memberMyReibursements: {
                            title: "Une question sur vos remboursements santé ?"
                        }
                    },
                    memberRequestReimbursement: {
                        helpHint:
                            "Ajoutez une image ou un fichier pour illustrer votre problème (optionnel)"
                    },
                    memberPayMyDues: {
                        cardHolder: "Titulaire de la carte",
                        name: "Prénom Nom",
                        cardNumber: "Numéro de la carte",
                        expirationDate: "Date d'expiration",
                        cvc: "CVC"
                    },
                    memberFollowUpRequests: {
                        filter: "Filtre",
                        request: "Thème",
                        status: "Statut de la demande"
                    },
                    error: {
                        page: {
                            helpLink: {
                                text: "Si vous rencontrez un problème technique ou ne trouvez pas un contenu ou un service, n'hésitez pas à nous le signaler via notre",
                                linkText: "page contacts.",
                                subtext: "Bonne visite !"
                            },
                            tryAgain: {
                                text: "Si vous rencontrez un problème technique ou ne trouvez pas un contenu ou un service, n'hésitez pas à nous le signaler via notre"
                            }
                        }
                    },
                    flow: {
                        cancel: "Abandonner",
                        save: "Sauvegarder pour plus tard",
                        back: "Précédent",
                        submit: "Suivant",
                        continue: "Continuer",
                        validate: "Valider",
                        quit: "Retour à l'accueil",
                        finish: "Accéder aux bons plans famille"
                    },
                    newborn: {
                        child: "Enfant",
                        firstName: "Prénom",
                        lastName: "Nom",
                        birthDate: "Date de naissance",
                        steps: {
                            identity: "Identité",
                            coverages: "Garanties",
                            socialSecurityAttachment: "Rattachement Sécurité Sociale",
                            coverDate: "Date de couverture"
                        },
                        identityDocuments: {
                            declareBirthOrAdoptionTitle: "Déclarer une naissance ou une adoption",
                            declarationTypeLabel: "Je souhaite déclarer une",
                            sendBirthCertificate:
                                "Je transmets l'acte de naissance ou le livret de famille complet",
                            sendAdoptionCertificate:
                                "Je transmets le certificat d'adoption ou le livret de famille complet",
                            uploadDescription:
                                "Documents acceptés (.pdf, .jpg, .jpeg, .png)<br/>5 fichiers maximum par envoi<br/>5 Mo maximum par fichier",
                            newborn: "Naissance",
                            adoption: "Adoption",
                            addChild: "+ Ajouter un enfant",
                            removeChild: "- Retirer enfant",
                            specificCoverageStartDatesInfo:
                                "Si vous souhaitez choisir des dates de début de couverture spécifiques pour chacun de vos enfants, veuillez les déclarer un par un.",
                            documentsModalText:
                                "Afin de prendre en compte votre demande, merci de préparer les  justificatifs suivants :",
                            birthCertificate: "Acte de naissance/livret de famille",
                            adoptionCertificate: "Certificat d'adoption (le cas échéant)",
                            socialSecurityCertificate:
                                "Attestation de sécurité sociale (le cas échéant)"
                        },
                        personDetails: {
                            subtitleSingleChild: "Je renseigne l'identité de mon enfant",
                            subtitleMultipleChildren: "Je renseigne l'identité de mes enfants",
                            gender: "Sexe",
                            boy: "Garçon",
                            girl: "Fille",
                            adoptionDate: "Date d'adoption",
                            rangTitle: "Je renseigne l'ordre de naissance de mes enfants.",
                            rangSubtitle: "Rang"
                        },
                        coverageSelection: {
                            applySameGaranties: "J'applique les mêmes garanties que:",
                            additionalPayment: "Complément de cotisation à payer:",
                            birthAid:
                                "Une demande d’aide naissance est transmise automatiquement pour examen par la Commission d’action sociale",
                            otherGarantiesText: "Je souhaite d'autres garanties pour mon enfant"
                        },
                        otherGaranties: {
                            title: "Autre garanties",
                            text: "Les garanties proposées ne correspondent pas à mes besoins. Je souhaite attribuer des garanties différentes pour mon/mes enfant(s)?"
                        },
                        socialSecuritySelection: {
                            attachToAnotherPersonLabel: "Je rattache à une autre personne",
                            selectionLabel: "Je choisis (double rattachement possible)"
                        },
                        socialSecurityDocuments: {
                            subtitleSingleChild:
                                "Je téléverse l'attestation de Sécurité Sociale d'une autre personne pour le rattachement de mon enfant",
                            subtitleMultipleChildren:
                                "Je téléverse l'attestation de Sécurité Sociale d'une autre personne pour le rattachement de mes enfants"
                        },
                        socialSecurityPersonDetails: {
                            infoText:
                                "Veuillez vérifier ou modifier les données pré-renseignées avant de les valider.",
                            subtitle: "Données sécurité sociale",
                            gender: "Civilité",
                            male: "M",
                            female: "Mme",
                            socialSecurityNumber: "Numéro de sécurité sociale",
                            socialSecurityOrganization: "Organisme de rattachement sécurité sociale"
                        },
                        effectiveDate: {
                            effectiveDateLabel: "Je sélectionne une date",
                            remarkSolidarm:
                                "* Pour la garantie d'accompagnement social Solidarm, la date est le",
                            remarkCNG:
                                "* Pour la garantie d'accompagnement social CNG, la date est le",
                            declareDifferentDates: "Je souhaite déclarer des dates spécifiques ?",
                            differentStartDateModalText:
                                "Si vous souhaitez choisir des dates de début de couverture spécifiques , veuillez déclarer vos enfants un par un.",
                            eligibleChildInfoText:
                                "La date de début de couverture étant supérieure à la date du jour, nous vous invitons à formuler votre demande d'aide naissance par téléphone au",
                            phoneForEligibleChild: "0 970 809 709",
                            tariffAndTime: "(0,12 €/min) du lundi au vendredi de 8 h 30 à 17 h 30."
                        },
                        summary: {
                            title: "Récapitulatif de ma demande",
                            birthAid: "Demande Aide naissance",
                            childrenAttachedToLabel: "Vos enfants sont rattachés à",
                            childAttachedToLabel: "Votre enfant est rattaché à",
                            socialSecurityNumber: "Numéro de Sécurité sociale",
                            socialSecurityOrganization: "Code organisme",
                            contributionTitle: "Complément de cotisation à payer:",
                            totalTitle: "La prime totale sera:"
                        },
                        success: {
                            title: "Votre déclaration de naissance a bien été enregistrée.",
                            newPaymentCardMessage:
                                'Une nouvelle carte de tiers payant sera disponible dans la rubrique "Mes documents" sous 48 heures maximum.',
                            requestRefundMessage:
                                'Vous pouvez déposer vos demandes de remboursement dès maintenant depuis la rubrique "Demander un remboursement".',
                            birthAidAcceptanceMessage:
                                "Si la demande d'aide naissance est acceptée vous serez contacté par nos services.",
                            additionalInfoMessage:
                                "Toute l'équipe d'Unéo vous félicite pour cet heureux évènement et vous souhaite beaucoup de bonheur en famille!"
                        },
                        error: {
                            infoMessage:
                                "<p>Votre cas requiert une expertise. Merci de nous contacter,<br/> votre demande sera traitée dans les meilleurs délais.</p>",
                            contactUs: "Contactez-nous"
                        }
                    },
                    quote: {
                        yes: "Oui",
                        no: "Non",
                        total: "Total",
                        start: {
                            memberQuestion: "Possédez-vous déjà un espace personnel ?"
                        },
                        popin: {
                            continueMyQuote: "Continuer mon devis",
                            continueYourQuote: "Continuer votre devis",
                            title: "Pourquoi vous arrêter en si bon chemin ?",
                            text: "Votre devis est toujours en cours.<br/>Plus que quelques étapes avant de découvrir nos propositions."
                        },
                        type: {
                            familySituation: "Composition familiale",
                            insured: "Vous",
                            insuredChild: "Vous et vos enfants",
                            insuredPartner: "Votre couple*",
                            insuredPartnerChild: "Vous et votre famille*",
                            partnerDescription: "*conjoint ou partenaire"
                        },
                        coverages: {
                            pagetitle: "Mon devis",
                            badge: {
                                active: "Actuellement",
                                recommended: "Notre conseil"
                            },
                            infoLink: "En savoir plus",
                            price: {
                                free: "Gratuit"
                            },
                            selected: "Sélectionné",
                            choose: "Choisir",
                            haveProtection: "-J'ai déjà une protection",
                            coverageSelect: "Sélectionner",
                            coverageSelectionText:
                                " cette garantie va également ajouter le(s) garantie(s) :",
                            coverageDeselect: "Désélectionner",
                            coverageDeselectionText:
                                " cette garantie va également supprimer le(s) garantie(s) :",
                            confirmChoiceMessage: "Confirmez-vous votre choix?"
                        },
                        memberScore: {
                            description:
                                "Suite aux informations renseignées, vos garanties actuelles sont :",
                            submit: "Découvrez nos propositions"
                        },
                        summary: {
                            noSelectedCoverages: "Aucune garantie sélectionnée",
                            subtotal: "Sous total",
                            total: "Total"
                        },
                        contactForm: {
                            subscribe: "Souscrire",
                            firstName: "Prénom",
                            lastName: "Nom",
                            phone: "Téléphone",
                            email: "E-mail",
                            yourMessage: "Votre message"
                        }
                    },
                    myQuotes: {
                        quote: "Devis",
                        valid: "Valide encore: ",
                        created: "Créé le",
                        day: "jour",
                        days: "jours",
                        neededDocuments: "Justificatifs à préparer",
                        total: "Total",
                        subscribe: "Souscrire",
                        viewCoverages: "Voir les garanties du devis",
                        documentsTitle: "Documents liés au devis",
                        documentsModalTitle: "Souscription en plusieurs étapes",
                        documentsModalText:
                            "Vous aurez besoin des pièces justificatives suivantes :"
                    },
                    affiliation: {
                        saveModal: {
                            cancel: "Non, je retourne",
                            confirm: "Oui, je vais finir plus tard",
                            text: "Vous pouvez reprendre votre affiliation à tout moment dans votre espace personnel Unéo",
                            title: "Vous avez encore %s jours pour finaliser votre affiliation"
                        },
                        introduction: {
                            hello: "Bonjour ",
                            necessaryTime: "Temps nécessaire",
                            neededDocuments: "Documents à préparer"
                        },
                        personDetails: {
                            identity: "Identité",
                            mySocialSecurity: "Ma sécurité sociale",
                            socialSecurity: "Sécurité sociale",
                            yes: "Oui",
                            no: "Non",
                            firstName: "Prénom",
                            lastName: "Nom",
                            birthDate: "Date de naissance",
                            country: "Pays",
                            phone: "Téléphone",
                            email: "Email",
                            gender: "Civilité",
                            childGender: "Sexe",
                            socialSecurityNumber: "N° de sécurité sociale",
                            socialSecurityOrganization: "Organisme de rattachement",
                            radladInfoMessage:
                                "Veuillez vérifier ou modifier les données pré-renseignées avant de les valider.",
                            employee: {
                                contactData: "Mes coordonnées",
                                bankData: "Mes données bancaires",
                                bankAccountOwner: "Titulaire du compte",
                                iban: "IBAN",
                                bic: "BIC",
                                company: "Entreprise",
                                ibanForServicePayments: "IBAN de versement des prestations",
                                ibanForPayments:
                                    "IBAN de versement des prestations et de prélèvement des cotisations",
                                country: "Pays",
                                address: "Adresse",
                                streetNumber: "Numéro de voie",
                                additionalStreetNumber: "Complément numéro",
                                streetName: "Nom de voie",
                                addressAdditionalInformation: "Complément d'adresse",
                                postalCode: "Code postal",
                                city: "Ville",
                                recipientAdditionalInfo: "Complément destinataire",
                                modify: "Modifier",
                                cancel: "Annuler"
                            },
                            child: {
                                socialSecurityAttachmentPerson:
                                    "Rattachement Sécurité Sociale Personne %d"
                            }
                        },
                        identityCoverages: {
                            requiredGroup: "Garanties obligatoires",
                            optionalGroup: "Garanties facultatives",
                            additionalInformation: "En savoir plus",
                            employee: {
                                requiredGroup: "Mon socle santé",
                                optionalGroup: "J’ajoute une option"
                            }
                        },
                        addBeneficiaries: {
                            additionalBeneficiary: "Bénéficiaire supplémentaire",
                            addSpouse: "+ Ajouter mon conjoint",
                            removeSpouse: "- Annuler",
                            addChild: "+ Ajouter mon enfant",
                            removeChild: "- Annuler",
                            spouse: "Conjoint",
                            child: "Mon enfant %d",
                            firstName: "Prénom",
                            lastName: "Nom",
                            selectEligibleBeneficiaries:
                                "Séléctionner les bénéficiaires connus éligibles"
                        },
                        socialSecurityAttachment: {
                            attachToPeople: "Mon enfant est rattaché à une ou deux personnes",
                            ownSocialSecurity:
                                "Mon enfant est couvert par sa propre sécurité sociale",
                            socialSecurityCertificate: "Attestation de sécurité sociale",
                            firstName: "Prénom",
                            lastName: "Nom",
                            otherPerson: "Autre personne"
                        },
                        ownSocialSecurityDetails: {
                            socialSecurityNumber: "N° de sécurité sociale",
                            socialSecurityOrganization:
                                "Organisme de rattachement sécurité sociale",
                            socialSecurityData: "Données sécurité sociale"
                        },
                        socialSecurityDocuments: {
                            socialSecurityCertificate: "Attestations de sécurité sociale"
                        },
                        summary: {
                            contributionAmount: "Cotisation mensuelle prélevée par Unéo",
                            iban: "RIB",
                            changedCoverages:
                                "Vous avez choisi de modifier votre couverture à partir du",
                            changedBeneficiaries:
                                "Vous avez choisi d'ajouter ces bénéficiaires à votre contrat PSC à partir du",
                            newMonthlyContribution: "Ma famille",
                            chargedIban: "Prélevée par Unéo sur l'IBAN",
                            mainInsured: "Assuré principal",
                            myAttachedRelatives: "Ma famille",
                            summaryByProtectedPeople: "Récap par personne protégée"
                        },
                        tariffInformationSummary: {
                            detailsByAddedBeneficiaries: "Détails par bénéficiaire ajouté",
                            additionalMonthlyCost: "Coût supplémentaire mensuel"
                        },
                        success: {
                            improveGaranties: "Améliorez vos garanties",
                            return: "Retour à l'accueil",
                            addBeneficiaries: "J'ajoute mes proches"
                        },
                        signature: {
                            toSign: "A signer"
                        },
                        adjustCoverages: {
                            myProtections: "Couverture actuelle",
                            availableOptions: "Option disponible",
                            permanentCoverage: "Garantie obligatoire",
                            addCoverage: "+ Ajouter l'option",
                            removeCoverage: "- Retirer l'option",
                            confirmReplace: "Oui, remplacer",
                            cancel: "Annuler",
                            terminationRequestTitle:
                                "Votre demande de résiliation ne peut être effective qu'à partir du %s",
                            terminationRequestText:
                                "Désolé, merci de renouveler votre demande dès le %s",
                            terminationRequestConfirm: "J'ai compris",
                            terminationRequestCancel: "Je modifie ma demande"
                        },
                        effectiveDate: {
                            coverageStartDate: "Date souhaitée"
                        },
                        rang: {
                            birthRangOf: "Rang de naissance de"
                        },
                        cancelIndividualContract: {
                            cancellingCoveragesOnContract:
                                "En résiliant les couvertures suivantes sur votre contrat",
                            coveragesTerminationEffectDate:
                                "La résiliation de ces garanties prendra effet ",
                            le: "le",
                            willConcern: "et concernera:"
                        },
                        contextualHelp: {
                            confirm: "OK, j'ai compris"
                        }
                    },
                    smartBanner: {
                        open: {
                            button: "Ouvrir",
                            text: "Ouvrir dans l’application"
                        },
                        view: {
                            button: "Voir",
                            text: {
                                iOS: "Gratuit sur l'App Store",
                                Android: "Gratuit sur Google Play"
                            }
                        }
                    },
                    tutorials: {
                        skip: "Passer"
                    }
                }
            }
        }
    });

export default i18n;
