export const maxFileSize = (value, size) => {
    const files = (value && value.files) || [];
    return files.every((file) => {
        if (!file.size) {
            //in case there is no file.size
            //this file has only description (sent from BE)
            return true;
        }
        return file.size <= size * 1024 * 1024;
    });
};
