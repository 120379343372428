//implementing Promise.allSettled for arrays of Promises
//resolving when all input promises have resolved/rejected
export default async function (promiseArray: Promise<any>[] = []) {
    let result: Array<any> = [];
    try {
        result = await new Promise((resolve) => {
            let counter = 0;
            if (promiseArray.length === 0) {
                resolve(promiseArray);
            }
            promiseArray.forEach((singlePromise) => {
                singlePromise
                    .then(() => {
                        counter++;
                        if (counter === promiseArray.length) {
                            resolve(promiseArray);
                        }
                    })
                    .catch(() => {
                        counter++;
                        if (counter === promiseArray.length) {
                            resolve(promiseArray);
                        }
                    });
            });
        });
    } catch (error) {
        console.error(error);
    }
    return result;
}
