import { Entity } from "../../webui/types";
import { ValidationContext, ValidationModel, ValidationRule } from "./interfaces";

const REQUIRED_RULE_TYPE = "required";
export const getValidationContext = (entity: Entity): ValidationContext => {
    return {
        rules: getValidationRules(entity),
        model: getValidationModel(entity)
    };
};
//TODO consider using the whole widget as a model instead of using only target entity
export const getValidationModel = (entity: Entity): ValidationModel => {
    const result = {};
    const applyFn = (entity: Entity) => {
        if (entity.properties?.data || entity.properties?.value) {
            result[entity.contextPath] = entity.properties?.data || entity.properties?.value;
        }
        if (entity.entities) {
            entity.entities.forEach((innerEntity) => {
                applyFn(innerEntity);
            });
        }
    };
    applyFn(entity);
    return result;
};

export const getValidationRules = (entity: Entity): Record<string, ValidationRule[]> => {
    const properties = entity.properties || {};
    const definedValidations = properties.validations || [];
    const result: Record<string, ValidationRule[]> = {};
    if (definedValidations.length) {
        result[entity.id] = definedValidations.map((item) => {
            return Object.assign({}, item);
        });
    }
    //adding required rule since required:true is set in properties
    //TODO move properties.required to a separate rule in contract
    if (properties.required) {
        addRequiredValidation(result, definedValidations, entity.id);
    }
    return result;
};

const addRequiredValidation = (validationResult, validationRules, entityId) => {
    let hasRequiredRule = false;
    if (validationRules.length) {
        hasRequiredRule = validationRules.find((item) => item.type === REQUIRED_RULE_TYPE);
    }
    if (!hasRequiredRule) {
        if (validationResult[entityId]) {
            validationResult[entityId].push({ type: REQUIRED_RULE_TYPE });
        } else {
            validationResult[entityId] = [{ type: REQUIRED_RULE_TYPE }];
        }
    }
};
