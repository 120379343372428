import React, { useEffect, useRef } from "react";

export default function DropdownOption(props: IDropdownOptionProps) {
    let className = props.selected ? "tq-current" : "";
    className += props.disabled ? " tq-disabled" : "";
    className +=
        props.keyboardMarker.value === props.value && props.keyboardMarker.visible
            ? " tq-keyboard-nav"
            : "";
    className += props.withCheckbox && props.selected ? " tq-selected" : "";
    const optionRef = useRef(null);
    useEffect(() => {
        if (
            props.keyboardMarker &&
            props.scrollbarRef?.current &&
            props.keyboardMarker.value === props.value
        ) {
            const option = optionRef?.current;
            let scrollTop = 0;
            if (option && option.offsetTop > 0) {
                scrollTop = option.offsetTop - option.offsetHeight;
            }
            props.scrollbarRef?.current?.scrollTop(scrollTop);
        }
    }, [props.keyboardMarker.value, props.keyboardMarker.visible]);
    return (
        <li tabIndex={-1} data-code={props.value} className={className} ref={optionRef}>
            {props.withCheckbox && (
                <div className="tq-checkbox" data-code={props.value}>
                    <input
                        disabled={props.disabled}
                        defaultChecked={props.selected}
                        className="tq-checkbox"
                        type="checkbox"
                        tabIndex={-1}
                        data-code={props.value}
                        value={props.value}
                    />
                </div>
            )}
            {props.label && (
                <span data-code={props.value} className="tq-text-node">
                    {props.label}
                </span>
            )}
        </li>
    );
}

export interface IDropdownOptionProps {
    value: string;
    label: string;
    selectedLabel?: string;
    selected?: boolean;
    disabled?: boolean;
    withCheckbox?: boolean;
    keyboardMarker?: { value: string | null; visible: boolean };
    scrollbarRef?: any;
}
