"use client";
import React, { useState, useRef, useEffect } from "react";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import isDecimal from "@tinqin/tinqin-utils/src/validations/isDecimal";
import Localization from "@tinqin/tinqin-utils/src/localization";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import ObjectUtils from "@tinqin/tinqin-utils/src/object";
import { getLabelProps, isEqual } from "../../../utils/utils";
import {
    ILabeledProps,
    IBaseComponentProps,
    IEventHandlers,
    ILabeledPropsKeys,
    IBaseComponentPropsKeys,
    IEventHandlersKeys
} from "../../../types";

type TNumericProps = {
    inputMode?:
        | "numeric"
        | "text"
        | "search"
        | "email"
        | "tel"
        | "url"
        | "none"
        | "decimal"
        | undefined;
    pattern?: "[0-9]*";
};

const InputNumber = (props: InputNumberProps): JSX.Element => {
    const getLocaleAdvancedOptions = (properties) => {
        const componentProps = properties || props;
        let minimumFractionDigits = 2;
        let maximumFractionDigits: number | undefined = 2;
        if (
            componentProps.minimumFractionDigits !== undefined &&
            componentProps.minimumFractionDigits >= 0
        ) {
            minimumFractionDigits = componentProps.minimumFractionDigits;
            maximumFractionDigits = undefined;
        }
        if (
            componentProps.maximumFractionDigits !== undefined &&
            componentProps.maximumFractionDigits >= 0
        ) {
            maximumFractionDigits = componentProps.maximumFractionDigits;
            if (!componentProps.minimumFractionDigits) {
                minimumFractionDigits = 0;
            }
        }
        //if only minFractionDigits is set and it is more than the default then max gets the same value as min
        if (maximumFractionDigits && minimumFractionDigits > maximumFractionDigits) {
            maximumFractionDigits = minimumFractionDigits;
        }
        return {
            minimumFractionDigits,
            maximumFractionDigits
        };
    };
    const getSeparators = () => {
        const separators: string[] = [];
        if (!props.skipLocalization) {
            const guessedDecimalSeparator = Localization.guessDecimalSeparator(props.locale);
            if (guessedDecimalSeparator) {
                separators.push(guessedDecimalSeparator);
            }
        }
        if (props.additionalSeparator) {
            separators.push(props.additionalSeparator);
        }
        return separators;
    };
    const computeNewStateValue = (newValue: string | undefined, thisProps?) => {
        const properties = thisProps || props;
        let value: string | null = newValue !== undefined ? newValue : null; //The empty value is null others are numbers!
        const options = getLocaleAdvancedOptions(properties);
        let displayValue = "";
        if (value || value === "0") {
            if (props.skipLocalization) {
                displayValue = `${value}`;
            } else {
                if (value !== state?.value) {
                    const separators = getSeparators();
                    const regexSeparators = new RegExp(
                        separators.join("|").replace(".", "\\."),
                        "g"
                    );
                    const [, fractionDigits] = value.split(regexSeparators);
                    if (
                        options.minimumFractionDigits &&
                        (!fractionDigits || fractionDigits?.length < options.minimumFractionDigits)
                    ) {
                        value = Number(value.replace(",", ".")).toFixed(
                            options.minimumFractionDigits
                        );
                    }
                    if (
                        options.maximumFractionDigits &&
                        fractionDigits?.length > options.maximumFractionDigits
                    ) {
                        value = Number(value.replace(",", ".")).toFixed(
                            options.maximumFractionDigits
                        );
                    }
                }
                displayValue = Localization.localizeDecimal(
                    value,
                    properties.locale,
                    options
                ).replace(",", ".");
            }
        }

        return {
            value: value,
            displayValue: displayValue
        };
    };
    const mounted = useRef<boolean>(false);
    let valueProps: Record<string, any> = {};
    // let valueProps: Record<string, any> = computeNewStateValue(props.value, props);
    if (!mounted.current) {
        valueProps = computeNewStateValue(props.value, props);
        mounted.current = true;
    }
    const inputNumber = useRef<HTMLInputElement>(null);
    const [state, setState] = useState<Record<string, any>>({
        value: valueProps.value,
        displayValue: valueProps.displayValue,
        failedValidations: props.failedValidations || {},
        valid: props.valid
    });

    useEffect(() => {
        const toSet: Record<string, any> = {};

        if (props.value !== state.value) {
            const newValues = computeNewStateValue(props.value, props);
            toSet.value = newValues.value;
            toSet.displayValue = newValues.displayValue;
        }

        if (props.valid !== state.valid) {
            toSet.valid = props.valid;
        }
        if (toSet.value && props.valid === state.valid) {
            toSet.valid = undefined;
        }

        if (!isEqual(props.failedValidations, state.failedValidations)) {
            toSet.failedValidations = props.failedValidations;
        }

        if (!ObjectUtils.isEmpty(toSet)) {
            setState({ ...state, ...toSet });
        }
    }, [props.valid, props.failedValidations, props.value]);

    const fitValueToMaxCharacters = (newValue) => {
        const maxDigits = props.maxDigits || 15; //Constraint of toLocaleString();
        const separators = getSeparators();

        let digitsCounter = 0;
        let maxIndexExceed;
        for (const char of newValue) {
            const currentCharacter = char;
            const space32 = String.fromCharCode(32);
            const space160 = String.fromCharCode(160);
            const space8239 = String.fromCharCode(8239); //fr thousands separator
            //Note isNaN(" ") is false we must exclude it!
            if (
                currentCharacter !== space32 &&
                currentCharacter !== space160 &&
                currentCharacter !== space8239 &&
                !isNaN(currentCharacter)
            ) {
                digitsCounter++;
            } else if (separators.includes(currentCharacter)) {
                break;
            }

            if (digitsCounter > maxDigits) {
                maxIndexExceed = true;
                break;
            }
        }
        if (maxIndexExceed) {
            return state.displayValue;
        }

        return newValue;
    };

    const validateValue = (value, validationType = "soft") => {
        const validationProps: Record<string, any> = {
            validationType,
            additionalSeparator: props.additionalSeparator,
            smart: true
        };
        if (!props.skipLocalization) {
            validationProps.locale = Localization.getLocale(props.locale);
        }
        let isValid = isDecimal(value, validationProps);
        //By default toLocaleString with maximumFractionDigits will round the value.
        //When maximumFractionDigits === 0 we don't want that by specification.
        //We want the user to be unable to input separator in that case.
        const decimalSeparators = getSeparators();
        const hundredsSeparators = ArrayUtils.arrayWithout([".", ","], decimalSeparators);
        hundredsSeparators.push(String.fromCharCode(32));
        hundredsSeparators.push(String.fromCharCode(160));
        hundredsSeparators.push(String.fromCharCode(8239)); //fr thousands separator
        let forbiddenCharacters: string[] = [];
        if (props.maxDigits && props.maxDigits < 4) {
            //hundred separators are not allowed on numbers with less than 4 digits.
            forbiddenCharacters = forbiddenCharacters.concat(hundredsSeparators);
        }
        if (props.maximumFractionDigits === 0) {
            forbiddenCharacters = forbiddenCharacters.concat(decimalSeparators);
        }
        forbiddenCharacters.forEach((forbiddenCharacter) => {
            if (value.includes(forbiddenCharacter)) {
                isValid = false;
            }
        });

        return isValid;
    };

    const parseValue = (value) => {
        if (props.skipLocalization) {
            const parsedValue = parseFloat(value);
            if (isNaN(parsedValue)) {
                return null;
            } else {
                return props.stripLeadingZeros ? parsedValue : value;
            }
        } else {
            return Localization.parseLocalDecimal(value, {
                locale: props.locale,
                additionalSeparator: props.additionalSeparator
            });
        }
    };

    //Used for setting the state when the value of the input changes. Called when the value of the input changes.
    const onChange = (event) => {
        let newValue = event.currentTarget.value;
        newValue = fitValueToMaxCharacters(newValue);
        const toSet: Record<string, any> = {};
        if (!newValue) {
            toSet.displayValue = newValue;
            toSet.value = null;
        } else {
            const isValid = validateValue(newValue, "soft");

            if (isValid) {
                //if the value is considered valid we are setting displayValue
                toSet.displayValue = newValue;
                toSet.value = parseValue(newValue);
            }
        }
        //clear the validations once we have changed the input value
        toSet.valid = undefined;
        toSet.failedValidations = {};

        if (!ObjectUtils.isEmpty(toSet)) {
            setState({ ...state, ...toSet });
        }
        if (props.onChange) {
            props.onChange({ ...state, ...toSet });
        }
    };

    //Used when un-focusing the input box.
    const onBlur = (event) => {
        let newValue = event.currentTarget.value;
        let toSet = {};
        if (newValue) {
            const separators = getSeparators();

            const isValid = validateValue(newValue, "hard");
            if (!isValid) {
                //Revert to last valid value!
                toSet = computeNewStateValue(state.value);
            } else {
                //used for auto-completing the decimal numbers if only <separator> and a number is provided(adds zero(s))
                if (separators.includes(newValue[newValue.length - 1])) {
                    const minimumFractionDigits = props.minimumFractionDigits || 1;
                    newValue += new Array(minimumFractionDigits).join("0");
                } else if (separators.includes(newValue[0])) {
                    newValue = "0" + newValue;
                }

                Object.assign(toSet, computeNewStateValue(newValue));
            }
        }

        if (!ObjectUtils.isEmpty(toSet)) {
            setState({ ...state, ...toSet });
        }
        if (props.onBlur) {
            props.onBlur({ ...state, ...toSet });
        }
    };

    //Deal with error message to be displayed.
    //"numKeyboardProps" shows the numeric keyboard on mobile devices
    const numKeyboardProps: TNumericProps = props.numericKeyboard
        ? { inputMode: "numeric", pattern: "[0-9]*" }
        : {};
    //Deal with Labeled component props.
    const valueContainerClass = props.valueContainerClass
        ? props.valueContainerClass
        : `tq-number-input `;
    const labeledProps = getLabelProps(props, {
        ...state,
        valueContainerClass: valueContainerClass,
        extraClasses: props.suffix
            ? `${props.extraClasses} un-input-with-suffix`
            : props.extraClasses
    });
    //Deal with valueAlignment
    let valueAlignment = "";
    if (props.valueAlignment) {
        valueAlignment = " tq-text-" + props.valueAlignment;
    }
    return (
        <Labeled {...labeledProps}>
            <input
                type="text"
                {...numKeyboardProps}
                ref={inputNumber}
                className={"tq-input" + valueAlignment}
                disabled={props.disabled}
                onChange={onChange}
                placeholder={props.placeholder}
                onBlur={onBlur}
                value={state.displayValue}
                autoComplete={props.autocomplete}
            />
            {props.suffix && <span className="un-input-label">{props.suffix}</span>}
        </Labeled>
    );
};

InputNumber.displayName = "InputNumber";
interface InputNumberProps extends ILabeledProps, IBaseComponentProps, IEventHandlers {
    suffix?: string;
    numericKeyboard?: boolean;
    placeholder?: string;
    locale: string;
    skipLocalization?: boolean;
    additionalSeparator?: string;
    valueAlignment?: "left" | "center" | "right";
    maxDigits?: number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    stripLeadingZeros?: boolean;
    autocomplete?: string;
    failedValidations?: Record<string, any>;
}

export const InputNumberPropsKeys = [
    ...ILabeledPropsKeys,
    ...IBaseComponentPropsKeys,
    ...IEventHandlersKeys,
    "suffix",
    "numericKeyboard",
    "valueContainerClass",
    "placeholder",
    "locale",
    "skipLocalization",
    "additionalSeparator",
    "valueAlignment",
    "maxDigits",
    "minimumFractionDigits",
    "maximumFractionDigits",
    "stripLeadingZeros",
    "autocomplete"
];

export default InputNumber;
