import { EvaluationResult, FunctionElement, FunctionElementFactory } from "../types";
import {
    basicToStringMethod,
    checkExactNumberOfParameters,
    checkMinNumberOfParameters,
    checkPrimitiveType,
    isInvalid
} from "./utils";

//Basic functions, accepting array of values

//math functions
//casting EvaluationResult[] to number[] for math function here is skipped
//type cheking is made explicitly via checkPrimitiveType method
const min = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    return Math.min(...args);
};

const max = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    return Math.max(...args);
};

const sum = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    return args.reduce((acc, curr) => acc + curr);
};

const subtract = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    return args.reduce((acc, curr) => acc - curr);
};

const multiply = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    return args.reduce((acc, curr) => acc * curr);
};

const divide = (args: number[] = []): number => {
    checkMinNumberOfParameters(args, 1);
    checkPrimitiveType(args, "number");
    const zeroArg = args.find((arg, i) => i !== 0 && arg === 0);
    if (zeroArg !== undefined) {
        throw new Error(`Division by 0: One of the arguments, starting from the second one, is 0.`);
    }
    return args.reduce((acc, curr) => acc / curr);
};

//logical functions
const isNull = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const value: any = args[0];
    return isInvalid(value);
};

const notNull = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    return !isNull(args);
};

const eq = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    let result = true;
    const first: EvaluationResult = args[0];
    checkMinNumberOfParameters(args, 1);
    args.forEach((item) => {
        if (result && first !== item) {
            result = false;
        }
    });
    return result;
};

const notEq = (args: EvaluationResult[] = []): boolean => {
    return !eq(args);
};

const gt = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const left: EvaluationResult = args[0];
    const right: EvaluationResult = args[1];
    checkExactNumberOfParameters(args, 2);
    checkPrimitiveType([left, right], "number");
    return left > right;
};

const ge = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const left: EvaluationResult = args[0];
    const right: EvaluationResult = args[1];
    checkExactNumberOfParameters(args, 2);
    checkPrimitiveType([left, right], "number");
    return left >= right;
};

const lt = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const left: EvaluationResult = args[0];
    const right: EvaluationResult = args[1];
    checkExactNumberOfParameters(args, 2);
    checkPrimitiveType([left, right], "number");
    return left < right;
};

const le = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const left: EvaluationResult = args[0];
    const right: EvaluationResult = args[1];
    checkExactNumberOfParameters(args, 2);
    checkPrimitiveType([left, right], "number");
    return left <= right;
};

const isTrue = (args: EvaluationResult[] = []): boolean => {
    checkMinNumberOfParameters(args, 1);
    return !!args[0];
};

//null?, undefined?
const isFalse = (args: EvaluationResult[] = []): boolean => {
    return !isTrue(args);
};
// null?, undefined?
const not = (args: EvaluationResult[] = []): boolean => {
    return isFalse(args);
};

const and = (args: EvaluationResult[] = []): boolean => {
    checkMinNumberOfParameters(args, 1);
    return !!args.reduce((acc, curr) => !!acc && !!curr);
};

const or = (args: EvaluationResult[] = []): boolean => {
    checkMinNumberOfParameters(args, 1);
    return !!args.reduce((acc, curr) => !!acc || !!curr);
};

//List functions
const list = (args: EvaluationResult[] = []): EvaluationResult[] => args;

//"in" is a reserved word
const IN = (args: EvaluationResult[] = []): boolean => {
    if (args === null) {
        args = [];
    }
    const value: EvaluationResult = args[0];
    const inputList: EvaluationResult = args[1];
    checkExactNumberOfParameters(args, 2);
    if (typeof inputList === "string") {
        return inputList.includes(value.toString());
    } else {
        return false;
    }
};

const notIn = (args: EvaluationResult[] = []): boolean => {
    return !IN(args);
};

//Only for testing purposes
// const notInTest = (args: any[] = []): boolean => {
//     return !IN(args);
// };

const isEmpty = (args: EvaluationResult[][] = []): boolean => {
    const inputList = args[0] as { length: number };
    checkExactNumberOfParameters(args, 1);
    if (inputList.length || inputList.length === 0) {
        return inputList.length === 0;
    } else {
        throw new Error(`Argument is not a list.`);
    }
};

export const createBasicFunctionElement =
    (basicFunction: BasicFunctions) =>
    (name: string, args: FunctionElement[]): FunctionElement => {
        const evaluate = async (state: Record<string, any>): Promise<EvaluationResult> => {
            let operands: EvaluationResult[];
            let result: any; // ReturnType<BasicDictionaryValues>;
            if (!basicFunction) {
                return Promise.reject(`${toString()} -> Unknown operation "${name}"`);
            }
            try {
                operands = await Promise.all(
                    args.map((functionElement) => functionElement.evaluate(state))
                );
                result = basicFunction(operands, state);
            } catch (error) {
                return Promise.reject(`${toString()} -> ${error}`);
            }
            return result;
        };
        const toString = basicToStringMethod(name, args);
        return {
            name,
            evaluate,
            toString
        };
    };

export type BasicFunctionElementNames =
    | "min"
    | "max"
    | "sum"
    | "subtract"
    | "divide"
    | "multiply"
    | "isNull"
    | "notNull"
    | "eq"
    | "notEq"
    | "gt"
    | "ge"
    | "lt"
    | "le"
    | "isTrue"
    | "isFalse"
    | "not"
    | "and"
    | "or"
    | "list"
    | "in"
    | "notIn"
    | "isEmpty";

type BasicFunctions = (
    args: EvaluationResult[],
    state: Record<string, any>
) => number | boolean | EvaluationResult[];

export const basicFunctionDictionary: Record<BasicFunctionElementNames, BasicFunctions> = {
    min,
    max,
    sum,
    subtract,
    divide,
    multiply,
    isNull,
    notNull,
    eq,
    notEq,
    gt,
    ge,
    lt,
    le,
    isTrue,
    isFalse,
    not,
    and,
    or,
    list,
    in: IN,
    notIn,
    isEmpty
    // notInTest
};

//creating FunctionELements from basic functions
// - Record<string, never> => representation of Empty Object
export const dictionary = Object.entries(basicFunctionDictionary).reduce((acc, [key, value]) => {
    acc[key] = createBasicFunctionElement(value);
    return acc;
}, {}) as Record<BasicFunctionElementNames, FunctionElementFactory>;
