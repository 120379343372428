"use client";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import {
    areParentsVisible,
    getRoundedBoundingClientRect,
    isEventOutsideContainer,
    isElementInsideContainer,
    isInModal,
    isOutOfWindowViewport
} from "../../utils/dom";
import { isIOSMobileDevice } from "../../utils/device";
import { IBaseComponentProps } from "../../types";

export default function Popover(props: IPopoverProps) {
    const { anchor } = props;
    const activeRef = useRef(props.active);
    const contentRef = useRef(null);
    useEffect(() => {
        if (activeRef.current !== props.active) {
            if (props.active) {
                _open();
            } else {
                _close();
            }
            activeRef.current = props.active;
        } else if (props.active) {
            _move();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        return function cleanup() {
            document.removeEventListener("scroll", _recalculatePosition);
            window.removeEventListener("resize", _recalculatePosition);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getContent = () => {
        const element = contentRef.current;
        if (element && (element as HTMLElement).childNodes) {
            return (element as HTMLElement).childNodes[0] as HTMLElement;
        }
        return element;
    };

    const _recalculatePosition = (event) => {
        const content = getContent();
        if (content) {
            const isScrollOutsidePopover = isEventOutsideContainer(event, content);
            if (isScrollOutsidePopover && content !== event.target) {
                if (_inViewport()) {
                    content.classList.remove("tq-hidden");
                    content.classList.add("tq-visible");
                    _move();
                } else {
                    content.classList.remove("tq-visible");
                    content.classList.add("tq-hidden");
                }
            }
        }
    };

    const _open = () => {
        const content = getContent();

        if (content && anchor) {
            if (isInModal(anchor)) {
                content.classList.add("tq-in-modal");
            }

            document.addEventListener("scroll", _recalculatePosition);
            window.addEventListener("resize", _recalculatePosition);
            if (areParentsVisible(anchor)) {
                content.classList.remove("tq-hidden");
                //call anchor getBoundingClientRect and pass the result to _calculateDimensions function
                const anchorRect = getRoundedBoundingClientRect(anchor);
                const dimensions = _calculateDimensions(anchorRect);
                if (!dimensions) {
                    return;
                }
                //Note: below we assume that popover don't want to be narrower than its anchor!
                if (props.minWidthToAnchor) {
                    content.style.minWidth = anchorRect.width + "px";
                }
                if (props.maxWidthToAnchor) {
                    content.style.maxWidth = anchorRect.width + "px";
                }

                content.style.width = "";
                const contentWidth = dimensions.contentWidth + "px";
                if (content) {
                    content.style.width = contentWidth;
                    _move();
                }
            }
        }
    };
    const _close = () => {
        document.removeEventListener("scroll", _recalculatePosition);
        window.removeEventListener("resize", _recalculatePosition);
    };

    const _inViewport = () => {
        const content = getContent();
        if (anchor) {
            if (anchor.offsetWidth === 0 || anchor.offsetHeight === 0) {
                return false;
            }
            const anchorRect = getRoundedBoundingClientRect(anchor);
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const midX = (anchorRect.left + anchorRect.right) / 2;
            const midY = (anchorRect.top + anchorRect.bottom) / 2;
            const inViewport =
                anchorRect.top >= 0 &&
                anchorRect.bottom <= windowHeight &&
                anchorRect.left >= 0 &&
                anchorRect.right <= windowWidth;

            if (!inViewport) {
                return false;
            }

            let topNode = document.elementFromPoint(midX, midY);
            let anchorIsOnTop = false;
            let contentIsOnTop = false;
            while (topNode && topNode.parentNode && !anchorIsOnTop && !contentIsOnTop) {
                anchorIsOnTop = topNode === anchor;
                contentIsOnTop = topNode === content;
                topNode = topNode.parentNode as Element;
            }
            return (anchorIsOnTop || contentIsOnTop) && inViewport;
        }
        return false;
    };

    const _calculateDimensions = (anchorRect) => {
        const content = getContent();
        if (!content) {
            return { contentWidth: 0, contentHeight: 0 };
        }
        const contentRect = getRoundedBoundingClientRect(content);
        const anchorWidth = anchorRect.width;
        const computedStyle = window.getComputedStyle(content);
        const marginTop = parseFloat(computedStyle.marginTop.replace("px", ""));
        const marginBottom = parseFloat(computedStyle.marginBottom.replace("px", ""));
        const marginLeft = parseFloat(computedStyle.marginLeft.replace("px", ""));
        const marginRight = parseFloat(computedStyle.marginRight.replace("px", ""));
        let contentWidth;
        contentWidth = contentRect.width + marginLeft + marginRight;
        if (props.minWidthToAnchor && props.maxWidthToAnchor) {
            contentWidth = anchorWidth;
        } else if (props.minWidthToAnchor) {
            contentWidth = contentWidth < anchorWidth ? anchorWidth : contentWidth;
        } else if (props.maxWidthToAnchor) {
            contentWidth = contentWidth > anchorWidth ? anchorWidth : contentWidth;
        }
        const contentHeight = contentRect.height + marginTop + marginBottom;
        return { contentWidth, contentHeight };
    };

    const _calculateDirection = () => {
        if (!anchor) {
            return;
        }
        const anchorRect = getRoundedBoundingClientRect(anchor);
        const dimensions = _calculateDimensions(anchorRect);
        const {
            outOfBoundsTop,
            outOfBoundsAnchorTop,
            outOfBoundsHalfTop,
            outOfBoundsBottom,
            outOfBoundsAnchorBottom,
            outOfBoundsHalfBottom,
            outOfBoundsRight,
            outOfBoundsAnchorRight,
            outOfBoundsHalfRight,
            outOfBoundsLeft,
            outOfBoundsAnchorLeft,
            outOfBoundsHalfLeft
        } = isOutOfWindowViewport(anchorRect, {
            height: dimensions.contentHeight,
            width: dimensions.contentWidth
        });

        const preferredPosition = props.preferredPosition || "bottom";
        let direction: string = preferredPosition;
        let className = "";

        if (preferredPosition === "bottom-left") {
            className = "tq-bottom-left-pos";
            if (outOfBoundsBottom) {
                direction = "top-left";
                className = "tq-top-left-pos";
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfRight) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft) {
                    direction = "top-right";
                    className = "tq-top-right-pos";
                }
            } else {
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfRight) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "bottom";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft) {
                    direction = "bottom-right";
                    className = "tq-bottom-right-pos";
                }
            }
        }
        if (preferredPosition === "bottom-right") {
            className = "tq-bottom-right-pos";
            if (outOfBoundsBottom) {
                direction = "top-right";
                className = "tq-top-right-pos";
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (
                    outOfBoundsAnchorLeft &&
                    outOfBoundsAnchorRight &&
                    outOfBoundsHalfRight
                ) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-top-pos";
                } else if (outOfBoundsTop && outOfBoundsAnchorRight) {
                    direction = "bottom-left";
                    className = "tq-bottom-left-pos";
                } else if (outOfBoundsAnchorRight) {
                    direction = "top-left";
                    className = "tq-top-left-pos";
                } else if (outOfBoundsTop) {
                    direction = "bottom-right";
                    className = "tq-bottom-right-pos";
                }
            } else {
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (
                    outOfBoundsAnchorLeft &&
                    outOfBoundsAnchorRight &&
                    outOfBoundsHalfRight
                ) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "bottom";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorRight) {
                    direction = "bottom-left";
                    className = "tq-bottom-left-pos";
                }
            }
        }
        if (preferredPosition === "bottom") {
            className = "tq-bottom-pos";
            if (outOfBoundsBottom) {
                direction = "top";
                className = "tq-top-pos";
                if (outOfBoundsHalfRight && outOfBoundsAnchorLeft) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsHalfLeft && outOfBoundsAnchorRight) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (outOfBoundsHalfRight) {
                    direction = "top-left";
                    className = "tq-top-left-pos";
                } else if (outOfBoundsHalfLeft) {
                    direction = "top-right";
                    className = "tq-top-right-pos";
                } else if (outOfBoundsTop) {
                    direction = "bottom";
                    className = "tq-bottom-pos";
                }
            } else {
                if (outOfBoundsHalfRight && outOfBoundsAnchorLeft) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsHalfLeft && outOfBoundsAnchorRight) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsHalfRight) {
                    direction = "bottom-left";
                    className = "tq-bottom-left-pos";
                } else if (outOfBoundsHalfLeft) {
                    direction = "bottom-right";
                    className = "tq-bottom-right-pos";
                }
            }
        }
        if (preferredPosition === "top-left") {
            className = "tq-top-left-pos";
            if (outOfBoundsTop) {
                direction = "bottom-left";
                className = "tq-bottom-left-pos";
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfRight) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft) {
                    direction = "bottom-right";
                    className = "tq-bottom-right-pos";
                }
            } else {
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfRight) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft) {
                    direction = "top-right";
                    className = "tq-top-right-pos";
                }
            }
        }
        if (preferredPosition === "top-right") {
            className = "tq-top-right-pos";
            if (outOfBoundsTop) {
                direction = "bottom-right";
                className = "tq-bottom-right-pos";
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (
                    outOfBoundsAnchorLeft &&
                    outOfBoundsAnchorRight &&
                    outOfBoundsHalfRight
                ) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsAnchorRight) {
                    direction = "bottom-left";
                    className = "tq-bottom-left-pos";
                }
            } else {
                if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight && outOfBoundsHalfLeft) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (
                    outOfBoundsAnchorLeft &&
                    outOfBoundsAnchorRight &&
                    outOfBoundsHalfRight
                ) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorLeft && outOfBoundsAnchorRight) {
                    direction = "top";
                    className = "tq-top-pos";
                } else if (outOfBoundsAnchorRight) {
                    direction = "top-left";
                    className = "tq-top-left-pos";
                }
            }
        }
        if (preferredPosition === "top") {
            className = "tq-top-pos";
            if (outOfBoundsTop) {
                direction = "top";
                className = "tq-bottom-pos";
                if (outOfBoundsHalfRight && outOfBoundsAnchorLeft) {
                    direction = "bottom-auto-right";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsHalfLeft && outOfBoundsAnchorRight) {
                    direction = "bottom-auto-left";
                    className = "tq-bottom-pos";
                } else if (outOfBoundsHalfRight) {
                    direction = "bottom-left";
                    className = "tq-bottom-left-pos";
                } else if (outOfBoundsHalfLeft) {
                    direction = "bottom-right";
                    className = "tq-bottom-right-pos";
                }
            } else {
                if (outOfBoundsHalfLeft && outOfBoundsAnchorRight) {
                    direction = "top-auto-left";
                    className = "tq-top-pos";
                } else if (outOfBoundsHalfRight && outOfBoundsAnchorLeft) {
                    direction = "top-auto-right";
                    className = "tq-top-pos";
                } else if (outOfBoundsHalfLeft) {
                    direction = "top-right";
                    className = "tq-top-right-pos";
                } else if (outOfBoundsHalfRight) {
                    direction = "top-left";
                    className = "tq-top-left-pos";
                }
            }
        }
        if (preferredPosition === "right-top") {
            className = "tq-right-top-pos";
            if (outOfBoundsRight) {
                direction = "left-top";
                className = "tq-left-top-pos";
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (
                    outOfBoundsAnchorTop &&
                    outOfBoundsAnchorBottom &&
                    outOfBoundsHalfBottom
                ) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "left";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop) {
                    direction = "left-bottom";
                    className = "tq-left-bottom-pos";
                }
            } else {
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (
                    outOfBoundsAnchorTop &&
                    outOfBoundsAnchorBottom &&
                    outOfBoundsHalfBottom
                ) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "right";
                    className = "tq-right-pos";
                } else if (outOfBoundsBottom) {
                    direction = "right-bottom";
                    className = "tq-right-bottom-pos";
                }
            }
        }
        if (preferredPosition === "right-bottom") {
            className = "tq-right-bottom-pos";
            if (outOfBoundsRight) {
                direction = "left-bottom";
                className = "tq-left-bottom-pos";
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfBottom) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "left";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorBottom) {
                    direction = "left-top";
                    className = "tq-left-top-pos";
                }
            } else {
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfBottom) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "right";
                    className = "tq-right-pos";
                } else if (outOfBoundsTop) {
                    direction = "right-top";
                    className = "tq-right-top-pos";
                }
            }
        }
        if (preferredPosition === "right") {
            className = "tq-right-pos";
            if (outOfBoundsRight) {
                direction = "left";
                className = "tq-left-pos";
                if (outOfBoundsHalfBottom && outOfBoundsAnchorTop) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsHalfTop && outOfBoundsAnchorBottom) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (outOfBoundsHalfBottom) {
                    direction = "left-top";
                    className = "tq-left-top-pos";
                } else if (outOfBoundsHalfTop) {
                    direction = "left-bottom";
                    className = "tq-left-bottom-pos";
                }
            } else {
                if (outOfBoundsHalfBottom && outOfBoundsAnchorTop) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsHalfTop && outOfBoundsAnchorBottom) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (outOfBoundsHalfBottom) {
                    direction = "right-bottom";
                    className = "tq-right-top-pos";
                } else if (outOfBoundsHalfTop) {
                    direction = "right-top";
                    className = "tq-right-bottom-pos";
                }
            }
        }
        if (preferredPosition === "left-top") {
            className = "tq-left-top-pos";
            if (outOfBoundsLeft) {
                direction = "right-top";
                className = "tq-right-top-pos";
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (
                    outOfBoundsAnchorTop &&
                    outOfBoundsAnchorBottom &&
                    outOfBoundsHalfBottom
                ) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "right";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop) {
                    direction = "right-bottom";
                    className = "tq-right-bottom-pos";
                }
            } else {
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (
                    outOfBoundsAnchorTop &&
                    outOfBoundsAnchorBottom &&
                    outOfBoundsHalfBottom
                ) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "left";
                    className = "tq-left-pos";
                } else if (outOfBoundsBottom) {
                    direction = "left-bottom";
                    className = "tq-left-bottom-pos";
                }
            }
        }
        if (preferredPosition === "left-bottom") {
            className = "tq-left-bottom-pos";
            if (outOfBoundsLeft) {
                direction = "right-bottom";
                className = "tq-right-bottom-pos";
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfBottom) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "right";
                    className = "tq-right-pos";
                } else if (outOfBoundsAnchorBottom) {
                    direction = "right-top";
                    className = "tq-right-top-pos";
                }
            } else {
                if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfBottom) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom && outOfBoundsHalfTop) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (outOfBoundsAnchorTop && outOfBoundsAnchorBottom) {
                    direction = "left";
                    className = "tq-left-pos";
                } else if (outOfBoundsTop) {
                    direction = "left-top";
                    className = "tq-left-top-pos";
                }
            }
        }
        if (preferredPosition === "left") {
            className = "tq-left-pos";
            if (outOfBoundsLeft) {
                direction = "left";
                className = "tq-left-pos";
                if (outOfBoundsHalfBottom && outOfBoundsAnchorTop) {
                    direction = "right-auto-bottom";
                    className = "tq-right-pos";
                } else if (outOfBoundsHalfTop && outOfBoundsAnchorBottom) {
                    direction = "right-auto-top";
                    className = "tq-right-pos";
                } else if (outOfBoundsHalfBottom) {
                    direction = "right-top";
                    className = "tq-right-top-pos";
                } else if (outOfBoundsHalfTop) {
                    direction = "right-bottom";
                    className = "tq-right-bottom-pos";
                }
            } else {
                if (outOfBoundsHalfBottom && outOfBoundsAnchorTop) {
                    direction = "left-auto-bottom";
                    className = "tq-left-pos";
                } else if (outOfBoundsHalfTop && outOfBoundsAnchorBottom) {
                    direction = "left-auto-top";
                    className = "tq-left-pos";
                } else if (outOfBoundsHalfBottom) {
                    direction = "left-bottom";
                    className = "tq-left-top-pos";
                } else if (outOfBoundsHalfTop) {
                    direction = "left-top";
                    className = "tq-left-bottom-pos";
                }
            }
        }

        return { direction, className };
    };
    const _removePosClass = () => {
        const content = getContent();
        if (content) {
            content.classList.remove("tq-top-pos");
            content.classList.remove("tq-top-right-pos");
            content.classList.remove("tq-top-left-pos");
            content.classList.remove("tq-bottom-pos");
            content.classList.remove("tq-bottom-right-pos");
            content.classList.remove("tq-bottom-left-pos");
            content.classList.remove("tq-left-pos");
            content.classList.remove("tq-left-top-pos");
            content.classList.remove("tq-left-bottom-pos");
            content.classList.remove("tq-right-pos");
            content.classList.remove("tq-right-top-pos");
            content.classList.remove("tq-right-bottom-pos");
        }
    };

    const _move = (event?) => {
        const content = getContent();
        if (!anchor || !content) {
            return;
        }
        //call anchor getBoundingClientRect and pass the result to _calculateDimensions function
        const anchorRect = getRoundedBoundingClientRect(anchor);
        const dimensions = _calculateDimensions(anchorRect);
        let anchorTop = anchorRect.top;
        if (isIOSMobileDevice()) {
            anchorTop = anchorRect.top + window.scrollY;
        }
        const anchorLeft = anchorRect.left;
        const anchorBottom = anchorTop + anchorRect.height;
        const anchorRight = anchorLeft + anchorRect.width;
        const anchorCenterX = anchorLeft + anchorRect.width / 2;
        const anchorCenterY = anchorTop + anchorRect.height / 2;
        const directionOptions = _calculateDirection();
        //scroll inside of the popoverContainer, no need to move
        if (event && isElementInsideContainer(event.target, content)) {
            return;
        }

        //If the anchor is hidden by css, close the popover, otherwise calculate its place.
        //This is done because if hide the anchor, for example when it's only visible on hover,
        //we want the popover to be closed.
        let directions;
        let additionalMargin = 0;
        //Arrow is only available in the css when direction is "top-left" or "bottom-left"
        if (props.arrow) {
            //added arrow in the design
            additionalMargin = directionOptions?.direction === "bottom-left" ? 25 : 45;
        }
        switch (directionOptions?.direction) {
            case "top":
                directions = {
                    top: anchorTop - dimensions.contentHeight - 2 + "px",
                    left: anchorCenterX - dimensions.contentWidth / 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "top-right":
                directions = {
                    top: anchorTop - dimensions.contentHeight - 2 + "px",
                    left: anchorLeft + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "top-left":
                directions = {
                    top: anchorTop - dimensions.contentHeight - additionalMargin - 2 + "px",
                    left: anchorRight - dimensions.contentWidth + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "top-auto-right":
                directions = {
                    top: anchorTop - dimensions.contentHeight - 2 + "px",
                    left: "",
                    right: "0px",
                    bottom: ""
                };
                break;
            case "top-auto-left":
                directions = {
                    top: anchorTop - dimensions.contentHeight - 2 + "px",
                    left: "0px",
                    right: "",
                    bottom: ""
                };
                break;
            case "bottom":
                directions = {
                    top: anchorBottom + "px",
                    left: anchorCenterX - dimensions.contentWidth / 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "bottom-right":
                directions = {
                    top: anchorBottom + "px",
                    left: anchorLeft + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "bottom-left":
                directions = {
                    top: anchorBottom + additionalMargin + "px",
                    left: anchorRight - dimensions.contentWidth + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "bottom-auto-right":
                directions = {
                    top: anchorBottom + "px",
                    left: "",
                    right: "0px",
                    bottom: ""
                };
                break;
            case "bottom-auto-left":
                directions = {
                    top: anchorBottom + "px",
                    left: "0px",
                    right: "",
                    bottom: ""
                };
                break;
            case "left":
                directions = {
                    top: anchorCenterY - dimensions.contentHeight / 2 + "px",
                    left: anchorLeft - dimensions.contentWidth - 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "left-top":
                directions = {
                    top: anchorTop + "px",
                    left: anchorLeft - dimensions.contentWidth + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "left-bottom":
                directions = {
                    top: anchorBottom - dimensions.contentHeight + "px",
                    left: anchorLeft - dimensions.contentWidth - 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "left-auto-top":
                directions = {
                    top: "0px",
                    left: anchorLeft - dimensions.contentWidth + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "left-auto-bottom":
                directions = {
                    top: "",
                    left: anchorLeft - dimensions.contentWidth + "px",
                    right: "",
                    bottom: "0px"
                };
                break;
            case "right":
                directions = {
                    top: anchorCenterY - dimensions.contentHeight / 2 + "px",
                    left: anchorRight + 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "right-top":
                directions = {
                    top: anchorTop + "px",
                    left: anchorRight + 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "right-bottom":
                directions = {
                    top: anchorBottom - dimensions.contentHeight + "px",
                    left: anchorRight + 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "right-auto-top":
                directions = {
                    top: "0px",
                    left: anchorRight + 2 + "px",
                    right: "",
                    bottom: ""
                };
                break;
            case "right-auto-bottom":
                directions = {
                    top: "",
                    left: anchorRight + 2 + "px",
                    right: "",
                    bottom: "0px"
                };
                break;
            default:
                break;
        }

        //Set the calculated dimensions:
        content.classList.add("tq-visible");
        _removePosClass();
        content.classList.add(directionOptions?.className || "");
        content.style.top = directions.top;
        content.style.left = directions.left;
        content.style.right = directions.right;
        if (isIOSMobileDevice()) {
            content.style.position = "absolute";
        }
    };

    let PopoverClass = "tq-popover-placeholder ";
    PopoverClass += props.active ? "tq-active " : "";
    PopoverClass += props.extraClasses || "";

    if (props.active) {
        return createPortal(
            <div ref={contentRef} className={PopoverClass}>
                {props.children}
            </div>,
            document.body
        );
    } else {
        return null;
    }
}

interface IPopoverProps extends IBaseComponentProps {
    active: boolean;
    anchor: HTMLElement | null;
    children: React.ReactElement;
    minWidthToAnchor?: boolean;
    maxWidthToAnchor?: boolean;
    preferredPosition?:
        | "top"
        | "top-right"
        | "top-left"
        | "bottom"
        | "bottom-right"
        | "bottom-left"
        | "left"
        | "left-top"
        | "left-bottom"
        | "right"
        | "right-top"
        | "right-bottom";
    arrow?: boolean;
}
