import { configureStore } from "@reduxjs/toolkit";
import widgetsReducer from "./widgetsSlice";

let storeSingleton;

export const getStore = () => {
    return storeSingleton;
};

export const store = storeSingleton;

export const createStore = (preloadedState = {}) => {
    storeSingleton = configureStore({
        preloadedState,
        reducer: {
            widgets: widgetsReducer
        },
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these paths in the state
                    ignoredPaths: [/^.*\.files\.\d+$/],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: [/^.*\.files\.\d+$/]
                }
            });
        }
    });
    return storeSingleton;
};
