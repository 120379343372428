import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fromEntityToStoreEntityTree, fromStoreEntityToEntity } from "../adapters";
import type {
    Widget,
    Entity,
    StoreWidgetStateSlice,
    StoreWidgetState,
    UpdateWidgetState
} from "../types";

const initialState: StoreWidgetStateSlice = {
    widgets: {}
};

const widgetsSlice = createSlice({
    name: "widgets",
    initialState,
    reducers: {
        createWidget: (state, action: PayloadAction<Widget>): void => {
            const newWidget: StoreWidgetState = {
                id: action.payload.id,
                parentId: action.payload.parentId,
                url: action.payload.url
            };
            if (action.payload.data) {
                newWidget.data = fromEntityToStoreEntityTree(action.payload.data);
            }
            state.widgets[action.payload.id] = newWidget;
        },
        deleteWidget: (state, action: PayloadAction<string>): void => {
            delete state.widgets[action.payload];
        },
        setWidgetData: (state, action: PayloadAction<{ id: string; data: Entity }>): void => {
            state.widgets[action.payload.id].data = fromEntityToStoreEntityTree(
                action.payload.data
            );
        },
        deleteWidgetData: (state, action: PayloadAction<string>): void => {
            delete state.widgets[action.payload].data;
        },
        updateWidget: (state, action: PayloadAction<UpdateWidgetState>): void => {
            if (!state.widgets[action.payload.id]) {
                return;
            }
            if (action.payload.url) {
                state.widgets[action.payload.id].url = action.payload.url;
            }
            if (action.payload.data) {
                state.widgets[action.payload.id].data = fromEntityToStoreEntityTree(
                    action.payload.data
                );
            }
            if (action.payload.loadingState !== undefined) {
                state.widgets[action.payload.id].loadingState = action.payload.loadingState;
            }
            if (action.payload.dataCacheId !== undefined) {
                state.widgets[action.payload.id].dataCacheId = action.payload.dataCacheId;
            }
        },
        addWidgetLoadingState: (state, action: PayloadAction<{ widgetId: string }>): void => {
            const currentWidget = state.widgets[action.payload.widgetId];
            if (!currentWidget) {
                return;
            }
            if (currentWidget.loadingState) {
                currentWidget.loadingState = currentWidget.loadingState + 1;
            } else {
                currentWidget.loadingState = 1;
            }
        },
        removeWidgetLoadingState: (state, action: PayloadAction<{ widgetId: string }>): void => {
            const currentWidget = state.widgets[action.payload.widgetId];
            if (!currentWidget) {
                return;
            }
            if (currentWidget.loadingState) {
                currentWidget.loadingState = currentWidget.loadingState - 1;
            }
        },
        //updates/creates entity tree at given path
        //it is possible that given path has missing entities, these entities will be created
        updateEntity: (
            state,
            action: PayloadAction<{ widgetId: string; entityStorePath: string; entity: Entity }>
        ) => {
            const entityStorePath = action.payload.entityStorePath;
            if (!state.widgets[action.payload.widgetId]) {
                return;
            }
            const isWidgetEmpty = !state.widgets[action.payload.widgetId].data;
            if (!state.widgets[action.payload.widgetId].data) {
                state.widgets[action.payload.widgetId].data = {};
            }
            const widgetData = state.widgets[action.payload.widgetId].data || {}; //typescript issue- not knowing that there is no need for "|| {}"
            const inputSplit = entityStorePath.split(".");
            const pathParent = Object.keys(widgetData).find((key) => key.endsWith(inputSplit[0]));
            //if there are widget entities and
            //if the input has no matching parent entity
            if (!pathParent && !isWidgetEmpty) {
                return;
            }
            let fullEntityStorePath: string;
            if (inputSplit.length > 1) {
                fullEntityStorePath = pathParent + "." + inputSplit.slice(1).join(".");
            } else {
                fullEntityStorePath = entityStorePath;
            }
            const split = fullEntityStorePath.split(".");
            const parentEntities: string[] = [];
            //creates all parent entity ids
            //goes through each and adds new entities
            for (let i = 0; i < split.length; ++i) {
                if (i === 0) {
                    parentEntities.push(split[i]);
                } else {
                    parentEntities[i] = parentEntities[i - 1] + "." + split[i];
                }
                if (!widgetData[parentEntities[i]]) {
                    // create new entity
                    widgetData[parentEntities[i]] = { id: split[i], entities: [] };
                    const parent = widgetData[parentEntities[i - 1]];
                    //update its parent entity
                    if (parent.entities) {
                        parent.entities.push(parentEntities[i]);
                    } else {
                        parent.entities = [parentEntities[i]];
                    }
                }
            }
            //delete existing entity tree if it exists
            if (widgetData[fullEntityStorePath]) {
                const affectedEntities = Object.keys(widgetData).filter(
                    (key) =>
                        key === fullEntityStorePath || key.startsWith(`${fullEntityStorePath}.`)
                );
                affectedEntities.forEach((entityId) => {
                    delete widgetData[entityId];
                });
            }
            //add input entity tree
            const newEntityTree = fromEntityToStoreEntityTree(
                action.payload.entity,
                fullEntityStorePath
            );
            Object.keys(newEntityTree).forEach((key) => {
                widgetData[key] = newEntityTree[key];
            });
        },
        updateEntityProps: (
            state,
            action: PayloadAction<{
                widgetId: string;
                entityStorePath: string;
                props: Record<string, any>;
            }>
        ): void => {
            const entityStorePath = action.payload.entityStorePath;
            if (
                !state.widgets[action.payload.widgetId] ||
                !state.widgets[action.payload.widgetId].data
            ) {
                return;
            }
            if (!action.payload.props) {
                return;
            }
            const widgetData = state.widgets[action.payload.widgetId].data;
            let targetStoreEntity = state.widgets[action.payload.widgetId].data[entityStorePath];
            if (!targetStoreEntity) {
                const targetEntityKey = Object.keys(widgetData).find((key) =>
                    key.endsWith(entityStorePath)
                );
                if (targetEntityKey) {
                    targetStoreEntity =
                        state.widgets[action.payload.widgetId].data[targetEntityKey];
                } else {
                    return;
                }
            }
            if (targetStoreEntity.properties) {
                Object.assign(targetStoreEntity.properties, action.payload.props);
            } else {
                targetStoreEntity.properties = action.payload.props;
            }
        },
        updateEntityActions: (
            state,
            action: PayloadAction<{
                widgetId: string;
                entityStorePath: string;
                actions: any[];
                override?: boolean;
            }>
        ): void => {
            const entityStorePath = action.payload.entityStorePath;
            if (
                !state.widgets[action.payload?.widgetId] ||
                !state.widgets[action.payload.widgetId].data
            ) {
                return;
            }
            if (!action.payload.actions) {
                return;
            }
            const widgetData = state.widgets[action.payload.widgetId].data;
            let targetStoreEntity = state.widgets[action.payload.widgetId].data[entityStorePath];
            if (!targetStoreEntity) {
                const targetEntityKey = Object.keys(widgetData).find((key) =>
                    key.endsWith(entityStorePath)
                );
                if (targetEntityKey) {
                    targetStoreEntity =
                        state.widgets[action.payload.widgetId].data[targetEntityKey];
                } else {
                    return;
                }
            }
            if (targetStoreEntity.actions && !action.payload.override) {
                targetStoreEntity.actions.push(...action.payload.actions);
            } else {
                targetStoreEntity.actions = action.payload.actions;
            }
        },
        updateEntityUiState: (
            state,
            action: PayloadAction<{
                widgetId: string;
                entityStorePath: string;
                uiState: Record<string, any>;
            }>
        ): void => {
            const entityStorePath = action.payload.entityStorePath;
            if (
                !state.widgets[action.payload.widgetId] ||
                !state.widgets[action.payload.widgetId].data
            ) {
                return;
            }
            if (!action.payload.uiState) {
                return;
            }
            const widgetData = state.widgets[action.payload.widgetId].data;
            let targetStoreEntity = state.widgets[action.payload.widgetId].data[entityStorePath];
            if (!targetStoreEntity) {
                const targetEntityKey = Object.keys(widgetData).find((key) =>
                    key.endsWith(entityStorePath)
                );
                if (targetEntityKey) {
                    targetStoreEntity =
                        state.widgets[action.payload.widgetId].data[targetEntityKey];
                } else {
                    return;
                }
            }
            if (targetStoreEntity.uiState) {
                Object.assign(targetStoreEntity.uiState, action.payload.uiState);
            } else {
                targetStoreEntity.uiState = action.payload.uiState;
            }
        },
        deleteEntity: (
            state,
            action: PayloadAction<{ widgetId: string; entityStorePath: string }>
        ) => {
            const entityStorePath = action.payload.entityStorePath;
            if (!state.widgets[action.payload.widgetId]) {
                return;
            }
            const isWidgetEmpty = !state.widgets[action.payload.widgetId].data;
            if (isWidgetEmpty) {
                state.widgets[action.payload.widgetId].data = {};
            }
            const widgetData = state.widgets[action.payload.widgetId].data || {};
            const inputSplit = entityStorePath.split(".");
            const pathParent = Object.keys(widgetData).find((key) => key.endsWith(inputSplit[0]));
            //if there are widget entities and
            //if the input has no matching parent entity
            if (!pathParent && !isWidgetEmpty) {
                return;
            }
            let fullEntityStorePath: string;
            let parentPath: string;
            if (inputSplit.length > 1) {
                fullEntityStorePath = pathParent + "." + inputSplit.slice(1).join(".");
                parentPath =
                    pathParent + "." + inputSplit.slice(1, inputSplit.length - 1).join(".");
            } else {
                fullEntityStorePath = entityStorePath;
            }
            //delete existing entity tree if it exists
            if (widgetData[fullEntityStorePath]) {
                const affectedEntities = Object.keys(widgetData).filter((key) =>
                    key.startsWith(fullEntityStorePath)
                );
                affectedEntities.forEach((entityId) => {
                    delete widgetData[entityId];
                });
            }
            //remove the entity key from parent's entities
            if (parentPath) {
                const entityChildren = widgetData[parentPath].entities || [];
                const newEntityChildren = entityChildren.filter(
                    (entityChild) => entityChild !== fullEntityStorePath
                );
                widgetData[parentPath].entities = newEntityChildren;
            }
        }
    }
});

export const selectWidget = (state, widgetId: string) => {
    return state.widgets.widgets[widgetId];
};

export const selectWidgetData = (state, widgetId: string) => {
    return state.widgets.widgets[widgetId]?.data;
};

export const selectEntityByPath = (state, widgetId: string, entityPath: string) => {
    const widgetState: StoreWidgetState = state.widgets.widgets[widgetId] || {};
    const widgetData = widgetState.data || {};
    const entityStorePath = getEntityStorePath(state, widgetId, entityPath);
    if (entityStorePath) {
        return fromStoreEntityToEntity(widgetData[entityStorePath], widgetState);
    } else {
        return false;
    }
};

export const getEntityStorePath = (state, widgetId: string, entityPath: string) => {
    const widgetState: StoreWidgetState = state.widgets.widgets[widgetId] || {};
    const widgetData = widgetState.data || {};
    if (widgetData[entityPath]) {
        return entityPath;
    }
    return Object.keys(widgetData).find((key) => key.endsWith(`.${entityPath}`));
};

export const {
    createWidget,
    deleteWidget,
    updateWidget,
    addWidgetLoadingState,
    removeWidgetLoadingState,
    setWidgetData,
    deleteWidgetData,
    updateEntity,
    updateEntityProps,
    updateEntityActions,
    updateEntityUiState,
    deleteEntity
} = widgetsSlice.actions;
export default widgetsSlice.reducer;
