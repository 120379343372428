"use client";
import { Entity } from "../../webui/types";
import supportedValidations from "./types";
import {
    ValidationContext,
    ValidationErrors,
    ValidationModel,
    ValidationResult,
    ValidationRule,
    ValidationRules
} from "./interfaces";
import { getValidationModel, getValidationRules } from "./ValidationContext";
import { applyRuleSettings } from "./rules";
import { getFeatureData } from "../features";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

export const validateEntity = (entity: Entity): ValidationResult => {
    if (!getFeatureData("validations")) {
        throw new Error(
            "Missing validation settings. Make sure settings are loaded before performing validations."
        );
    }
    return validateEntityTree(entity, { rules: {}, model: getValidationModel(entity) });
};

export const triggerExplicitValidations = (
    value: any,
    validationRules: ValidationRule[],
    validationModel?: ValidationModel
): ValidationErrors => {
    return validate(value, validationRules, validationModel || {});
};

// Wrapper function to make the debounced function return a Promise
export const debouncedTriggerExplicitValidations = (
    value: any,
    validationRules: ValidationRule[],
    validationModel?: ValidationModel
): Promise<ValidationErrors> => {
    return new Promise((resolve, reject) => {
        const debouncedFunction = debounce((val, rules, model) => {
            try {
                const result = triggerExplicitValidations(val, rules, model);
                resolve(result);
            } catch (error) {
                reject(error);
            }
        }, 300);

        debouncedFunction(value, validationRules, validationModel);
    });
};

const validateEntityTree = (entity: Entity, parentContext: ValidationContext) => {
    let result = {};
    const entityValidationRules = getValidationRules(entity);
    //TODO handle overriding rules for an entity
    const currentValidationRules = mergeValidationRules(parentContext.rules, entityValidationRules);
    let validationRulesToPerform: ValidationRule[] = currentValidationRules[entity.id];
    const currentValidationContext = {
        rules: currentValidationRules,
        model: parentContext.model
    };
    let value = undefined;
    if (entity.properties && Object.prototype.hasOwnProperty.call(entity.properties, "value")) {
        value = entity.properties.value;
    }
    if (validationRulesToPerform) {
        //if the value is empty trigger only "required" rules
        if (isEmptyValue(value)) {
            validationRulesToPerform = validationRulesToPerform.filter(isRequiredRule);
        }
        const validationResult = validate(value, validationRulesToPerform, parentContext.model);
        if (validationResult.length) {
            result = {
                [entity.contextPath || entity.id]: validationResult
            };
        }
    }
    if (entity.entities && entity.entities.length) {
        entity.entities.forEach((e) =>
            Object.assign(result, validateEntityTree(e, currentValidationContext))
        );
    }
    return result;
};

const validate = (
    value: any,
    rules: ValidationRule[],
    model: ValidationModel
): ValidationErrors => {
    const failedValidations: ValidationRule[] = [];
    rules.forEach((rule) => {
        //creating new rule for the current validation (to preserve the original rule)
        let evaluationRule = applyRuleSettings(rule);
        evaluationRule = evaluateRuleParameters(evaluationRule, model);
        const valid = performValidation(value, evaluationRule);
        if (!valid) {
            failedValidations.push(evaluationRule);
        }
    });
    return failedValidations;
};

const performValidation = (value: string, validationRule: ValidationRule): boolean => {
    if (supportedValidations[validationRule.type]) {
        const parameters = validationRule.parameters || [];
        return supportedValidations[validationRule.type](value, ...parameters);
    }
    console.warn("Validation not defined", validationRule);
    return true;
};

const evaluateRuleParameters = (rule: ValidationRule, model: ValidationModel): ValidationRule => {
    if (rule.parameters) {
        return {
            ...rule,
            ...{
                parameters: rule.parameters.map((param) => {
                    if (isReference(param)) {
                        const strippedParam = param.replace("{", "").replace("}", "");
                        if (model[strippedParam]) {
                            return model[strippedParam];
                        }
                        const matchedModelKey = Object.keys(model).find((key) =>
                            key.endsWith("." + strippedParam)
                        );
                        return matchedModelKey ? model[matchedModelKey] : undefined;
                    }
                    return param;
                })
            }
        };
    }
    return rule;
};

const isReference = (param: any): boolean => {
    return typeof param === "string" && param.startsWith("{");
};

const mergeValidationRules = (
    parentRules: ValidationRules,
    currentRules: ValidationRules
): ValidationRules => {
    const mergedRules = {};
    Object.keys(currentRules).forEach((key) => {
        if (parentRules[key]) {
            mergedRules[key] = parentRules[key].concat(currentRules[key]);
        } else {
            mergedRules[key] = currentRules[key];
        }
    });
    return Object.assign({}, parentRules, mergedRules);
};
const isRequiredRule = (rule: ValidationRule): boolean => {
    return rule.type.startsWith("required");
};

const isEmptyValue = (value: any): boolean => {
    return value === undefined || value === null || value === "";
};
