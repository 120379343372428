import { basicToStringMethod, checkMinNumberOfParameters, isInvalid } from "./utils";
import { createFunctionElement } from "../functionalContext";
import { EvaluationResult, FunctionElement, FunctionElementFactory } from "../types";

//Advanced functions accepting array of FuncitonalElements and state
export const block = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult> => {
        try {
            let blockStateCopy: Record<string, any> = {};
            if (state.block) {
                blockStateCopy = JSON.parse(JSON.stringify(state.block));
            }
            const newState: Record<string, any> = Object.assign({}, state, {
                block: blockStateCopy
            });
            let result: EvaluationResult;
            for (const element of args) {
                result = await element.evaluate(newState);
            }
            return result;
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
    };
    const toString: () => string = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

const set = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult | null> => {
        let variableName: EvaluationResult;
        let value: EvaluationResult;
        try {
            const operands: EvaluationResult[] = await Promise.all(
                args.map((functionElement) => functionElement.evaluate(state))
            );
            variableName = operands[0];
            value = operands[1];
            if (variableName === null || variableName === undefined) {
                throw new Error("missing variable name.");
            }
            if (!state.block) {
                throw new Error("missing block state.");
            }

            if (typeof variableName === "string") {
                state.block[variableName] = value;
            } else {
                throw new Error(
                    `Variable name: ${variableName} is not a string and cannot be used as an index type.`
                );
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
        return null;
    };
    const toString: () => string = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

const get = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult> => {
        let result: EvaluationResult;
        let variableName: EvaluationResult; // because it it is extracted from an array of Evaluation result
        try {
            const operands: EvaluationResult[] = await Promise.all(
                args.map((functionElement) => functionElement.evaluate(state))
            );

            variableName = operands[0];
            if (variableName === null || variableName === undefined) {
                throw new Error("missing variable name.");
            }

            if (!state.block) {
                throw new Error("missing block state.");
            }

            if (typeof variableName === "string") {
                result = state.block[variableName];
            } else {
                throw new Error(
                    `Variable name: ${variableName} is not a string cannot be used as an index type.`
                );
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
        return result;
    };
    const toString = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

const IF = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult> => {
        let condition: FunctionElement, conditionResult: EvaluationResult;
        let thenStatement: FunctionElement;
        let elseStatement: FunctionElement;
        let result: EvaluationResult | Promise<EvaluationResult>;
        try {
            condition = args[0];
            thenStatement = args[1];
            elseStatement = args[2];
            if (!condition) {
                throw new Error("Missing condition.");
            }
            conditionResult = await condition.evaluate(state);
            if (conditionResult) {
                if (thenStatement) {
                    result = thenStatement.evaluate(state);
                }
            } else {
                if (elseStatement) {
                    result = elseStatement.evaluate(state);
                }
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
        return result;
    };
    const toString = (): string => {
        return `["${name}", ${args.map((item) => item.toString()).join(", ")}]`;
    };
    return {
        name,
        evaluate,
        toString
    };
};

//[expression, defaultValueExpression] => expressionValue?expressionValue:defaultValue
const optional = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult> => {
        let mainExpression: FunctionElement;
        let defaultValueFunctionElement: FunctionElement;
        let result: EvaluationResult;
        try {
            mainExpression = args[0];
            defaultValueFunctionElement = args[1];
            if (!mainExpression) {
                throw new Error("Missing condition.");
            }
            result = await mainExpression.evaluate(state);
            if (isInvalid(result)) {
                result = await defaultValueFunctionElement.evaluate(state);
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
        return result;
    };
    const toString = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

//get data from evaluation context
//args=["name1", "name2", "name3"] - extract data from name1.name2.name3
//context is based on bussiness model of the evaluation
//defined for each case separately for now
const path = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<EvaluationResult | null> => {
        let result: EvaluationResult | null;
        try {
            const pathNames: EvaluationResult[] = await Promise.all(
                args.map((functionElement) => functionElement.evaluate(state))
            );
            if (pathNames === null || pathNames === undefined || pathNames.length === 0) {
                throw new Error("missing path names.");
            }
            if (!state.context) {
                throw new Error("missing context state.");
            }
            //searching in the context tree by path names
            result = state.context;
            pathNames.forEach((item) => {
                if (typeof item === "string") {
                    if (result) {
                        result = result[item];
                    } else {
                        result = null;
                    }
                } else {
                    throw new Error(`Path name: ${item} is not of type string`);
                }
            });
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
        return result;
    };
    const toString = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

//receiving another function name as a second argument with parameters following
//input list is filtered using the input function, and each item within the list becomes the first argument
const filter = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<any> => {
        try {
            checkMinNumberOfParameters(args, 2);
            const operands: EvaluationResult[] = await Promise.all(
                args.map((functionElement) => functionElement.evaluate(state))
            );
            const [list, operationName, ...operationParams] = operands;
            if (!Array.isArray(list)) {
                throw new Error(`First argument is not a list.`);
            }
            if (typeof operationName === "string") {
                const mappingResult = await Promise.all(
                    list
                        .map((item) =>
                            createFunctionElement(operationName, [item, ...operationParams])
                        )
                        .map((item) => item.evaluate())
                );
                return list.filter((_item, index) => mappingResult[index]);
            } else {
                throw new Error(
                    `Operation name: ${operationName} is not a string and cannot be used as a name for FunctionElement.`
                );
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
    };
    const toString = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

const map = (name = "", args: FunctionElement[] = []): FunctionElement => {
    const evaluate = async (state: Record<string, any>): Promise<any> => {
        checkMinNumberOfParameters(args, 2);
        const operands: EvaluationResult[] = await Promise.all(
            args.map((functionElement) => functionElement.evaluate(state))
        );
        const [list, operationName, ...operationParams] = operands;
        if (!Array.isArray(list)) {
            throw new Error(`First argument is not a list.`);
        }
        try {
            if (typeof operationName === "string") {
                return Promise.all(
                    list
                        .map((item) =>
                            createFunctionElement(operationName, [item, ...operationParams])
                        )
                        .map((item) => item.evaluate())
                );
            } else {
                throw new Error(`"operationName" is not of type string`);
            }
        } catch (error) {
            return Promise.reject(`${toString()} -> ${error}`);
        }
    };
    const toString = basicToStringMethod(name, args);
    return {
        name,
        evaluate,
        toString
    };
};

export type AdvFunctionElementNames =
    | "block"
    | "set"
    | "get"
    | "if"
    | "optional"
    | "path"
    | "filter"
    | "map";

export const dictionary: Record<AdvFunctionElementNames, FunctionElementFactory> = {
    block,
    set,
    get,
    if: IF,
    optional,
    path,
    filter,
    map
};
