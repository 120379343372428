"use client";
import React, { useState } from "react";
import { Entity } from "@uneo/platform-commons/webui/types";
import InfoModal from "../infoModal/InfoModal";

export default function ProcessSubtitle({
    entity,
    extraClasses = ""
}: {
    entity: Entity;
    extraClasses?: string;
}) {
    const [modalOpened, setModalOpened] = useState(false);

    if (!entity) {
        return null;
    }

    return (
        <p className={`un-ds-heading-3 tq-neutral-color-1 un-process-subtitle ${extraClasses}`}>
            {entity?.properties?.value}
            {entity?.properties?.tooltip && (
                <span
                    className="tq-input-feedback"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        setModalOpened(true);
                    }}
                >
                    <i className="tq-feedback-icon tq-o-icon-circle-info" />
                </span>
            )}
            {modalOpened && (
                <InfoModal
                    value={entity?.properties?.tooltip}
                    onClose={() => setModalOpened(false)}
                />
            )}
        </p>
    );
}
