import {
    Action,
    Entity,
    SirenAction,
    SirenDashboardDescription,
    SirenEntity,
    SirenEntityChild,
    SirenWidgetDescription,
    StoreEntity,
    StoreEntityMap,
    StoreWidgetState,
    Widget
} from "./types";

//##################  Entity => Store
export const fromEntityToStoreEntityTree = (
    entity: Entity,
    targetEntityStoreId?: string
): StoreEntityMap => {
    const resultTree: StoreEntityMap = {};
    const entityStoreId = targetEntityStoreId ? targetEntityStoreId : entity.id;
    const mainEntity: StoreEntity = {
        id: entity.id,
        properties: entity.properties,
        uiState: entity.uiState,
        actions: entity.actions
    };
    resultTree[entityStoreId] = mainEntity;
    if (entity.entities) {
        mainEntity.entities = entity.entities.map(
            (innerEntity) => entityStoreId + "." + innerEntity.id
        );
        Object.assign(
            resultTree,
            ...entity.entities.map((innerEntity) =>
                fromEntityToStoreEntityTree(innerEntity, entityStoreId + "." + innerEntity.id)
            )
        );
    }
    return resultTree;
};
//################## SIREN => Entity
export const fromSirenDashboardDescriptionToEntity = (
    dashboard: SirenDashboardDescription
): Entity => {
    return {
        id: dashboard.id,
        entities: dashboard.widgets.map((widgetDescription) =>
            fromSirenWidgetDescriptionToEntity(widgetDescription)
        )
    };
};

export const fromSirenWidgetDescriptionToEntity = (
    widgetDescription: SirenWidgetDescription
): Entity => {
    return {
        id: widgetDescription.id,
        properties: { url: widgetDescription.href, widgetId: widgetDescription.widgetId }
    };
};

export const fromSirenEntityToEntity = (entity: SirenEntity): Entity => {
    if (!entity || !entity.class || !entity.class[0] || typeof entity.class[0] !== "string") {
        console.error("Incorrect Siren Entity format at fromSirenEntityToEntity");
        throw new Error("Incorrect Siren Entity format at fromSirenEntityToEntity");
    }

    return {
        id: entity.class[0],
        properties: entity.properties,
        actions: entity.actions?.map(fromSirenActionToAction),
        entities: entity.entities?.map((innerSirenEntity, index) =>
            //some inner entities are not full entity objects, miss "class" property (example: enum inner entities)
            //so conversion is required
            fromSirenEntityToEntity(fromSirenEntityChildToSirenEntity(innerSirenEntity, index))
        )
    };
};

const fromSirenEntityChildToSirenEntity = (
    entityChild: SirenEntityChild,
    index: number
): SirenEntity => {
    if ("class" in entityChild) {
        return entityChild;
    } else {
        return { class: ["" + index], ...entityChild };
    }
};

export const fromSirenActionToAction = (sirenAction: SirenAction): Action => {
    let events = sirenAction.events;
    if (!events && sirenAction.event) {
        events = [sirenAction.event];
    }
    return {
        type: sirenAction.actionType,
        url: sirenAction.href,
        method: sirenAction.method,
        targets: sirenAction.targets,
        sources: sirenAction.sources,
        events,
        actions: sirenAction.actions?.map((innerAction) => fromSirenActionToAction(innerAction)),
        properties: sirenAction.properties,
        parameters: sirenAction.parameters
    };
};

//###################  Entity => Siren
// const fromEntityToSirenEntity = (entity: Entity): SirenEntity => {
//     return {
//         class: [entity.id],
//         properties: entity.properties,
//         actions: entity.actions?.map(fromActionToSirenAction),
//         entities: entity.entities?.map((innerEntity) => fromEntityToSirenEntity(innerEntity))
//     };
// };

export const fromActionToSirenAction = (action: Action): SirenAction => {
    return {
        actionType: action.type,
        href: action.url,
        method: action.method,
        targets: action.targets,
        sources: action.sources,
        events: action.uiEvents,
        event: action.parentActionEvent,
        actions: action.actions?.map((innerAction) => fromActionToSirenAction(innerAction)),
        properties: action.properties,
        parameters: action.parameters
    };
};

//################## Store => Entity
//StoreEntity => Entity
export const fromStoreEntityToEntity = (
    storeEntity: StoreEntity,
    widgetData: StoreWidgetState,
    entityStoreId?: string
): Entity => {
    const result: Entity = {
        id: storeEntity.id,
        properties: storeEntity.properties,
        actions: storeEntity.actions,
        uiState: storeEntity.uiState
    };
    if (!widgetData.data) {
        return result;
    }
    const storeWidgetState = widgetData.data;
    if (entityStoreId) {
        result.contextPath = entityStoreId;
    }
    if (storeEntity.entities) {
        result.entities = storeEntity.entities.map((innerStoreEntityStoreId) =>
            fromStoreEntityToEntity(
                storeWidgetState[innerStoreEntityStoreId],
                widgetData,
                innerStoreEntityStoreId
            )
        );
    }

    return result;
};
//StoreWidget => Widget
export const fromStoreWidgetToWidget = (storeWidget: StoreWidgetState): Widget => {
    const result: Widget = {
        id: storeWidget.id,
        url: storeWidget.url,
        parentId: storeWidget.parentId
    };
    if (storeWidget.data) {
        //TODO: using presumption that widget data is named with widgetId, to be resolved
        result.data = fromStoreEntityToEntity(storeWidget.data[storeWidget.id], storeWidget);
    }
    if (storeWidget.loadingState !== undefined) {
        result.loadingState = storeWidget.loadingState;
    }
    if (storeWidget.dataCacheId !== undefined) {
        result.dataCacheId = storeWidget.dataCacheId;
    }
    return result;
};

//general purpose factory methods
// proto types are used to represent expected input
// at the moment the expected input is the same as resulting types
type ProtoEntity = Entity;
type ProtoAction = Action;
export const toEntity = ({ id, properties, actions, entities }: ProtoEntity): Entity => {
    return {
        id: id,
        properties: Object.assign({}, properties),
        actions: actions?.map(toAction) || [],
        entities: entities?.map(toEntity) || []
    };
};

export const toAction = ({
    type,
    url,
    method,
    targets,
    sources,
    events,
    actions,
    properties,
    parameters
}: ProtoAction): Action => {
    return {
        type,
        url,
        method,
        targets,
        sources,
        events,
        actions: actions?.map(toAction),
        properties: properties,
        parameters: parameters
    };
};
