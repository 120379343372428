"use client";
import React, { useState } from "react";

export default function SimpleToggleSection(props: {
    children: string | JSX.Element | JSX.Element[];
    extraClasses?: string;
    initialExpandedState?: boolean;
    onExpand?: any;
    onFirstExpand?: any;
}) {
    const { extraClasses, children, initialExpandedState, onFirstExpand, onExpand } = props;
    const [expanded, setExpanded] = useState(
        initialExpandedState !== undefined ? initialExpandedState : false
    );
    const [expandedOnce, setExpandedOnce] = useState(
        initialExpandedState !== undefined ? initialExpandedState : false
    );
    const toggle = () => {
        setExpanded((prevState) => !prevState);
        if (onFirstExpand && !expanded && !expandedOnce) {
            setExpandedOnce(true);
            onFirstExpand();
        }
        if (onExpand && !expanded) {
            onExpand();
        }
    };

    return (
        <div className="un-ds-expandable-content">
            <div
                className={`tq-section tq-expandable ${extraClasses} ${
                    expanded ? "tq-expanded" : ""
                }`}
            >
                {children}
            </div>
            <button
                type="button"
                aria-label="Voir plus/moins"
                className="tq-button un-ds-button un-ds-button-only-icon"
                onClick={toggle}
            >
                <i className="tq-o-icons tq-icon-arrows-right" />
            </button>
        </div>
    );
}
