import React, { useCallback } from "react";
import InputNumber, { InputNumberPropsKeys } from "./InputNumber";
import { extractComponentProps } from "../../../utils/utils";

export default function InputNumberContainer(props) {
    const onBlur = useCallback(
        (state) => {
            if (props.onBlur) {
                props.onBlur(state);
            }
        },
        [props]
    );

    if (!props) {
        return null;
    }
    const extractedProps: any = extractComponentProps(props, {
        componentProps: InputNumberPropsKeys,
        propsDefaults: {
            locale: "fr-FR"
        }
    });

    const onChange = (state) => {
        if (props.onChange) {
            props.onChange(state);
        }
    };
    extractedProps.onChange = onChange;
    extractedProps.additionalSeparator = ".";
    if (!extractedProps.tooltip) {
        extractedProps.labelButton = undefined;
    }
    return <InputNumber {...extractedProps} onBlur={onBlur} />;
}
