"use client";
import React, { useState, useRef, useEffect } from "react";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import {
    IEventHandlers,
    ILabeledProps,
    ILabeledPropsKeys,
    IEventHandlersKeys
} from "@uneo/platform-commons/platform/types";

interface IOTPInputProps extends ILabeledProps, IEventHandlers {
    name?: string;
    disabled?: boolean;
}

interface CustomCredentialRequestOptions extends CredentialRequestOptions {
    otp?: {
        transport: string[];
    };
}
interface CustomCredentials extends Credential {
    code?: string;
}

const OTPInput: React.FC<IOTPInputProps> = (props) => {
    const [code, setCode] = useState<string[]>([]);

    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    useEffect(() => {
        const handleOTP = async () => {
            //https://developer.mozilla.org/en-US/docs/Web/API/OTPCredential
            try {
                if ("OTPCredential" in window) {
                    const abort = new AbortController();
                    const otpOptions: CustomCredentialRequestOptions = {
                        otp: { transport: ["sms"] },
                        signal: abort.signal
                    };
                    navigator.credentials
                        .get(otpOptions)
                        .then((otp: CustomCredentials) => {
                            const otpCode = otp.code;
                            if (otpCode) {
                                applyNewCode(otpCode);
                                abort.abort();
                            }
                        })
                        .catch((err) => {
                            abort.abort();
                            console.log(err);
                        });
                }
            } catch (error) {
                console.error("Error retrieving OTP:", error);
            }
        };
        handleOTP();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const labeledRef = useRef<HTMLTextAreaElement>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const input = e.target;
        const previousInput = inputRefs[index - 1];
        const nextInput = inputRefs[index + 1];
        const newCode: string[] = [...code];
        newCode[index] = input.value.trim();
        setCode(newCode);

        input.select();

        if (input.value === "") {
            // If the value is deleted, select previous input, if exists
            if (previousInput) {
                previousInput.current.focus();
            }
        } else if (!/^[0-9]$/.test(input.value)) {
            input.value = "";
        } else if (nextInput) {
            // Select next input on entry, if exists
            nextInput.current.select();
        }
        if (props.onChange) {
            props.onChange({
                value: newCode.filter((value) => !!value).length === 6 ? newCode.join("") : ""
            });
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur({ code });
        }
    };

    const onFocus = (e) => {
        e.target.select();
        if (props.onFocus) {
            props.onFocus({ code });
        }
    };

    const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedCode = e.clipboardData.getData("text");
        applyNewCode(pastedCode);
    };

    const applyNewCode = (pastedCode: string) => {
        if (pastedCode.length === 6) {
            const newCode = pastedCode.split("");
            setCode(newCode);
            inputRefs.forEach((inputRef, index) => {
                inputRef.current.value = pastedCode[index];
            });
            if (props.onChange) {
                props.onChange({
                    value: newCode.filter((value) => !!value).length === 6 ? newCode.join("") : ""
                });
            }
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const input = e.target as HTMLInputElement;
        if (input instanceof HTMLInputElement) {
            const previousInput = inputRefs[index - 1];
            const nextInput = inputRefs[index + 1];

            if ((e.code === "Backspace" || e.code === "Delete") && input.value === "") {
                e.preventDefault();
                const newCode = [...code];
                newCode[index] = "";
                setCode(newCode);
                if (previousInput) {
                    previousInput.current.focus();
                }
            }
            //Handle arrows to switch inputs
            if (e.code === "ArrowLeft" && previousInput) {
                previousInput.current.focus();
                e.preventDefault();
            }
            if (e.code === "ArrowRight" && nextInput) {
                nextInput.current.focus();
                e.preventDefault();
            }
            //if type the same value should move to next input
            if (input.value === e.key && nextInput) {
                nextInput.current.focus();
                e.preventDefault();
            }
            const newValue = e.currentTarget.value;
            if (props.onKeyDown) {
                props.onKeyDown(e.key, { value: newValue }, e);
            }
        }
    };

    const onMouseDown = (e) => {
        e.preventDefault();
        e.target.select();
    };

    //Form label props
    const labelProps = getLabelProps(props, {
        valid: props.valid,
        valueContainerClass: "tq-text-input"
    });

    return (
        <Labeled {...labelProps} labeledRef={labeledRef}>
            {[0, 1, 2, 3, 4, 5].map((index) => (
                //No autofocus because - https://bugs.webkit.org/show_bug.cgi?id=195884#c4 (does not work in safari mobile and cause issues with the input)
                <input
                    key={index}
                    ref={inputRefs[index]}
                    type="tel"
                    onClick={onFocus}
                    onFocus={onFocus}
                    onChange={(e) => onChange(e, index)}
                    onPaste={onPaste}
                    maxLength={1}
                    onBlur={onBlur}
                    onKeyDown={(e) => onKeyDown(e, index)}
                    className="tq-input"
                    disabled={props.disabled}
                    inputMode="numeric"
                    pattern="[0-9]"
                    name={props.name}
                    onMouseDown={onMouseDown}
                    autoComplete="one-time-code"
                />
            ))}
        </Labeled>
    );
};

export default OTPInput;

export const IOTPInputPropsKeys = [...IEventHandlersKeys, ...ILabeledPropsKeys, "disabled", "name"];
