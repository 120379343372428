export default function isElementInsideContainer(
    element: HTMLElement | Document | Window,
    container: HTMLElement | Document
): boolean {
    if (element instanceof Window) {
        return false;
    }

    // Check if container contains element
    return container.contains(element);
}
