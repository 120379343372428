"use client";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { xssFilter } from "../../utils/xssFilter";
import "@tinqin/design-uneo-pf/css/modal.css";
import { tabulateArea } from "@uneo/platform-commons/platform/utils/tabulateArea";
import { hideModal, showModal } from "@uneo/platform-commons/platform/utils/modalUtils";

export default function InfoModal(props: {
    onClose?: (event) => void;
    title?: string;
    value?: string;
    hideIcon?: boolean;
    extraClasses?: string;
}) {
    const modalContent = useRef<HTMLDivElement>(null);
    const closeButton = useRef<HTMLLIElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const closeFn = (event) => {
        hideModal(modalRef).then(() => {
            props.onClose(event);
        });
    };
    const onKeyDown = (event) => {
        const isTabPressed = event.key === "Tab";
        const isEscapePressed = event.key === "Escape";

        let tabulateBy = 1;
        if (event.shiftKey && isTabPressed) {
            tabulateBy = -1;
        }

        if (isEscapePressed) {
            return closeFn(event);
        }
        if (isTabPressed) {
            event.preventDefault();
            tabulateArea(modalContent, tabulateBy, closeButton);
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        showModal(modalRef);
        return function cleanup() {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    const content = (
        <div
            className={`tq-modal un-ds-modal un-ds-modal-generic ${props.extraClasses || ""}`}
            ref={modalRef}
        >
            <div className="tq-modal-overlay" role="dialog" aria-modal="true" onClick={closeFn} />
            <div className="tq-modal-container tq-modal-l">
                <div className=" tq-modal-header">
                    <h3 className="tq-panel-title tq-light-splitter tq-with-right-menu">
                        <span className="tq-text-node" />
                        <ul className=" tq-menu tq-selectable">
                            <li
                                className=""
                                tabIndex={0}
                                ref={closeButton}
                                role="button"
                                onClick={closeFn}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        closeFn(event);
                                    }
                                }}
                            >
                                <div className="tq-item">
                                    <i className="tq-icon-close" />
                                </div>
                            </li>
                        </ul>
                    </h3>
                </div>
                <div className="tq-modal-scroll">
                    <div className="tq-modal-content">
                        {!props.hideIcon && <i className="tq-o-icon-circle-info un-main-icon" />}
                        {props.title && <p className="un-modal-title">{props.title}</p>}
                        <p
                            className="tq-text-color un-ds-text-1 un-mb-1 tq-text-center tq-block"
                            dangerouslySetInnerHTML={{
                                __html: xssFilter.process(props.value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return createPortal(content, document.body);
}
