import React, { useCallback } from "react";
import Datepicker, { IDatePickerPropsKeys } from "./Datepicker";
import typeOfOperand from "@tinqin/tinqin-utils/src/base/typeOfOperand";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";

const fromISO_8601 = (isoDate: string): string => {
    const dateTokens: string[] = isoDate.split("-");
    //Note: The assumption here is that the expected result is formatted DD/MM/YYYY "old format"
    return `${dateTokens[2]}/${dateTokens[1]}/${dateTokens[0]}`;
};

export default function DatepickerContainer(props) {
    const getValue = useCallback(
        (datepickerValue) => {
            let value: string | string[] | null = datepickerValue;
            const extractedProps: any = extractComponentProps(props, {
                componentProps: IDatePickerPropsKeys,
                additionalProps: ["multiSelection", "iso8601", "value"]
            });
            let lastStoreValue: string[];
            if (extractedProps.value && typeOfOperand(extractedProps.value) !== "array") {
                lastStoreValue = [extractedProps.value];
            } else {
                lastStoreValue = [];
            }
            if (!datepickerValue.length) {
                value = extractedProps.multiSelection ? [] : null;
            } else {
                //In redux store we may want to store DD/MM/YYYY a.k.a "the old web-ui format".
                if (!extractedProps.iso8601) {
                    if (Array.isArray(value)) {
                        value = value.map((date) => fromISO_8601(date));
                    }
                }
                //In case of single selection our backend want to communicate string not array!
                if (!extractedProps.multiSelection && value) {
                    value = value[0];
                }
            }
            return {
                value,
                lastStoreValue
            };
        },
        [props]
    );

    const onInputChange = (state) => {
        if (props && props.valid === false) {
            if (props.onInputChange) {
                props.onInputChange(state);
            }
        }
    };

    const onBlur = useCallback(
        (state): void => {
            const { lastStoreValue } = getValue(state.value);
            // saveState(props, value, state);
            if (!ArrayUtils.shallowEquals(state.value, lastStoreValue)) {
                if (props.onChange) {
                    props.onChange({
                        ...state,
                        value: state.value.join(",")
                    });
                }
            }
        },
        [props, getValue]
    );

    const onOpen = useCallback(
        (state): void => {
            if (props.onOpen) {
                props.onOpen(state);
            }
        },
        [props]
    );

    const onClose = useCallback(
        (state): void => {
            if (props.onClose) {
                props.onClose(state);
            }
        },
        [props]
    );

    const datePickerProps: any = extractComponentProps(props, {
        componentProps: IDatePickerPropsKeys,
        propsDefaults: {
            format: "DD/MM/YYYY",
            locale: "fr-FR",
            value: ""
        },
        additionalProps: ["iso8601", "info"]
    });

    if (!datePickerProps.value) {
        datePickerProps.value = [];
    } else if (typeOfOperand(datePickerProps.value) !== "array") {
        datePickerProps.value = datePickerProps.value.split(",");
    }
    datePickerProps.onBlur = onBlur;
    datePickerProps.onOpen = onOpen;
    datePickerProps.onClose = onClose;
    datePickerProps.onInputChange = onInputChange;
    if (datePickerProps.label) {
        datePickerProps.labelSuffix = "(jj/mm/aaaa)";
    }

    return <Datepicker {...datePickerProps} />;
}

DatepickerContainer.displayName = "UneoRegularDatepicker"; //minification concern
