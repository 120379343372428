"use client";
import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "@tinqin/design-uneo-pf/css/modal.css";
import { tabulateArea } from "@uneo/platform-commons/platform/utils/tabulateArea";
import { showModal } from "@uneo/platform-commons/platform/utils/modalUtils";
import { findEntityById, getEntityProps } from "../../../../webui/search";
import LoaderButton from "../../Button/LoaderButton";
import { Entity } from "../../../../webui/types";
import { refreshSession } from "@uneo/platform-commons/platform/actions/refreshSession";

export default function SessionModal({ data }: { data: Entity }) {
    const modalRef = useRef<HTMLDivElement>(null);
    const sessionModalEntity = findEntityById(data, "modals.session");
    const opened = getEntityProps(sessionModalEntity, "", "opened");
    const link = findEntityById(sessionModalEntity, "link");

    useEffect(() => {
        refreshSession(true);
    }, []);

    const onKeyDown = useCallback(
        (event) => {
            if (opened) {
                const isTabPressed = event.key === "Tab";

                let tabulateBy = 1;
                if (event.shiftKey && isTabPressed) {
                    tabulateBy = -1;
                }

                if (isTabPressed) {
                    event.preventDefault();
                    tabulateArea(modalRef, tabulateBy);
                }
            }
        },
        [opened]
    );

    useEffect(() => {
        if (opened) {
            document.addEventListener("keydown", onKeyDown);
            showModal(modalRef);
        }
        return function cleanup() {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [opened, onKeyDown]);

    if (!opened) {
        return <></>;
    }
    if (opened) {
        const content = (
            <div
                ref={modalRef}
                className={`tq-modal un-ds-modal un-ds-modal-generic un-session-expired-modal`}
            >
                <div className="tq-modal-overlay" role="dialog" aria-modal="true" />
                <div className="tq-modal-container tq-modal-l">
                    <div className="tq-modal-scroll">
                        <div className="tq-modal-content">
                            <i className="un-ds-d-icon-sand-clock" />
                            <p className="tq-main-color un-ds-heading-3">Session expirée</p>
                            <p className="tq-text-color un-modal-subtitle">
                                Votre session a expiré en raison d&apos;une inactivité prolongée.
                            </p>
                            <LoaderButton
                                entity={link}
                                label={link.properties?.value || "Relancer la session"}
                                extraClasses={"un-ds-button-solid un-ds-component-sm"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
        return createPortal(content, document.body);
    }
}
