"use client";
import { useWidgetContext } from "../../../webui/widget/WidgetContext";
import React, { useEffect } from "react";
import { Entity } from "../../../webui/types";
import { createPortal } from "react-dom";
import { findEntityById, getEntityProps } from "../../../webui/search";
import Message from "../messages/Message";
import { xssFilter } from "../../utils/xssFilter";

export default function SuccessMessage({ data, isToast }: { data: Entity; isToast?: boolean }) {
    if (!data) {
        return null;
    }
    const messages = findEntityById(data, "success-messages");
    const messageType = getEntityProps(data, "success-messages", "type");
    if (!messages || !messages.entities) {
        return <></>;
    }
    if (isToast) {
        return <ToastMessages data={messages} />;
    } else {
        return <Message type={messageType || "success"} multipleMessagesEntity={messages} />;
    }
}

const ToastMessages = ({ data }) => {
    const { triggerActions } = useWidgetContext();
    useEffect(() => {
        if (data.entities.length) {
            let shouldExecute = true;
            const timer = setTimeout(() => {
                if (shouldExecute) {
                    clearTimeout(timer);
                    shouldExecute = false;
                    triggerActions([
                        { type: "removeMessage", targets: data.entities?.map((i) => i.id) }
                    ]);
                }
            }, 3000);
            return () => {
                shouldExecute = false;
                clearTimeout(timer);
            };
        }
    }, [data, triggerActions]);
    return (
        <>
            {createPortal(
                <div className="tq-float-alert-container un-ds-float-alert-container tq-show">
                    {data.entities?.map((item, index) => (
                        <SingleToastMessage data={item} key={index}></SingleToastMessage>
                    ))}
                </div>,
                document.body
            )}
        </>
    );
};

function SingleToastMessage({ data }: { data: Entity }) {
    const { triggerActions } = useWidgetContext();
    const text = data?.properties?.value;
    if (!text) {
        return <></>;
    }
    const containsParagraph = /(<p[^>]+?>|<p>|<\/p>)/.test(text);
    const xssFilteredText = xssFilter.process(text);
    return (
        <div className="tq-alert tq-alert-success tq-alert-dark tq-new-alert tq-expanded">
            <div className="tq-alert-icon">
                <i className="tq-o-icon-check" />
            </div>
            <div className="tq-alert-content">
                <div className="tq-fixed-container">
                    <div className="tq-fixed-top">
                        <h3 className="tq-sub-title">
                            {containsParagraph && (
                                <div
                                    className="tq-text-node"
                                    dangerouslySetInnerHTML={{
                                        __html: xssFilteredText
                                    }}
                                ></div>
                            )}
                            {!containsParagraph && (
                                <span
                                    className="tq-text-node"
                                    dangerouslySetInnerHTML={{
                                        __html: xssFilteredText
                                    }}
                                ></span>
                            )}
                        </h3>
                    </div>
                </div>
            </div>
            <i
                role="button"
                className="tq-alert-close tq-icon-close tq-clickable"
                onClick={() => triggerActions([{ type: "removeMessage", targets: [data.id] }])}
            />
        </div>
    );
}
