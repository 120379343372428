import React from "react";
import { getEntityProps } from "@uneo/platform-commons/webui/search";
import { Entity } from "@uneo/platform-commons/webui/types";

const STEP_STATUS_ACTIVE = "active";
const STEP_STATUS_DONE = "done";
const STEP_STATUS_INACTIVE = "inactive";

export default function SummarySteps({
    currentStep,
    extraClasses = "",
    steps = []
}: {
    currentStep: number;
    extraClasses?: string;
    steps?: Entity[];
}) {
    return (
        <div className={`un-flow-progress ${extraClasses}`}>
            <div className="un-ds-summary-container">
                {steps.map((step, idx) => {
                    const title = getEntityProps(step, step.id, "label");
                    const icon = getEntityProps(step, step.id, "icon");
                    //todo - review status - BE also provides status
                    return (
                        <Step
                            key={step.id}
                            title={title}
                            icon={icon}
                            status={getStatus(idx + 1, currentStep)}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function Step({ title, icon, status }) {
    return (
        <div className={`un-summary-step un-${status}`}>
            <div className="un-summary-icon">
                <i className={icon} />
            </div>
            <div className="un-summary-label">
                <p>{title}</p>
            </div>
        </div>
    );
}

function getStatus(stepIndex: number, currentStep: number) {
    if (stepIndex === currentStep) {
        return STEP_STATUS_ACTIVE;
    }
    if (stepIndex < currentStep) {
        return STEP_STATUS_DONE;
    }
    return STEP_STATUS_INACTIVE;
}
