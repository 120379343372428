import { ActionContext, Entity } from "../../webui/types";
import { getEntity } from "../../webui/store/actions";
import { validateEntity } from "./ValidateEntity";
import { ValidationResult } from "./interfaces";

export const validateTargets = (
    targets: string[] = [],
    context: ActionContext
): ValidationResult => {
    const entities: Entity[] = [];
    targets.forEach((source) => {
        const foundEntity = getEntity({
            widgetId: context.widgetId,
            entityPath: source,
            currentEntityPath: context.currentEntityPath
        });
        if (foundEntity) {
            entities.push(foundEntity);
        } else {
            console.log("Entity for validation not found:", source);
        }
    });
    return Object.assign({}, ...entities.map(validateEntity));
};
