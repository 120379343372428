"use-client";
import { useEffect, useState } from "react";
const URL_SCROLL_MAP: string = "scrollMap";

interface IScrollMap {
    [key: string]: number;
}
let initializedScrollPositionManagement = false;

const getUrl = (): string => {
    if (typeof window !== "undefined") {
        //Skip query params
        return (window.location.origin + window.location.pathname).replace(/\/$/, "");
    }
    return "window not defined";
};
//keeps the last visited url string
let lastVisitedUrl: string = getUrl();
//List with urls - "true" should be scrolled, "false" if the url is clicked from link and should not be scrolled - stays at the top
const urlsScrollList: Record<string, boolean> = {};

export const updateUrlsScrollList = () => {
    //Current page url if clicked from link should not scrolled
    const url = getUrl();
    urlsScrollList[url] = false;
    lastVisitedUrl = url;
    //Default behavior because of IOS issue for the current page
    scrollPageTo(-1);
};

//This hook runs only once for all instances
export const useManageScrollPosition = (hasData: boolean, isLoading: boolean) => {
    const [initialLoadingStarted, setInitialLoadingStarted] = useState(isLoading);
    useEffect(() => {
        if (!initializedScrollPositionManagement) {
            initializedScrollPositionManagement = true;
            //Manage scroll position on change url manually
            window.history.scrollRestoration = "manual";

            //In case we go to a page and scroll, but instead of click on some link we go back
            //As a result the url is changed first, so we need to save the scroll for the previous url before the new one is loaded
            //The first event that happens is the "popstate" - so save the scroll position for the previous url in the event listener
            const handleSaveScrollOnPopstate = () => {
                //save scrollY for previous url before url is changed
                const scrollMap: IScrollMap = getScrollMap();
                //save previous url scrollY
                scrollMap[lastVisitedUrl] = window.scrollY;
                //set previous url to be scrolled
                urlsScrollList[lastVisitedUrl] = true;
                lastVisitedUrl = getUrl();
                //Save new data to sessionStorage
                window.sessionStorage.setItem(URL_SCROLL_MAP, JSON.stringify(scrollMap));
            };
            window.addEventListener("popstate", handleSaveScrollOnPopstate);
        }
    }, []);

    useEffect(() => {
        //To ensure the content is rendered we use check start loading and loading ended
        if (hasData && !initialLoadingStarted && isLoading) {
            setInitialLoadingStarted(true);
        }
        if (hasData && initialLoadingStarted && !isLoading) {
            //As the content is rendered call scroll restoration
            const url: string = getUrl(); //remove trailing slash if any
            const scrollMap: IScrollMap = getScrollMap();
            if (urlsScrollList[url] && scrollMap[url]) {
                //Scroll to previously remembered position
                scrollPageTo(scrollMap[url]);
            }
        }
    }, [hasData, isLoading, initialLoadingStarted]);
};

//On screen load save url with the scrollY position
export const saveScrollPositionForCurrentUrl = () => {
    const url: string = getUrl();
    if (typeof window !== "undefined" && window.sessionStorage) {
        const scrollMap: IScrollMap = getScrollMap();
        //save scrollY for current url
        scrollMap[url] = window.scrollY;
        //set url to be scrolled
        urlsScrollList[url] = true;
        //Save new data to sessionStorage
        window.sessionStorage.setItem(URL_SCROLL_MAP, JSON.stringify(scrollMap));
    }
};

const getScrollMap = (): IScrollMap => {
    const scrollMapString = window.sessionStorage.getItem(URL_SCROLL_MAP);
    return scrollMapString ? JSON.parse(scrollMapString) : {};
};

//Scrolling page to top
const scrollPageTo = (top: number) => {
    //issue with IOS scrolling
    setTimeout(() => {
        window.scroll({ top, left: 0, behavior: "smooth" });
    }, 10);
};
