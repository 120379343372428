"use client";
import React, { useState, useEffect, useRef } from "react";
import Labeled from "@uneo/platform-commons/platform/components/form-elements/Labeled/Labeled";
import { getLabelProps } from "@uneo/platform-commons/platform/utils/utils";
import { ValidationTooltip } from "./ValidationTooltip";
import Popover from "../../popover/Popover";
import {
    IEventHandlers,
    ILabeledProps,
    ILabeledPropsKeys,
    IEventHandlersKeys,
    IBaseComponentPropsKeys
} from "@uneo/platform-commons/platform/types";

interface IInputProps extends ILabeledProps, IEventHandlers {
    password?: boolean;
    placeholder?: string;
    name?: string;
    value?: string;
    maxCharacters?: number;
    validateOnChange: boolean; // do we use it ???
    preventInvalidInput?: boolean;
    locales?: string[];
    preventPaste?: boolean;
    upperCase?: boolean;
    passwordToggle?: boolean;
    validationTooltip?: boolean;
    validationTooltipTitle?: string;
    validations?: any[];
    popoverClasses?: string;
    autocomplete?: string;
    disabled?: boolean;
    onPaste?: (...args: any[]) => any | void;
    failedValidations?: Record<string, any>;
}

interface IStateProps {
    value?: string;
    valid?: boolean | undefined;
    failedValidations?: Record<string, any>;
    showValidationTooltip?: boolean;
}

interface IValidationOptions {
    locales?: string[];
    validationType: string;
}

const Input: React.FC<IInputProps> = (props) => {
    const [value, setValue] = useState<string>(props.value || "");
    const [valid, setValid] = useState<boolean | undefined>(props.valid);
    const [failedValidations, setFailedValidations] = useState<Record<any, any>>(
        props.failedValidations || {}
    );
    const [showValidationTooltip, setShowValidationTooltip] = useState<boolean>(false);
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const labeledRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (props.valid !== valid) {
            setValid(props.valid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.valid]);

    useEffect(() => {
        const stateToSet: IStateProps = {};
        if (props.value !== value) {
            stateToSet.value = props.value || "";
            stateToSet.failedValidations = {};
        }
        if (props.value === "" && props.valid !== false) {
            stateToSet.failedValidations = {};
        }
        if ("value" in stateToSet) {
            setValue(stateToSet.value || "");
        }
        if ("failedValidations" in stateToSet) {
            setFailedValidations(stateToSet.failedValidations || {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useEffect(() => {
        if (props.valid !== valid) {
            setValid(props.valid);
        }
    }, [props.valid, valid]);

    useEffect(() => {
        if (!props.value && inputRef?.current?.value) {
            // check if the input is autofilled from the browser and propagate the input value to the component
            const autofilled = !!inputRef.current?.matches("*:-webkit-autofill");
            if (autofilled) {
                const syntheticEvent = {
                    currentTarget: { value: inputRef.current.value }
                } as React.ChangeEvent<HTMLInputElement>;
                onChange(syntheticEvent);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.value;
        const start = event.currentTarget.selectionStart;
        const end = event.currentTarget.selectionEnd;
        const validationOptions: IValidationOptions = { validationType: "soft" }; //Performing soft validation on change!
        if (props.locales) {
            validationOptions.locales = props.locales;
        }

        //clear the validations once we have changed the input value
        const newValid: boolean | undefined = undefined;
        const newFailedValidations = {};
        if (newValue !== "") {
            props.upperCase && (newValue = newValue.toUpperCase());
            //Perform front-end validation!
            const feValidationResult = { valid };
            //Manage preventInvalidInput!
            if (props.preventInvalidInput && !feValidationResult.valid) {
                return;
            }
        }

        if (value !== newValue) setValue(newValue);
        if (valid !== newValid) setValid(newValid);
        if (failedValidations !== newFailedValidations) setFailedValidations(newFailedValidations);
        if (props.onChange) {
            props.onChange({ value: newValue });
        }
        //manage manually the cursor position since toUpperCase() transforms the value as a whole new one, which causes the cursor to go to the end after setState
        if (props.upperCase) {
            inputRef.current?.setSelectionRange(start, end);
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur({ value });
        }
        if (props.validationTooltip) {
            setShowValidationTooltip(false);
        }
    };

    const onFocus = () => {
        if (props.validationTooltip) {
            setShowValidationTooltip(true);
        }
        if (props.onFocus) {
            props.onFocus({ value });
        }
    };

    const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        if (props.onPaste) {
            props.onPaste(event);
        }
        // We want to prevent the user for pasting in the input filed,
        // if the preventPaste property is set to true.
        if (props.preventPaste) {
            event.preventDefault();
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        //!NB we do not set state here, because onChange will do that. onChange is always triggered when the value is changed!
        if (props.onKeyDown) {
            props.onKeyDown(event.key, { value: newValue }, event);
        }
    };

    const togglePasswordVisible = () => {
        setPasswordVisible(!passwordVisible);
    };

    //Form label props
    const labelProps = getLabelProps(props, {
        valid: props.valid,
        valueContainerClass: "tq-text-input"
    });

    const valueToDisplay = props.password ? undefined : value;
    const inputType = props.password && !passwordVisible ? "password" : "text";

    return (
        <Labeled {...labelProps} labeledRef={labeledRef}>
            <input
                type={inputType}
                onFocus={onFocus}
                onChange={onChange}
                onPaste={onPaste}
                maxLength={props.maxCharacters}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className="tq-input"
                value={valueToDisplay}
                placeholder={props.placeholder}
                disabled={props.disabled}
                ref={inputRef}
                name={props.name}
                autoComplete={props.autocomplete}
            />
            {props.validationTooltip && (
                <Popover
                    extraClasses={props.popoverClasses || "checklist-popover"}
                    preferredPosition="top-left"
                    anchor={inputRef?.current as HTMLElement}
                    active={showValidationTooltip}
                    minWidthToAnchor
                    maxWidthToAnchor
                    arrow
                >
                    <ValidationTooltip
                        validations={props.validations}
                        value={value}
                        title={props.validationTooltipTitle}
                    />
                </Popover>
            )}
            {props.passwordToggle && (
                <div className="tq-feedback-container">
                    <div className="tq-input-feedback" onClick={togglePasswordVisible}>
                        <i
                            tabIndex={0}
                            className={`tq-feedback-icon tq-icon-circle-info ${
                                passwordVisible ? "hide-pass" : "show-pass"
                            }`}
                        />
                    </div>
                </div>
            )}
        </Labeled>
    );
};

export default Input;

export const IInputPropsKeys = [
    ...IEventHandlersKeys,
    ...IBaseComponentPropsKeys,
    ...ILabeledPropsKeys,
    "placeholder",
    "maxCharacters",
    "preventInvalidInput",
    "validationTooltip",
    "validationTooltipTitle",
    "validations",
    "upperCase",
    "preventPaste",
    "passwordToggle",
    "password",
    "popoverClasses",
    "autocomplete",
    "disabled",
    "name",
    "onPaste"
];
