import React from "react";
import IbanInput, { IbanInputPropKeys } from "./IbanInput";
import { extractComponentProps } from "@uneo/platform-commons/platform/utils/utils";

export default function IbanInputContainer(props) {
    const onBlur = (state) => {
        if (props.onBlur) {
            props.onBlur(state);
        }
    };

    const extractedProps: any = extractComponentProps(props, {
        componentProps: IbanInputPropKeys,
        propsDefaults: {
            separator: "-"
        }
    });

    const onChange = (state): void => {
        if (props.onChange) {
            props.onChange(state);
        }
    };

    extractedProps.onChange = onChange;

    return <IbanInput {...extractedProps} onBlur={onBlur} />;
}
IbanInputContainer.displayName = "UneoIbanInput";
