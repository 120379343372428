import React from "react";

export default function ProcessTitle({
    title,
    extraClasses = ""
}: {
    title: string;
    extraClasses?: string;
}) {
    if (!title) {
        return null;
    }
    return (
        <h1 className={`tq-main-color tq-title un-ds-heading-1 ${extraClasses}`}>
            <span className="tq-text-node">{title}</span>
        </h1>
    );
}
