import React from "react";
import { Entity, Widget } from "@uneo/platform-commons/webui/types";

import { useFetchWidgetData } from "@uneo/platform-commons/platform/hooks";
import { useWidgetContext } from "@uneo/platform-commons/webui/widget/WidgetContext";
import { getEntityChildren } from "@uneo/platform-commons/webui/search";
import { triggerMatomoEvent } from "@uneo/platform-commons/platform/components/MatomoTracker/MatomoTracker";
import { useFetchFeature } from "@uneo/platform-commons/platform/features";
import RadioPlayer from "./RadioPlayer";
import { Hyperlink } from "@uneo/platform-commons/platform/components";
import { createDate } from "@uneo/platform-commons/platform/utils/dateUtils";
import FacilitiButton from "@uneo/platform-commons/platform/components/Faciliti/FacilitiButton";

export default function PortalFooter({ info }: { info: Widget }) {
    const { data, error } = useFetchWidgetData(info);
    const { enabled, settings } = useFetchFeature("radio");
    if (!data) {
        return <></>;
    }
    if (error) {
        throw new Error("No data available in PortalFooter");
    }
    const leftMenuLinks = getEntityChildren(data, "left-menu.links");
    const rightMenuLinks = getEntityChildren(data, "right-menu.links");
    const bottomMenuLinks = getEntityChildren(data, "bottom-menu.links");
    const socialLinks = getEntityChildren(data, "social-links.links");

    return (
        <>
            {enabled && (
                <div className="un-skyrock-container">
                    <RadioPlayer settings={settings} />
                </div>
            )}
            <footer className="tq-footer" role={"contentinfo"}>
                <nav className="un-footer-wrapper">
                    <div className="tq-flex-container">
                        <div className="tq-flex-item">
                            <span
                                className="tq-image un-footer-logo tq-block-image"
                                role="button"
                                onClick={() =>
                                    triggerMatomoEvent("Footer", "[Footer] - Logo Unéo", "Cliquer")
                                }
                            >
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img src="/img/uneo-contextual.png" alt="Logo Unéo" />
                            </span>
                            <div
                                className="un-footer-logo-desc"
                                data-tq-id="header.footer.description"
                            >
                                <p className="tq-paragraph">Unéo, bien plus qu&apos;une mutuelle</p>
                            </div>
                        </div>
                        <div className="tq-flex-item">
                            <LinksMenu links={leftMenuLinks} extraClasses="un-list-links" />
                        </div>
                        <div className="tq-flex-item">
                            <LinksMenu links={rightMenuLinks} extraClasses="un-list-links-sm" />
                        </div>
                        <div className="tq-flex-item">
                            <div className="un-cp-footer-social">
                                <span className="tq-image un-footer-logos tq-block-image">
                                    {/* eslint-disable-next-line @next/next/no-img-element */}
                                    <img src="/img/uneo-logos.svg" alt="Reference Logos" />
                                </span>
                                <LinksMenu
                                    links={socialLinks}
                                    extraClasses="un-horizontal tq-selectable un-social"
                                    iconsFlag={true}
                                />
                                <FacilitiButton className="un-faciliti-btn-footer" />
                            </div>
                        </div>
                    </div>
                    <div className="tq-flex-container tq-text-center un-copyright-box">
                        <div className="tq-flex-item">
                            <div className="un-list-copyright">
                                <LinksMenu links={bottomMenuLinks} extraClasses="un-horizontal" />
                                <span className="" data-hyperlinks-container="true">
                                    {`Copyrights © ${createDate().getUTCFullYear()}, Unéo`}
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </footer>
        </>
    );
}

function LinksMenu({
    links,
    extraClasses = "",
    iconsFlag = false
}: {
    links: Entity[];
    extraClasses?: string;
    iconsFlag?: boolean;
}) {
    const { triggerEntityActions, widgetId } = useWidgetContext();
    if (!links.length) {
        return <></>;
    }
    return (
        <ul className={"tq-list-new " + extraClasses}>
            {links.map((link) => {
                let content = <LinkText value={link.properties?.value} />;
                if (iconsFlag) {
                    content = <LinkIcon value={link.properties?.value} />;
                }
                return (
                    <li key={link.id}>
                        <Hyperlink
                            entity={link}
                            aria-label={link.properties?.value}
                            onClick={() => {
                                triggerMatomoEvent(
                                    "Footer",
                                    `[Footer] - ${link.properties?.value}`,
                                    "Cliquer"
                                );
                            }}
                        >
                            {content}
                        </Hyperlink>
                    </li>
                );
            })}
        </ul>
    );
}

function LinkText({ value }: { value: string }) {
    return <span className="tq-text-node">{value}</span>;
}

function LinkIcon({ value }: { value: string }) {
    if (value === "twitter") {
        return <i className="un-ds-s-icon-twitter"></i>;
    }
    return <i className={"tq-icon-" + value}></i>;
}
