"use client";
import React, { ReactNode, useEffect, useState } from "react";

export default function ApplicationLoadingAnimation({
    isLoading,
    children,
    minLoadingTime = 500
}: {
    isLoading: boolean;
    children: ReactNode | ReactNode[];
    minLoadingTime?: number; // Minimum loading time in milliseconds
}) {
    const [showPreloader, setShowPreloader] = useState(false);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if (isLoading) {
            // If isLoading is true, set a timeout to show the preloader
            timeoutId = setTimeout(() => {
                setShowPreloader(true);
            }, minLoadingTime);
        } else {
            // If isLoading becomes false before the timeout, clear the timeout
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setShowPreloader(false);
        }

        return () => {
            // Cleanup: Clear the timeout when the component unmounts
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isLoading, minLoadingTime]);

    return (
        <div
            className={
                "un-ds-preloader-container un-global-area-preloader " +
                (showPreloader ? "tq-preloader-container" : "")
            }
        >
            <div className="tq-preloader-mask tq-load"></div>
            <div className="tq-preloader-item tq-load">
                <div className="un-preloader-main">
                    <div className="un-preloader-dot"></div>
                    <div className="un-preloader-dot"></div>
                    <div className="un-preloader-dot"></div>
                </div>
            </div>
            {children}
        </div>
    );
}
